import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getWorkspaces,
    getUserWorkspaces,
} from '../../../core/modules/workspaces/actions';
import {
    ListItemIcon,
    ListItem,
    ListItemText,
    Avatar,
    Tooltip
} from '@material-ui/core';
import {
    Home as HomeIcon
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { route, ROUTE_WORKSPACE_CLIENTS } from '../../../core/routing/routing';
import { Permissions, Roles } from 'core/modules/auth/constants';
import clsx from 'clsx';
import { getCurrentWorkspaces, selectCurrentWorkspace } from 'core/modules/workspaces/selectors';

const styles = theme => ({
    workspaceClose: {
        height: '84px',
        display: 'block',
        textAlign: 'center',
    },
    itemIcon: {
        minWidth: 'auto',
        color: 'white',
        width: 30,
        height: 'auto',
        marginRight: 16,
    },
    itemIconClose: {},
    itemIconImg: {
        width: 30,
        height: 'auto',
    },
    itemText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '24px !important',
        fontWeight: 'bold !important',
        color: theme.palette.common.white,
    },
    listItem: {
        flex: '0',
        padding: '12px 10px 12px 16px',
        width: '100%',
        color: 'white',
        whiteSpace: 'nowrap',
        borderBottom: '1px solid #404854',
        display: 'flex',
        '&:hover': {
            background: 'rgba(255, 255, 255, .08)',
        },
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    sideMenu: {
        minWidth: 'auto',
        marginLeft: 10,
    },
    sideMenuClose: {
        marginLeft: 0,
    },
    logoClose: {
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    workspaceListItem: {
        flex: 'none'
    }
});

const WorkspaceListItem = ({ classes, open }) => {
    const dispatch = useDispatch();
    const workspaceLocalstorage = useSelector(state => state.app.workspace);
    const workspaceRoles = useSelector(
        state => state.auth.user.workspace_roles
    );
    const workspacesUnique = useSelector(state => getCurrentWorkspaces(state));
    const currentWorkspace = useSelector(state => selectCurrentWorkspace(state));

    const isSuperAdmin = useSelector(
        state =>
            state.auth.user.role === Roles.Admin &&
            state.auth.user.permission === Permissions.Owner
    );

    useEffect(() => {
        if (!workspacesUnique && isSuperAdmin) {
            dispatch(getWorkspaces());
        } else if (!workspacesUnique && !!workspaceRoles[workspaceLocalstorage]) {
            dispatch(getUserWorkspaces());
        }
    }, [
        dispatch,
        workspacesUnique,
        isSuperAdmin,
        workspaceRoles,
        workspaceLocalstorage,
    ]);

    return workspacesUnique && !!currentWorkspace ? (
        <Tooltip
            placement="right"
            title={currentWorkspace.name}
            disableHoverListener={open}
            disableFocusListener={open}
            disableTouchListener={open}
            key={workspaceLocalstorage}
            className={classes.workspaceListItem}>
            <ListItem
                className={clsx(
                    `${classes.listItem} ${classes.item} ${classes.itemCategory}`,
                    {
                        [classes.logoOpen]: open,
                        [classes.logoClose]: !open,
                    }
                )}
                component={NavLink}
                activeClassName={classes.itemActiveItem}
                to={route(ROUTE_WORKSPACE_CLIENTS, { workspaceId: currentWorkspace.id })}>
                <ListItemIcon
                    className={clsx(classes.itemIcon, {
                        [classes.itemIconOpen]: open,
                        [classes.itemIconClose]: !open,
                    })}>
                    <>
                        {
                            !!currentWorkspace ?
                                <Avatar
                                    key={currentWorkspace.id}
                                    variant="rounded"
                                    className={classes.itemIconImg}
                                >
                                    {
                                        !!currentWorkspace.image_url ?
                                            <img src={currentWorkspace.image_url} alt={currentWorkspace.id} className={classes.itemIconImg} /> :
                                            <HomeIcon key={currentWorkspace.id} />
                                    }
                                </Avatar>
                                : ""
                        }
                    </>
                </ListItemIcon>
                <ListItemText
                    disableTypography={true}
                    className={classes.itemText}>
                    {currentWorkspace.name}
                </ListItemText>
            </ListItem>
        </Tooltip>
    ) : null;
};

export default withStyles(styles)(WorkspaceListItem);
