import Alert from '@material-ui/lab/Alert';
import Progress from '../progress/Progress';
import React from 'react';

const BaseFormContainer = ({ isLoading, error, initialData, render }) => {
    return (
        <>
            {error && <Alert severity="error">{error}</Alert>}
            {isLoading ? (
                <Progress isLoading={isLoading} />
            ) : initialData ? (
                render()
            ) : null}
        </>
    );
};

export default BaseFormContainer;
