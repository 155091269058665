import NumberComponent from 'react-number-format';
import { useSelector } from 'react-redux';

export const DecimalSeparators = Object.freeze({
    Point: '.',
    Comma: ',',
});

export const ThousandSeparator = Object.freeze({
    Point: '.',
    Comma: ',',
    Space: ' ',
});

const NumberFormat = ({ currency, number }) => {
    const decimal_seperator = useSelector(state => state.auth.user.preferences?.decimal_seperator || null)
    const thousand_seperator = useSelector(state => state.auth.user.preferences?.thousand_seperator || null)
    const thousandSeparator = ThousandSeparator[thousand_seperator] || ThousandSeparator.Point;
    const decimalSeparator = DecimalSeparators[decimal_seperator] || DecimalSeparators.Comma;

    return (
        <NumberComponent
            decimalSeparator={decimalSeparator}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={thousandSeparator}
            prefix={!!currency ? currency + " " : false}
            displayType={'text'}
            value={number}
        />
    );
}

export default NumberFormat;