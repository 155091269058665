import {
    TableRow,
    TableCell
} from '@material-ui/core';
import {
    selectTotal,
    selectVatRates,
    selectMargin,
} from '../../../../../core/modules/accounting/invoicing/selectors';
import React from 'react';
import { MarginLevels } from '../../../../../core/modules/accounting/invoicing/constants';
import { t } from 'core/i18n';
import { withStyles } from '@material-ui/core/styles';
import { InvoicingStatus } from '../../../../../core/modules/accounting/invoicing/constants';
import NumberFormat from 'components/shared/form/generic/NumberFormat';

const styles = () => ({
    red: {
        color: "red",
    },
    green: {
        color: "green"
    },
    orange: {
        color: "orange"
    }
});

const OverviewRows = ({ items, currency, classes, status }) => {
    const margin = selectMargin(items);

    return (
        <>
            <TableRow>
                <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                    {t('invoicing.overview.subtotal')}
                </TableCell>
                <TableCell
                    align="right"
                    style={{
                        fontVariantNumeric: 'tabular-nums',
                        fontWeight: 'bold',
                    }}>
                    <NumberFormat currency={currency} number={selectTotal(items)} />
                </TableCell>
                <TableCell />
            </TableRow>
            {selectVatRates(items).map(rate => (
                <TableRow key={`vat_${rate.rate}`}>
                    <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>
                        {t('invoicing.overview.vat')}
                    </TableCell>
                    <TableCell
                        style={{
                            fontVariantNumeric: 'tabular-nums',
                        }}>{`${rate.rate}%`}</TableCell>
                    <TableCell
                        align="right"
                        style={{ fontVariantNumeric: 'tabular-nums' }}>
                        {'('}<NumberFormat currency={currency} number={rate.subTotal} />{') '}<NumberFormat currency={currency} number={rate.value} />
                    </TableCell>
                    <TableCell />
                </TableRow>
            ))}
            <TableRow>
                <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                    {t('invoicing.overview.total')}
                </TableCell>
                <TableCell
                    align="right"
                    style={{
                        fontVariantNumeric: 'tabular-nums',
                        fontWeight: 'bold',
                    }}>
                    <NumberFormat currency={currency} number={selectTotal(items, true)} />
                </TableCell>
                <TableCell />
            </TableRow>
            {(status !== InvoicingStatus.Accepted &&
                status !== InvoicingStatus.Published &&
                margin !== null) && (
                    <TableRow>
                        <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                        <TableCell
                            colSpan={2}
                            style={{ fontWeight: 'bold' }}
                            className={
                                MarginLevels.Accepted <= margin ? classes.green :
                                    MarginLevels.Warning >= margin ? classes.red :
                                        classes.orange
                            }>
                            {t('invoicing.overview.margin')}
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{
                                fontVariantNumeric: 'tabular-nums',
                                fontWeight: 'bold',
                            }}
                            className={
                                MarginLevels.Accepted <= margin ? classes.green :
                                    MarginLevels.Warning >= margin ? classes.red :
                                        classes.orange
                            }>
                            {margin}{'%'}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                )}
        </>
    );
};

export default withStyles(styles)(OverviewRows);
