import React from 'react';
import InvoicingPdfScene from './InvoicingPdfScene';
import {
    PDFViewer
} from '@react-pdf/renderer';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const styles = () => ({
    '@global': {
        body: {
            padding: 0,
            margin: 0,
        },
    },
})

const InvoicingPdf = ({ type, item }) => {
    return (
        <Box>
            {item && (
                <>
                    <PDFViewer style={{ width: '100%', height: '100vh', borderWidth: 0 }}>
                        <InvoicingPdfScene
                            type={type}
                            item={item} />
                    </PDFViewer>
                </>
            )}
        </Box>
    )

}

export default withStyles(styles)(InvoicingPdf);