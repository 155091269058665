import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        createNotification(state, action) {
            state.push({
                id: `${action.payload.type}_${new Date().getTime()}`,
                ...action.payload,
            });
        },
        removeNotification(state, action) {
            return state.filter(message => message.id !== action.payload);
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = notificationsSlice;
// Extract and export each action creator by name
export const { createNotification, removeNotification } = actions;
// Export the reducer, either as a default or named export
export default reducer;
