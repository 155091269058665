import { clearUser, setUser } from './reducer';
import { firebaseAuth, fireStore, oldRealTimeDb } from 'core/networking/firebase';
import { Collections } from '../../networking/firebase';
import {
    AuthStates,
    Roles,
    //Permissions
} from './constants';
import { t } from '../../i18n';
import firebase from 'firebase/app';
import { createNotification } from '../notifications/reducer';
import {
    route,
    history,
    ROUTE_COMPLETE_REGISTER_ON_INVITE,
    ROUTE_USER,
    ROUTE_LOGIN,
} from 'core/routing/routing';
import { setLocale } from '../app/reducer';
import * as Sentry from "@sentry/react";

const combineUserData = (account, user, privData, isFullyRegistered) => {
    // remove all "garbage" from account object and only keep fields we need
    let currentWorkspace = '';
    let currentClient = '';
    let clientRoles = {};
    let workspaceRoles = {};
    let workspacePermission = '';

    if (!!privData) {
        if (!!privData.workspace_agency?.default) {
            currentWorkspace = privData.workspace_agency.default;
        } else if (!!privData.workspace_agency?.roles) {
            currentWorkspace = Object.keys(privData.workspace_agency.roles)[0];
        } else {
            currentWorkspace = !!privData.workspace_agency
                ? privData.workspace_agency
                : '';
        }

        workspaceRoles = privData.workspace_agency?.roles || {};
        workspacePermission = !!privData.workspace_agency?.roles
            ? privData.workspace_agency?.roles[currentWorkspace]
            : '';

        if (!!privData.client) {
            currentClient = Object.keys(privData.client.roles)[0];
            clientRoles = privData.client?.roles;
        }
    }

    //console.log('account', account)
    //console.log('user', user)

    // Add user values to sentry logging
    Sentry.setUser({ email: account.email, username: account.displayName, id: account.uid });

    return {
        email: account.email,
        photoUrl: account.photoURL,
        uid: account.uid,
        emailVerified: account.emailVerified,
        role: privData?.role || Roles.Guest,
        client_roles: clientRoles,
        permission: privData?.permission || '',
        workspace_agency: currentWorkspace,
        client_id: currentClient,
        workspace_permission: workspacePermission,
        workspace_roles: workspaceRoles,
        customer: privData?.customer,
        isFullyRegistered: isFullyRegistered,
        displayName: account.displayName,
        phoneNumber: account.phoneNumber,
        providerData: account.providerData,
        lastSignIn: new Date(user.lastSignIn),
        createdAt: new Date(account.metadata.creationTime),
        ...user,
    };
};

const checkSignInMethod = async email => {
    return firebaseAuth
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then(function (signInMethods) {
            // This returns the same array as fetchProvidersForEmail but for email
            // provider identified by 'password' string, signInMethods would contain 2
            // different strings:
            // 'emailLink' if the user previously signed in with an email/link
            // 'password' if the user has a password.
            // A user could have both.
            if (
                signInMethods.indexOf(
                    firebase.auth.EmailAuthProvider
                        .EMAIL_PASSWORD_SIGN_IN_METHOD
                ) !== -1
            ) {
                // User can sign in with email/password.
                return true;
            }
            if (
                signInMethods.indexOf(
                    firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
                ) !== -1
            ) {
                // User can sign in with email/link.
                return false;
            }
            // if another (google auth)
            // return true because is verified by Google
            return true;
        })
        .catch(function (error) {
            console.log(error);
            // if another signin method (google auth)
            // return true because is verified by Google
            return true;
            // Some error occurred, you can inspect the code: error.code
        });
};

export const fetchUser = account => async dispatch => {
    const query = fireStore.collection(Collections.users);
    await query.doc(account.uid).set({ online: true }, { merge: true })

    // https://blog.campvanilla.com/firebase-firestore-guide-how-to-user-presence-online-offline-basics-66dc27f67802
    const usersRef = fireStore.collection(Collections.users);
    const onlineRef = oldRealTimeDb.ref('.info/connected');
    onlineRef.on('value', snapshot => {
        // Set the Firestore User's online status to true
        usersRef
            .doc(account.uid)
            .set({
                online: true,
            }, { merge: true });
        // Let's also create a key in our real-time database
        // The value is set to 'online'
        oldRealTimeDb.ref(`/status/${account.uid}`).set('online');

        oldRealTimeDb
            .ref(`/status/${account.uid}`)
            .onDisconnect() // Set up the disconnect hook
            .set('offline') // The value to be set for this key when the client disconnects
            .then(() => {
                // Set the Firestore User's online status to true
                usersRef
                    .doc(account.uid)
                    .set({
                        online: true,
                    }, { merge: true });

                // Let's also create a key in our real-time database
                // The value is set to 'online'
                oldRealTimeDb.ref(`/status/${account.uid}`).set('online');
            });
    });

    const doc = await fireStore
        .collection(Collections.users)
        .doc(account.uid)
        .get();

    const privData = await fireStore
        .collection(Collections.users)
        .doc(account.uid)
        .collection(Collections.private_data)
        .doc('private')
        .get()
        .then(doc => {
            if (doc.exists) {
                return doc.data();
            }
            return [];
        });

    const isFullyRegistered = await checkSignInMethod(account.email);
    /*let claims = null;

    await firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(idTokenResult => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims) {
                // Show admin UI.
                claims = idTokenResult.claims;
            }
        })
        .catch(error => {
            console.log(error);
        });*/

    if (doc.exists) {
        const user = combineUserData(
            account,
            doc.data(),
            privData,
            isFullyRegistered
        );
        // save to store
        dispatch(setUser(user));
        if (!!user.preferences?.default_language) {
            dispatch(setLocale(user.preferences?.default_language));
        }
        return user;
    } else {
        return Promise.reject(new Error(t('errors.user_not_found')));
    }
};

export const sendEmailVerification = () => async dispatch => {
    const user = firebaseAuth.auth().currentUser;
    const sendEmailVerificationLink = firebaseAuth
        .functions()
        .httpsCallable('sendEmailVerificationLink');
    return await sendEmailVerificationLink({
        email: user.email,
    })
        .then(function (result) {
            // Read result of the Cloud Function.
            console.log(result)
            if (!!result.data) {
                //console.log(result.data.email);
                return result.data;
            }
        })
        .catch(function (error) {
            // Getting the Error details.
            return console.log(error);
            /*var code = error.code;
        var message = error.message;
        var details = error.details;*/
            // ...
        });
};

const createNewUser = async (uid, data, userRole) => {
    // create user data
    await fireStore
        .collection(Collections.users)
        .doc(uid)
        .set(
            {
                ...data,
            },
            { merge: true }
        );

    return await fireStore
        .collection(Collections.users)
        .doc(uid)
        .collection(Collections.private_data)
        .doc('private')
        .set(
            {
                role: userRole || Roles.Guest,
            },
            { merge: true }
        );
};

export const registerAccount = data => async dispatch => {
    try {
        // create user authentication
        const auth = await firebaseAuth
            .auth()
            .createUserWithEmailAndPassword(data.email, data.password);

        // create user data
        await createNewUser(auth.user.uid, {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            isFullyRegistered: true,
            lastSignIn: auth.user.metadata.lastSignInTime,
            createdAt: auth.user.metadata.creationTime,
        });

        // get user
        const doc = await fireStore
            .collection(Collections.users)
            .doc(auth.user.uid)
            .get();
        // combine both
        const user = combineUserData(auth.user, doc.data());
        // even if this fails, the user can always trigger it himself, so ignore errors for now
        dispatch(sendEmailVerification());
        // save to store
        dispatch(setUser(user));

        return user;
    } catch (error) {
        // logout again if failed
        dispatch(logout());
        return Promise.reject(error);
    }
};

export const setupAuth = () => async (dispatch, getState) => {
    await firebaseAuth.auth().onAuthStateChanged(user => {
        if (getState().auth.status === AuthStates.Idle) {
            if (user !== null) {
                // only when idle, when logged out register/login/... will fetch user
                dispatch(fetchUser(user))
                    /*.then(async () => {
                        onAuthChangeCloudFunction(user, `user.${getState().auth.status}`);
                    })*/
                    .catch(err => {
                        console.log(err);
                        dispatch(clearUser());
                    });
            } else {
                dispatch(clearUser());
            }
        }
    });
};

export const loginWithCredentials = (email, password) => async (dispatch, getState) => {
    const account = await firebaseAuth
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(response => {
            return response.user
        })
        .catch(error => {
            // set user
            dispatch(clearUser());
            return Promise.reject(error);
        });


    try {
        await dispatch(fetchUser(account));
        //onAuthChangeCloudFunction(account.uid, `user.${getState().auth.status}`);
        return history.push(route(ROUTE_USER));
    } catch (err) {
        // set user
        console.log(err)
        dispatch(clearUser());
        return Promise.reject(account.error);
    }
};

export const loginWithGoogle = () => async (dispatch, getState) => {
    try {
        const account = await firebaseAuth
            .auth()
            .signInWithPopup(new firebase.auth.GoogleAuthProvider())
            .then(response => {
                //console.log("response.user", response.user)
                return response.user
            })

        try {
            await dispatch(fetchUser(account));
            //console.log("account", account)
            //onAuthChangeCloudFunction(account.uid, `user.${getState().auth.status}`);
        } catch (err) {
            //console.log("error google1", err)
            if (err.code !== 'auth/account-exists-with-different-credential') {
                //console.log("New user")
                // user does not exist yet, so create
                await createNewUser(account.uid, {
                    full_name: account.displayName,
                    avatar: account.photoURL,
                    email: account.email,
                });
            }

            // now download user
            await dispatch(fetchUser(account));
        }
    } catch (error) {
        //console.log("error google2", error)
        // set user
        dispatch(clearUser());
        return Promise.reject(error);
    }
};

export const logout = () => dispatch => {
    return firebaseAuth
        .auth()
        .signOut()
        .then(() => {
            localStorage.clear();
            dispatch(clearUser());
            return history.push(route(ROUTE_LOGIN));
        })
        .catch(error => {
            window.alert(error);
        });
};

/*export const onAuthChangeCloudFunction = (user, authState) => {
    const onAuthChange = firebaseAuth
        .functions()
        .httpsCallable('onAuthChange');
    return onAuthChange({
        uid: user.uid,
        authState
    }).then(() => {
        return;
    })
};*/

export const signInWithEmailLink = async email => {
    const sendSignInLinkToEmail = firebaseAuth
        .functions()
        .httpsCallable('sendSignInLinkToEmail');
    return await sendSignInLinkToEmail({
        email: email,
    })
        .then(function (result) {
            // Read result of the Cloud Function.
            if (!!result.data) {
                //console.log(result.data.email);
                return result.data;
            }
        })
        .catch(function (error) {
            // Getting the Error details.
            return console.log(error);
            /*var code = error.code;
        var message = error.message;
        var details = error.details;*/
            // ...
        });

    /*const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `${process.env.REACT_APP_URL}/register-on-invite`,
        // This must be true.
        handleCodeInApp: true,
        //dynamicLinkDomain: 'example.page.link'
    };*/

    /*return firebaseAuth.auth().sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function (res) {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            console.log('email succesfully sent')
        })
        .catch(function (error) {
            console.log(error)
            // Some error occurred, you can inspect the code: error.code
        });*/
};

export const userLinkWithCredential = data => async (dispatch, getState) => {
    const user = firebaseAuth.auth().currentUser;

    return user
        .updatePassword(data.password)
        .then(async res => {
            // Update successful.
            await createNewUser(
                user.uid,
                {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: user.email,
                },
                Roles.User
            );
            await dispatch(fetchUser(firebaseAuth.auth().currentUser));
            return history.push(route(ROUTE_USER));
        })
        .catch(error => {
            return error;
            // An error happened.
        });
};

export const passwordReset = email => async dispatch => {
    const sendPasswordResetLinkEmail = firebaseAuth
        .functions()
        .httpsCallable('sendPasswordResetEmail');
    return await sendPasswordResetLinkEmail({
        email: email,
    })
        .then(function (result) {
            // Read result of the Cloud Function.
            if (!!result.data) {
                //console.log(result.data.email);
                console.log(result.data);
            }

            return dispatch(
                createNotification({
                    type: 'success',
                    message: `${t(
                        'onboarding.password_reset.email_sent'
                    )} ${email}`,
                })
            );
        })
        .catch(function (error) {
            // Getting the Error details.
            console.log(error);
            /*var code = error.code;
        var message = error.message;
        var details = error.details;*/
            // ...
            return dispatch(
                createNotification({
                    type: 'error',
                    message: error.message,
                })
            );
        });
};

export const userManagementActions = (
    mode,
    oobCode,
    apiKey,
    lang,
    newPassword
) => async dispatch => {
    // Handle the user management action.
    switch (mode) {
        case 'resetPassword':
            // Display reset password handler and UI.
            return await handleResetPassword(oobCode, lang, newPassword);
        /*case 'recoverEmail':
            // Display email recovery handler and UI.
            handleRecoverEmail(firebaseAuth, oobCode, lang);
            break;*/
        case 'verifyEmail':
            // Display email verification handler and UI.
            return dispatch(handleVerifyEmail(oobCode, lang));
        default:
        // Error: invalid mode.
    }

    return;
};

const handleResetPassword = async oobCode => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Verify the password reset code is valid.
    return await firebaseAuth.auth().verifyPasswordResetCode(oobCode);
};

export const handleConfirmEmail = email => async dispatch => {
    // Confirm the link is a sign-in with email link.
    if (firebaseAuth.auth().isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        //var email = window.localStorage.getItem('emailForSignIn');
        /*if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation');
        }*/
        // The client SDK will parse the code from the link for you.
        return firebase
            .auth()
            .signInWithEmailLink(email, window.location.href)
            .then(async result => {
                const account = result.user;
                // Clear email from storage.
                //window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
                // create user data
                if (result.additionalUserInfo.isNewUser) {
                    await createNewUser(
                        result.user.uid,
                        {
                            email: email,
                        },
                        Roles.User
                    ).then(async () => {
                        return await dispatch(fetchUser(account)).then(user => {
                            if (!!user) {
                                history.push(
                                    route(ROUTE_COMPLETE_REGISTER_ON_INVITE)
                                );
                            }
                        });
                    });
                    // create user data
                    /*await fireStore
                        .collection(Collections.users)
                        .doc(result.user.uid)
                        .set({
                            email: email,
                        }, { merge: true });*/
                } else {
                    // now download user
                    return await dispatch(fetchUser(result.user)).then(user => {
                        if (!!user) {
                            history.push(
                                route(ROUTE_COMPLETE_REGISTER_ON_INVITE)
                            );
                        }
                    });
                }
            })
            .catch(function (error) {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                return error;
            });
    }
};

export const handleConfirmPasswordReset = (
    resetEmail,
    oobCode,
    newPassword
) => async dispatch => {
    // Save the new password.
    firebaseAuth
        .auth()
        .confirmPasswordReset(oobCode, newPassword)
        .then(function (resp) {
            // Password reset has been confirmed and new password updated.

            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            return dispatch(loginWithCredentials(resetEmail, newPassword));

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
        })
        .catch(function (error) {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            return dispatch(
                createNotification({
                    type: 'error',
                    message: error.message,
                })
            );
        });
};

const handleVerifyEmail = (oobCode, lang) => async dispatch => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.

    try {
        await firebaseAuth.auth().applyActionCode(oobCode);

        return dispatch(
            createNotification({
                type: 'success',
                message: `${t(
                    'onboarding.user_management.actions.verify_email.success'
                )}`,
            })
        );
    } catch (error) {
        return error;
    }
    /*.then(function (resp) {
        // Email address has been verified.
 
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        console.log(resp)
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
    }).catch(function (error) {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        console.log(error)
    });*/
};
