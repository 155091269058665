import React, { useMemo, useState, useCallback } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Card,
    CardActions,
    CardContent,
} from '@material-ui/core';
import Progress from 'components/shared/progress/Progress';
import {
    getClientBillingAccountById,
    storeBillingAccount,
} from '../../../../../../core/modules/dashboard/clients/billing-accounts/actions';
import BaseFormContainer from '../../../../../shared/form/BaseFormContainer';
import ShippingAddressFields from '../../../../../shared/form/address/ShippingAddressFields';
import { useForm } from '../../../../../../core/hooks/useForm';
import {
    Edit as EditIcon,
    DeleteForever as DeleteForeverIcon,
    ArrowUpward as ArrowUpwardIcon
} from '@material-ui/icons';
import DeleteDialog from '../../../../../shared/dialog/DeleteDialog';
import { useParams } from 'react-router-dom';

const Schema = yup.object().shape({
    /*shipping_address: yup.object().shape({
        street: yup.string().required(),
        number: yup.string().required(),
        country: yup.string().required(),
    }),*/
});

const styles = () => ({
    paper: {
        marginBottom: 30,
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
});

const prepareData = original => {
    const data = { ...original };

    if (!!data.client) {
        if (!!data.client.id) {
            return data;
        } else {
            data.client_id = data.client.id;
        }
    }
    return data;
};

const ShippingAddressFormContainer = ({
    classes,
}) => {
    const { billingId, clientId } = useParams();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [addressIndex, setAddressIndex] = useState(0);
    const [, updateState] = useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const config = useMemo(
        () => ({
            fetch: () => getClientBillingAccountById(billingId, clientId),
            store: values => storeBillingAccount(prepareData(values)),
            defaultValue: {
                shipping_address: {

                },
                client: {
                    id: !!clientId ? clientId : '',
                },
            },
            id: billingId,
            onSuccess: handleClose
        }),
        [billingId, clientId]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    const addAddress = helper => {
        if (!!initialData.shipping_address && initialData.shipping_address.length) {
            setAddressIndex(initialData.shipping_address.length);
        }
        setOpen(true);
    };

    const setDefault = useCallback((index) => {
        initialData.shipping_address.unshift(initialData.shipping_address.splice(index, 1)[0]);
        dispatch(storeBillingAccount(prepareData(initialData)))
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                updateState({})
            });
    }, [dispatch, initialData]);

    const [deleteIndex, setDeleteIndex] = useState(null);
    const handleConfirmDelete = () => {
        const values = { ...initialData }
        // remove shipping address based on index
        values.shipping_address.splice(deleteIndex, 1);
        dispatch(storeBillingAccount(prepareData(values)))
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setDeleteIndex(null);
            });
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                <Typography variant="h5">
                    {t('clients.billing_accounts.fields.shipping_addresses')}
                </Typography>
                {!!initialData && (
                    <>
                        <BaseFormContainer
                            isLoading={isLoading}
                            error={error}
                            initialData={initialData}
                            render={() => (
                                <Formik
                                    validationSchema={Schema}
                                    validateOnChange={false}
                                    initialValues={initialData}
                                    onSubmit={onSubmit}>
                                    {({ errors, isSubmitting }) => (
                                        <>
                                            {!!initialData.shipping_address && (
                                                (!!initialData.shipping_address && initialData.shipping_address.length) && (

                                                    <FieldArray
                                                        name="shipping_address"
                                                        render={helper => (
                                                            <Box>
                                                                {initialData.shipping_address.map((field, index) => (
                                                                    <Card
                                                                        className={classes.paper}
                                                                        key={index}>
                                                                        {!!initialData.shipping_address && (
                                                                            <CardContent>
                                                                                <Box>
                                                                                    <Typography variant="body1">
                                                                                        {initialData.shipping_address[index]?.contact_name}
                                                                                    </Typography>
                                                                                    <Typography variant="body1">
                                                                                        {initialData.shipping_address[index]?.email}
                                                                                    </Typography>
                                                                                    <Typography variant="body1">
                                                                                        {initialData.shipping_address[index]?.street} {initialData.billing_address[index]?.number}
                                                                                    </Typography>
                                                                                    <Typography variant="body1">
                                                                                        {initialData.shipping_address[index]?.postal_code} {initialData.billing_address[index]?.city}
                                                                                    </Typography>
                                                                                    <Typography variant="body1">
                                                                                        {initialData.shipping_address[index]?.state}
                                                                                    </Typography>
                                                                                    <Typography variant="body1">
                                                                                        {initialData.shipping_address[index]?.country}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </CardContent>
                                                                        )}
                                                                        <CardActions>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => { handleClickOpen(); setAddressIndex(index) }}
                                                                                startIcon={<EditIcon />}>
                                                                                {t((!!initialData.shipping_address && !!billingId) ? 'clients.billing_accounts.fields.edit_shipping_address' : 'clients.billing_accounts.fields.add_shipping_address')}
                                                                            </Button>
                                                                            <Button
                                                                                aria-label={t(
                                                                                    'actions.delete'
                                                                                )}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => { setDeleteIndex(index) }}
                                                                                startIcon={<DeleteForeverIcon />}>
                                                                                {t('clients.billing_accounts.fields.delete_shipping_address')}
                                                                            </Button>
                                                                            {(index !== 0) ? (
                                                                                <Button
                                                                                    aria-label={t(
                                                                                        'actions.update'
                                                                                    )}
                                                                                    variant="contained"
                                                                                    onClick={() => { setDefault(index) }}
                                                                                    startIcon={<ArrowUpwardIcon />}>
                                                                                    {t('clients.billing_accounts.fields.default_shipping_address')}
                                                                                </Button>
                                                                            ) : null}
                                                                        </CardActions>
                                                                    </Card>
                                                                ))}

                                                            </Box>
                                                        )}
                                                    />
                                                ))}
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="form-dialog-title">
                                                <DialogTitle>
                                                    {t(
                                                        'clients.billing_accounts.fields.shipping_address'
                                                    )}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Form>
                                                        <ShippingAddressFields index={addressIndex} />
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.btn}
                                                            disabled={isSubmitting}>
                                                            {t(
                                                                billingId ? 'actions.update' : 'actions.create'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleClose}
                                                            className={classes.btn}>
                                                            {t('actions.cancel')}
                                                        </Button>
                                                    </Form>
                                                </DialogContent>
                                            </Dialog>
                                        </>
                                    )}
                                </Formik>
                            )
                            }
                        />
                        <DeleteDialog
                            open={deleteIndex !== null}
                            item={!!deleteIndex ? deleteIndex : null}
                            onCancel={() => setDeleteIndex(null)}
                            onConfirm={handleConfirmDelete}
                        />
                    </>
                )}
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => addAddress()}>
                        {t('invoicing.fields.billing_account.shipping_address.add')}
                    </Button>
                </Box>
            </>
        );
    }
}

export default withStyles(styles)(ShippingAddressFormContainer);
