import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '../../../storage/LocalStorage';

const INITIAL_STATE = {
    current: LocalStorage.getProject(),
};

const projectSlice = createSlice({
    name: 'project',
    initialState: { INITIAL_STATE, project: null },
    reducers: {
        setProject(state, action) {
            const { project, current } = action.payload;
            state.project = project;
            //LocalStorage.setProject(state.current);
            state.current =
                INITIAL_STATE.current === null
                    ? current
                    : INITIAL_STATE.current;
        },
        setCurrentProject(state, action) {
            state.current = action.payload;
        },
        updateProject(state, action) {
            const projectUpdated = action.payload;
            state.project = projectUpdated;
        }
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = projectSlice;
// Extract and export each action creator by name
export const { setProject, setCurrentProject, updateProject } = actions;
// Export the reducer, either as a default or named export
export default reducer;
