import React, { useCallback, useState } from 'react'
import { useList } from 'core/hooks/useList';
import { t } from 'core/i18n';
import Progress from '../../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import BaseMaterialTable from '../../../../shared/table/BaseMaterialTable';
import { withStyles } from '@material-ui/core/styles';
import ClientUsersFormScene from '../form/ClientUsersFormScene';
import ClientRolesDialogScene from '../form/ClientRolesDialogScene';
import { getClientUserRoles, deleteClientUserRole } from 'core/modules/dashboard/clients/actions';
import { signInWithEmailLink } from 'core/modules/auth/actions';
import { CRUD } from 'core/modules/auth/constants';
import { Can } from 'components/shared/can/Can';
import { Collections } from 'core/networking/firebase';
import {
    Grid,
    Typography
} from '@material-ui/core';
import AvatarStatus from 'components/shared/content/AvatarStatus';
import DateFormat from 'components/shared/form/generic/DateFormat';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    }
});

const ClientUsersOverviewScene = ({ classes }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [userItem, setUserItem] = useState(false)

    const fetchList = useCallback(() => getClientUserRoles(), []);

    const { items, isLoading, error, refreshList, canSoftDelete, canDelete, canEdit } = useList(
        fetchList, Collections.clients
    );

    const handleSuccess = () => {
        setOpenDialog(false);
        refreshList();
    };

    const headCells = [
        {
            id: 'id',
            hidden: true,
            field: 'id',
        },
        {
            id: 'email',
            title: t('users.fields.email'),
            field: 'email',
            render: rowData => (
                <Grid container wrap="nowrap" spacing={1} alignItems="center">
                    <Grid item>
                        <AvatarStatus user={rowData}
                            className={classes.smallAvatar}
                        />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography noWrap>
                            {!!rowData.email ? rowData.email : rowData.first_name + " " + rowData.last_name}
                        </Typography>
                    </Grid>
                </Grid>
            )
        },
        {
            id: 'permission',
            title: t('users.fields.permission'),
            field: 'permission',
            render: rowData => t(`users.permissions.${rowData.permission}.title`),
        },
        {
            id: 'status',
            title: t('users.fields.last_active'),
            field: 'status',
            render: rowData => !!rowData.id ? <DateFormat date={rowData.lastSignIn} time={true} /> : t('users.invitation_pending'),
        },
    ]

    const menuActions = [
        {
            action: 'mail',
            actionFunc: signInWithEmailLink,
            param: 'email'
        },
        {
            action: 'deleteForever',
        },
    ]

    const handleRowClick = (event, rowData) => {
        setOpenDialog(true);
        setUserItem(rowData);
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                <Can i={CRUD.Create} the={Collections.client_private_data}>
                    <ClientUsersFormScene onSuccess={handleSuccess} />
                </Can>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <BaseMaterialTable
                            title={t('nav.users_and_permissions')}
                            headCells={headCells}
                            items={items}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                            collection={Collections.clients}
                            handleRowClick={handleRowClick}
                            deleteItemFunc={deleteClientUserRole}
                            menuActions={menuActions}
                            refreshList={refreshList}
                        />
                    </>
                )}
                <ClientRolesDialogScene
                    openDialog={openDialog}
                    onCancel={() => { setOpenDialog(false); setUserItem(false) }}
                    rowData={userItem}
                    onSuccess={handleSuccess}
                />

            </>
        )
    }
}

export default withStyles(styles)(ClientUsersOverviewScene);