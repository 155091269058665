import React from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    Grid,
    Typography
} from '@material-ui/core';

const PageHeader = ({ title }) => {
    return (
        <Box displayPrint="none">
            <AppBar component="div" color="primary" position="static" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <Typography color="inherit" variant="h5" component="h1">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default PageHeader;
