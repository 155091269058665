import React, { useCallback, useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Box,
    Link,
    Paper
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as yup from 'yup';
import { registerAccount } from '../../../core/modules/auth/actions';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '../../../core/networking/utils';
import { t } from '../../../core/i18n';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../../core/routing/routing';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../../shared/form/generic/TextField';
import { Formik, Form } from 'formik';

// let's keep this out of the render to avoid useMemo
const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
    password: yup
        .string()
        .min(8)
        .required(),
});

const styles = () => ({
    paper: {
        padding: 30,
    },
    loginFooter: {
        color: 'white',
        padding: 30,
    },
    linkWhite: {
        '&:after': {
            backgroundColor: 'white',
        },
    },
    btn: {
        margin: '1em 0',
    },
    inputLeft: {
        paddingRight: '4px',
    },
    inputRight: {
        paddingLeft: '4px',
    },
});

// TODO classes won't be passed!!
const RegisterScene = ({ classes }) => {
    const dispatch = useDispatch();

    const [initialData, setInitialData] = useState({ email: '', password: '' });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setInitialData({});
    }, []);

    const onSubmit = useCallback(
        data => {
            // set as loading
            setLoading(true);
            // try to log in
            dispatch(registerAccount(data)).catch(error => {
                // not loading anymore
                setLoading(false);
                // show error
                setError(getErrorMessage(error));
            });
        },
        [dispatch]
    );

    return (
        <div>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h6">
                    {t('onboarding.register.title')}
                </Typography>

                {initialData ? (
                    <Formik
                        initialValues={initialData}
                        validationSchema={LoginSchema}
                        onSubmit={onSubmit}>
                        <Form>
                            <Box display="flex">
                                <TextField
                                    label={t(
                                        'onboarding.register.form.first_name'
                                    )}
                                    name="first_name"
                                    variant="outlined"
                                    className={classes.inputLeft}
                                />
                                <TextField
                                    label={t(
                                        'onboarding.register.form.last_name'
                                    )}
                                    name="last_name"
                                    variant="outlined"
                                    className={classes.inputRight}
                                />
                            </Box>
                            <TextField
                                label={t('onboarding.register.form.email')}
                                name="email"
                                type="email"
                                variant="outlined"
                            />
                            <TextField
                                label={t('onboarding.register.form.password')}
                                name="password"
                                type="password"
                                variant="outlined"
                            />
                            {error && <Alert severity="error">{error}</Alert>}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                disableElevation
                                className={classes.btn}>
                                {t('onboarding.register.form.submit')}
                            </Button>
                        </Form>
                    </Formik>
                ) : null}
            </Paper>

            <Box className={classes.loginFooter}>
                <Typography variant="body1" gutterBottom>
                    {t('onboarding.register.has_account')}
                </Typography>
                <Link
                    component={RouterLink}
                    to={ROUTE_LOGIN}
                    variant="body1"
                    color="inherit"
                    underline="none"
                    className={classes.linkWhite}>
                    {t('onboarding.register.login')}
                </Link>
            </Box>
        </div>
    );
};

export default withStyles(styles)(RegisterScene);
