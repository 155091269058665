import Address from '../../../../../shared/address/Address';
import React from 'react';

const BillingAddress = ({ billingAccount, ...props }) => (
    <>
        {/* eslint-disable-next-line camelcase */}
        {billingAccount.legal_name || ''}<br />
        {/* eslint-disable-next-line camelcase */}
        <Address address={billingAccount.billing_address} /><br />
        {/* eslint-disable-next-line camelcase */}
        {billingAccount.vat_number?.vat || ''}
    </>
);

export default BillingAddress;
