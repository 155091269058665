import {
    fireStore,
    Collections,
} from '../../../../../core/networking/firebase';
import { docMetaData, getDocData } from '../../../../../core/networking/utils';
import { isVoid } from '../../../../../core/utils/isVoid';
import { t } from 'core/i18n';
import { createNotification } from '../../../notifications/reducer';
import firebase from 'firebase/app';
import {
    selectCurrentUserId,
    selectCurrentUserName,
} from 'core/modules/workspaces/selectors';
import { checkNestedProperty } from '../../../../utils/objectHelpers';
import { timestampToDate } from '../../../../utils/formatTime';
import moment from 'moment';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchProjectLogs = (allItems, project) => {
    if (isVoid(project)) {
        throw new Error('Require project id');
    }

    let log_reset_date = new Date(1970, 0, 1);
    if (!!project.log_reset_date) {
        log_reset_date = timestampToDate(project.log_reset_date);
        log_reset_date.setHours(0);
        log_reset_date.setMinutes(0);
        log_reset_date.setSeconds(0);
    }


    const query = fireStore
        .collection(Collections.project_logs)
        .where('project_id', '==', project.id)
        .where('log_date', '>=', log_reset_date)
        .orderBy('log_date', 'desc');

    if (allItems) {
        query.where('meta.doc_status', '==', DocStatus.Active)
    }

    return query
        .get()
        .then(async response => {
            const allInternalLogs = response.docs.map(doc => getDocData(doc));
            // @todo: Move this to apicenter logic.
            let allExternalLogs = [];

            const loopLogs = async _ => {
                if (
                    checkNestedProperty(
                        project,
                        'integrations',
                        'log_connectors',
                        'ids'
                    ) &&
                    Array.isArray(project.integrations.log_connectors.ids)
                ) {
                    for (
                        let i = 0;
                        i < project.integrations.log_connectors.ids.length;
                        i++
                    ) {
                        const connectorData = project.integrations.log_connectors.data.find(
                            dataSet => {
                                return (
                                    dataSet.id ===
                                    project.integrations.log_connectors.ids[i]
                                );
                            }
                        );
                        const everhourProjectId =
                            connectorData.connector_data.everhour_project_id;
                        const externalLogs = await fireStore
                            .collection(Collections.project_logs)
                            .where(
                                'external.everhour_project_id',
                                '==',
                                everhourProjectId
                            )
                            .where('log_date', '>=', log_reset_date)
                            .orderBy('log_date', 'desc')
                            .get()
                            .then(response => {
                                return response.docs
                                    .map(doc => getDocData(doc))
                                    .filter(log => {
                                        if (
                                            checkNestedProperty(
                                                connectorData,
                                                'settings',
                                                'date_from'
                                            )
                                        ) {
                                            const momentSettingsDateFrom = moment(
                                                timestampToDate(
                                                    connectorData.settings
                                                        .date_from
                                                )
                                            );
                                            const momentLogDate = moment(
                                                timestampToDate(log.log_date)
                                            );
                                            return (
                                                momentSettingsDateFrom.diff(
                                                    momentLogDate
                                                ) <= -1
                                            );
                                        } else {
                                            return true;
                                        }
                                    });
                            });
                        allExternalLogs = allExternalLogs.concat(externalLogs);
                    }
                }
                const allLogs = allInternalLogs.concat(allExternalLogs);
                return allLogs.sort((logA, logB) => {
                    return logB.log_date.seconds - logA.log_date.seconds;
                });
            };
            return loopLogs();
        });
};

export const getProjectLogs = (allItems, project) => dispatch => {
    return fetchProjectLogs(allItems, project);
};

export const fetchProjectLogById = (id, projectId) => {
    return fireStore
        .collection(Collections.project_logs)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);

                if (data.project_id === projectId) {
                    return data;
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getProjectLogById = (id, projectId) => dispatch => {
    return fetchProjectLogById(id, projectId);
};

export const storeProjectLog = ({ id, ...data }) => async (
    dispatch,
    getState
) => {
    const query = fireStore.collection(Collections.project_logs);
    data.meta = docMetaData(getState());
    data.user = {
        id: selectCurrentUserId(getState()),
        name: selectCurrentUserName(getState()),
    };
    if (id) {
        await query.doc(id).set({
            ...data,
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } else {
        const ref = await query.add({
            ...data,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        });
        id = ref.id;
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(
                `projects.logs.notifications.${id ? 'update' : 'create'}`
            ),
        })
    );

    return { ...data, id };
};

export const deleteProjectLog = data => async dispatch => {
    await fireStore
        .collection(Collections.project_logs)
        .doc(data.id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('projects.logs.notifications.delete'),
        })
    );
};
