import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import {
    route,
    ROUTE_PROJECT_LOGS,
    ROUTE_PROJECTS_EDIT,
    ROUTE_PROJECT_LOGS_ADD,
} from '../../../../core/routing/routing';
import {
    getProjectById,
} from '../../../../core/modules/dashboard/projects/actions';
import { useDispatch } from 'react-redux';
import { t } from '../../../../core/i18n';
import NavItem from 'components/sidebar/NavItem';
import { setProject } from 'core/modules/dashboard/projects/reducer';

const ProjectListItem = ({ open }) => {
    const dispatch = useDispatch();
    const projectLocalstorage = useSelector(state => state.app.project);
    const project = useSelector(state => state.project.project);
    const workspaceId = useSelector(state => state.app.workspace);

    useEffect(() => {
        const action = {}
        if (!!projectLocalstorage) {
            dispatch(getProjectById(projectLocalstorage))
                .then(res => {
                    action.project = res;
                    action.current = res.id;
                    dispatch(setProject(action));
                })
        }
    }, [dispatch, projectLocalstorage]);

    if (!!projectLocalstorage && !!project) {
        return (
            <NavItem
                placeholderName="project"
                placeholderIcon={<DashboardIcon />}
                open={open}
                mainRoute={route(ROUTE_PROJECT_LOGS, { workspaceId, projectId: projectLocalstorage })}
                item={project}
                menuLinks={[
                    {
                        to: route(ROUTE_PROJECT_LOGS, { workspaceId, projectId: projectLocalstorage }),
                        label: t('project.tabs.logs'),
                    },
                    {
                        to: route(ROUTE_PROJECTS_EDIT, { workspaceId, projectId: projectLocalstorage }),
                        label: t('project.tabs.settings'),
                    },
                    {
                        to: route(ROUTE_PROJECT_LOGS_ADD, { workspaceId, projectId: projectLocalstorage }),
                        label: t('projects.logs.add'),
                    },
                ]}
            />
        );
    }
    else {
        return null;
    }

};

export default ProjectListItem;
