import React from 'react';
import { Field, useField } from 'formik';
import {
    FormHelperText,
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup as MaterialRadioGroup
} from '@material-ui/core';
import { isVoid } from '../../../../core/utils/isVoid';

const RadioGroup = ({
    label,
    type,
    options,
    defaultValue,
    inline = false,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormLabel component="legend" id={`${props.name}_label`}>
                {label}
            </FormLabel>
            <Field
                as={MaterialRadioGroup}
                margin="dense"
                {...field}
                type={type || 'text'}
                value={isVoid(field.value) ? '' : field.value} // allow "0" for numbers
                aria-label={`${props.name}_label`}
                {...props}>
                {options.map(option => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        margin="dense"
                        style={
                            !!inline ? {} : { display: 'block', width: '100%' }
                        }
                    />
                ))}
            </Field>
            {!!meta.error && meta.touched && (
                <FormHelperText error={true}>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default RadioGroup;
