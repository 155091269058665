import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'core/hooks/useForm';
import { t } from 'core/i18n';
import { getClientBillingAccountById } from 'core/modules/dashboard/clients/billing-accounts/actions';
import { history, route, ROUTE_BILLING_ACCOUNTS_EDIT, ROUTE_CLIENT_BILLING_ACCOUNTS_EDIT } from 'core/routing/routing';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import BillingAddressFormContainer from './form/BillingAddressFormContainer';
import ShippingAddressFormContainer from './form/ShippingAddressFormContainer';


const styles = () => ({
    paper: {
        //padding: 20,
        marginBottom: 30,
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
    paragraph: {
        whiteSpace: 'break-spaces',
    }
});

const BillingAccountsContainer = ({ classes }) => {
    const { billingId, clientId, workspaceId } = useParams()
    const config = useMemo(
        () => ({
            fetch: () => getClientBillingAccountById(billingId, clientId),
            id: billingId,
        }),
        [billingId, clientId]
    );

    const { initialData } = useForm(config);

    const handleClick = () => {
        if (!!clientId) {
            history.push(route(ROUTE_CLIENT_BILLING_ACCOUNTS_EDIT, { workspaceId, billingId, clientId }))
        } else {
            history.push(route(ROUTE_BILLING_ACCOUNTS_EDIT, { workspaceId, billingId }))
        }
    }

    return (
        initialData && (
            <>
                <Typography variant="h4">
                    {initialData.name} ({initialData.account_number})
                </Typography>
                <Card className={classes.paper}>
                    <CardContent>
                        <Box>
                            {/*!!initialData.client && (
                                <Typography variant="body1">
                                    {initialData?.client?.name || ""}
                                </Typography>
                            )*/}
                            <Typography variant="body1">
                                {initialData?.vat_number?.type || ""}
                            </Typography>
                            <Typography variant="body1">
                                {initialData?.vat_number?.vat || ""}
                            </Typography>
                            <Typography paragraph className={classes.paragraph} variant="body1">
                                {initialData?.vat_number?.summary || ""}
                            </Typography>
                            <Typography variant="body1">
                                {initialData?.legal_name || ""}
                            </Typography>
                            <Typography variant="body1">
                                {initialData?.contact_name || ""}
                            </Typography>
                            <Typography variant="body1">
                                {initialData?.language || ""}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => handleClick()}
                            className={classes.btn}>
                            {t('actions.edit')}
                        </Button>
                    </CardContent>
                </Card>
                {billingId && (
                    <>
                        <BillingAddressFormContainer />
                        <ShippingAddressFormContainer />
                    </>
                )}
            </>
        )
    );
};

export default withStyles(styles)(BillingAccountsContainer);
