import React from 'react';
import { Locales } from '../../../../core/i18n';
import TextField from './TextField';
import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';

const TranslatableTextField = ({ label, name, type, ...props }) => {
    const { values } = useFormikContext();

    return (
        <Box display="flex">
            {Locales.map((locale, key) => (
                <TextField
                    label={`${label} (${locale.toUpperCase()})`}
                    name={`${name}.${locale}`}
                    key={`${name}.${locale}`}
                    placeholder={values[name].en || ''}
                    {...props}
                    style={{ marginLeft: key > 0 ? 14 : 0 }} // TODO remove
                />
            ))}
        </Box>
    );
};

export default TranslatableTextField;
