import { Collections, fireStore } from '../../networking/firebase';
import { setWorkspaces, updateWorkspace } from './reducer';
import { docMetaData, getDocData } from '../../networking/utils';
import { createNotification } from '../notifications/reducer';
import { t } from 'core/i18n';
import { selectWorkspaceById } from './selectors';
import { caseFoldNormalize } from 'core/utils/caseFold';
import { isEmptyText } from 'core/utils/isEmptyText';
import { signInWithEmailLink } from 'core/modules/auth/actions';
import moment from 'moment';
import { firebaseAuth } from 'core/networking/firebase';
import { setUserRole, deleteUserRole } from '../users/actions';
import { selectCurrentUserWorkspaces, selectCurrentUserWorkspacePermission } from 'core/modules/workspaces/selectors';
import { LocalStorage } from 'core/storage/LocalStorage';
import { selectCurrentWorkspaceId } from '../app/selectors';
import { DocStatus } from '../auth/constants';

export const getWorkspaces = () => (dispatch, getState) => {
    return fireStore
        .collection(Collections.workspaces)
        .where('meta.doc_status', '==', DocStatus.Active)
        .get()
        .then(response => {
            const workspace_agency = getState().app.workspace_agency;
            const workspaces = response.docs.map(doc => getDocData(doc));

            // save to reducer based on roles
            const action = {};
            //if (!!workspace_agency) {
            // show all
            action.current = workspace_agency;
            action.workspaces = workspaces;
            /*} else {
                // show none (guest)
                action.workspaces = [];
            }*/
            return dispatch(setWorkspaces(action));
        });
};

export const getWorkspaceById = async workspaceId => {
    return await fireStore
        .collection(Collections.workspaces)
        .doc(workspaceId)
        .get()
        .then(doc => {
            if (doc.exists) {
                return getDocData(doc);
            }
            return workspaceId;
        })
        .catch(err => {
            console.log(err);
        })
}

export const getWorkspacePublicDataById = async workspaceId => {
    return await fireStore
        .collection(Collections.workspaces)
        .doc(workspaceId)
        .collection('public_data')
        .doc('public')
        .get()
        .then(doc => {
            if (doc.exists) {
                //const data = doc.data();
                return { ...doc.data(), id: workspaceId };
            }
            return workspaceId;
        })
        .catch(err => {
            console.log(err);
        })
}

export const getUserWorkspaces = () => (dispatch, getState) => {
    const user = getState().auth.user;
    const workspace_agency = getState().app.workspace;
    const localstorageWorkspaceId = LocalStorage.getWorkspace()
    const localstorageClientId = LocalStorage.getWorkspaceClient();

    // save to reducer based on roles
    const action = {};
    const workspaceIds = selectCurrentUserWorkspaces(getState())
    const permission = selectCurrentUserWorkspacePermission(getState(), workspace_agency);
    return Promise.all(workspaceIds.map(async id => {
        const workspace = await getWorkspaceById(id);
        return { ...workspace }
    }))
        .then((res) => {
            action.workspaces = res;

            if (!!localstorageWorkspaceId || (!localstorageClientId && !localstorageWorkspaceId)) {
                action.current = user.workspace_agency;
                action.permission = permission;
            }
            return dispatch(setWorkspaces(action));
        })
}

export const patchWorkspace = ({ id, ...data }) => async (
    dispatch,
    getState
) => {
    const query = fireStore.collection(Collections.workspaces);
    data.meta = docMetaData(getState());
    // get full workspace data (without id)
    const workspace = {
        ...selectWorkspaceById(getState(), id),
        ...data,
    };

    const publicData = {
        name: data.name,
        image_url: data.image_url,
        logo_image_url: data.logo_image_url,
    }

    delete workspace.id;
    let isUpdate = true;
    if (id) {
        await query.doc(id).set({ id, ...workspace });
        await query
            .doc(id)
            .collection('public_data')
            .doc('public')
            .set({ ...publicData });
    } else {
        //const ref = await query.add(data);
        id = caseFoldNormalize(data.name);
        isUpdate = false;
    }

    // save workspace
    dispatch(updateWorkspace({ ...workspace, id }));

    if (isUpdate) {
        dispatch(
            createNotification({
                type: 'success',
                message: t(`workspace.notifications.update`),
            })
        );
    } else {
        dispatch(
            createNotification({
                type: 'success',
                message: t(`workspace.notifications.create`),
            })
        );
    }

    // only return what we've updated (or maybe return workspace in future? Tbd)
    return { ...data, id };
};

export const fetchWorkspaceUserRoles = async workspace => {
    const privateData = await fireStore
        .collection(Collections.workspaces)
        .doc(workspace)
        .collection(Collections.private_data)
        .doc('private')
        .get()
        .then(doc => {
            if (doc.exists) {
                return getDocData(doc);
            }
        });

    const userDoc = async id => {
        return await fireStore
            .collection(Collections.users)
            .doc(id)
            .get()
            .then(doc => {
                if (doc.exists) {
                    return getDocData(doc);
                }
            });
    };

    if (!!privateData) {
        const arr = Object.keys(privateData.roles).map(async (key, i) => {
            const user = await userDoc(key);

            if (!!user) {
                return { ...user, permission: privateData.roles[key] }
            } else {
                return { permission: privateData.roles[key], email: key }
            }
        })

        return Promise.all(arr);
    } else {
        return []
    }
}

export const getWorkspaceUserRoles = () => (dispatch, getState) => {
    return fetchWorkspaceUserRoles(selectCurrentWorkspaceId(getState()));
};

export const storeWorkspaceUserRole = (workspaceId, data) => async (getState, dispatch) => {
    const { email, permission, uid } = data;

    const userId = !!data.id ? data.id : uid;
    const docId = workspaceId;
    const collection = Collections.workspaces;
    const workspaceUserRoles = await fireStore
        .collection(Collections.workspaces)
        .doc(workspaceId)
        .collection(Collections.private_data)
        .doc('private')
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = { ...doc.data() };
                return data.roles;
            }
        });

    const query = await fireStore
        .collection(Collections.workspaces)
        .doc(workspaceId)
        .collection(Collections.private_data)
        .doc('private');

    const key = isEmptyText(userId) ? data.email : userId;

    let userExists = false;
    if (!!workspaceUserRoles) {
        userExists = typeof workspaceUserRoles[key] !== 'undefined';
    }

    if (!userExists) {
        const roles = {
            ...workspaceUserRoles,
        }
        roles[key] = data.permission;

        await query.set({
            roles: roles,
        });

        if (isEmptyText(userId)) {
            await dispatch(signInWithEmailLink(data.email));
            await dispatch(invitationRequest(email, permission, docId, collection))
        } else {
            await dispatch(setUserRole(userId, docId, permission, collection));
        }
    } else {
        const roles = {
            ...workspaceUserRoles,
        }
        roles[key] = data.permission;

        await query
            .set({
                roles: roles
            });

        await dispatch(setUserRole(userId, docId, permission, collection));
        return dispatch(
            createNotification({
                type: 'error',
                message: t('workspace.notifications.user_already_added'),
            })
        );
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`workspace.notifications.update`),
        })
    );

    return { ...data, workspaceId };
};

export const deleteWorkspaceUserRole = (data) => async (dispatch, getState) => {
    const { email, id } = data;
    const arrKey = !!id ? id : email;
    const workspaceId = selectCurrentWorkspaceId(getState())

    const query = fireStore
        .collection(Collections.workspaces)
        .doc(workspaceId)
        .collection(Collections.private_data)
        .doc('private');

    const userRoles = await query
        .get()
        .then(doc => {
            if (doc.exists) {
                return doc.data();
            }
        });

    delete userRoles.roles[arrKey];

    if (!!id) {
        await deleteUserRole(id, workspaceId, Collections.workspaces)
    }

    return await query
        .set(userRoles);
}

export const invitationRequest = async (email, permission, docId, collection) => {
    const query = fireStore.collection(Collections.user_invitations);
    const data = [];

    const timestamp = moment().format();
    // add log (spread in case data.logs is undefined)
    data.logs = [
        {
            timestamp: timestamp,
            action: 'create',
            user: firebaseAuth.auth().currentUser.uid,
        },
    ];

    await fireStore
        .collection(Collections.user_invitations)
        .doc(email)
        .get()
        .then(doc => {
            if (doc.exists) {
                const result = getDocData(doc);
                const existingInvitations = result.invitation;
                return data.invitation = [
                    ...existingInvitations,
                    {
                        email: email || '',
                        permission: permission || '',
                        collection: collection || '',
                        doc_id: docId || ''
                    }];
            } else {
                return data.invitation = [{
                    email: email || '',
                    permission: permission || '',
                    collection: collection || '',
                    doc_id: docId || ''
                }];
            }
        })
        .catch(err => {
            console.log(err);
        });

    await query.doc(email).set({ email, ...data });
    //id = ref.id;

    // only return what we've updated (or maybe return workspace in future? Tbd)
    return { ...data, email };
};

export const fetchWorkspaces = async () => {
    return await fireStore
        .collection(Collections.workspaces)
        .orderBy('name', 'asc')
        .get()
        .then(response => response.docs.map(doc => getDocData(doc)));
};

export const getAllWorkspaces = () => dispatch => {
    return fetchWorkspaces();
};

export const deleteWorkspace = data => async dispatch => {
    await fireStore
        .collection(Collections.workspaces)
        .doc(data.id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('workspace.notifications.delete'),
        })
    );
};
