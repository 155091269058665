import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Localization } from '../../core/i18n/localization';
import { selectCurrentLocale } from '../../core/modules/app/selectors';

const LocalizationProvider = ({ children }) => {
    const [isLoading, setLoading] = useState(true);
    const currentLocale = useSelector(state => selectCurrentLocale(state));

    useEffect(() => {
        setLoading(true);
        // set locale
        Localization.setLocale(currentLocale)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }, [currentLocale]);

    // if we use a div with a key here, the content will re-render and unmount instantly (bcz. of setLoading(true)) if locale changes
    // we can either try to solve this, or don't for know since setLoading(true) is always called, even if the dictionary was already downloaded
    return isLoading ? null : children;
};

export default LocalizationProvider;
