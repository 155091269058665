import React from 'react';
import { TextField as MaterialTextField } from '@material-ui/core';
import { Field, useField } from 'formik';
import { isVoid } from '../../../../core/utils/isVoid';

const TextField = ({ label, type, defaultValue, ...props }) => {
    const [field, meta] = useField(props.name);

    return (
        <Field
            as={MaterialTextField}
            fullWidth
            margin="dense"
            label={label}
            {...field}
            type={type || 'text'}
            value={isVoid(field.value) ? '' : field.value} // allow "0" for numbers
            error={!!meta.error && meta.touched}
            helperText={meta.touched ? meta.error : ''}
            {...props}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default TextField;
