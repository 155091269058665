import React from 'react';
import { t } from 'core/i18n';
import AppContent from '../../../../shared/content/AppContent';
import { InvoicingStatus } from '../../../../../core/modules/accounting/invoicing/constants';
import {
    acceptInvoice,
} from '../../../../../core/modules/accounting/invoicing/actions';
import { useDispatch } from 'react-redux';
import {
    TableContainer,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Button,
    Paper,
    IconButton,
} from '@material-ui/core';
import OverviewRows from '../table/OverviewRows';
import ItemRow from './row/ItemRow';
import GroupRow from './row/GroupRow';
import { Link } from 'react-router-dom';
import {
    route,
    ROUTE_ADMINISTRATION_TYPE_EDIT,
    ROUTE_ADMINISTRATION_TYPE_CHANGE,
    ROUTE_ADMINISTRATION_PUBLIC,
} from '../../../../../core/routing/routing';
import {
    Edit as EditIcon,
    Print as PrintIcon,
    Link as LinkIcon,
} from '@material-ui/icons';
import BillingAddress from './billing/BillingAddress';
import ShippingAddress from './billing/ShippingAddress';
import OverviewGroups from '../table/OverviewGroups';
import {
    InvoicingTypes,
    InvoicingFollowUpTypes,
} from '../../../../../core/modules/accounting/invoicing/constants';
import PageHeader from '../../../../shared/header/PageHeader';
import { withStyles } from '@material-ui/core/styles';
import InvoicingPdfDownload from '../pdf/InvoicingPdfDownload';
import StatusChip from '../../../../shared/progress/StatusChip';

const styles = () => ({
    paper: {
        padding: 20,
    },
    "@media print":
    {
        paper: {
            padding: 0,
            boxShadow: "none"
        },
    },
    actionsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 20,
    }
});

const InvoicingDetail = ({ type, item, onUpdate, classes }) => {
    const dispatch = useDispatch();

    /*const handlePublishClick = () => {
        // todo move to own component "PublishButton"?
        dispatch(publishInvoice(type, item.id))
            .then(() => {
                onUpdate();
            })
            .catch(e => {
                console.log(e);
            });
    };*/

    const handleAcceptClick = () => {
        // todo move to own component "PublishButton"?
        dispatch(acceptInvoice(type, item.id))
            .then(() => {
                onUpdate();
            })
            .catch(e => {
                console.log(e);
            });
    };

    /*const handlePrintClick = () => {
        window.print();
    };*/

    return (
        <>
            <Box displayPrint="none">
                <PageHeader title={t(`invoicing.tabs.${type}`)} />
                <AppContent>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center">
                        <h1 style={{ whiteSpace: 'pre-wrap' }}>
                            {item.description}
                            {!!item.deleted_at ? " (" + t('invoicing.notifications.delete') +
                                (item.deleted_at.uid_version ? " " + item.deleted_at.uid_version : "") + ")" : null}
                        </h1>
                        <StatusChip
                            type={type}
                            status={
                                item.document_approval?.status ?
                                    item.document_approval.status :
                                    null
                            }
                        />
                    </Grid>
                </AppContent>
            </Box>

            <AppContent>
                <Box display="none" displayPrint="block">
                    {!!item.from.logo_image_url ?
                        <img width="160px" height="auto" src={item.from.logo_image_url} alt={item.from.contact_name} /> :
                        null}
                    <br />
                    <br />
                </Box>

                <Paper className={classes.paper}>
                    <Box displayPrint="none" className={classes.actionsWrapper}>
                        <Box>
                            <InvoicingPdfDownload
                                type={type}
                                item={item}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                target="_blank"
                                startIcon={<PrintIcon />}
                                to={route(
                                    ROUTE_ADMINISTRATION_PUBLIC,
                                    {
                                        workspaceId: item.workspace_agency,
                                        id: item.id,
                                        type: type,
                                        query: { access_token: item.access_token, report_type: "pdf" }
                                    }
                                )}>
                                {t(
                                    `invoicing.detail.print`
                                )}
                            </Button>
                            <IconButton
                                variant="contained"
                                color="primary"
                                component={Link}
                                target="_blank"
                                to={route(
                                    ROUTE_ADMINISTRATION_PUBLIC,
                                    {
                                        workspaceId: item.workspace_agency,
                                        id: item.id,
                                        type: type,
                                        query: { access_token: item.access_token }
                                    }
                                )}>
                                <LinkIcon />
                            </IconButton>
                        </Box>
                        <Box>
                            {!!!item.deleted_at && (
                                (item.status === InvoicingStatus.Draft) ? (
                                    <Button
                                        aria-label={t('actions.edit')}
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={route(ROUTE_ADMINISTRATION_TYPE_EDIT, {
                                            workspaceId: item.workspace_agency,
                                            id: item.id,
                                            type: type,
                                        })}>
                                        <EditIcon />
                                    </Button>
                                ) : (
                                        <>
                                            {(type === InvoicingTypes.Quote && item.status === InvoicingStatus.Published)
                                                && (
                                                    <Button
                                                        style={{ marginLeft: '8px' }}
                                                        variant="contained"
                                                        color="primary"
                                                        component={Link}
                                                        to={route(
                                                            ROUTE_ADMINISTRATION_TYPE_CHANGE,
                                                            {
                                                                workspaceId: item.workspace_agency,
                                                                id: item.id,
                                                                fromType: type,
                                                                toType: type,
                                                            }
                                                        )}>
                                                        {t(
                                                            `actions.${type}.edit`
                                                        )}
                                                    </Button>
                                                )}
                                            {(type === InvoicingTypes.Invoice && item.status === InvoicingStatus.Accepted)
                                                && (
                                                    <Button
                                                        style={{ marginLeft: '8px' }}
                                                        variant="contained"
                                                        color="primary"
                                                        component={Link}
                                                        to={route(
                                                            ROUTE_ADMINISTRATION_TYPE_CHANGE,
                                                            {
                                                                workspaceId: item.workspace_agency,
                                                                id: item.id,
                                                                fromType: type,
                                                                toType:
                                                                    InvoicingFollowUpTypes[
                                                                    type
                                                                    ],
                                                            }
                                                        )}>
                                                        {t(
                                                            `invoicing.detail.make_${InvoicingFollowUpTypes[type]}`
                                                        )}
                                                    </Button>
                                                )}
                                            {(!!InvoicingFollowUpTypes[type] && type !== InvoicingTypes.Invoice && item.status === InvoicingStatus.Published)
                                                && (
                                                    <Button
                                                        style={{ marginLeft: '8px' }}
                                                        variant="contained"
                                                        color="primary"
                                                        component={Link}
                                                        to={route(
                                                            ROUTE_ADMINISTRATION_TYPE_CHANGE,
                                                            {
                                                                workspaceId: item.workspace_agency,
                                                                id: item.id,
                                                                fromType: type,
                                                                toType:
                                                                    InvoicingFollowUpTypes[
                                                                    type
                                                                    ],
                                                            }
                                                        )}>
                                                        {t(
                                                            `invoicing.detail.make_${InvoicingFollowUpTypes[type]}`
                                                        )}
                                                    </Button>
                                                )}
                                            {(!!!InvoicingFollowUpTypes[type] || type === InvoicingTypes.Invoice || type === InvoicingTypes.CreditNote) &&
                                                item.status ===
                                                InvoicingStatus.Published && (
                                                    <Button
                                                        style={{ marginLeft: '8px' }}
                                                        onClick={handleAcceptClick}
                                                        variant="contained"
                                                        color="secondary">
                                                        {t(`invoicing.detail.${type}.accept`)}
                                                    </Button>
                                                )}

                                        </>
                                    ))}

                        </Box >
                    </Box >

                    <Box display="flex">
                        <Box style={{ width: '50%' }}>
                            <strong>
                                {!!item.to.shipping_address ?
                                    t('clients.billing_accounts.fields.billing_address') :
                                    t('clients.billing_accounts.fields.billing_shipping_address')}
                            </strong>
                            <br />
                            <BillingAddress
                                billingAccount={item.to || {}}
                            />
                        </Box>
                        {!!item.to.shipping_address && (
                            <Box style={{ width: '50%' }}>
                                <>
                                    <strong>{t('clients.billing_accounts.fields.shipping_address')}</strong>
                                    <br />
                                    <ShippingAddress
                                        billingAccount={item.to || {}}
                                    />
                                </>
                            </Box>
                        )}
                    </Box>

                    {/* todo remove use styling */}
                    <br />
                    <h2>{!!item.uid ? "#" + item.uid : null}{!!item.version ? "/" + item.version : null}</h2>
                    <br />

                    <TableContainer style={{ marginTop: '24px' }}>
                        <Table aria-label={t('invoicing.items')} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t('invoicing.fields.item.description')}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontVariantNumeric: 'tabular-nums',
                                        }}>
                                        {t('invoicing.fields.item.quantity')}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontVariantNumeric: 'tabular-nums',
                                        }}>
                                        {t('invoicing.fields.item.price')}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontVariantNumeric: 'tabular-nums',
                                        }}>
                                        {t('invoicing.fields.item.vat')}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{
                                            fontVariantNumeric: 'tabular-nums',
                                        }}>
                                        {t('invoicing.fields.item.total')}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item.items.map((row, index) =>
                                    !row.group ? (
                                        <ItemRow
                                            key={`item_${index}`}
                                            item={row}
                                            status={item.status}
                                        />
                                    ) : (
                                            <GroupRow
                                                key={`group_${index}`}
                                                item={row}
                                            />
                                        )
                                )}
                                <OverviewGroups
                                    currency={item.currency}
                                    items={item.items || []}
                                />
                                <OverviewRows
                                    currency={item.currency}
                                    items={item.items || []}
                                    status={item.status}
                                />

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper >
                <Box display="none" displayPrint="block">
                    <br />
                    <br />
                    <hr style={{ height: "2px", backgroundColor: 'black', borderStyle: 'solid' }} />
                    <BillingAddress
                        billingAccount={item.from || {}}
                    />
                </Box>
                {!!item.converted_from ? item.converted_from.type === InvoicingTypes.Invoice && (
                    <Box displayPrint="none">
                        <br />
                        <br />
                        <h2>{t('invoicing.fields.item.converted_from') + item.converted_from.id}</h2>
                    </Box>
                ) : null}
            </AppContent >
        </>
    );
};

export default withStyles(styles)(InvoicingDetail);
