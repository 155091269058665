import { t } from '../../../../../../core/i18n';
import React from 'react';
import { selectItemTotal } from '../../../../../../core/modules/accounting/invoicing/selectors';
import VatSelect from '../../../../../shared/form/vat/VatSelect';
import {
    Close as RemoveIcon
} from '@material-ui/icons';
import {
    IconButton,
    TableCell,
    InputAdornment
} from '@material-ui/core';
import TranslatableAutoComplete from '../../../../../shared/form/generic/TranslatableAutoComplete';
import {
    getProductById,
    getProducts,
} from '../../../../../../core/modules/accounting/products/actions';
import { useField, useFormikContext } from 'formik';
import { Locales, tf } from 'core/i18n/localization';
import SupplierAutoComplete from '../../../../../shared/form/supplier/SupplierAutoComplete';
import DraggableRow from './DraggableRow';
import { withStyles } from '@material-ui/core/styles';
import { InvoicingStatus } from '../../../../../../core/modules/accounting/invoicing/constants';
import { MarginLevels } from '../../../../../../core/modules/accounting/invoicing/constants';
import { selectMarginRow } from '../../../../../../core/modules/accounting/invoicing/selectors';
import StockItemSelector from '../../../../stock/stock_items/shared/StockItemSelector';
import { useDispatch } from 'react-redux';
//import TextFastField from 'components/shared/form/generic/TextFastField';
import NumberFormat from 'components/shared/form/generic/NumberFormat';
import TextUltraFastField from 'components/shared/form/generic/TextUltraFastField';

const styles = () => ({
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
    orange: {
        color: 'orange',
    },
});

const ItemRow = ({
    name,
    onDelete,
    onMove,
    index,
    row,
    id,
    status,
    showRemoveButton = true,
    recurringContext = false,
    classes,
}) => {
    const dispatch = useDispatch();
    const [field] = useField(name);
    const { setFieldValue } = useFormikContext();
    const margin = selectMarginRow(row);

    const handleProductSelect = (product, extraDescription = '') => {
        if (product != null) {
            const productCopy = { ...product };

            // Delete some of these attributes.
            // Improvement: Only copy relevant values,
            // now whole product is injected, except the ones we delete.
            delete productCopy.id;
            delete productCopy.product_group;
            delete productCopy.workspace_agency;
            delete productCopy.name_lower;

            // Set default quantity.
            productCopy.quantity = 1;

            // Set group = false.
            productCopy.group = false;

            if (!!field.value.sales_product_stock) {
                productCopy.sales_product_stock =
                    field.value.sales_product_stock;
            }

            // Extra description concat.
            const extraDescString =
                extraDescription.length > 0
                    ? (tf(product.description).length > 0 ? ' - ' : '') +
                    extraDescription
                    : '';

            setFieldValue(`${name}`, {
                ...productCopy,
                name: tf(product.name),
                description: tf(product.description) + extraDescString,
                product: { id: product.id, name: product.name },
            });
        }
    };

    const handleBlurProduct = e => {
        setFieldValue(`${name}.name`, e.target.value);
        Locales.map(val => {
            setFieldValue(`${name}.product.name.${val}`, e.target.value);
        })
    }

    const handleRecurringStockSubmit = item => {
        if (!!item.sales_product_id) {
            dispatch(getProductById(item.sales_product_id)).then(product => {
                product.sales_product_stock = item.id;
                handleProductSelect(product, item.name);
            });
        } else {
            // Reset row values but set stock item.
            const resetRowValues = {
                index_id: field.value.index_id,
                group: field.value.group,
                currency: field.value.currency,
                sales_product_stock: item.id,
                description: item.name ? item.name : '',
            };
            setFieldValue(`${name}`, resetRowValues);
        }
    };
    //console.log('render row', field)
    return (
        <DraggableRow onMove={onMove} index={index} id={row.id}>
            <TableCell
                component="th"
                scope="row"
                width={250}
            >
                <TranslatableAutoComplete
                    name={`${name}.product`}
                    label={t('invoicing.fields.item.name')}
                    freeSolo
                    onChange={handleProductSelect}
                    onBlur={handleBlurProduct}
                    performQuery={getProducts}
                />
                {/*<TextFastField
                    label={t('invoicing.fields.item.name')}
                    name={`${name}.name`}
                />*/}
                <TextUltraFastField
                    label={t('invoicing.fields.item.description')}
                    name={`${name}.description`}
                    multiline
                />
                {!!recurringContext && (
                    <StockItemSelector
                        name={`${name}.sales_product_stock`}
                        onSourceSubmit={handleRecurringStockSubmit}
                    />
                )}
            </TableCell>
            <TableCell
                padding="none"
                width={50}
            >
                <TextUltraFastField
                    label={t('invoicing.fields.item.quantity')}
                    name={`${name}.quantity`}
                    type="number"
                />
            </TableCell>
            <TableCell
                width={150}
            >
                <TextUltraFastField
                    label={t('invoicing.fields.item.purchase_price')}
                    name={`${name}.purchase_price`}
                    type="number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {row.currency}
                            </InputAdornment>
                        ),
                    }}
                />
                <TextUltraFastField
                    label={t('invoicing.fields.item.price')}
                    name={`${name}.price`}
                    type="number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {row.currency}
                            </InputAdornment>
                        ),
                    }}
                />
            </TableCell>
            <TableCell
                width={50}
            >
                <VatSelect
                    label={t('invoicing.fields.item.vat')}
                    name={`${name}.vat`}
                />
            </TableCell>
            <TableCell
                width={200}
            >
                <SupplierAutoComplete
                    label={t('invoicing.fields.item.supplier')}
                    name={`${name}.supplier`}
                />
            </TableCell>
            <TableCell align="right">
                <NumberFormat currency={row.currency} number={selectItemTotal(row || {}, true)} />
            </TableCell>
            <TableCell width={50}>
                {showRemoveButton && (
                    <IconButton
                        aria-label={t('actions.remove')}
                        size="small"
                        onClick={onDelete}>
                        <RemoveIcon />
                    </IconButton>
                )}
            </TableCell>
            {status !== InvoicingStatus.Accepted &&
                status !== InvoicingStatus.Published &&
                margin !== null && (
                    <TableCell
                        align="right"
                        style={{
                            fontVariantNumeric: 'tabular-nums',
                            fontWeight: 'bold',
                        }}
                        padding="none"
                        className={
                            MarginLevels.Accepted <= margin
                                ? classes.green
                                : MarginLevels.Warning >= margin
                                    ? classes.red
                                    : classes.orange
                        }>
                        {<NumberFormat number={margin} />}
                        {'%'}
                    </TableCell>
                )}
        </DraggableRow>
    );
};

export default withStyles(styles)(ItemRow);
