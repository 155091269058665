import React from 'react';
import AutoComplete from './AutoComplete';
import { tf } from 'core/i18n/localization';

const TranslatableAutoComplete = props => {
    return (
        <AutoComplete
            filter={(options, input) =>
                options.filter(
                    o =>
                        tf(o.name)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                )
            }
            getName={option => tf(option.name)}
            {...props}
        />
    );
};

export default TranslatableAutoComplete;
