import React from 'react';
import PageHeader from '../../../shared/header/PageHeader';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ROUTE_ADMINISTRATION_RECURRING,
    ROUTE_ADMINISTRATION_RECURRING_ADD,
    ROUTE_ADMINISTRATION_RECURRING_EDIT,
} from '../../../../core/routing/routing';
import RecurringOverviewScene from './overview/RecurringOverviewScene';
import { t } from '../../../../core/i18n';
import AppContent from '../../../shared/content/AppContent';
import RecurringFormScene from './form/RecurringFormScene';

const RecurringScene = props => {
    return (
        <>
            <PageHeader title={t('recurring.title')} />
            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_ADMINISTRATION_RECURRING_ADD}
                        component={RecurringFormScene}
                    />
                    <Route
                        path={ROUTE_ADMINISTRATION_RECURRING_EDIT}
                        component={RecurringFormScene}
                    />
                    <Route
                        path={ROUTE_ADMINISTRATION_RECURRING}
                        component={RecurringOverviewScene}
                    />
                    <Redirect to={ROUTE_ADMINISTRATION_RECURRING} />
                </Switch>
            </AppContent>
        </>
    );
};

export default RecurringScene;
