import React from 'react';
import {
    ROUTE_ADMINISTRATION_TYPE,
    ROUTE_ADMINISTRATION_TYPE_CHANGE,
    ROUTE_ADMINISTRATION_TYPE_EDIT,
    ROUTE_ADMINISTRATION_TYPE_ADD,
    ROUTE_ADMINISTRATION_TYPE_DETAIL,
    ROUTE_ADMINISTRATION_TYPE_DETAIL_PDF,
    route,
    ROUTE_ADMINISTRATION_RECURRING,
} from '../../../core/routing/routing';
import { Redirect, Route, Switch } from 'react-router-dom';
import { InvoicingTypes } from '../../../core/modules/accounting/invoicing/constants';
import InvoicingFromScene from '../accounting/invoicing/type-change/InvoicingFromScene';
import InvoicingFormScene from '../accounting/invoicing/form/InvoicingFormScene';
import InvoicingDetailScene from '../accounting/invoicing/detail/InvoicingDetailScene';
import InvoicingPdf from '../accounting/invoicing/pdf/InvoicingPdf';
import InvoicingOverviewScene from '../accounting/invoicing/overview/InvoicingOverviewScene';
import RecurringScene from '../accounting/recurring/RecurringScene';
import { useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'core/modules/workspaces/selectors';

const AdministrationScene = props => {
    const workspace = useSelector(state => selectCurrentWorkspace(state));
    return (
        <>
            <Switch>
                <Route
                    path={ROUTE_ADMINISTRATION_RECURRING}
                    component={RecurringScene}
                />

                <Route
                    path={ROUTE_ADMINISTRATION_TYPE_CHANGE}
                    component={InvoicingFromScene}
                />

                <Route
                    path={ROUTE_ADMINISTRATION_TYPE_EDIT}
                    component={InvoicingFormScene}
                />
                <Route
                    path={ROUTE_ADMINISTRATION_TYPE_ADD}
                    component={InvoicingFormScene}
                />

                <Route
                    path={ROUTE_ADMINISTRATION_TYPE_DETAIL_PDF}
                    component={InvoicingPdf}
                />

                <Route
                    path={ROUTE_ADMINISTRATION_TYPE_DETAIL}
                    component={InvoicingDetailScene}
                />

                <Route
                    path={ROUTE_ADMINISTRATION_TYPE}
                    component={InvoicingOverviewScene}
                />

                <Redirect
                    to={route(ROUTE_ADMINISTRATION_TYPE, {
                        workspaceId: workspace?.id || "", type: InvoicingTypes.Quote,
                    })}
                />
            </Switch>
        </>
    );
};

export default AdministrationScene;
