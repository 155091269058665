import React from 'react';
import { ROUTE_STOCK_ITEMS } from '../../../core/routing/routing';
import { Redirect, Route, Switch } from 'react-router-dom';
import StockItemScene from './stock_items/StockItemScene';

const StockScene = props => {
    return (
        <>
            <Switch>
                <Route path={ROUTE_STOCK_ITEMS} component={StockItemScene} />
                <Redirect to={ROUTE_STOCK_ITEMS} />
            </Switch>
        </>
    );
};

export default StockScene;
