import React from 'react';
import {
    ROUTE_USER,
    ROUTE_USER_OVERVIEW,
    ROUTE_USER_EDIT,
    ROUTE_USER_PREFERENCES
} from 'core/routing/routing';
import UserOverviewScene from './overview/UserOverviewScene';
import UserFormScene from './form/UserFormScene';
import UserPreferencesFormScene from './preferences/UserPreferencesFormScene';
import UserLandingScene from './UserLandingScene';
import PageHeader from '../../shared/header/PageHeader';
import { t } from 'core/i18n';
import AppContent from '../../shared/content/AppContent';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

const UserScene = () => {
    return (
        <>
            <PageHeader title={t('user.title')} />
            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_USER_PREFERENCES}
                        component={UserPreferencesFormScene}
                    />

                    <Route
                        path={ROUTE_USER_EDIT}
                        component={UserFormScene}
                    />

                    <Route
                        path={ROUTE_USER_OVERVIEW}
                        component={UserOverviewScene}
                    />

                    <Route
                        path={ROUTE_USER}
                        component={UserLandingScene}
                    />

                    <Redirect
                        to={ROUTE_USER}
                    />
                </Switch>
            </AppContent>
        </>
    );
};

export default UserScene;
