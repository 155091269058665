import Address from './address/Address';
import React from 'react';
import { View, Text } from '@react-pdf/renderer';

const ShippingAddress = ({ billingAccount, styles }) => {
    return (
        <View>
            {/* eslint-disable-next-line camelcase */}
            <Text>{billingAccount.legal_name || ''}</Text>
            {/* eslint-disable-next-line camelcase */}
            <Address
                address={billingAccount.shipping_address}
                styles={styles} />
        </View>
    )
};

export default ShippingAddress;
