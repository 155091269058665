import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '../../../storage/LocalStorage';

const INITIAL_STATE = {
    client_id: LocalStorage.getWorkspaceClient(),
};

const clientSlice = createSlice({
    name: 'client',
    initialState: { INITIAL_STATE, client: null },
    reducers: {
        setClients(state, action) {
            const { clients, workspaces } = action.payload;
            state.clients = clients;
            state.workspaces = workspaces;
        },
        setCurrentWorkspaceClient(state, action) {
            //LocalStorage.setWorkspaceClient(action.payload.client);
            const { client, permission, client_id } = action.payload;

            state.client = client || {};
            state.permission = permission;
            state.client_id = !!client_id ? client_id : INITIAL_STATE.client_id;
        },
        updateWorkspaceClient(state, action) {
            const clientUpdated = action.payload;
            state.client_id = clientUpdated;
        },
        clearWorkspaceClient(state, action) {
            state.client = {};
            state.client_id = null;
        }
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = clientSlice;
// Extract and export each action creator by name
export const { setCurrentWorkspaceClient, updateWorkspaceClient, setClients, clearWorkspaceClient } = actions;
// Export the reducer, either as a default or named export
export default reducer;
