import React, {
    useEffect,
    useState,
    useCallback,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import {
    Button,
    CircularProgress
} from '@material-ui/core';
import TextField from '../../../shared/form/generic/TextField';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    useDispatch
} from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Select from '../../../shared/form/generic/Select';
import { Permissions } from 'core/modules/auth/constants';
import { fetchUserByEmail } from 'core/modules/users/actions';
import {
    MailOutline as MailOutlineIcon,
    AccountCircle as AccountCircleIcon
} from '@material-ui/icons';
import { isEmptyText } from 'core/utils/isEmptyText';
import { getErrorMessage } from 'core/networking/utils';

const userRoleSchema = yup.object().shape({
    email: yup.string().email().required(),
    permission: yup.string().required(),
});

const emailSchema = yup.object().shape({
    email: yup.string().email().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
        marginBottom: 30,
    },
})

const UserPermissionFormContainer = ({ classes, storeUserRole, onSuccess, id }) => {
    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const [initialData, setInitialData] = useState({ email: "", permission: "" });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [latestInputValue, setLatestInputValue] = useState(null);
    //let count = 0;
    useEffect(() => {
        setInitialData({ email: "", permission: "" })
        setLatestInputValue(inputValue);
        emailSchema.validate({ email: inputValue })
            .then((val) => {
                dispatch(fetchUserByEmail(val.email))
                    .then((res) => {
                        if (res.email === latestInputValue) {
                            const arr = [{ email: res?.email || inputValue, uid: res?.uid || '' }]
                            setOptions(
                                Object.keys(arr).map((key) => arr[key])
                            );
                            setIsEmail(!!res.uid ? true : false);

                            setIsLoading(false);
                        }
                    });
            })
            .catch((err) => {
                //return console.log('err', err)
                //err.name; // => 'ValidationError'
                //err.errors; // => [{ key: 'field_too_short', values: { min: 18 } }]
            });

        if (!inputValue) {
            setIsLoading(false);
        }

    }, [inputValue, dispatch, loading, latestInputValue]);

    const onSubmit = useCallback(
        data => {
            // set as loading
            setLoading(true);
            // try to log in
            dispatch(storeUserRole(id, data))
                .then(() => {
                    // not loading anymore
                    setLoading(false);
                    onSuccess(data);
                    setInitialData({ email: "", permission: "", uid: "" })
                    setOptions([]);
                })
                .catch(
                    error => {
                        // not loading anymore
                        setLoading(false);
                        setError(getErrorMessage(error));
                    }
                );
        },
        [dispatch, id, onSuccess, storeUserRole]
    );

    return (
        initialData ? (
            <Formik
                initialValues={initialData}
                validationSchema={userRoleSchema}
                onSubmit={onSubmit} >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <Autocomplete
                            name="email"
                            type="email"
                            style={{ width: "100%" }}
                            options={options}
                            getOptionSelected={(option, value) => option.uid === value.uid}
                            getOptionLabel={(option) => option?.email || ""}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            //inputValue={latestInputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                setIsLoading(true);
                            }}
                            onChange={(event, newValue) => {
                                if (!isEmptyText(newValue?.uid)) {
                                    setFieldValue('uid', newValue.uid);
                                    setValue(newValue?.uid);
                                } else {
                                    setValue(newValue?.email);
                                    setFieldValue('uid', "");
                                }
                            }}
                            renderOption={(option) => (
                                <>
                                    {!isEmptyText(inputValue) && (
                                        (isEmail) ? (
                                            <AccountCircleIcon />
                                        ) : (
                                                <MailOutlineIcon />
                                            ))}
                                    <span key={option.uid} style={{ marginLeft: 10 }}>
                                        {option.email}
                                    </span>
                                </>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ width: "100%" }}
                                    label={t('user_permission.fields.email')}
                                    type="email"
                                    name="email"
                                    margin="dense"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                        startAdornment: (
                                            <>
                                                {!isEmptyText(value) && (
                                                    (isEmail) ? (
                                                        <AccountCircleIcon />
                                                    ) : (
                                                            <MailOutlineIcon />
                                                        ))}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                        <Select
                            label={t(
                                'user_permission.fields.permission'
                            )}
                            name="permission"
                            radioOptions={Object.keys(Permissions).map(
                                key => ({
                                    value: Permissions[key],
                                    label: t(`users.permissions.${Permissions[key]}.title`),
                                })
                            )}
                        />
                        {error && (
                            <Alert severity="error">{error}</Alert>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            {t('actions.invite')}
                        </Button>
                    </Form>
                )}
            </Formik >
        ) : null
    )
}

export default withStyles(styles)(UserPermissionFormContainer);