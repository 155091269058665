import { selectTotal } from 'core/modules/accounting/invoicing/selectors';
import React from 'react';
import { formatValuta } from 'core/utils/formatValuta';
import { t } from 'core/i18n';
import {
    Text,
    View,
} from '@react-pdf/renderer';

const OverviewGroups = ({ items, currency, styles }) => {
    return (
        <>
            {items
                .filter(i => i.group)
                .map(group => (
                    <View wrap={false} style={styles.tableRow} key={group.id}>
                        <View style={styles.table2Col}>
                            <Text style={styles.tableCellBold}>
                                {group.name} {t('invoicing.overview.exclusive_vat')}
                            </Text>
                        </View>
                        <View style={styles.table2Col}>
                            <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                                {currency}{' '}
                                {formatValuta(
                                    selectTotal(
                                        items.filter(i => i.parent === group.id),
                                        false
                                    )
                                )}
                            </Text>
                        </View>
                    </View>
                ))}
        </>
    );
};

export default OverviewGroups;
