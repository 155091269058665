import auth from './auth/reducer';
import app from './app/reducer';
import notifications from './notifications/reducer';
import workspaces from './workspaces/reducer';
import tags from './tagging/reducer';
import client from './dashboard/clients/reducer';
import project from './dashboard/projects/reducer';

export const rootReducers = {
    auth,
    app,
    notifications,
    workspaces,
    tags,
    client,
    project,
};
