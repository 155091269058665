import {
    selectTotal,
    selectVatRates,
} from 'core/modules/accounting/invoicing/selectors';
import React from 'react';
import { formatValuta } from 'core/utils/formatValuta';
import { t } from 'core/i18n';
import {
    Text,
    View,
} from '@react-pdf/renderer';

const OverviewRows = ({ items, currency, classes, status, styles }) => {

    return (
        <>
            <View wrap={false} style={styles.tableRow}>

                <View style={styles.table2Col}>
                    <Text style={styles.tableCellBold}>
                        {t('invoicing.overview.subtotal')}
                    </Text>
                </View>
                <View style={styles.table2Col}>
                    <Text style={[styles.tableCellBold, { textAlign: 'right' }]}>
                        {currency} {formatValuta(selectTotal(items))}
                    </Text>
                </View>
            </View>

            {selectVatRates(items).map(rate => (
                <View wrap={false} style={styles.tableRow} key={`vat_${rate.rate}`}>

                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellBold}>
                            {t('invoicing.overview.vat')}
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                            {currency} {formatValuta(selectTotal(items, true))}
                        </Text>
                    </View>
                    <View style={styles.table2Col}>
                        <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                            {currency} {'(' + formatValuta(rate.subTotal) + ') ' + formatValuta(rate.value)}
                        </Text>
                    </View>
                </View>
            ))}

            <View wrap={false} style={[styles.tableRow, { borderBottomWidth: 2, borderBottomColor: '#000000' }]}>

                <View style={styles.table2Col}>
                    <Text style={styles.tableCellBold}>
                        {t('invoicing.overview.total')}
                    </Text>
                </View>
                <View style={styles.table2Col}>
                    <Text style={[styles.tableCellBold, { textAlign: 'right' }]}>
                        {currency} {formatValuta(selectTotal(items, true))}
                    </Text>
                </View>
            </View>
        </>
    );
};

export default OverviewRows;
