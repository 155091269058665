import { t } from "core/i18n";
import { fireStore } from "core/networking/firebase";
import { docMetaData } from "core/networking/utils";
import { DocStatus } from "../auth/constants";
import { createNotification } from "../notifications/reducer";

export const softDeleteItem = (item, coll) => async (dispatch, getState) => {
    const id = item.id;
    const query = fireStore.collection(coll);
    const data = {}
    data.meta = docMetaData(
        getState(),
        (item.meta?.doc_status === DocStatus.SoftDeleted) ?
            DocStatus.Active :
            DocStatus.SoftDeleted);

    if (id) {
        await query.doc(id).set(data, { merge: true });
    } else {
        return Promise.reject(new Error(t('errors.not_found')));
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`products.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

/*export const createDocStatus = async () => {
    const documentSnapshotArray = await fireStore
        .collection(Collections.recurring)
        .get();

    const batchArray = [];
    batchArray.push(fireStore.batch());
    let operationCounter = 0;
    let batchIndex = 0;

    documentSnapshotArray.forEach(documentSnapshot => {
        const documentData = documentSnapshot.data();

        // Create query map with selected fields
        //const doc_status = DocStatus.Active;
        documentData.meta = {
            ...documentData.meta,
            doc_status: DocStatus.Active
        }

        batchArray[batchIndex].update(documentSnapshot.ref, documentData);
        operationCounter++;

        if (operationCounter === 499) {
            batchArray.push(fireStore.batch());
            batchIndex++;
            operationCounter = 0;
        }
    });

    batchArray.forEach(async batch => await batch.commit());
};*/