import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
    Paper,
    Typography,
    Button,
    Box,
    Link
} from '@material-ui/core';
import { t } from '../../../core/i18n';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import TextField from '../../shared/form/generic/TextField';
import { passwordReset } from '../../../core/modules/auth/actions';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../../core/routing/routing';
import { getErrorMessage } from '../../../core/networking/utils';
import Alert from '@material-ui/lab/Alert';

const passwordResetSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
});

const styles = () => ({
    paper: {
        padding: 30,
    },
    loginFooter: {
        color: 'white',
        padding: 30,
    },
    linkWhite: {
        '&:after': {
            backgroundColor: 'white',
        },
    },
    btn: {
        margin: '1em 0 0',
    },
});

const PasswordResetScene = ({ classes }) => {
    const dispatch = useDispatch();

    const [initialData, setInitialData] = useState({ email: '' });
    const [error, setError] = useState(null);

    useEffect(() => {
        setInitialData({});
    }, []);

    const onSubmit = useCallback(
        (data, { setSubmitting }) => {
            // set as loading
            setSubmitting(true);
            // try to log in
            dispatch(passwordReset(data.email)).catch(error => {
                setSubmitting(false);
                setError(getErrorMessage(error));
            });
        },
        [dispatch]
    );

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <Paper className={classes.paper}>
                <Typography component="p" variant="h6">
                    {t('onboarding.password_reset.title')}
                </Typography>

                {initialData ? (
                    <Formik
                        initialValues={initialData}
                        validationSchema={passwordResetSchema}
                        onSubmit={onSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <TextField
                                    label={t(
                                        'onboarding.password_reset.form.email'
                                    )}
                                    name="email"
                                    variant="outlined"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    className={classes.btn}>
                                    {t('onboarding.password_reset.form.submit')}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </Paper>

            <Box className={classes.loginFooter}>
                <Link
                    component={RouterLink}
                    to={ROUTE_LOGIN}
                    variant="body1"
                    color="inherit"
                    underline="none"
                    className={classes.linkWhite}>
                    {t('onboarding.password_reset.cancel')}
                </Link>
            </Box>
        </div>
    );
};

export default withStyles(styles)(PasswordResetScene);
