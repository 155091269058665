import React, { useState, useCallback } from 'react';
import RadioGroup from '../generic/RadioGroup';
import { t } from 'core/i18n';
import * as yup from 'yup';
import VatValidationField from './VatValidationField';
import { useFormikContext } from 'formik';

export const VatNumberFieldValidation = {
    vat_number: yup.object().shape({
        type: yup.string().required(),
        vat: yup.string().required(),
    }),
};

const VatNumberField = ({ errors }) => {
    const { values, setFieldValue } = useFormikContext();
    const [vatType, setVatType] = useState(
        !values.vat_number?.type ? null : values.vat_number.type
    );

    const onRadioInputChange = useCallback(
        e => {
            setVatType(e.target.value);
            if (vatType === 'none') {
                setFieldValue('vat_number.vat', '');
                setFieldValue('vat_number.summary', '');
            }
            setFieldValue('vat_number.type', e.target.value);
        },
        [setFieldValue, vatType]
    );

    return (
        <>
            <RadioGroup
                label={t('vat.fields.vat')}
                name="vat_number.type"
                inline={true}
                row
                value={vatType}
                onChange={onRadioInputChange}
                options={[
                    {
                        value: 'eu',
                        label: t('vat.fields.eu.eu'),
                    },
                    {
                        value: 'other',
                        label: t('vat.fields.eu.other'),
                    },
                    {
                        value: 'none',
                        label: t('vat.fields.eu.none'),
                    },
                ]}
            />
            {vatType !== 'none' && (
                <VatValidationField vatType={vatType} errors={errors} />
            )}
        </>
    );
};

export default VatNumberField;
