import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import TextField from '../../../shared/form/generic/TextField';
import {
    Paper,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from '../../../../core/i18n';
import {
    deleteProject,
    getProjectById,
    storeProject,
} from '../../../../core/modules/dashboard/projects/actions';
import BaseFormContainer from '../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../core/hooks/useForm';
import ClientBillingAccountSelect from '../../../shared/form/billing/ClientBillingAccountSelect';
import UploadField from 'components/shared/form/generic/UploadField';
import { useParams } from 'react-router-dom';
import { setProject } from 'core/modules/dashboard/projects/reducer';
import ProjectIntegrations from '../../apicenter/project/ProjectIntegrations';
import DatePicker from '../../../shared/form/generic/DatePicker';
import { CRUD, DocStatus } from 'core/modules/auth/constants';
import DeleteDialog from 'components/shared/dialog/DeleteDialog';
import { history, route, ROUTE_CLIENT_PROJECTS } from 'core/routing/routing';
import { Can } from 'components/shared/can/Can';
import { Collections } from 'core/networking/firebase';
import { softDeleteItem } from 'core/modules/general/actions';

const ProjectsSchema = yup.object().shape({
    name: yup.string().required(),
    showClient: yup.boolean(),
    billing_account: yup
        .string()
        .email()
        .when("showClient", {
            is: true,
            then: yup.string().required()
        })
});

const styles = () => ({
    paper: {
        padding: 20,
    },
    image: {
        maxWidth: 100,
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, .05)',
        padding: 4,
        margin: '10px 0',
    },
    deleteBtn: {
        margin: 20,
    },
});

const ProjectsFormContainer = ({ classes, onSuccess }) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const currentWorkspaceId = useSelector(state =>
        state.app.workspace
    );
    const { client } = useSelector(state => state.client);
    const workspace_client = useSelector(state => state.app.workspace_client);

    const [deleteItem, setDeleteItem] = useState(null);
    const [hardDelete, setHardDelete] = useState(null);

    const config = useMemo(
        () => ({
            fetch: () => getProjectById(projectId),
            store: values => storeProject(values),
            defaultValue: {
                client: {
                    id: workspace_client,
                    name: !!client ? client.name : '',
                },
                name: '',
                billing_account: '',
            },
            onSuccess,
            id: projectId,
        }),
        [client, onSuccess, workspace_client, projectId]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(
        config
    );

    useEffect(() => {
        const action = {};
        if (!!initialData) {
            action.project = initialData;
            action.current = initialData.id;
            dispatch(setProject(action));
        }
    }, [dispatch, initialData]);

    const handleConfirmDelete = () => {
        return dispatch(!!hardDelete ? deleteProject(deleteItem) : softDeleteItem(deleteItem, Collections.projects))
            .then(() => {
                setDeleteItem(null);
            })
            .finally(() => {
                history.push(
                    route(ROUTE_CLIENT_PROJECTS, { clientId: workspace_client, workspaceId: currentWorkspaceId })
                );
            });
    };

    return (
        !!initialData && (
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <>
                        <h1>{t(
                            projectId ? 'projects.update' : 'projects.create'
                        )}
                        </h1>
                        <Paper className={classes.paper}>
                            <Formik
                                validationSchema={ProjectsSchema}
                                initialValues={initialData}
                                onSubmit={onSubmit}>
                                {({
                                    isSubmitting,
                                    setFieldValue,
                                    submitForm,
                                }) => (
                                    <Form>
                                        {!initialData.client.id && (
                                            <ClientBillingAccountSelect />
                                        )}
                                        <TextField
                                            label={t('projects.fields.name')}
                                            name="name"
                                        />

                                        {!!initialData.image_url ? (
                                            <img
                                                src={initialData.image_url}
                                                alt={initialData.name}
                                                className={classes.image}
                                            />
                                        ) : null}
                                        {!!projectId && (
                                            <UploadField
                                                label={t(
                                                    'projects.fields.image'
                                                )}
                                                name="image_url"
                                                storagePath={`files/workspace_agencies/${currentWorkspaceId}/workspace_client/${workspace_client}/projects/${projectId}`}
                                                setFieldValue={setFieldValue}
                                                submitForm={submitForm}
                                            />
                                        )}

                                        <ProjectIntegrations name="integrations" />

                                        <DatePicker
                                            name="log_reset_date"
                                            label={t(
                                                'projects.fields.log_reset_date'
                                            )}
                                        />
                                        <Can i={projectId ? CRUD.Update : CRUD.Create} the={Collections.projects}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.btn}
                                                disabled={isSubmitting}>
                                                {t(
                                                    projectId
                                                        ? 'actions.update'
                                                        : 'actions.create'
                                                )}
                                            </Button>
                                        </Can>
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                        {!!projectId && (
                            <>
                                {initialData.meta?.doc_status === DocStatus.Active ? (
                                    <>
                                        <Can i={CRUD.SoftDelete} the={Collections.projects}>
                                            <Button
                                                aria-label={t('actions.soft_delete')}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.deleteBtn}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteItem(initialData);
                                                    setHardDelete(false);
                                                }}>
                                                {t('actions.soft_delete')}
                                            </Button>
                                        </Can>
                                        <Can i={CRUD.Delete} the={Collections.projects}>
                                            <Button
                                                aria-label={t('actions.soft_delete')}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.deleteBtn}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteItem(initialData);
                                                    setHardDelete(true);
                                                }}>
                                                {t('actions.delete')}
                                            </Button>

                                        </Can>
                                    </>
                                ) : (
                                        <Can i={CRUD.SoftDelete} the={Collections.projects}>
                                            <Button
                                                aria-label={t('actions.restore_delete')}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.deleteBtn}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteItem(initialData);
                                                    setHardDelete(false);
                                                }}>
                                                {t('actions.restore_delete')}
                                            </Button>
                                        </Can>
                                    )}
                                <DeleteDialog
                                    open={!!deleteItem}
                                    item={!!deleteItem ? deleteItem : null}
                                    hardDelete={!!hardDelete ? hardDelete : null}
                                    onCancel={() => { setDeleteItem(null); setHardDelete(null) }}
                                    onConfirm={handleConfirmDelete}
                                />
                            </>
                        )}
                    </>
                )}
            />
        )
    );
};

export default withStyles(styles)(ProjectsFormContainer);
