import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import TextField from 'components/shared/form/generic/TextField';
import {
    Paper,
    Button,
    Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import BaseFormContainer from 'components/shared/form/BaseFormContainer';
import { useForm } from 'core/hooks/useForm';
import { useSelector } from 'react-redux';
import { patchWorkspace } from '../../../../core/modules/workspaces/actions';
import AddressFields from '../../../shared/form/address/AddressFields';
import Select from '../../../shared/form/generic/Select';
import { Currencies } from '../../../../core/modules/currency/constants';
import TranslatableTextField from '../../../shared/form/generic/TranslatableTextField';
import RepeaterField from '../../../shared/form/repeater/RepeaterField';
import VatNumberField, {
    VatNumberFieldValidation,
} from '../../../shared/form/vat/VatNumberField';

const Schema = yup.object().shape({
    legal_name: yup.string().required(),
    billing_address: yup.object().shape({
        street: yup.string().required(),
        number: yup.string().required(),
        country: yup.string().required(),
    }),
    currency: yup.string().required(),
    vat_percentages: yup
        .array()
        .of(yup.number().required())
        .min(1),
    ...VatNumberFieldValidation,
    vat_default: yup.number().required(),
    label_current_book_year: yup.string().required(),
    prefix_current_book_year: yup.object().shape({
        quote: yup.string().required(),
    }),
});

// only select the values we'll actually need
const selectData = data => {
    return {
        id: data.id,
        ...{
            // default values
            invoice_terms: {},
            invoice_footer: {},
            quote_terms: {},
            vat_percentages: [''],
        },
        ...(data.accounting || {}),
    };
};

const prepareData = ({ id, ...original }) => {
    return {
        id: id,
        accounting: {
            ...original,
        },
    };
};

const styles = () => ({
    paper: {
        padding: 20,
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
});

const WorkspaceAccountingSettingsScene = ({ classes }) => {
    const workspaceId = useSelector(state => state.app.workspace);
    const workspaces = useSelector(state => state.workspaces.workspaces);
    let workspace = [];
    if (!!workspaces) {
        workspace = workspaces.find(space => space.id === workspaceId);
    }

    const config = useMemo(
        () => ({
            store: values => patchWorkspace(prepareData(values)),
            defaultValue: selectData(workspace) || {
                vat: {
                    type: 'eu',
                },
                legal_name: '',
                vat_default: '',
                label_current_book_year: '',
                prefix_current_book_year: {
                    quote: '',
                    order: '',
                    invoice: '',
                    credit_note: '',
                },
                vat_percentages: '',
                currency: '',
                billing_address: {
                    street: '',
                    number: '',
                    country: '',
                },
                vat_number: {
                    vat: '',
                    type: '',
                },
            },
        }),
        [workspace]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);
    return (
        !!initialData?.id && (
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <Paper className={classes.paper}>
                        <Formik
                            validationSchema={Schema}
                            initialValues={initialData}
                            onSubmit={onSubmit}>
                            {({ isSubmitting, values }) => (
                                <Form>
                                    <h2>{t('workspace.accounting.settings')}</h2>

                                    <TextField
                                        label={t(
                                            'workspace.accounting.fields.legal_name'
                                        )}
                                        name="legal_name"
                                    />

                                    <VatNumberField />

                                    <TextField
                                        label={t(
                                            'workspace.accounting.fields.label_current_book_year'
                                        )}
                                        name="label_current_book_year"
                                    />

                                    <h2>
                                        {t(
                                            'workspace.accounting.fields.prefix_current_book_year'
                                        )}
                                    </h2>

                                    <Box display="flex">
                                        <TextField
                                            label={t(
                                                'workspace.accounting.fields.prefix_current_book_year.quote'
                                            )}
                                            name="prefix_current_book_year.quote"
                                        />
                                        <TextField
                                            label={t(
                                                'workspace.accounting.fields.prefix_current_book_year.order'
                                            )}
                                            name="prefix_current_book_year.order"
                                            className={classes.flexBoxItem}
                                        />
                                        <TextField
                                            label={t(
                                                'workspace.accounting.fields.prefix_current_book_year.invoice'
                                            )}
                                            name="prefix_current_book_year.invoice"
                                            className={classes.flexBoxItem}
                                        />
                                        <TextField
                                            label={t(
                                                'workspace.accounting.fields.prefix_current_book_year.credit_note'
                                            )}
                                            name="prefix_current_book_year.credit_note"
                                            className={classes.flexBoxItem}
                                        />
                                    </Box>

                                    <Select
                                        label={t(
                                            'workspace.accounting.fields.currency'
                                        )}
                                        name="currency"
                                        options={Object.keys(Currencies).map(
                                            key => ({
                                                value: key,
                                                label: key,
                                            })
                                        )}
                                    />

                                    <RepeaterField
                                        label={t(
                                            'workspace.accounting.fields.vat_percentages'
                                        )}
                                        name="vat_percentages"
                                        type="number"
                                    />

                                    <Select
                                        label={t(
                                            'workspace.accounting.fields.default_vat_percentage'
                                        )}
                                        name="vat_default"
                                        options={values.vat_percentages.map(
                                            key => ({
                                                value: key,
                                                label: key,
                                            })
                                        )}
                                    />

                                    <h2>{t('workspace.accounting.terms')}</h2>

                                    <TranslatableTextField
                                        label={t(
                                            'workspace.accounting.fields.invoice_terms'
                                        )}
                                        name="invoice_terms"
                                        multiline
                                        rows="2"
                                    />

                                    <TranslatableTextField
                                        label={t(
                                            'workspace.accounting.fields.invoice_footer'
                                        )}
                                        name="invoice_footer"
                                        multiline
                                        rows="2"
                                    />

                                    <TranslatableTextField
                                        label={t(
                                            'workspace.accounting.fields.quote_terms'
                                        )}
                                        name="quote_terms"
                                        multiline
                                        rows="2"
                                    />

                                    <h2>
                                        {t('workspace.accounting.billing_address')}
                                    </h2>
                                    <AddressFields name="billing_address" />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn}
                                        disabled={isSubmitting}>
                                        {t('actions.update')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}
            />
        )
    );
};

export default withStyles(styles)(WorkspaceAccountingSettingsScene);
