import React from 'react';
import { Field, useField } from 'formik';
import {
    FormControl,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

const MaterialCheckboxInput = ({ label, defaultValue, ...props }) => {
    const [field] = useField(props);
    return (
        <FormControlLabel
            control={<Checkbox checked={!!field.value} {...field} {...props} />}
            label={label}
        />
    );
};

const CheckboxInput = ({ label, defaultValue, ...props }) => {
    const [field] = useField(props);

    return (
        <FormControl>
            <Field
                as={MaterialCheckboxInput}
                value={!!field.value}
                label={label}
                {...field}
                {...props}
            />
        </FormControl>
    );
};

export default CheckboxInput;
