import React from 'react';
import PageHeader from '../../../shared/header/PageHeader';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import {
    ROUTE_BILLING_ACCOUNTS,
    ROUTE_BILLING_ACCOUNTS_ADD,
    ROUTE_BILLING_ACCOUNTS_EDIT,
    ROUTE_BILLING_ACCOUNTS_DETAIL,
    ROUTE_CLIENT_BILLING_ACCOUNTS_EDIT,
    ROUTE_CLIENT_BILLING_ACCOUNTS_ADD,
    ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL,
    ROUTE_CLIENT_BILLING_ACCOUNTS,
} from '../../../../core/routing/routing';
import BillingAccountsOverview from '../../clients/detail/billing-accounts/overview/BillingAccountsOverview';
import BillingAccountsFormScene from '../../clients/detail/billing-accounts/form/BillingAccountsFormScene';
import { t } from '../../../../core/i18n';
import AppContent from '../../../shared/content/AppContent';
import BillingAccountsContainer from 'components/app/clients/detail/billing-accounts/BillingAccountsContainer';

const BillingAccountsScene = props => {
    const { clientId } = useParams();

    return (
        <>
            {!clientId && (
                <PageHeader title={t('billing_accounts.title')} />
            )}
            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_BILLING_ACCOUNTS_EDIT}
                        component={BillingAccountsFormScene}
                    />

                    <Route
                        path={ROUTE_CLIENT_BILLING_ACCOUNTS_EDIT}
                        component={BillingAccountsFormScene}
                    />

                    <Route
                        path={ROUTE_BILLING_ACCOUNTS_ADD}
                        component={BillingAccountsFormScene}
                    />

                    <Route
                        path={ROUTE_CLIENT_BILLING_ACCOUNTS_ADD}
                        component={BillingAccountsFormScene}
                    />

                    <Route
                        path={ROUTE_BILLING_ACCOUNTS_DETAIL}
                        component={BillingAccountsContainer}
                    />

                    <Route
                        path={ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL}
                        component={BillingAccountsContainer}
                    />

                    <Route
                        path={ROUTE_BILLING_ACCOUNTS}
                        component={BillingAccountsOverview}
                    />

                    <Route
                        path={ROUTE_CLIENT_BILLING_ACCOUNTS}
                        component={BillingAccountsOverview}
                    />

                    <Redirect to={ROUTE_BILLING_ACCOUNTS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default BillingAccountsScene;
