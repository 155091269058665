import React from 'react';
import PageHeader from '../../../shared/header/PageHeader';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ROUTE_PRODUCTS,
    ROUTE_PRODUCTS_ADD,
    ROUTE_PRODUCTS_EDIT,
} from '../../../../core/routing/routing';
import ProductsOverviewScene from './overview/ProductsOverviewScene';
import { t } from '../../../../core/i18n';
import ProductsFormScene from './form/ProductsFormScene';
import AppContent from '../../../shared/content/AppContent';

const ProductsScene = props => {
    return (
        <>
            <PageHeader title={t('products.title')} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_PRODUCTS_EDIT}
                        component={ProductsFormScene}
                    />
                    <Route
                        path={ROUTE_PRODUCTS_ADD}
                        component={ProductsFormScene}
                    />
                    <Route
                        path={ROUTE_PRODUCTS}
                        component={ProductsOverviewScene}
                    />
                    <Redirect to={ROUTE_PRODUCTS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default ProductsScene;
