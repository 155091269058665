import React, {
    useMemo,
    useState,
} from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import TextField from '../../../shared/form/generic/TextField';
import {
    Paper,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from '../../../../core/i18n';
import {
    deleteClient,
    getClientById,
    storeClient,
    //getClientImages,
} from '../../../../core/modules/dashboard/clients/actions';
import BaseFormContainer from '../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../core/hooks/useForm';
import UploadField from 'components/shared/form/generic/UploadField';
import { useDispatch, useSelector } from 'react-redux';
import { history, route, ROUTE_WORKSPACE_CLIENTS } from 'core/routing/routing';
import { Can } from 'components/shared/can/Can';
import { CRUD, DocStatus } from 'core/modules/auth/constants';
import { Collections } from 'core/networking/firebase';
import DeleteDialog from 'components/shared/dialog/DeleteDialog';
import { useParams } from 'react-router-dom';
import { softDeleteItem } from 'core/modules/general/actions';

const Schema = yup.object().shape({
    name: yup.string().required(),
    //full_name: yup.string(),
    //language: yup.string().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
    image: {
        maxWidth: 100,
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, .05)',
        padding: 4,
        margin: '10px 0',
    },
    editOriginalImageContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 15,
    },
    editOriginalImage: {
        marginLeft: 15,
        marginBottom: 10,
    },
    deleteBtn: {
        margin: 20,
    },
});

const ClientsFormContainer = ({ classes, onSuccess }) => {
    const dispatch = useDispatch();
    const { clientId } = useParams();
    const currentWorkspace = useSelector(state => state.app.workspace);

    const [deleteItem, setDeleteItem] = useState(null);
    const [hardDelete, setHardDelete] = useState(null);

    const config = useMemo(
        () => ({
            fetch: () => getClientById(clientId),
            store: values => storeClient(values),
            defaultValue: {

            },
            onSuccess,
            id: clientId,
        }),
        [clientId, onSuccess]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    const handleConfirmDelete = () => {
        return dispatch(!!hardDelete ? deleteClient(deleteItem) : softDeleteItem(deleteItem, Collections.clients))
            .then(() => {
                setDeleteItem(null);
            }).finally(() => {
                history.push(
                    route(ROUTE_WORKSPACE_CLIENTS, { workspaceId: currentWorkspace })
                );
            })
    };

    return (
        !!initialData && (
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <>
                        <h1>{t(
                            clientId ? 'clients.update' : 'clients.create'
                        )}
                        </h1>
                        <Paper className={classes.paper}>
                            <Formik
                                validationSchema={Schema}
                                initialValues={initialData}
                                onSubmit={onSubmit}>
                                {({ isSubmitting, setFieldValue, submitForm }) => (
                                    <Form>
                                        <TextField
                                            label={t('clients.fields.name')}
                                            name="name"
                                        />
                                        {!!initialData.image_url ? (
                                            <div
                                                className={
                                                    classes.editOriginalImageContainer
                                                }>
                                                <img
                                                    src={initialData.image_url}
                                                    alt={initialData.name}
                                                    className={classes.image}
                                                />
                                                {/*<Button
                                            variant="contained"
                                            color="primary"
                                            className={
                                                classes.editOriginalImage
                                            }
                                            startIcon={<EditIcon />}
                                            onClick={onClickImageEdit}>
                                            Edit crop
                                        </Button>
                                        {showEditField && (
                                            <EditUploadField
                                                label={t(
                                                    'clients.fields.original.image'
                                                )}
                                                name="file"
                                                setFieldValue={setFieldValue}
                                                className="editUploadField"
                                            />
                                                )}*/}
                                            </div>
                                        ) : null}
                                        <UploadField
                                            label={t('clients.fields.image')}
                                            name="image_url"
                                            storagePath={`files/workspace_agencies/${currentWorkspace}/workspace_client/${clientId}`}
                                            setFieldValue={setFieldValue}
                                            submitForm={submitForm}
                                        />
                                        <Can i={clientId ? CRUD.Update : CRUD.Create} the={Collections.clients}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.btn}
                                                disabled={isSubmitting}>
                                                {t(
                                                    clientId ? 'actions.update' : 'actions.create'
                                                )}
                                            </Button>
                                        </Can>
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                        {!!clientId && (
                            <>
                                {initialData.meta?.doc_status === DocStatus.Active ? (
                                    <>
                                        <Can i={CRUD.SoftDelete} the={Collections.clients}>
                                            <Button
                                                aria-label={t('actions.soft_delete')}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.deleteBtn}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteItem(initialData);
                                                    setHardDelete(false);
                                                }}>
                                                {t('actions.soft_delete')}
                                            </Button>
                                        </Can>
                                        <Can i={CRUD.Delete} the={Collections.clients}>
                                            <Button
                                                aria-label={t('actions.soft_delete')}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.deleteBtn}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteItem(initialData);
                                                    setHardDelete(true);
                                                }}>
                                                {t('actions.delete')}
                                            </Button>
                                        </Can>
                                    </>
                                ) : (
                                        <Can i={CRUD.SoftDelete} the={Collections.clients}>
                                            <Button
                                                aria-label={t('actions.restore_delete')}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.deleteBtn}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteItem(initialData);
                                                    setHardDelete(false);
                                                }}>
                                                {t('actions.restore_delete')}
                                            </Button>
                                        </Can>
                                    )}
                                <DeleteDialog
                                    open={!!deleteItem}
                                    item={!!deleteItem ? deleteItem : null}
                                    hardDelete={!!hardDelete ? hardDelete : null}
                                    onCancel={() => { setDeleteItem(null); setHardDelete(null) }}
                                    onConfirm={handleConfirmDelete}
                                />
                            </>
                        )}
                    </>
                )}
            />
        )
    );
};

export default withStyles(styles)(ClientsFormContainer);
