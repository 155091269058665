import { Breadcrumbs } from '@material-ui/core';
import { Collections } from 'core/networking/firebase';
import { history, route, ROUTE_CLIENT_PROJECTS, ROUTE_WORKSPACE } from 'core/routing/routing';
import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ data }) => {
    const workspaceAgency = data?.workspace_agency || null;
    //const clientId = data?.workspace_agency || null;
    //const docId = data?.payload?.id || null;
    const collection = data?.collection || null;
    console.log('d', data)
    return (
        <Breadcrumbs aria-label="breadcrumb">

            {!!workspaceAgency && (
                <Link onClick={() => history.push(route(ROUTE_WORKSPACE, { workspaceId: workspaceAgency }))}>
                    Portal
                </Link>
            )}

            {((collection === Collections.clients)) && (
                <Link onClick={() => history.push(route(ROUTE_CLIENT_PROJECTS, { workspaceId: workspaceAgency, clientId: data.payload.id }))}>
                    Workspace
                </Link>
            )}

        </Breadcrumbs>
    )
}

export default BreadCrumbs;