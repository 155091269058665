import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectLogsOverview from './overview/ProjectLogsOverview';
import {
    ROUTE_PROJECT_LOGS_ADD,
    ROUTE_PROJECT_LOGS_EDIT,
} from 'core/routing/routing';
import ProjectLogsFormScene from './form/ProjectLogsFormScene';

const ProjectLogsContainer = ({ project, projectId }) => {
    return (
        <>
            <Switch>
                <Route path={ROUTE_PROJECT_LOGS_EDIT}>
                    <ProjectLogsFormScene projectId={projectId} />
                </Route>

                <Route path={ROUTE_PROJECT_LOGS_ADD}>
                    <ProjectLogsFormScene projectId={projectId} />
                </Route>

                <Route>
                    <ProjectLogsOverview
                        project={project}
                        projectId={projectId}
                    />
                </Route>
            </Switch>
        </>
    );
};

export default ProjectLogsContainer;
