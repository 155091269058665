import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Button,
    Card,
    CardActions,
    CardContent
} from '@material-ui/core';
import { t } from 'core/i18n';
import { withStyles } from '@material-ui/core/styles';
import { getClientBillingAccountById } from '../../../core/modules/dashboard/clients/billing-accounts/actions';

const styles = () => ({
    paper: {
        padding: 20,
        marginBottom: 30,
    },
});

const ShippingAddressSelector = ({ onChange, classes, address, billingAccountId }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [adresses, setAdresses] = useState(null);

    const handleClickOpen = useCallback(() => {
        dispatch(getClientBillingAccountById(billingAccountId))
            .then(items => {
                setAdresses(items.shipping_address);
                setOpen(true);
            })
            .catch(error => console.log(error));

    }, [billingAccountId, dispatch]);

    const changeAddress = index => {
        onChange(adresses[index])
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={handleClickOpen}
            >
                {t(
                    'actions.invoicing.select_other.shipping_address'
                )}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                aria-labelledby="form-dialog-title">
                <DialogTitle>
                    {t(
                        'clients.billing_accounts.fields.shipping_address'
                    )}
                </DialogTitle>
                <DialogContent>
                    {!!adresses && (
                        adresses.map((field, index) => (
                            <Card
                                className={classes.paper}
                                key={index}>
                                <CardContent>
                                    <Box>
                                        <Typography variant="body1">
                                            {adresses[index]?.contact_name}
                                        </Typography>
                                        <Typography variant="body1">
                                            {adresses[index]?.email}
                                        </Typography>
                                        <Typography variant="body1">
                                            {adresses[index]?.street} {adresses[index]?.number}
                                        </Typography>
                                        <Typography variant="body1">
                                            {adresses[index]?.postal_code} {adresses[index]?.city}
                                        </Typography>
                                        <Typography variant="body1">
                                            {adresses[index]?.state}
                                        </Typography>
                                        <Typography variant="body1">
                                            {adresses[index]?.country}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        aria-label={t(
                                            'actions.delete'
                                        )}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { setOpen(false); changeAddress(index); }}
                                    >
                                        {t('actions.invoicing.select.shipping_address')}
                                    </Button>
                                </CardActions>
                            </Card>
                        ))
                    )}
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        className={classes.btn}>
                        {t('actions.cancel')}
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    )
};

export default withStyles(styles)(ShippingAddressSelector);
