import React from 'react';
import PageHeader from '../../../shared/header/PageHeader';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_STOCK_ITEMS } from '../../../../core/routing/routing';
import StockItemOverviewScene from './overview/StockItemOverviewScene';
import { t } from '../../../../core/i18n';
import AppContent from '../../../shared/content/AppContent';

const StockItemScene = props => {
    return (
        <>
            <PageHeader title={t('stock_items.title')} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_STOCK_ITEMS}
                        component={StockItemOverviewScene}
                    />
                    <Redirect to={ROUTE_STOCK_ITEMS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default StockItemScene;
