import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    ROUTE_WORKSPACE_SETTINGS_ACCOUNTING,
    ROUTE_WORKSPACE_SETTINGS_GENERAL,
    ROUTE_WORKSPACE_CLIENTS,
    ROUTE_WORKSPACE_CLIENTS_ADD,
    ROUTE_WORKSPACE_USERS,
    ROUTE_WORKSPACE_EVENT_LOGS,
    ROUTE_WORKSPACE_SETTINGS_TAGS,
} from '../../../core/routing/routing';
import PageHeader from '../../shared/header/PageHeader';
import WorkspaceAccountingSettingsScene from './settings/WorkspaceAccountingSettingsScene';
import WorkspaceGeneralSettingsScene from './settings/WorkspaceGeneralSettingsScene';
import AppContent from '../../shared/content/AppContent';
import ClientsOverviewScene from '../clients/overview/ClientsOverviewScene';
import ClientsFormScene from '../clients/form/ClientsFormScene';
import WorkspaceUsersOverviewScene from './users/overview/WorkspaceUsersOverviewScene';
import WorkspaceTagsSettingsScene from './settings/WorkspaceTagsSettingsScene';
import { useSelector } from 'react-redux';
import WorkspaceEventsOverview from './events/overview/WorkspaceEventsOverview';
import { selectCurrentWorkspace } from 'core/modules/workspaces/selectors';

const WorkspaceScene = props => {
    const currentWorkspace = useSelector(state => selectCurrentWorkspace(state));
    return (
        <>
            <PageHeader title={currentWorkspace?.name || ""} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_WORKSPACE_EVENT_LOGS}
                        component={WorkspaceEventsOverview}
                    />

                    <Route
                        path={ROUTE_WORKSPACE_USERS}
                        component={WorkspaceUsersOverviewScene}
                    />

                    <Route
                        path={ROUTE_WORKSPACE_SETTINGS_ACCOUNTING}
                        component={WorkspaceAccountingSettingsScene}
                    />

                    <Route
                        path={ROUTE_WORKSPACE_SETTINGS_GENERAL}
                        component={WorkspaceGeneralSettingsScene}
                    />

                    <Route
                        path={ROUTE_WORKSPACE_SETTINGS_TAGS}
                        component={WorkspaceTagsSettingsScene}
                    />

                    <Route
                        path={ROUTE_WORKSPACE_CLIENTS_ADD}
                        component={ClientsFormScene}
                    />

                    <Route
                        path={ROUTE_WORKSPACE_CLIENTS}
                        component={ClientsOverviewScene}
                    />

                    <Redirect to={ROUTE_WORKSPACE_CLIENTS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default WorkspaceScene;
