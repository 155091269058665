import React from 'react';
import PageHeader from '../../shared/header/PageHeader';
import {
    ROUTE_PROJECTS_EDIT,
    ROUTE_PROJECTS_ADD,
    ROUTE_PROJECT_LOGS,
} from '../../../core/routing/routing';
import ProjectsFormScene from './form/ProjectsFormScene';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import AppContent from '../../shared/content/AppContent';
import ProjectDetailScene from './detail/ProjectDetailScene';
import { useSelector } from 'react-redux';

const ProjectsScene = props => {
    const projectName = useSelector(state => state.project.project?.name || "")

    return (
        <>
            <PageHeader title={projectName} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_PROJECTS_EDIT}
                        component={ProjectsFormScene}
                    />

                    <Route
                        path={ROUTE_PROJECTS_ADD}
                        component={ProjectsFormScene}
                    />

                    <Route
                        path={ROUTE_PROJECT_LOGS}
                        component={ProjectDetailScene}
                    />

                    <Redirect to={ROUTE_PROJECT_LOGS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default ProjectsScene;
