import { Collections, fireStore } from '../../networking/firebase';
import { docMetaData, getDocData } from '../../networking/utils';
import { t } from '../../i18n';
import { setGlobalTags } from './reducer';
import { createNotification } from '../notifications/reducer';
import { selectCurrentWorkspaceId } from '../app/selectors';

// Readme: we'll make this more dynamic so it's not only for stock tags.

export const fetchTagsForStock = async (id, workspace) => {
    return fireStore
        .collection(Collections.product_stock)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);
                if (data.workspace_agency === workspace) {
                    return data.tags ?? [];
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getTagsForStock = docId => async (dispatch, getState) => {
    return await fetchTagsForStock(docId, selectCurrentWorkspaceId(getState()));
};

export const storeTagsForStock = (id, tags) => async (dispatch, getState) => {
    const query = fireStore.collection(Collections.product_stock);
    //data.meta = docMetaData(getState());
    await query.doc(id).set({ tags: tags, meta: docMetaData(getState()) }, { merge: true });
    return tags;
};

export const addTagToStock = (id, tag) => async (dispatch, getState) => {
    return await dispatch(getTagsForStock(id)).then(async data => {
        // Check first if it is not in there yet.
        if (!!data && Array.isArray(data)) {
            if (data.indexOf(tag) === -1) {
                data.push(tag);
                return dispatch(storeTagsForStock(id, data)).then(data => {
                    return data;
                });
            }
        }
        return data;
    });
};

export const addTagForCurrentWorkspace = tagName => async (
    dispatch,
    getState
) => {
    return await dispatch(fetchAllTagsForCurrentWorkspace()).then(
        async currentTags => {
            const filtered = currentTags.filter(i => i.name === tagName);
            // Check if it does not exist.
            if (filtered.length === 0) {
                const query = fireStore.collection(Collections.tags);
                const newDocData = {};
                newDocData.name = tagName;
                newDocData.workspace_agency = selectCurrentWorkspaceId(
                    getState()
                );
                const newRef = await query.add(newDocData);
                const id = newRef.id;
                const newTagItem = { ...newDocData, id };
                const newTags = [...currentTags];
                newTags.push(newTagItem);
                // Set new global tags in redux.
                dispatch(setGlobalTags(newTags));
                return newTagItem;
            }
            return null;
        }
    );
};
export const fetchTagById = (id, workspace) => {
    return fireStore
        .collection(Collections.tags)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);

                if (data.workspace_agency === workspace) {
                    return data;
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getTagById = id => (dispatch, getState) => {
    return fetchTagById(id, selectCurrentWorkspaceId(getState()));
};

export const storeTag = ({ id, ...data }) => async (dispatch, getState) => {
    const query = fireStore.collection(Collections.tags);

    // Add workspace.
    data.workspace_agency = selectCurrentWorkspaceId(getState());

    if (id) {
        await query.doc(id).set(data);
    } else {
        const ref = await query.add(data);
        id = ref.id;
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`tagging.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const deleteTag = id => async dispatch => {
    await fireStore
        .collection(Collections.tags)
        .doc(id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('tagging.notifications.delete'),
        })
    );
};

export const fetchAllTagsForCurrentWorkspace = () => async (
    dispatch,
    getState
) => {
    const workspace = selectCurrentWorkspaceId(getState());

    // Check if current workspace is known.
    if (!!workspace) {
        return fireStore
            .collection(Collections.tags)
            .where('workspace_agency', '==', workspace)
            .get()
            .then(resp => {
                // Send the tags to redux and return new state tags!
                const action = resp.docs.map(doc => getDocData(doc));
                dispatch(setGlobalTags(action));
                const { items } = getState().tags;
                return items;
            });
    }

    // Return state.
    return getState().tags.items;
};

export const getAllTagsForCurrentWorkspace = () => async (
    dispatch,
    getState
) => {
    // Return state.
    return getState().tags.items;
};
