import React, { useMemo, useState } from 'react';
import { storeUser } from 'core/modules/users/actions';
import {
    Paper,
    Avatar,
    Box,
    Typography,
    InputAdornment,
    Grid,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useForm } from 'core/hooks/useForm';
import UploadField from 'components/shared/form/generic/UploadField';
import { t } from 'core/i18n';
import TextField from '../../../shared/form/generic/TextField';
import { ROUTE_USER_OVERVIEW } from 'core/routing/routing';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BaseFormContainer from 'components/shared/form/BaseFormContainer';
import { passwordReset, sendEmailVerification } from 'core/modules/auth/actions';
import CountrySelect from 'components/shared/form/country/CountrySelect';
import DatePicker from '../../../shared/form/generic/DatePicker';
import RadioGroup from 'components/shared/form/generic/RadioGroup';
import PhoneInputField from 'components/shared/form/generic/PhoneInputField';

const Schema = yup.object().shape({
    phoneNumber: yup.string().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
    image: {
        maxWidth: 100,
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, .05)',
        padding: 4,
        margin: '10px 0'
    },
    large: {
        margin: "20px 0",
        width: "120px",
        height: "120px",
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
    btn: {
        marginTop: 20,
        marginRight: 20,
    }
});

const UserFormContainer = ({ onSuccess, classes }) => {
    //const { setFieldValue } = useFormikContext();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    const [initialData] = useState(user || {})
    const [submitting, setSubmitting] = useState(false);
    // Check if user has log in other than with password
    // If for example google login (> 0)
    // user cannot upload profile img
    const googleIndex = user.providerData.findIndex(e => e.providerId === 'google.com');

    const config = useMemo(
        () => ({
            store: values => storeUser(values),
            defaultValue: {

            },
            onSuccess
        }),
        [onSuccess]
    );

    const { onSubmit, isLoading, error } = useForm(config);

    const handleVerifyEmail = () => {
        setSubmitting(true);
        dispatch(sendEmailVerification())
            .then(() => {
                setSubmitting(false);
            })
            .catch(() => {
                window.alert(t('errors.generic'));
            });
    };

    const handlePasswordReset = () => {
        setSubmitting(true);
        dispatch(passwordReset(user.email))
            .then(() => {
                setSubmitting(false);
            })
            .catch(() => {
                window.alert(t('errors.generic'));
            });
    };

    return (
        !!initialData && (
            <>
                <Typography variant="h4" component="h2" gutterBottom>
                    {t('user.profile.edit.title')}
                </Typography>
                <BaseFormContainer
                    isLoading={isLoading}
                    error={error}
                    initialData={initialData}
                    render={() => (
                        <Paper className={classes.paper}>
                            <Formik
                                validationSchema={Schema}
                                initialValues={initialData}
                                onSubmit={onSubmit}>
                                {({ setFieldValue, submitForm, isSubmitting }) => (
                                    <Form>
                                        <Avatar
                                            alt={initialData.userName}
                                            src={initialData.avatar}
                                            className={classes.large}
                                        />
                                        {googleIndex !== 0 && (
                                            <UploadField
                                                label={t('user.overview.profile.fields.image')}
                                                name="avatar"
                                                storagePath={`files/users/${initialData.uid}`}
                                                setFieldValue={setFieldValue}
                                                submitForm={submitForm}
                                            />
                                        )}
                                        <TextField
                                            label={t('user.overview.profile.fields.email')}
                                            name="email"
                                            disabled
                                            InputProps={{
                                                endAdornment:
                                                    !user.emailVerified ? (
                                                        <>
                                                            <InputAdornment position="end">
                                                                <Button
                                                                    aria-label={t('actions.verify')}
                                                                    onClick={() => handleVerifyEmail()}
                                                                    edge="end"
                                                                    disabled={submitting}
                                                                >
                                                                    {t('actions.verify')}
                                                                </Button>
                                                            </InputAdornment>,
                                                        </>
                                                    ) : (
                                                            <>
                                                                <InputAdornment
                                                                    position="end"
                                                                    disabled={true}>
                                                                    {t('actions.verified')}
                                                                </InputAdornment>
                                                            </>
                                                        )
                                            }}
                                        />
                                        <Typography component="p" variant="h6">
                                            {t('user.overview.profile.personal_data')}
                                        </Typography>
                                        <Box display="flex">
                                            <RadioGroup
                                                label={t('user.overview.profile.fields.gender')}
                                                name="gender"
                                                inline={true}
                                                row
                                                onChange={(e) => setFieldValue('gender', e.target.value)}
                                                options={[
                                                    {
                                                        value: 'male',
                                                        label: t('user.overview.profile.fields.gender.male'),
                                                    },
                                                    {
                                                        value: 'female',
                                                        label: t('user.overview.profile.fields.gender.female'),
                                                    },
                                                    {
                                                        value: 'x',
                                                        label: t('user.overview.profile.fields.gender.x'),
                                                    },
                                                    {
                                                        value: 'none',
                                                        label: t('user.overview.profile.fields.gender.not_given'),
                                                    },
                                                ]}
                                            />
                                            <DatePicker
                                                disableFuture
                                                openTo="year"
                                                views={["year", "month", "date"]}
                                                className={classes.flexBoxItem}
                                                label={t('user.overview.profile.fields.dob')}
                                                name="dob"
                                                inputValue={user.dob}
                                            />
                                        </Box>
                                        <Box display="flex">
                                            <TextField
                                                label={t('user.overview.profile.fields.first_name')}
                                                name="first_name"
                                            />
                                            <TextField
                                                className={classes.flexBoxItem}
                                                label={t('user.overview.profile.fields.last_name')}
                                                name="last_name"
                                            />
                                        </Box>
                                        <PhoneInputField
                                            label={t('user.overview.profile.fields.phone_number')}
                                            name="phoneNumber"
                                        />
                                        <Typography component="p" variant="h6">
                                            {t('user.overview.profile.address')}
                                        </Typography>
                                        <TextField
                                            label={t('user.overview.profile.fields.street_nr')}
                                            name="street_nr"
                                        />
                                        <Box display="flex">
                                            <TextField
                                                label={t('user.overview.profile.fields.city')}
                                                name="city"
                                            />
                                            <TextField
                                                className={classes.flexBoxItem}
                                                label={t('user.overview.profile.fields.postal_code')}
                                                name="postal_code"
                                            />
                                        </Box>
                                        <CountrySelect
                                            label={t('user.overview.profile.fields.country')}
                                            name={`country`}
                                        />
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    disabled={submitting}>
                                                    {t('actions.update')}
                                                </Button>
                                                <Button
                                                    component={NavLink}
                                                    to={
                                                        ROUTE_USER_OVERVIEW
                                                    }
                                                    className={classes.btn}
                                                    variant="contained"
                                                    disabled={submitting}
                                                >
                                                    {t('actions.cancel')}
                                                </Button>
                                            </Grid>
                                            <Grid>
                                                <Button
                                                    color="primary"
                                                    onClick={() => handlePasswordReset()}
                                                    disabled={submitting}>
                                                    {t('onboarding.password_reset.title')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    )}
                />
            </>
        )
    )
}

export default withStyles(styles)(UserFormContainer);