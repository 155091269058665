import { Collections, fireStore } from '../../../networking/firebase';
import { createNotification } from '../../notifications/reducer';
import { t } from '../../../i18n';
import { docMetaData, getDocData } from '../../../networking/utils';
import { isVoid } from 'core/utils/isVoid';
import { updateProject } from './reducer';
import { selectCurrentClientWorkspace } from '../clients/selector';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { isEmptyText } from 'core/utils/isEmptyText';
import { setLocalStorage } from 'core/modules/app/reducer';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchProjects = async (allItems, id, workspace) => {
    let query = fireStore
        .collection(Collections.projects)
        .orderBy('name_lower', 'asc');

    if (!allItems) {
        query = query.where('meta.doc_status', '==', DocStatus.Active);
    }

    if (isVoid(id)) {
        return await query
            .where('workspace_agency', '==', workspace)
            .get()
            .then(response => response.docs.map(doc => getDocData(doc)));
    }
    if (!isEmptyText(workspace)) {
        return await query
            .where('workspace_agency', '==', workspace)
            .where('client.id', '==', id)
            .get()
            .then(response => response.docs.map(doc => getDocData(doc)));
    }
    return await query
        .where('client.id', '==', id)
        .get()
        .then(response => response.docs.map(doc => getDocData(doc)));
};

export const getProjects = (allItems, clientId) => (dispatch, getState) => {
    const clientWorkspace = selectCurrentClientWorkspace(getState());
    const workspace = selectCurrentWorkspaceId(getState());
    return fetchProjects(allItems, clientId, !!workspace ? workspace : clientWorkspace);
};

export const fetchProjectById = (id, workspace) => {
    return fireStore
        .collection(Collections.projects)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);
                return data;
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getProjectById = id => (dispatch, getState) => {
    const clientWorkspace = selectCurrentClientWorkspace(getState());
    const workspace = selectCurrentWorkspaceId(getState());
    return fetchProjectById(
        id,
        !!clientWorkspace ? clientWorkspace : workspace
    );
};

export const storeProject = ({ id, ...data }) => async (dispatch, getState) => {
    console.log('project data', data)
    const query = fireStore.collection(Collections.projects);
    data.meta = docMetaData(getState());
    // add workspace
    data.workspace_agency = selectCurrentWorkspaceId(getState());

    // add lowercase name fiel to order by
    data.name_lower = data.name.toLowerCase();

    /*if (!!data.file) {
        const file = data.file;
        const storageRef = fireStorage.ref();
        const mainImage = storageRef.child('files/workspace_agencies/' + data.workspace_agency + '/workspace_client/' + data.client.id + '/project/' + id + '/' + file.name);

        await mainImage.put(file).then(function (snapshot) {

        });

        await mainImage.getDownloadURL().then(function (downloadURL) {
            data.image_url = downloadURL;
        });

        delete data.file;
    }*/

    if (id) {
        await query.doc(id).set(data);
    } else {
        const ref = await query.add(data);
        id = ref.id;
    }

    // save workspaceClient
    dispatch(updateProject({ ...data }));

    dispatch(
        createNotification({
            type: 'success',
            message: t(`projects.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const deleteProject = data => async dispatch => {
    const action = {};
    action.workspace = data.workspace_agency;
    action.workspace_client = data.client.id;
    try {
        await fireStore
            .collection(Collections.projects)
            .doc(data.id)
            .delete();

        dispatch(setLocalStorage(action));

        return dispatch(
            createNotification({
                type: 'error',
                message: t('projects.notifications.delete'),
            })
        );
    } catch (error) {
        console.log(error)
    }
};
