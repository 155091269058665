import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    Link,
    Button,
    Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
    ROUTE_LOGIN,
} from 'core/routing/routing';
import Alert from '@material-ui/lab/Alert';
import { t } from 'core/i18n';
import TextField from '../../shared/form/generic/TextField';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    handleConfirmEmail,
} from 'core/modules/auth/actions';
import { getErrorMessage } from 'core/networking/utils';

const Schema = yup.object().shape({
    email: yup
        .string()
        .min(8)
        .required(),
});

const styles = () => ({
    paper: {
        padding: 30,
    },
    loginFooter: {
        color: 'white',
        padding: 30,
    },
    linkWhite: {
        '&:after': {
            backgroundColor: 'white',
        },
    },
    btn: {
        margin: '1em 0',
    },
});

/*const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}*/

const CompleteInviteScene = ({ classes }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const [
        initialData,
        //setInitialData
    ] = useState({ new_password: '' });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    //const [resetEmail, setResetEmail] = useState(null);

    //let query = useQuery();
    //const mode = query.get('mode');
    //const oobCode = query.get('oobCode');
    //const apiKey = query.get('apiKey');
    //const lang = query.get('lang');

    useEffect(() => {
        if (!!user) {
            //dispatch(logout());
        }
    }, [dispatch, user])

    const onSubmit = useCallback(
        data => {
            // set as loading
            setLoading(true);
            // try to log in
            dispatch(handleConfirmEmail(data.email))
                .then(res => {
                    if (!!res?.code) {
                        setLoading(false);
                        setError(getErrorMessage(res));
                    }
                })
        },
        [dispatch]
    );

    return !user && (
        <>
            <Paper className={classes.paper}>
                <Typography component="p" variant="h6">
                    {t('onboarding.user_management.actions.title.complete_register')}
                </Typography>

                <Formik
                    initialValues={initialData}
                    validationSchema={Schema}
                    onSubmit={onSubmit}>
                    {({ errors, touched, isValid }) => (
                        <Form>
                            <TextField
                                label={t('onboarding.login.form.email')}
                                name="email"
                                type="email"
                                variant="outlined"
                            />
                            {error && (
                                <Alert severity="error">{error}</Alert>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                className={classes.btn}
                                disableElevation>
                                {t('actions.continue')}
                            </Button>

                        </Form>
                    )}
                </Formik>

            </Paper>
            <Box className={classes.loginFooter}>
                <Link
                    component={RouterLink}
                    to={ROUTE_LOGIN}
                    variant="body1"
                    color="inherit"
                    underline="none"
                    className={classes.linkWhite}>
                    {t('onboarding.password_reset.cancel')}
                </Link>
            </Box>
        </>
    )
}

export default withStyles(styles)(CompleteInviteScene);