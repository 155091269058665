import { Collections, fireStore } from '../../../networking/firebase';
import { createNotification } from '../../notifications/reducer';
import { t } from '../../../i18n';
import { docMetaData, getDocData } from '../../../networking/utils';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchSuppliers = async (allItems, workspace) => {
    const query = fireStore
        .collection(Collections.suppliers)
        .where('workspace_agency', '==', workspace)
        .orderBy('name_lower', 'asc');

    if (allItems) {
        return await query
            .get()
            .then(resp => resp.docs.map(doc => getDocData(doc)));
    } else {
        return await query
            .where('meta.doc_status', '==', DocStatus.Active)
            .get()
            .then(resp => resp.docs.map(doc => getDocData(doc)));
    }
};

export const getSuppliers = (allItems) => (dispatch, getState) => {
    return fetchSuppliers(allItems, selectCurrentWorkspaceId(getState()));
};

export const fetchSupplierById = (id, workspace) => {
    return fireStore
        .collection(Collections.suppliers)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);

                if (data.workspace_agency === workspace) {
                    return data;
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getSupplierById = id => (dispatch, getState) => {
    return fetchSupplierById(id, selectCurrentWorkspaceId(getState()));
};

export const storeSupplier = ({ id, ...data }) => async (
    dispatch,
    getState
) => {
    const query = fireStore.collection(Collections.suppliers);

    // add workspace
    data.workspace_agency = selectCurrentWorkspaceId(getState());
    data.meta = docMetaData(getState());
    // add lowercase name field to order by
    data.name_lower = data.name.toLowerCase();

    if (id) {
        await query.doc(id).set(data);
    } else {
        const ref = await query.add(data);
        id = ref.id;
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`suppliers.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const deleteSupplier = data => async dispatch => {
    await fireStore
        .collection(Collections.suppliers)
        .doc(data.id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('suppliers.notifications.delete'),
        })
    );
};
