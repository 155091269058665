import React from 'react';
import {
    Chip
} from '@material-ui/core';
import {
    InvoicingStatus,
    InvoicingApporvalStatus,
} from 'core/modules/accounting/invoicing/constants';
import { t } from 'core/i18n';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Schedule as ScheduleIcon } from '@material-ui/icons';

const styles = () => ({
    root: {
        '&.draft': {
            backgroundColor: '#e0e0e0',
        },
        '&.published': {
            backgroundColor: '#3f51b5',
        },
        '&.accepted': {
            backgroundColor: '#2ecc71',
        },
        '&.approval_accepted': {
            backgroundColor: '#2ecc71',
        },
        '&.refused': {
            backgroundColor: '#f50057',
        },
        '&.approval_refused': {
            backgroundColor: '#f50057',
        }
    },
});

const StatusChip = ({ classes, type, status }) => {

    return (
        <Chip
            avatar={(!status || status === InvoicingApporvalStatus.ApprovalAwaiting) ? <ScheduleIcon /> : null}
            label={
                !!status ?
                    t(`invoicing.status.${type}.${status}`) :
                    t(`invoicing.status.${type}.approval_awaiting`)
            }
            size="small"
            color={
                status ===
                    InvoicingStatus.Draft
                    ? 'default'
                    : 'primary'
            }
            className={
                clsx(classes.root, status)
            }
        />
    )
}

export default withStyles(styles)(StatusChip);