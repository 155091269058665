import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import Progress from 'components/shared/progress/Progress';

const styles = () => ({
    paper: {
        padding: 20,
        marginBottom: 30,
    },
    resultContainer: {
        padding: "20px 0",
    }
});


const VatResult = ({ classes, result, onSelectVat, isLoading, onClose, errors }) => {
    const [open, setOpen] = useState(!!result ? result.valid : false);

    useEffect(() => {
        if (!!result) {
            setOpen(true);
        } else {
            setOpen(isLoading)
        }
    }, [result, isLoading])

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    return (
        //!!result || isLoading && (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <DialogTitle>
                {t(
                    'vat.fields.vat_number'
                )}
            </DialogTitle>
            <DialogContent>
                <Card
                    className={classes.paper}>
                    <CardContent>
                        <Box>
                            <Progress isLoading={isLoading} />
                            {!!result && (
                                (result?.valid) ? (
                                    <Typography variant="body1">
                                        {result.company_address}<br />
                                        {result.company_name}<br />
                                        {result.country_code}
                                    </Typography>
                                ) : (
                                        <>
                                            {!!result.error ? (
                                                <Typography variant="body1">
                                                    Error code: {result.error.code}<br />
                                                    Error info: {result.error.info}<br />
                                                    Error type: {result.error.type}
                                                </Typography>
                                            ) : (
                                                    <Typography variant="body1">
                                                        {t(`${errors?.vat_number?.vat}`)}
                                                    </Typography>
                                                )}
                                        </>
                                    ))}
                        </Box>
                    </CardContent>
                    <CardActions>
                        {result?.valid && (
                            <Button
                                aria-label={t(
                                    'actions.select'
                                )}
                                variant="contained"
                                color="primary"
                                onClick={() => { onSelectVat(result); handleClose(); }}
                            >
                                {t('actions.select')}
                            </Button>
                        )}
                    </CardActions>
                </Card >
            </DialogContent>
        </Dialog>
        //)
    )
}

export default withStyles(styles)(VatResult);