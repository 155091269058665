import React, { useEffect, useState } from 'react';
import {
    Paper,
    Button,
    Box,
    Dialog
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TagList from '../../../tagging/TagList';
import {
    deleteTag,
    fetchAllTagsForCurrentWorkspace,
} from '../../../../core/modules/tagging/actions';
import DeleteDialog from '../../../shared/dialog/DeleteDialog';
import TagFormContainer from '../../../tagging/form/TagFormContainer';

const styles = () => ({
    paper: {
        padding: 20,
    },
});

const WorkspaceTagsSettingsScene = ({ classes }) => {
    const dispatch = useDispatch();
    const [tags, setTags] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [openTagFormDialog, setOpenTagFormDialog] = useState(false);
    const [refresh, setRefresh] = useState(new Date());

    useEffect(() => {
        dispatch(fetchAllTagsForCurrentWorkspace()).then(data => {
            setTags(data);
        });
    }, [dispatch, refresh]);

    const handleAddTag = () => {
        setOpenTagFormDialog(true);
    };

    const handleAddTagSuccess = () => {
        setRefresh(new Date());
        setEditItem(null);
        setOpenTagFormDialog(false);
    };

    const handleClickTag = tag => {
        setEditItem(tag.id);
        setOpenTagFormDialog(true);
    };

    const handleDeleteTag = tag => {
        setDeleteItem(tag);
    };

    const handleDeleteTagConfirm = () => {
        if (!!deleteItem) {
            dispatch(deleteTag(deleteItem.id))
                .then(() => {
                    setRefresh(new Date());
                })
                .finally(() => {
                    setDeleteItem(null);
                });
        }
    };

    return (
        <Paper className={classes.paper}>
            <TagList
                tags={tags}
                onDelete={handleDeleteTag}
                onClick={handleClickTag}
            />
            <Box style={{ marginTop: '20px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTag}>
                    Add
                </Button>
            </Box>

            <DeleteDialog
                open={!!deleteItem}
                item={!!deleteItem ? deleteItem : null}
                onCancel={() => setDeleteItem(null)}
                onConfirm={handleDeleteTagConfirm}
            />
            <Dialog
                open={openTagFormDialog}
                onClose={() => setOpenTagFormDialog(false)}>
                <TagFormContainer
                    id={editItem}
                    onSuccess={handleAddTagSuccess}
                />
            </Dialog>
        </Paper>
    );
};

export default withStyles(styles)(WorkspaceTagsSettingsScene);
