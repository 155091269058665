import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce/lib';

// Source: https://medium.com/@vlad_19457/reducing-the-number-of-renders-when-using-formik-9790bf111ab9
const TextUltraFastField = ({ label, type, defaultValue, name, ...props }) => {
    const [field, meta] = useField(name);
    const { handleChange } = useFormikContext();
    const [innerValue, setInnerValue] = useState('');

    useEffect(() => {
        if (field.value) {
            setInnerValue(field.value);
        } else {
            setInnerValue('');
        }
    }, [field.value]);

    // https://github.com/xnimorz/use-debounce
    const debouncedHandleOnChange = useDebouncedCallback(
        (e) => {
            if (handleChange) {
                handleChange(e);
            }
        },
        200
    );

    const handleOnChange = useCallback((e) => {
        e.persist();

        const newValue = e.currentTarget.value;
        setInnerValue(newValue);
        debouncedHandleOnChange.callback(e);
    }, []);

    return (
        <TextField
            {...field}
            name={name}
            fullWidth
            margin="dense"
            label={label}
            value={innerValue}
            onChange={handleOnChange}
            type={type || 'text'}
            error={!!meta.error && meta.touched}
            helperText={meta.touched ? meta.error : ''}
            {...props}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default TextUltraFastField;
