import { withStyles } from '@material-ui/core/styles';
import AppContent from 'components/shared/content/AppContent';
import { ROUTE_SUPER_ADMIN_EVENT_LOGS } from 'core/routing/routing';
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import EventsOverview from './overview/EventsOverview';

const styles = () => ({

});

const EventsScene = ({ classes }) => {
    return (
        <>
            <AppContent>
                <Switch>

                    <Route
                        path={ROUTE_SUPER_ADMIN_EVENT_LOGS}
                        component={EventsOverview}
                    />

                    <Redirect to={ROUTE_SUPER_ADMIN_EVENT_LOGS} />
                </Switch>
            </AppContent>
        </>
    );
}

export default withStyles(styles)(EventsScene);