import React from 'react';
import { Box } from '@material-ui/core';
import { t } from 'core/i18n';
import Select from '../../../../shared/form/generic/Select';
import DatePicker from '../../../../shared/form/generic/DatePicker';

const RecurringFields = ({ ...props }) => {
    const frequencies = [
        { id: 'none', name: t('select.none') },
        { id: 'monthly', name: t('recurring.frequency.monthly') },
        { id: 'quarterly', name: t('recurring.frequency.quarterly') },
        { id: 'yearly', name: t('recurring.frequency.yearly') },
    ];

    const onRecurringDateChange = (date, value) => {
        // Not used right now.
    };

    return (
        <>
            <Box>
                <Box style={{ width: '40%' }}>
                    <Select
                        label={t('recurring.frequency')}
                        name="recurring.frequency"
                        options={frequencies.map(value => ({
                            value: value.id,
                            label: value.name,
                        }))}
                    />

                    <DatePicker
                        label={t('recurring.start_date')}
                        name="recurring.start_date"
                        onChange={onRecurringDateChange}
                    />
                </Box>
            </Box>
        </>
    );
};

export default RecurringFields;
