import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '../../storage/LocalStorage';
import { isEmptyText } from 'core/utils/isEmptyText';

const INITIAL_STATE = {
    locale: LocalStorage.getLocale(),
    workspace: LocalStorage.getWorkspace(),
    workspace_client: LocalStorage.getWorkspaceClient(),
    project: LocalStorage.getProject(),
};

const appSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        setLocale(state, action) {
            LocalStorage.setLocale(action.payload);
            state.locale = action.payload;
        },
        setNumberFormat(state, action) {
            const { decimal, thousands } = action.payload;
            state.number_format = {
                decimal: decimal,
                thousands: thousands
            };
        },
        setLocalStorage(state, action) {
            const { workspace_client, workspace, project } = action.payload;
            state.workspace = workspace;
            state.workspace_client = workspace_client;
            state.project = project;

            if (!!workspace) {
                LocalStorage.setWorkspace(workspace);
            } else {
                localStorage.removeItem(LocalStorage.KEY_WORKSPACE_CLIENT);
                localStorage.removeItem(LocalStorage.KEY_PROJECT);
                localStorage.removeItem(LocalStorage.KEY_WORKSPACE);
            }

            if (isEmptyText(workspace_client)) {
                localStorage.removeItem(LocalStorage.KEY_WORKSPACE_CLIENT);
            }

            if (isEmptyText(project)) {
                localStorage.removeItem(LocalStorage.KEY_PROJECT);
            }

            if (!!workspace_client) {
                LocalStorage.setWorkspaceClient(workspace_client);
            } else if (!isEmptyText(workspace_client)) {
                if (!!INITIAL_STATE.workspace_client) {
                    state.workspace_client = INITIAL_STATE.workspace_client;
                }
            }

            if (!!project) {
                LocalStorage.setProject(project);
            } else if (!isEmptyText(project)) {
                if (!!INITIAL_STATE.project) {
                    state.project = INITIAL_STATE.project;
                }
            }
        }
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = appSlice;
// Extract and export each action creator by name
export const { setLocale, setLocalStorage, setNumberFormat } = actions;
// Export the reducer, either as a default or named export
export default reducer;
