import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = { items: null };

const tagsSlice = createSlice({
    name: 'tags',
    initialState: INITIAL_STATE,
    reducers: {
        setGlobalTags(state, action) {
            state.items = action.payload;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = tagsSlice;
// Extract and export each action creator by name
export const { setGlobalTags } = actions;
// Export the reducer, either as a default or named export
export default reducer;
