import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Drawer,
    List,
    ListItem,
    Box,
    Avatar,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@material-ui/core';
import { getMainRoutes, route, ROUTE_WORKSPACE } from '../../core/routing/routing';
import { useDispatch, useSelector } from 'react-redux';
import NavGroup from './NavGroup';
import WorkspaceListItem from '../workspace/select/WorkspaceListItem';
import ClientListItem from '../app/clients/sidebar/ClientListItem';
import ProjectListItem from '../app/projects/sidebar/ProjectListItem';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Toc as TocIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { Roles, Permissions } from 'core/modules/auth/constants';
import WorkspaceList from 'components/workspace/list/WorkspaceList';
import { NavLink } from 'react-router-dom';
import { t } from 'core/i18n';
import WorkspaceMenu from 'components/workspace/menu/WorkspaceMenu';
import {
    getUserWorkspaces,
    getWorkspaces,
} from 'core/modules/workspaces/actions';
import { fetchAllTagsForCurrentWorkspace } from 'core/modules/tagging/actions';

const drawerWidth = 256;
//const drawerWidth = 316;
const workspaceDrawerWidth = 60;

const styles = theme => ({
    drawer: {
        backgroundColor: '#041E34',
        width: 'auto',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        flexDirection: 'column',
        marginLeft: 60,
        overflow: 'hidden',
    },
    drawerNav: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        flex: '1',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create(('width', 'margin-left'), {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create(('width', 'margin-left'), {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 53,
        marginLeft: 0,
    },
    drawerSimple: {
        marginLeft: 0,
    },
    workspaceDrawer: {
        backgroundColor: '#041E34',
        width: 'auto',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        flexDirection: 'column',
        borderRightColor: '#404854',
        borderRightWidth: '1px',
        //left: 0,
    },
    workspaceDrawerOpen: {
        width: workspaceDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    workspaceDrawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '54px',
    },
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        whiteSpace: 'nowrap',
        '& svg': {
            color: 'white',
        },
    },
    categoryHeaderHidden: {
        display: 'none',
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        whiteSpace: 'nowrap',
        display: 'flex',
        color: '#6FBDF8',
        fontSize: '16px',
        paddingLeft: '12px',
        paddingRight: '12px',
        background: '#123350',
        '&:hover': {
            background: 'rgba(51,97,136, 1)',
        },
    },
    brandingTitle: {
        fontSize: '24px !important',
        fontWeight: 'bold !important',
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
        backgroundColor: '#336188',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    dividerClose: {
        marginTop: 0,
    },
    logo: {
        width: '30px !important',
        height: 'auto',
    },
    chevronIcon: {
        color: 'white',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        '& svg': {
            float: 'right',
        },
    },
    settingsIcon: {
        color: 'white',
    },
    headerDescription: {
        display: 'block',
        color: 'white',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        position: 'absolute',
        width: '80%',
        left: 16,
        bottom: 0,
        fontSize: '12px',
        opacity: 0.5,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    headerDescriptionOpen: {
        opacity: 0,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    logoClose: {
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    square: {
        backgroundColor: 'rgba(255,255,255,0)',
    },

});

const Sidebar = props => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const workspaceRoles = useSelector(
        state => state.auth.user.workspace_roles
    );
    const clientRoles = useSelector(state => state.auth.user.client_roles);
    const { workspaces } = useSelector(state => state.workspaces);
    const workspaceLocalstorage = useSelector(state => state.app.workspace);
    const [open, setOpen] = useState(true);

    const isSuperAdmin = useSelector(
        state =>
            state.auth.user.role === Roles.Admin &&
            state.auth.user.permission === Permissions.Owner
    );

    useEffect(() => {
        // todo: Optimize tags call, do not call it on 2 places.
        if (!workspaces && isSuperAdmin) {
            dispatch(getWorkspaces());
            // We do this call to fill redux with the workspace global tags here.
            dispatch(fetchAllTagsForCurrentWorkspace());
        } else if (!workspaces) {
            dispatch(getUserWorkspaces());
            if (!!workspaceRoles[workspaceLocalstorage]) {
                // We do this call to fill redux with the workspace global tags here.
                dispatch(fetchAllTagsForCurrentWorkspace());
            }
        }
    }, [
        dispatch,
        workspaces,
        isSuperAdmin,
        workspaceLocalstorage,
        workspaceRoles,
    ]);

    const routes = useMemo(() => {
        // should be recursive in future
        return getMainRoutes(user, workspaceLocalstorage).filter(route => !route.hidden);
    }, [user, workspaceLocalstorage]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const { classes } = props;
    return (
        <Box displayPrint="none">
            {// Check if user has been added to multiple portals
                (Object.keys(workspaceRoles).length +
                    Object.keys(clientRoles).length >
                    1 ||
                    isSuperAdmin) && (
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.workspaceDrawer, {
                            [classes.workspaceDrawerOpen]: open,
                            [classes.workspaceDrawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx(classes.workspaceDrawer, {
                                [classes.workspaceDrawerOpen]: open,
                                [classes.workspaceDrawerClose]: !open,
                            }),
                        }}>
                        <nav className={classes.drawerNav}>
                            <WorkspaceList open={open} />
                        </nav>
                    </Drawer>
                )}

            {(!!workspaceLocalstorage) && (
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                        [classes.drawerSimple]:
                            Object.keys(workspaceRoles).length +
                                Object.keys(clientRoles).length >
                                1 || isSuperAdmin
                                ? false
                                : true,
                    })}
                    classes={{
                        paper: clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                            [classes.drawerSimple]:
                                Object.keys(workspaceRoles).length +
                                    Object.keys(clientRoles).length >
                                    1 || isSuperAdmin
                                    ? false
                                    : true,
                        }),
                    }}>
                    <WorkspaceListItem open={open} />

                    <nav className={classes.drawerNav}>
                        <List disablePadding>
                            {isSuperAdmin &&
                                routes.map(route => (
                                    <React.Fragment key={route.path}>
                                        <NavGroup
                                            key={route.path}
                                            route={route}
                                            classes={classes}
                                            drawerOpen={open}
                                            routeChildren={route.children}
                                        />
                                    </React.Fragment>
                                ))}
                        </List>
                        <Tooltip
                            placement="right"
                            title={t('nav.projects_overview')}
                            disableHoverListener={open}
                            disableFocusListener={open}
                            disableTouchListener={open}>
                            <ListItem
                                button
                                component={NavLink}
                                to={route(ROUTE_WORKSPACE, { workspaceId: workspaceLocalstorage || "" })}
                                className={clsx(
                                    `${classes.itemCategory} ${classes.item}`
                                )}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <Avatar
                                        variant="square"
                                        className={clsx(
                                            `${classes.square} ${classes.logo}`
                                        )}>
                                        <TocIcon
                                            style={{
                                                fontSize: '24px',
                                                color: '#6FBDF8',
                                            }}
                                        />
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.itemPrimary,
                                    }}>
                                    {t('nav.projects_overview')}
                                </ListItemText>
                            </ListItem>
                        </Tooltip>
                        <ClientListItem open={open} />

                        <ProjectListItem open={open} />
                    </nav>

                    <Box>
                        <WorkspaceMenu open={open} />

                        <ListItem
                            button
                            onClick={handleDrawerToggle}
                            className={classes.chevronIcon}>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </ListItem>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

export default withStyles(styles)(Sidebar);
