import React, { useEffect, useState } from 'react';
import {
    TextField,
    CircularProgress
} from '@material-ui/core';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import { useDispatch } from 'react-redux';

const defaultFilter = (options, input) =>
    options.filter(
        o => o.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );

const defaultSelectLabel = value => value.name;

const AutoComplete = ({
    label,
    performQuery,
    filter = defaultFilter,
    getName = defaultSelectLabel,
    onChange,
    addCustomOption,
    ...props
}) => {
    const [field, meta] = useField(props);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [options, setOptions] = useState(null);

    useEffect(() => {
        if (!!performQuery) {
            dispatch(performQuery())
                .then(items => {
                    if (addCustomOption) {
                        items.push({ name: '' })
                    }
                    setOptions(items)
                })
                .catch(ignore => { })
                .finally(() => setLoading(false));
        }
    }, [performQuery, dispatch]);

    const handleChange = (e, value) => {
        if (options) {
            if (onChange) {
                onChange(value);
            }
            field.onChange({
                target: {
                    name: field.name,
                    value: value
                        ? {
                            id: value.id,
                            name: value.name,
                        }
                        : null,
                },
            });
        }
    };

    return (
        <>
            <MaterialAutocomplete
                id={field.name}
                style={{ width: '100%' }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                forcePopupIcon={true}
                onChange={handleChange}
                getOptionSelected={(option, value) => option.id === value.id}
                filterOptions={(options, { inputValue }) =>
                    filter(options, inputValue)
                }
                getOptionLabel={option => (option ? getName(option) : '')}
                options={options || []}
                loading={isLoading}
                value={field.value || null}
                {...props}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </>
    );
};

export default AutoComplete;
