import React from 'react';
import {
    Button,
    Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { t } from '../../../../../../core/i18n';
import { useList } from '../../../../../../core/hooks/useList';
import {
    getProductGroups,
    deleteProductGroup,
} from '../../../../../../core/modules/accounting/products/groups/actions';
import Progress from '../../../../../shared/progress/Progress';
import {
    history,
    route,
    ROUTE_PRODUCT_GROUPS_ADD,
    ROUTE_PRODUCT_GROUPS_EDIT,
} from '../../../../../../core/routing/routing';
import Alert from '@material-ui/lab/Alert';
import { Add as AddIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import BaseMaterialTable from '../../../../../shared/table/BaseMaterialTable';
import { Collections } from 'core/networking/firebase';
import { useSelector } from 'react-redux';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    }
});

const ProductGroupsOverviewScene = ({ classes }) => {
    const workspaceId = useSelector(state => state.app.workspace);

    const { items, isLoading, error, refreshList, canSoftDelete, canDelete, canEdit } = useList(
        getProductGroups, Collections.product_groups
    );

    const headCells = [
        {
            id: 'name',
            title: t('product_groups.fields.name'),
            field: 'name',
            render: rowData => rowData.name,
        },
    ];

    const menuActions = [
        {
            action: 'edit',
            to: ROUTE_PRODUCT_GROUPS_EDIT,
            routeParam: 'id',
            routeParamExtra: { workspaceId: workspaceId }
        },
        {
            action: 'delete',
        },
        {
            action: 'deleteForever',
        },
        {
            action: 'restoreDelete',
        },
    ]

    const handleRowClick = (event, rowData) => {
        history.push(route(ROUTE_PRODUCT_GROUPS_EDIT, { workspaceId, id: rowData.id }))
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                className={classes.addBtn}
                                startIcon={<AddIcon />}
                                to={route(ROUTE_PRODUCT_GROUPS_ADD, { workspaceId })}>
                                {t('product_groups.add')}
                            </Button>
                        </Grid>
                        <BaseMaterialTable
                            title={t('product_groups.title')}
                            headCells={headCells}
                            items={items}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                            deleteItemFunc={deleteProductGroup}
                            menuActions={menuActions}
                            refreshList={refreshList}
                            collection={Collections.product_groups}
                            handleRowClick={handleRowClick}
                        />
                    </>
                )}
            </>
        );
    }
};

export default withStyles(styles)(ProductGroupsOverviewScene);
