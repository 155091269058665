import React, { useCallback } from 'react';
import SuppliersFormContainer from './SuppliersFormContainer';
import { useParams } from 'react-router-dom';
import { history, route, ROUTE_SUPPLIERS } from '../../../../../core/routing/routing';
import { useSelector } from 'react-redux';

const SuppliersFormScene = () => {
    const { id } = useParams();
    const workspaceId = useSelector(state => state.app.workspace);

    const handleSuccess = useCallback(() => {
        history.push(route(ROUTE_SUPPLIERS, { workspaceId }));
    }, []);

    return <SuppliersFormContainer id={id} onSuccess={handleSuccess} />;
};

export default SuppliersFormScene;
