import React from 'react';
import { storeWorkspaceUserRole } from 'core/modules/workspaces/actions';
import UserPermissionFormContainer from 'components/app/user/form/UserPermissionFormContainer';

const WorkspaceUsersFormContainer = ({ id, onSuccess }) => {
    return (
        <UserPermissionFormContainer
            storeUserRole={storeWorkspaceUserRole}
            onSuccess={onSuccess}
            id={id}
        />
    )
}

export default WorkspaceUsersFormContainer;