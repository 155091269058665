import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import {
    Card,
    Box
} from '@material-ui/core';
import TextFastField from '../generic/TextFastField';
import CountrySelect from '../country/CountrySelect';

const styles = () => ({
    flexBoxItem: {
        marginLeft: '8px',
    },
});

const ShippingAddressFields = ({ disabled, classes, index }) => {
    return (
        <Card
            style={{
                padding: '20px',
                boxShadow: 'none',
                border: '1px solid #eee',
                opacity: disabled ? '0.8' : '1',
            }}>
            <TextFastField
                label={t('address.fields.contact_name')}
                name={`shipping_address[${index}].contact_name`}
                disabled={disabled}
            />
            <TextFastField
                label={t(
                    'address.fields.email'
                )}
                type="email"
                name={`shipping_address[${index}].email`}
                disabled={disabled}
            />
            <Box display="flex">
                <TextFastField
                    label={t('address.fields.street')}
                    name={`shipping_address[${index}].street`}
                    disabled={disabled}
                />
                <TextFastField
                    label={t('address.fields.number')}
                    name={`shipping_address[${index}].number`}
                    disabled={disabled}
                    className={classes.flexBoxItem}
                />
            </Box>
            <Box display="flex">
                <TextFastField
                    label={t('address.fields.postal_code')}
                    name={`shipping_address[${index}].postal_code`}
                    disabled={disabled}
                />
                <TextFastField
                    label={t('address.fields.city')}
                    name={`shipping_address[${index}].city`}
                    disabled={disabled}
                    className={classes.flexBoxItem}
                />
            </Box>
            <TextFastField
                label={t('address.fields.state')}
                name={`shipping_address[${index}].state`}
                disabled={disabled}
            />
            <CountrySelect
                label={t('address.fields.country')}
                name={`shipping_address[${index}].country`}
                disabled={disabled}
            />
        </Card>

    );
};

export default withStyles(styles)(ShippingAddressFields);
