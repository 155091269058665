import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { isFunction } from '../../core/utils/isFunction';

const styles = () => ({});

const TagList = ({ tags, onClick = null, onDelete = null }) => {
    return (
        <>
            {!!tags &&
                Array.isArray(tags) &&
                tags.map((tag, index) => {
                    const bgColor = tag.bgColor ?? '';
                    const color = tag.color ?? '';
                    return (
                        <Chip
                            key={index}
                            label={tag.name}
                            size="small"
                            onClick={
                                isFunction(onClick) ? () => onClick(tag) : null
                            }
                            onDelete={
                                isFunction(onDelete)
                                    ? () => onDelete(tag)
                                    : null
                            }
                            style={{ backgroundColor: bgColor, color: color }}
                        />
                    );
                })}
        </>
    );
};

export default withStyles(styles)(TagList);
