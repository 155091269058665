import {
    Collections,
    fireStore,
    fireStorage,
    //firebaseAuth,
} from '../../../networking/firebase';
import { docMetaData, getDocData } from '../../../networking/utils';
import { t } from '../../../i18n';
import { createNotification } from '../../notifications/reducer';
import { updateWorkspaceClient } from './reducer';
import {
    selectCurrentUserClients,
    //selectCurrentUserClientPermission 
} from './selector';
import { deleteUserRole, setUserRole } from 'core/modules/users/actions';
import { isEmptyText } from 'core/utils/isEmptyText';
import { signInWithEmailLink } from 'core/modules/auth/actions';
import { getWorkspacePublicDataById, invitationRequest } from 'core/modules/workspaces/actions';
import { setClients } from './reducer';
import { selectCurrentWorkspaceId, selectCurrentClientId } from 'core/modules/app/selectors';
import { setLocalStorage } from 'core/modules/app/reducer';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchClients = (allItems, workspace) => {
    const query = fireStore
        .collection(Collections.clients)
        .where('workspace_agency', '==', workspace)
        .orderBy('name_lower', 'asc');

    if (allItems) {
        return query
            .get()
            .then(response => {
                return response.docs.map(doc => getDocData(doc));
            });
    } else {
        return query
            .where('meta.doc_status', '==', DocStatus.Active)
            .get()
            .then(response => {
                return response.docs.map(doc => getDocData(doc));
            });
    }

};

export const getClients = (allItems) => (dispatch, getState) => {
    return fetchClients(allItems, selectCurrentWorkspaceId(getState()));
};

export const fetchClientById = (id) => {
    return fireStore
        .collection(Collections.clients)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);
                return data;
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getClientById = id => (dispatch, getState) => {
    return fetchClientById(id);
};

export const getUserClients = () => (dispatch, getState) => {
    //const user = getState().auth.user;

    // save to reducer based on roles
    const action = {};
    const clientIds = selectCurrentUserClients(getState())

    return Promise.all(clientIds.map(async id => {
        const client = await fetchClientById(id);
        const workspace = await getWorkspacePublicDataById(client.workspace_agency);
        return { ...client, workspace: { ...workspace } }
    }))
        .then((res) => {
            action.clients = res;
            const pp = res.filter((ele, ind) => ind === res.findIndex(elem => elem.workspace_agency === ele.workspace_agency))
            const result = Object.values(pp.reduce((a, { workspace }) => {
                (a[workspace] || (a[workspace] = { workspace: [] })).workspace.push(workspace);
                return a;
            }, Object.create(null)));
            action.workspaces = result[0]?.workspace;
            dispatch(setClients(action));
            return res;
        })
}

export const storeClient = ({ id, ...data }) => async (dispatch, getState) => {
    const query = fireStore.collection(Collections.clients);
    const currentWorkspace = selectCurrentWorkspaceId(getState())
    // add workspace
    data.workspace_agency = currentWorkspace;

    // add lowercase name field to order by
    data.name_lower = data.name.toLowerCase();
    data.meta = docMetaData(getState());

    if (id) {
        await query.doc(id).set(data);
    } else {
        const ref = await query.add(data);
        id = ref.id;
    }

    // save workspaceClient
    dispatch(updateWorkspaceClient({ ...data }));

    dispatch(
        createNotification({
            type: 'success',
            message: t(`clients.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const deleteClient = data => async dispatch => {
    const action = {}
    action.workspace = data.workspace_agency;
    try {
        await fireStore
            .collection(Collections.clients)
            .doc(data.id)
            .delete();
        dispatch(setLocalStorage(action));
        return dispatch(
            createNotification({
                type: 'error',
                message: t('clients.notifications.delete'),
            })
        );
    } catch (error) {
        console.log(error)
    }
};

export const getClientImages = (id, filename, workspaceAgency) => {
    const storageRef = fireStorage.ref();
    const spaceRef = storageRef.child(
        'files/workspace_agencies/' +
        workspaceAgency +
        '/workspace_client/' +
        id +
        '/original/IEYs Team.jpg'
    );
    spaceRef
        .getDownloadURL()
        .then(function (url) {
            document.querySelector('.ReactCrop__image').src = url;
        })
        .catch(function (error) {
            console.error(error);
        });
};

export const fetchClientUserRoles = async client => {
    const privateData = await fireStore
        .collection(Collections.clients)
        .doc(client)
        .collection(Collections.private_data)
        .doc('private')
        .get()
        .then(doc => {
            if (doc.exists) {
                return getDocData(doc);
            }
        });

    const userDoc = async id => {
        return await fireStore
            .collection(Collections.users)
            .doc(id)
            .get()
            .then(doc => {
                if (doc.exists) {
                    return getDocData(doc);
                }
            })
    };

    if (!!privateData) {
        const arr = Object.keys(privateData.roles).map(async (key, i) => {
            const user = await userDoc(key);

            if (!!user) {
                return { ...user, permission: privateData.roles[key] }
            } else {
                return { permission: privateData.roles[key], email: key }
            }
        })

        return Promise.all(arr);
    } else {
        return []
    }
}

export const getClientUserRoles = () => (dispatch, getState) => {
    return fetchClientUserRoles(selectCurrentClientId(getState()));
};

export const deleteClientUserRole = (data) => async (dispatch, getState) => {
    const { email, id } = data;
    const arrKey = !!id ? id : email;
    const clientId = selectCurrentClientId(getState())

    const query = fireStore
        .collection(Collections.clients)
        .doc(clientId)
        .collection(Collections.private_data)
        .doc('private');

    const userRoles = await query
        .get()
        .then(doc => {
            if (doc.exists) {
                return doc.data();
            }
        });

    delete userRoles.roles[arrKey];

    if (!!id) {
        await deleteUserRole(id, clientId, Collections.clients)
    }

    return await query
        .set(userRoles);
}

export const storeClientUserRole = (clientId, data) => async (getState, dispatch) => {
    const { email, permission, uid } = data;
    const userId = !!data.id ? data.id : uid;
    const docId = clientId;
    const collection = Collections.clients;
    const ClientUserRoles = await fireStore
        .collection(Collections.clients)
        .doc(clientId)
        .collection(Collections.private_data)
        .doc('private')
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = { ...doc.data() };
                return data.roles;
            }
        });

    const query = await fireStore
        .collection(Collections.clients)
        .doc(clientId)
        .collection(Collections.private_data)
        .doc('private');

    const key = isEmptyText(userId) ? data.email : userId;

    let userExists = false;
    if (!!ClientUserRoles) {
        userExists = typeof ClientUserRoles[key] !== 'undefined';
    }

    if (!userExists) {
        const roles = {
            ...ClientUserRoles,
        }
        roles[key] = data.permission;

        await query
            .set({
                roles: roles
            });

        if (isEmptyText(userId)) {
            await dispatch(signInWithEmailLink(data.email));
            await dispatch(invitationRequest(email, permission, docId, collection))
        } else {
            await dispatch(setUserRole(userId, docId, permission, collection));
        }
    } else {
        const roles = {
            ...ClientUserRoles,
        }
        roles[key] = data.permission;

        await query
            .set({
                roles: roles
            });

        await dispatch(setUserRole(userId, docId, permission, collection));
        return dispatch(
            createNotification({
                type: 'error',
                message: t('workspace.notifications.user_already_added'),
            })
        );
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`workspace.notifications.update`),
        })
    );

    return { ...data, clientId };
}