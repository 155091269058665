import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    Button,
    Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { t } from 'core/i18n';
import TextField from '../../shared/form/generic/TextField';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { userLinkWithCredential } from 'core/modules/auth/actions';
import { getErrorMessage } from 'core/networking/utils';
import { signInWithEmailLink } from 'core/modules/auth/actions';

const Schema = yup.object().shape({
    first_name: yup
        .string()
        .required(),
    last_name: yup
        .string()
        .required(),
    password: yup
        .string()
        .min(8)
        .required(),
});

const styles = () => ({
    paper: {
        padding: 30,
    },
    loginFooter: {
        color: 'white',
        padding: 30,
    },
    linkWhite: {
        '&:after': {
            backgroundColor: 'white',
        },
    },
    btn: {
        margin: '1em 0',
    },
    inputLeft: {
        paddingRight: '4px',
    },
    inputRight: {
        paddingLeft: '4px',
    },
});

const CompleteRegisterScene = ({ classes }) => {
    const dispatch = useDispatch();
    const [sent, setSend] = useState(false);
    const user = useSelector(state => state.auth.user);

    const [
        initialData, setInitialData
    ] = useState({ password: '' });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setInitialData({});
    }, []);

    const onSubmit = useCallback(
        data => {
            // set as loading
            setLoading(true);
            // try to log in
            dispatch(userLinkWithCredential(data))
                .then(res => {
                    if (!!res?.code) {
                        setLoading(false);
                        setError(getErrorMessage(res));
                    }
                })

        },
        [dispatch]
    );

    return (
        <>
            <Paper className={classes.paper}>
                <Typography component="p" variant="h4">
                    {t('onboarding.user_management.actions.title.complete_register')}
                </Typography>
                <Typography component="p" variant="h6">
                    {t('onboarding.user_management.actions.subtitle.complete_register')}
                </Typography>
                <Formik
                    initialValues={initialData}
                    validationSchema={Schema}
                    onSubmit={onSubmit}>
                    {({ errors, touched, isValid }) => (
                        <Form>
                            <Box display="flex">
                                <TextField
                                    label={t(
                                        'onboarding.register.form.first_name'
                                    )}
                                    name="first_name"
                                    variant="outlined"
                                    className={classes.inputLeft}
                                />
                                <TextField
                                    label={t(
                                        'onboarding.register.form.last_name'
                                    )}
                                    name="last_name"
                                    variant="outlined"
                                    className={classes.inputRight}
                                />
                            </Box>
                            <TextField
                                label={t('onboarding.register.form.set_password')}
                                name="password"
                                type="password"
                                variant="outlined"
                            />
                            {error && (
                                <Alert
                                    severity="error"
                                    action={
                                        <Button
                                            color="inherit"
                                            size="small"
                                            disabled={sent}
                                            onClick={() => { signInWithEmailLink(user.email); setSend(true); }}>
                                            {t(`register_verification.${sent ? 'sent' : 'resend'}`)}
                                        </Button>
                                    }>
                                    {error}

                                </Alert>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                className={classes.btn}
                                disableElevation>
                                {t('actions.continue')}
                            </Button>

                        </Form>
                    )}
                </Formik>
            </Paper>
        </>
    )
}

export default withStyles(styles)(CompleteRegisterScene);