import React, { useState } from 'react';
import { t } from 'core/i18n';
import Progress from '../../../shared/progress/Progress';
import BaseMaterialTable from '../../../shared/table/BaseMaterialTable';
import Alert from '@material-ui/lab/Alert';
import RolesDialogScene from '../roles/RolesDialogScene';
import {
    Grid,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ROUTE_SUPER_ADMIN_USER_DETAIL } from 'core/routing/routing';
import AvatarStatus from 'components/shared/content/AvatarStatus';
import { Collections } from 'core/networking/firebase';
import { useSnapshotList } from 'core/hooks/useSnapshotList';
import DateFormat from 'components/shared/form/generic/DateFormat';

const styles = () => ({
    smallAvatar: {
        width: 30,
        height: 30
    }
});

const UsersOverviewScene = ({ classes, props }) => {
    const fetchCollection = Collections.users;

    const [openDialog, setOpenDialog] = useState(false);
    const [userItem, setUserItem] = useState(false);

    const { isLoading, error, items, canSoftDelete, canDelete, canEdit } = useSnapshotList(fetchCollection);

    const handleSuccess = () => {
        setOpenDialog(false);
    };

    const headCells = [
        {
            id: 'id',
            hidden: true,
            field: 'id',
        },
        {
            id: 'displayName',
            title: t('users.fields.full_name'),
            field: 'displayName',
            render: rowData => (
                <Grid container wrap="nowrap" spacing={1} alignItems="center">
                    <Grid item>
                        <AvatarStatus user={rowData}
                            className={classes.smallAvatar}
                        />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography noWrap>
                            {!!rowData.displayName
                                ? rowData.displayName
                                : !!rowData.first_name ? rowData.first_name + ' ' + rowData.last_name : rowData.full_name}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            id: 'email',
            title: t('users.fields.email'),
            field: 'email',
            render: rowData => rowData.email,
        },
        {
            id: 'first_name',
            hidden: true,
            title: '',
            field: 'first_name',
            searchable: true,
        },
        {
            id: 'last_name',
            hidden: true,
            title: '',
            field: 'last_name',
            searchable: true,
        },
        /*{
            id: 'role',
            title: t('users.fields.role'),
            field: 'role',
            render: rowData => t(`users.roles.${rowData.role}.title`),
        },
        {
            id: 'permission',
            title: t('users.fields.permission'),
            field: 'permission',
            render: rowData => (rowData.role === Roles.Admin) ? t(`users.permissions.${rowData.permission}.title`) : t('non_applicable'),
        },*/
        {
            id: 'lastSignIn',
            title: t('users.fields.last_active'),
            field: 'lastSignIn',
            render: rowData => !!rowData.lastSignIn ? (!!rowData.online ? t('users.active') : <DateFormat date={rowData.lastSignIn} time={true} />) : ""
        },
    ]

    const menuActions = [
        {
            action: 'edit',
            to: ROUTE_SUPER_ADMIN_USER_DETAIL,
            routeParam: 'userId'
        },
    ]

    const handleRowClick = (event, rowData) => {
        setOpenDialog(true);
        setUserItem(rowData);
        //console.log(rowData);
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <BaseMaterialTable
                            title={t('nav.users_and_permissions')}
                            headCells={headCells}
                            items={items}
                            handleRowClick={handleRowClick}
                            options={{
                                sorting: true,
                                thirdSortClick: false,
                            }}
                            collection={Collections.workspaces}
                            menuActions={menuActions}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                        />
                    </>
                )
                }
                <RolesDialogScene
                    openDialog={openDialog}
                    onCancel={() => {
                        setOpenDialog(false);
                        setUserItem(false);
                    }}
                    rowData={userItem}
                    onSuccess={handleSuccess}
                />
            </>
        );
    }
};

export default withStyles(styles)(UsersOverviewScene);
