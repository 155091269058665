import {
    Card,
    Grid,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppContent from 'components/shared/content/AppContent';
import DateFormat from 'components/shared/form/generic/DateFormat';
import { useList } from 'core/hooks/useList';
import { t } from 'core/i18n';
import { getInvoiceEventsById } from 'core/modules/accounting/invoicing/events/actions';
import React, { useCallback } from 'react';

const styles = () => ({
    eventGrid: {
        width: '100%',
        padding: 10,
    },
    eventCard: {
        padding: 20,
        backgroundColor: '#fafafa'
    }
});


const InvoicingEventsScene = ({ type, item, classes }) => {
    const id = item.id;
    const fetchList = useCallback(() =>
        getInvoiceEventsById(type, id)
        , [type, id]);

    const { items } = useList(fetchList);

    return (
        <AppContent>
            <Typography variant="h4" component="h2" gutterBottom>
                {t('invoicing.detail.history')}
            </Typography>

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                {!!items && (
                    items.map(item => (
                        <Grid
                            item
                            xs={6}
                            key={item.id}
                            className={classes.eventGrid}>
                            <Card
                                variant="outlined"
                                className={classes.eventCard}
                            >
                                {<DateFormat date={item.createdAt} time={true} />}<br />
                                {t(`invoicing.type.${item.collection}`)} {t(`events.actions.${item.name}`)}<br />
                                {item.user.displayName}<br />
                                {!!item.payload.snap.converted_from ? "From: " + item.payload.snap.converted_from?.id : ""}
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>
        </AppContent>
    )
}

export default withStyles(styles)(InvoicingEventsScene);