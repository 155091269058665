import React from 'react';
import { storeClientUserRole } from 'core/modules/dashboard/clients/actions';
import UserPermissionFormContainer from 'components/app/user/form/UserPermissionFormContainer';

const ClientUsersFormContainer = ({ id, onSuccess }) => {
    return (
        <UserPermissionFormContainer
            storeUserRole={storeClientUserRole}
            onSuccess={onSuccess}
            id={id}
        />
    )
}

export default ClientUsersFormContainer;