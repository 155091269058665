import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '../../storage/LocalStorage';

const INITIAL_STATE = {
    current: LocalStorage.getWorkspace(),
};

const workspacesSlice = createSlice({
    name: 'workspaces',
    initialState: { INITIAL_STATE, workspaces: null },
    reducers: {
        setWorkspaces(state, action) {
            const { workspaces, current, permission } = action.payload;
            state.workspaces = workspaces;
            state.current =
                !!INITIAL_STATE.current
                    ? INITIAL_STATE.current
                    : current;
            //LocalStorage.setWorkspace(state.current);
            state.permission = permission;
        },
        setCurrentWorkspace(state, action) {
            const { current, permission } = action.payload;
            state.current = current;
            state.permission = permission;
        },
        updateWorkspace(state, action) {
            state.workspaces = state.workspaces.map(space => {
                if (space.id === action.payload.id) {
                    space = action.payload;
                }
                return space;
            });
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = workspacesSlice;
// Extract and export each action creator by name
export const { setWorkspaces, setCurrentWorkspace, updateWorkspace } = actions;
// Export the reducer, either as a default or named export
export default reducer;
