import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    history,
    route,
    ROUTE_ADMINISTRATION_RECURRING,
} from '../../../../../core/routing/routing';
import RecurringFormContainer from './RecurringFormContainer';

const RecurringFormScene = () => {
    const { id } = useParams();
    const workspaceId = useSelector(state => state.app.workspace);

    const handleSuccess = useCallback(() => {
        history.push(route(ROUTE_ADMINISTRATION_RECURRING, { workspaceId }));
    }, []);

    return (
        <div>
            <RecurringFormContainer id={id} onSuccess={handleSuccess} />
        </div>
    );
};

export default RecurringFormScene;
