import {
    ListItemIcon,
    MenuItem
} from '@material-ui/core';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    DeleteForever as DeleteForeverIcon,
    RestoreFromTrash as RestoreFromTrashIcon,
    MailOutline as MailOutlineIcon,
} from '@material-ui/icons';
import { t } from 'core/i18n';
import { route } from 'core/routing/routing';
import { DocStatus } from 'core/modules/auth/constants';

const ThreeDotsMenuItem = forwardRef((props, ref) => {
    const { classes, data, action, onActionSelect, handleClose, canDelete, canEdit, canSoftDelete } = props
    let icon;
    let label;
    let param;
    let funcParam;
    let permission;
    let actionFunc;
    let isActive = true;

    const actionSwitcher = action => {
        switch (data.meta?.doc_status) {
            case DocStatus.Active:
                switch (action.action) {
                    case 'edit':
                        icon = <EditIcon />;
                        param = { [action.routeParam]: data.id };
                        label = t('actions.edit');
                        permission = canEdit;
                        actionFunc = onActionSelect;
                        return;
                    case 'delete':
                        icon = <DeleteIcon />;
                        label = t('actions.soft_delete');
                        permission = canSoftDelete;
                        actionFunc = onActionSelect;
                        funcParam = { data: data, action: action.action };
                        return;
                    case 'deleteForever':
                        icon = <DeleteForeverIcon />;
                        label = t('actions.delete');
                        permission = canDelete;
                        actionFunc = onActionSelect;
                        funcParam = { data: data, action: action.action };
                        return;
                    default:
                        return isActive = false;
                }
            case DocStatus.SoftDeleted:
                switch (action.action) {
                    case 'restoreDelete':
                        icon = <RestoreFromTrashIcon />;
                        label = t('actions.restore_delete');
                        permission = canSoftDelete;
                        actionFunc = onActionSelect;
                        funcParam = { data: data, action: action.action };
                        return;
                    default:
                        return isActive = false;
                }
            default:
                switch (action.action) {
                    case 'edit':
                        icon = <EditIcon />;
                        param = { [action.routeParam]: data.id };
                        label = t('actions.edit');
                        permission = canEdit;
                        actionFunc = onActionSelect;
                        return;
                    case 'mail':
                        icon = <MailOutlineIcon />;
                        label = t('actions.mail');
                        permission = canDelete;
                        actionFunc = action.actionFunc;
                        funcParam = data[action.param];
                        return;
                    case 'deleteForever':
                        icon = <DeleteForeverIcon />;
                        label = t('actions.delete');
                        permission = canDelete;
                        actionFunc = onActionSelect;
                        funcParam = { data: data, action: action.action };
                        return;
                    default:
                        return isActive = false;
                }
            //return isActive = false;
        }

    };

    actionSwitcher(action);

    if (isActive && permission) {
        return (
            !!action.to ? (
                <MenuItem
                    key={label}
                    component={NavLink}
                    value={action.to}
                    to={route(action.to, !!action.routeParamExtra ? { ...action.routeParamExtra, ...param } : param)}
                    label={label}
                    onClick={handleClose}
                    ref={ref}
                    aria-label={label}>
                    <ListItemIcon
                        className={classes.icon}>
                        {icon}
                    </ListItemIcon>
                    {/*tab.label*/}
                </MenuItem>
            ) : (
                    <MenuItem
                        key={label}
                        ref={ref}
                        value={action.action}
                        aria-label={label}
                        label={label}
                        onClick={(e) => {
                            e.stopPropagation();
                            actionFunc(funcParam);
                        }}
                    >
                        <ListItemIcon
                            className={classes.icon}>
                            {icon}
                        </ListItemIcon>
                    </MenuItem>
                )
        )
    } else {
        return null;
    }
})

export default ThreeDotsMenuItem;