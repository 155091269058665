import React, { useState, useRef, useCallback } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { dataURLtoFile } from 'core/utils/dataURLtoFile';
import {
    fireStorage,
} from 'core/networking/firebase';
import { t } from 'core/i18n';

const styles = () => ({
    cropBox: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-start',
    },
    button: {
        marginBottom: 20,
    },
    inputField: {
        display: 'none',
    },
    image: {
        maxWidth: 200,
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, .05)',
        padding: 4,
        marginBottom: 20,
    },
    cropImage: {
        maxWidth: 500,
    },
    cropFile: {
        maxWidth: 250,
        margin: '0 15px',
    },
    previewBox: {
        display: 'flex',
        alignItems: 'flex-end',
        margin: '15px 0',
    },
});

const UploadField = ({
    classes,
    name,
    label,
    setFieldValue,
    defaultValue,
    storagePath,
    submitForm,
    ...props
}) => {
    const [upFile, setUpFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const fileRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 80, height: 100 });
    const [previewUrl, setPreviewUrl] = useState(null);
    const [originalUrl, setOrginalUrl] = useState(null);
    const [croppedlUrl, setCroppedUrl] = useState(null);
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setFieldValue('original_' + name, originalUrl);
        setFieldValue(name, croppedlUrl);
        setOpen(false);
        submitForm();
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const fileToStorageRef = async (file, storagePath, fieldName, original) => {
        if (!!file) {
            const newFile = file;

            const storageRef = fireStorage.ref();
            const mainImage = storageRef.child(
                storagePath +
                file.name
            );

            // Create file metadata to update
            const metadata = {
                cacheControl: 'public,max-age=300',
                contentType: 'image/jpeg',
                name: file.name,
            }

            const uploadTask = mainImage.put(newFile, metadata);

            await uploadTask.on('state_changed',
                (snapshot) => {
                    if (original) {
                        setOpen(true)
                        setProgress(Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        ));
                    }

                }, (err) => {
                    console.log(err)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        if (original) {
                            return setOrginalUrl(downloadURL)
                        } else {
                            return setCroppedUrl(downloadURL);
                        }
                    }).catch(err => {
                        console.log(err)
                    });
                });

        }
    };

    const onSelectFile = e => {
        const fieldName = name;
        const original = true;
        storagePath = storagePath + '/' + fieldName + '/original/';
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setUpFile(reader.result)
            });
            reader.readAsDataURL(e.target.files[0]);
            setFileName(e.target.files[0].name);
            return fileToStorageRef(e.target.files[0], storagePath, fieldName, original);
        }
    };

    const onLoad = useCallback(file => {
        fileRef.current = file;
    }, []);

    const makeClientCrop = async crop => {
        if (fileRef.current && crop.width && crop.height) {
            createCropPreview(
                fileRef.current,
                crop,
                fileName
            );
        }
    };

    const createCropPreview = async (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        const fieldName = name;
        storagePath = storagePath + '/' + fieldName + '/styles/';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            window.URL.revokeObjectURL(previewUrl);
            setPreviewUrl(window.URL.createObjectURL(blob));
            reader.onloadend = () => {
                return fileToStorageRef(dataURLtoFile(reader.result, fileName), storagePath, fieldName);
            }
        }, 'image/jpeg')
    };

    return (
        <Box className={classes.cropBox}>
            <Button
                variant="contained"
                color="primary"
                component="label"
                className={classes.button}
                startIcon={<CloudUploadIcon />}>
                <input
                    id={name}
                    type="file"
                    label={label}
                    name={name}
                    className={classes.inputField}
                    {...props}
                    onChange={onSelectFile}
                />
                {label}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle>
                    {label}
                </DialogTitle>
                <DialogContent dividers>
                    {(progress < 100) ? (
                        <CircularProgress variant="static" value={progress} />

                    ) : (
                            <div className={classes.previewBox}>
                                <ReactCrop
                                    src={upFile}
                                    onImageLoaded={onLoad}
                                    crop={crop}
                                    onChange={c => setCrop(c)}
                                    onComplete={makeClientCrop}
                                    className={classes.cropImage}
                                />
                                {/*previewUrl && (
                            <img
                                alt="Crop preview"
                                id="cropFile"
                                className={classes.cropFile}
                                src={previewUrl}
                            />
                        )*/}
                            </div>
                        )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        color="primary">
                        {t('actions.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        onClick={handleClose}
                        color="primary"
                    >
                        {t('actions.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
};

export default withStyles(styles)(UploadField);
