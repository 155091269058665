import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { t } from '../../core/i18n';
import { Link } from 'react-router-dom';
import {
    route, ROUTE_COMPLETE_REGISTER_ON_INVITE,

} from 'core/routing/routing';

const CompleteRegisterAlert = () => {
    const isFullyRegistered = useSelector(state => state.auth.user.isFullyRegistered);

    return !isFullyRegistered ? (
        <>
            <Alert
                severity="warning"
                action={
                    <Button
                        color="inherit"
                        size="small"
                        component={Link}
                        to={route(
                            ROUTE_COMPLETE_REGISTER_ON_INVITE
                        )}>
                        {t(`register_verification.complete`)}
                    </Button>
                }>
                {t('register_verification.title')}
            </Alert>
        </>
    ) : null;
};

export default CompleteRegisterAlert;
