import React, { forwardRef, useCallback, useState } from 'react';
import MaterialTable from 'material-table';
import {
    Search,
    Clear,
    ArrowDownward,
    Edit,
    DeleteOutline,
    FilterList,
    ChevronRight,
    ChevronLeft,
    FirstPage,
    LastPage,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { DocStatus } from 'core/modules/auth/constants';
import ThreeDotsMenu from './ThreeDotsMenu';
import { useDispatch } from 'react-redux';
import { softDeleteItem } from 'core/modules/general/actions';
import DeleteDialog from '../dialog/DeleteDialog';
import { getErrorMessage } from 'core/networking/utils';

const styles = () => ({

});

const tableIcons = {
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
};

const BaseMaterialTable = ({
    classes,
    headCells,
    title,
    items,
    components,
    handleRowClick,
    options,
    onOrderChange,
    detailPanelContent,
    paging,
    canEdit,
    canDelete,
    canSoftDelete,
    deleteItemFunc,
    menuActions,
    refreshList,
    collection,
    ...props
}) => {
    const dispatch = useDispatch();

    const [, setError] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [hardDelete, setHardDelete] = useState(null);

    const onActionSelect = useCallback(({ data, action }) => {
        if ((action === "delete") || (action === "restoreDelete")) {
            setDeleteItem(data);
            setHardDelete(false);
        } if (action === "deleteForever") {
            setDeleteItem(data);
            setHardDelete(true);
        }
    })

    const handleConfirmDelete = () => {
        dispatch(!!hardDelete ? deleteItemFunc(deleteItem) : softDeleteItem(deleteItem, collection))
            .then(() => {
                refreshList();
            })
            .catch(error => {
                console.log('error', error)
                setError(getErrorMessage(error));
            })
            .finally(() => {
                setDeleteItem(null);
            });
    };

    const actions = [
        {
            icon: 'three_dots_menu',
            tooltip: 'Edit Index',
            onClick: (event, rowData) => {
            }
        },
    ]

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                columns={headCells}
                options={{
                    sorting: false,
                    thirdSortClick: false,
                    paging: paging ?? false,
                    pageSize: 20,
                    pageSizeOptions: [20],
                    actionsColumnIndex: 99,
                    actionsCellStyle: {
                        justifyContent: 'flex-end',
                    },
                    tableLayout: 'auto',
                    rowStyle: rowData => ({
                        background:
                            rowData.meta?.doc_status === DocStatus.SoftDeleted
                                ? 'repeating-linear-gradient( -45deg, rgba(204, 0, 0, .1), rgba(204, 0, 0, .1) 2px, #fff 2px, #fff 4px )'
                                : '',
                    }),
                    ...options,
                }}
                data={items}
                onRowClick={handleRowClick}
                onOrderChange={onOrderChange}
                actions={!!menuActions ? actions : null}
                classes={{
                    actions: classes.actions
                }}
                components={{
                    Action:
                        props => {
                            return (
                                <>
                                    {(props.action.icon === 'three_dots_menu') && (
                                        (props.data.log_type !== 'external') && (
                                            <ThreeDotsMenu
                                                data={props.data}
                                                menuActions={menuActions}
                                                onActionSelect={onActionSelect}
                                                canEdit={canEdit}
                                                canSoftDelete={canSoftDelete}
                                                canDelete={canDelete}
                                                action={props.action}
                                            />
                                        )
                                    )}
                                </>
                            )
                        },
                    ...components
                }
                }
                title={title}
                {...props}
                detailPanel={
                    !!props.detailPanel
                        ? [
                            {
                                render: rowData => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: '#eee',
                                                padding: '15px',
                                            }}>
                                            {props.detailPanel(rowData)}
                                        </div>
                                    );
                                },
                            },
                        ]
                        : []
                }
            />
            <DeleteDialog
                open={!!deleteItem}
                item={!!deleteItem ? deleteItem : null}
                hardDelete={!!hardDelete ? hardDelete : null}
                onCancel={() => { setDeleteItem(null); setHardDelete(null) }}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};

export default withStyles(styles)(BaseMaterialTable);
