import { fireStore, Collections } from '../../../../networking/firebase';
import { docMetaData, getDocData } from '../../../../networking/utils';
import { createNotification } from '../../../notifications/reducer';
import { t } from '../../../../i18n';
import { caseFoldDocument } from '../../../../utils/caseFold';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchProductGroups = async (allItems, workspace) => {
    const query = fireStore
        .collection(Collections.product_groups)
        .where('workspace_agency', '==', workspace)
        .orderBy('query_fields.name', 'asc');

    if (allItems) {
        return await query
            .get()
            .then(resp => resp.docs.map(doc => getDocData(doc)));
    } else {
        return await query
            .where('meta.doc_status', '==', DocStatus.Active)
            .get()
            .then(resp => resp.docs.map(doc => getDocData(doc)));
    }
};

export const getProductGroups = (allItems) => (dispatch, getState) => {
    return fetchProductGroups(allItems, selectCurrentWorkspaceId(getState()));
};

export const fetchProductGroupById = (id, workspace) => {
    return fireStore
        .collection(Collections.product_groups)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);

                if (data.workspace_agency === workspace) {
                    return data;
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getProductGroupById = id => (dispatch, getState) => {
    return fetchProductGroupById(id, selectCurrentWorkspaceId(getState()));
};

export const storeProductGroup = ({ id, ...data }) => async (
    dispatch,
    getState
) => {
    const query = fireStore.collection(Collections.product_groups);

    // add workspace
    data.workspace_agency = selectCurrentWorkspaceId(getState());
    data.meta = docMetaData(getState());
    // Create query map with selected fields
    const queryFields = {
        name: data.name,
    }
    data.query_fields = caseFoldDocument(queryFields);

    let isUpdate = true;
    if (id) {
        await query.doc(id).set(data);
    } else {
        const ref = await query.add(data);
        id = ref.id;
        isUpdate = false;
    }

    if (isUpdate) {
        dispatch(
            createNotification({
                type: 'success',
                message: t('product_groups.notifications.update'),
            })
        );
    } else {
        dispatch(
            createNotification({
                type: 'success',
                message: t('product_groups.notifications.create'),
            })
        );
    }

    return { ...data, id };
};

export const deleteProductGroup = data => async dispatch => {
    await fireStore
        .collection(Collections.product_groups)
        .doc(data.id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('product_groups.notifications.delete'),
        })
    );
};
