import React, { useEffect, useState } from 'react';
import { getProjectLogConnectors } from '../../../../core/modules/apicenter/actions';
import { useDispatch } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import { t } from '../../../../core/i18n';
import {
    FormGroup,
    TextField,
    FormLabel,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    FormControl
} from '@material-ui/core';

const ProjectIntegrations = ({ name, ...props }) => {
    const dispatch = useDispatch();
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();
    const [allLogConnectors, setAllLogConnectors] = useState(null);
    const [selectedConnectorIds, setSelectedConnectorIds] = useState(
        field.value?.log_connectors?.ids !== undefined && Array.isArray(field.value.log_connectors.ids)
            ? field.value.log_connectors.ids
            : [])

    const [logConnectorsSearchText, setLogConnectorsSearchText] = useState('');

    useEffect(() => {
        dispatch(getProjectLogConnectors()).then(data => {
            setAllLogConnectors(data);
        });
    }, [dispatch]);

    const onChangeCheckbox = (ev, val) => {
        const tickedCheckboxName = ev.target.name;
        const newSelectedConnectorIds = selectedConnectorIds.map(x => x);
        const indexInSelected = selectedConnectorIds.indexOf(
            tickedCheckboxName
        );

        if (val === true) {
            if (indexInSelected === -1) {
                newSelectedConnectorIds.push(tickedCheckboxName);
                setSelectedConnectorIds(newSelectedConnectorIds);
            }
        } else {
            if (indexInSelected > -1) {
                newSelectedConnectorIds.splice(indexInSelected, 1);
                setSelectedConnectorIds(newSelectedConnectorIds);
            }
        }

        const dataToStore = allLogConnectors.filter(connector => {
            return newSelectedConnectorIds.includes(connector.id);
        });

        setFieldValue('integrations.log_connectors', {});
        setFieldValue(
            'integrations.log_connectors.ids',
            newSelectedConnectorIds
        );
        setFieldValue('integrations.log_connectors.data', dataToStore);
    };

    const onChangeLogConnectorSearch = ev => {
        setLogConnectorsSearchText(ev.target.value);
    };

    return (
        <>
            <h2>{t('apicenter.project_integrations.title')}</h2>
            {(() => {
                if (!!allLogConnectors && allLogConnectors.length > 0) {
                    let logConnectorOptions = allLogConnectors.map(
                        connector => {
                            return {
                                name: connector.id,
                                label: connector.name,
                                value: selectedConnectorIds.includes(
                                    connector.id
                                ),
                            };
                        }
                    );
                    if (logConnectorsSearchText.length > 0) {
                        logConnectorOptions = logConnectorOptions.filter(
                            connectorOption => {
                                return (
                                    connectorOption.label
                                        .toLowerCase()
                                        .indexOf(
                                            logConnectorsSearchText.toLowerCase()
                                        ) !== -1
                                );
                            }
                        );
                    }
                    return (
                        <>
                            <FormControl
                                component="fieldset"
                                style={{ width: '100%' }}>
                                <FormLabel
                                    component="legend"
                                    id={`${props.name}_label`}>
                                    {t(
                                        'apicenter.project_integrations.choose_log_connectors'
                                    )}
                                </FormLabel>
                                <TextField
                                    label={t(
                                        'apicenter.project_integrations.search'
                                    )}
                                    onChange={e =>
                                        onChangeLogConnectorSearch(e)
                                    }
                                />
                                <FormGroup
                                    aria-label={`${props.name}_label`}
                                    {...props}>
                                    {logConnectorOptions.map(option => (
                                        <FormControlLabel
                                            key={option.name}
                                            name={option.name}
                                            value={option.value}
                                            checked={option.value}
                                            control={<Checkbox />}
                                            label={option.label}
                                            onChange={onChangeCheckbox}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                            }}
                                        />
                                    ))}
                                </FormGroup>
                                {!!meta.error && meta.touched && (
                                    <FormHelperText error={true}>
                                        {meta.error}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </>
                    );
                } else {
                    return (
                        <p>
                            {t(
                                'apicenter.project_integrations.no_log_connectors'
                            )}
                        </p>
                    );
                }
            })()}
        </>
    );
};

export default ProjectIntegrations;
