import { NavLink } from 'react-router-dom';
import {
    ListItemIcon,
    ListItemText,
    ListItem,
    Tooltip
} from '@material-ui/core';
import {
    BusinessCenter as BusinessCenterIcon,
    GroupWork,
    Receipt,
    LocalShipping as LocalShippingIcon,
    Business as BusinessIcon,
    Description as DescriptionIcon
} from '@material-ui/icons';
import React from 'react';
import {
    ROUTE_PRODUCTS,
    ROUTE_PRODUCT_GROUPS,
    ROUTE_SUPPLIERS,
    ROUTE_BILLING_ACCOUNTS,
    ROUTE_ADMINISTRATION_TYPE,
    ROUTE_ADMINISTRATION_RECURRING,
    ROUTE_ADMINISTRATION,
    ROUTE_STOCK_ITEMS,
    route as routeWithVariable,
    ROUTE_API_CENTER_SOURCE,
} from 'core/routing/routing';
import { t } from 'core/i18n';
import { ApiCenterSources } from '../../core/modules/apicenter/constants';

const NavChild = ({
    route,
    classes,
    drawerOpen,
}) => {
    let icon;
    let label;
    let params;
    const type = !!route.params ? route.params.type : '';
    const listKey = !!route.params ? route.params.type : route.path;

    const routeSwitcher = route => {
        switch (route.path) {
            case ROUTE_ADMINISTRATION_TYPE:
                icon = <Receipt />;
                params = route.params;
                label = t('nav.administration.' + type);
                break;

            case ROUTE_PRODUCTS:
                icon = <BusinessCenterIcon />;
                params = route.params;
                label = t('nav.accounting.products');
                break;

            case ROUTE_PRODUCT_GROUPS:
                icon = <GroupWork />;
                params = route.params;
                label = t('nav.accounting.product_groups');
                break;

            case ROUTE_ADMINISTRATION:
                icon = <Receipt />;
                params = route.params;
                label = t('nav.accounting.invoices');
                break;

            case ROUTE_SUPPLIERS:
                icon = <LocalShippingIcon />;
                params = route.params;
                label = t('nav.accounting.suppliers');
                break;

            case ROUTE_BILLING_ACCOUNTS:
                icon = <BusinessIcon />;
                params = route.params;
                label = t('nav.accounting.billing_accounts');
                break;

            case ROUTE_ADMINISTRATION_RECURRING:
                icon = <DescriptionIcon />;
                params = route.params;
                label = t('nav.administration.recurring');
                break;

            case ROUTE_STOCK_ITEMS:
                icon = <DescriptionIcon />;
                params = route.params;
                label = t('nav.stock.stock_items');
                break;

            case ROUTE_API_CENTER_SOURCE:
                icon = <DescriptionIcon />;
                params = route.params;
                label = ApiCenterSources[route.params.type];
                break;

            default:
                label = '';
                break;
        }
    };

    routeSwitcher(route);

    return (
        <Tooltip
            placement="right"
            title={label}
            disableHoverListener={drawerOpen}
            disableFocusListener={drawerOpen}
            disableTouchListener={drawerOpen}>
            <ListItem
                key={listKey}
                button
                component={NavLink}
                to={routeWithVariable(route.path, params)}
                activeClassName={classes.itemActiveItem}
                className={classes.item}>
                {
                    <ListItemIcon className={classes.itemIcon}>
                        {icon}
                    </ListItemIcon>
                }
                <ListItemText
                    classes={{
                        primary: classes.itemPrimary,
                    }}>
                    {label}
                </ListItemText>
            </ListItem>
        </Tooltip>
    );
};

export default NavChild;
