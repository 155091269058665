import { Roles } from '../auth/constants';
import { selectCurrentWorkspaceId } from '../app/selectors';

export const userRequiresWorkspace = user => {
    return [Roles.User, Roles.Admin].indexOf(user.role) >= 0;
};

export const selectCurrentUserId = state => {
    return state.auth.user.uid;
};

export const selectCurrentUserWorkspaces = state => {
    const workspaces = [];
    Object.keys(state.auth.user.workspace_roles).map(key => workspaces.push(key))
    return workspaces;
};

export const selectCurrentUserWorkspacePermission = (state, currentWorkspaceId) => {
    return state.auth.user.workspace_roles[currentWorkspaceId];
};

export const selectCurrentUserName = state => {
    return state.auth.user.first_name + ' ' + state.auth.user.last_name;
};


export const getCurrentWorkspaces = state => {
    const workspacesAgency = state.workspaces.workspaces;
    const workspacesClient = state.client.workspaces;
    const items = [
        ...(workspacesAgency || []),
        ...(workspacesClient || []),
    ];
    let combineSpaces = {};

    if (!!items) {
        combineSpaces = items.map(item =>
            Object.assign(
                {},
                item,
                !!item.workspace_agency
                    ? { type: 'client' }
                    : { type: 'workspace_agency' }
            )
        );
    }

    function getUnique(arr) {
        const unique = arr
            .map(e => (e.type === "client") ? e['workspace_agency'] : e['id'])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }
    return getUnique(combineSpaces);
};

export const selectCurrentWorkspace = state => {
    const workspaces = getCurrentWorkspaces(state);
    const currentWorkspaceId = selectCurrentWorkspaceId(state);
    let currentWorkspace = null;
    (workspaces || []).map(space => (
        (space.id === currentWorkspaceId ?
            currentWorkspace = space :
            null
        )))
    return currentWorkspace;
};

export const selectWorkspaceById = (state, id) => {
    return state.workspaces.workspaces.find(space => space.id === id);
};
