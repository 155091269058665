import {
    TableCell,
    TableRow,
    Box
} from '@material-ui/core';
import React from 'react';
import { selectItemTotal } from '../../../../../../core/modules/accounting/invoicing/selectors';
import { withStyles } from '@material-ui/core/styles';
import { InvoicingStatus } from '../../../../../../core/modules/accounting/invoicing/constants';
import { MarginLevels } from '../../../../../../core/modules/accounting/invoicing/constants';
import {
    selectMarginRow,
} from '../../../../../../core/modules/accounting/invoicing/selectors';
import NumberFormat from 'components/shared/form/generic/NumberFormat';

const styles = () => ({
    red: {
        color: "red",
    },
    green: {
        color: "green"
    },
    orange: {
        color: "orange"
    }
});

const ItemRow = ({ item, classes, status }) => {
    const margin = selectMarginRow(item);

    console.log(item);
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <p style={{ fontWeight: 'bold', margin: '0', whiteSpace: 'pre-wrap' }}>{item.name}</p>
                <p style={{ margin: '0', whiteSpace: 'pre-wrap' }}>{item.description || ''}</p>
                <p style={{ margin: '0', whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>{item.supplier?.name || ''}</p>
            </TableCell>
            <TableCell>{item.quantity || ''}</TableCell>
            <TableCell>
                {item.price ?
                    <NumberFormat number={item.price} /> :
                    ''}
            </TableCell>
            <TableCell>{item.vat}%</TableCell>
            <TableCell align="right">
                <NumberFormat number={selectItemTotal(item || {}, true)} />
            </TableCell>

            {(status !== InvoicingStatus.Accepted &&
                status !== InvoicingStatus.Published &&
                margin !== null) ? (
                <TableCell
                    align="right"
                    style={{
                        fontVariantNumeric: 'tabular-nums',
                        fontWeight: 'bold',
                    }}
                    className={
                        MarginLevels.Accepted <= margin ? classes.green :
                            MarginLevels.Warning >= margin ? classes.red :
                                classes.orange
                    }>
                    <Box displayPrint="none">
                        {margin}{'%'}
                    </Box>
                </TableCell>
            ) :
                <TableCell />
            }
        </TableRow>
    );
};

export default withStyles(styles)(ItemRow);
