import React from 'react';
import UsersOverviewScene from '../users/overview/UsersOverviewScene';
import {
    ROUTE_SUPER_ADMIN_EVENT_LOGS,
    ROUTE_SUPER_ADMIN_USERS,
    ROUTE_SUPER_ADMIN_USER_DETAIL,
    ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES,
    ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES_ADD,
} from 'core/routing/routing';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import WorkspaceOverviewScene from '../workspace/overview/WorkspaceOverviewScene';
import WorkspaceFormScene from '../workspace/form/WorkspaceFormScene';
import PageHeader from '../../shared/header/PageHeader';
import { t } from 'core/i18n';
import AppContent from '../../shared/content/AppContent';
import EventsScene from '../events/EventsScene';
import UsersDetailScene from '../users/detail/UsersDetailScene';

const AdminScene = () => {
    return (
        <>
            <PageHeader title={t('app.name.settings')} />
            <AppContent>
                <Switch>

                    <Route
                        path={ROUTE_SUPER_ADMIN_EVENT_LOGS}
                        component={EventsScene}
                    />

                    <Route
                        path={ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES_ADD}
                        component={WorkspaceFormScene}
                    />

                    <Route
                        path={ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES}
                        component={WorkspaceOverviewScene}
                    />

                    <Route
                        path={ROUTE_SUPER_ADMIN_USER_DETAIL}
                        component={UsersDetailScene}
                    />

                    <Route
                        path={ROUTE_SUPER_ADMIN_USERS}
                        component={UsersOverviewScene}
                    />

                    <Redirect to={ROUTE_SUPER_ADMIN_USERS} />
                </Switch>
            </AppContent>
        </>
    )
}

export default AdminScene;