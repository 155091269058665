import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import InvoicingFormContainer from './InvoicingFormContainer';
import AppContent from '../../../../shared/content/AppContent';
import {
    history,
    route,
    ROUTE_ADMINISTRATION_TYPE,
} from '../../../../../core/routing/routing';
import PageHeader from '../../../../shared/header/PageHeader';
import { t } from '../../../../../core/i18n';
import { useSelector } from 'react-redux';

const InvoicingFormScene = () => {
    const { type, id } = useParams();
    const workspaceId = useSelector(state => state.app.workspace)

    const handleSuccess = useCallback(() => {
        history.push(route(ROUTE_ADMINISTRATION_TYPE, { type, workspaceId }));
    }, [type, workspaceId]);

    return (
        <div>
            <PageHeader title={t(`invoicing.tabs.${type}`)} />
            <AppContent>
                <InvoicingFormContainer
                    type={type}
                    id={id}
                    onSuccess={handleSuccess}
                />
            </AppContent>
        </div>
    );
};

export default InvoicingFormScene;
