import React, { useMemo } from 'react';
import { storeUserRole, getUserById } from 'core/modules/users/actions';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
} from '@material-ui/core';
import { t } from 'core/i18n';
import { Roles, Permissions } from 'core/modules/auth/constants';
import BaseFormContainer from '../../../shared/form/BaseFormContainer';
import { Formik, Form } from 'formik';
import { useForm } from 'core/hooks/useForm';
import * as yup from 'yup';
import Select from '../../../shared/form/generic/Select';
import Alert from '@material-ui/lab/Alert';
import { isEmptyObject } from 'core/utils/isEmptyObject';
import AutoComplete from 'components/shared/form/generic/AutoComplete';
import { getAllWorkspaces } from 'core/modules/workspaces/actions';

const UserRoleSchema = yup.object().shape({
    role: yup.string().required(),
    permission: yup.string().required(),
});

const RolesDialogScene = ({ openDialog, onCancel, rowData, onSuccess }) => {
    const config = useMemo(
        () => ({
            fetch: () => getUserById(rowData.id),
            store: values => storeUserRole(values),
            defaultValue: false,
            onSuccess,
            id: rowData.id,
        }),
        [rowData.id, onSuccess]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    const handleWorkspaceSelect = (workspace_agency, setFieldValue) => {
        if (workspace_agency.id != null) {
            setFieldValue('workspace_agency.default', workspace_agency.id);
        }
    };

    return (
        <>
            {error && <Alert severity="error">{error}</Alert>}
            {!isEmptyObject(initialData) && (
                <BaseFormContainer
                    isLoading={isLoading}
                    error={error}
                    initialData={initialData}
                    render={() => (
                        <Formik
                            validationSchema={UserRoleSchema}
                            initialValues={initialData}
                            onSubmit={onSubmit}>
                            {({ isSubmitting, submitForm, handleChange, setFieldValue }) => (
                                <Dialog
                                    open={openDialog}
                                    onClose={onCancel}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="sm"
                                    fullWidth={true}>
                                    <DialogTitle>
                                        <Typography component="p" variant="body1">{!!initialData.full_name ? initialData.full_name : initialData.first_name + " " + initialData.last_name}</Typography>
                                        <Typography component="p" variant="body1">{initialData.email}</Typography>
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <Form>
                                            <Select
                                                label={t('users.fields.role')}
                                                //value={!!data.role ? data.role : ""}
                                                //onChange={handleChange}
                                                name="role"
                                                radioOptions={Object.keys(
                                                    Roles
                                                ).map(key => ({
                                                    value: Roles[key],
                                                    label: t(
                                                        `users.roles.${Roles[key]}.title`
                                                    ),
                                                }))}
                                            />
                                            <Select
                                                label={t(
                                                    'users.fields.permission'
                                                )}
                                                //value={!!data.permission ? data.permission : ""}
                                                //onChange={handleChange}
                                                name="permission"
                                                radioOptions={Object.keys(
                                                    Permissions
                                                ).map(key => ({
                                                    value: Permissions[key],
                                                    label: t(
                                                        `users.permissions.${Permissions[key]}.title`
                                                    ),
                                                }))}
                                            />

                                            <AutoComplete
                                                //multiple
                                                name="workspace_default"
                                                label={t('users.fields.default_workspace_agency')}
                                                onChange={e => handleWorkspaceSelect(e, setFieldValue)}
                                                performQuery={getAllWorkspaces}
                                            />
                                        </Form>
                                        {/*<FormControl component="fieldset" fullWidth>
                                            <InputLabel id="role">
                                                {t('users.fields.role')}
                                            </InputLabel>
                                            <Field
                                                as={MaterialSelect}
                                                label="role"
                                                name="role"
                                                //value={role}
                                                //input={<Input />}
                                                //onChange={handleRoleChange}
                                                renderValue={(selected) => selected}
                                            >
                                                {Object.entries(Roles).map(([key, value]) => (
                                                    <MenuItem key={key} value={value}>
                                                        <Radio
                                                            checked={value === initialData.role}
                                                            value={value}
                                                            inputProps={value}
                                                        />
                                                        <ListItemText primary={t(`users.roles.${value}.title`)} />
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                        <FormControl component="fieldset" fullWidth>
                                            <InputLabel id="permission">
                                                {t('users.fields.permission')}
                                            </InputLabel>
                                            <Field
                                                as={MaterialSelect}
                                                label="permission"
                                                name="permission"
                                                //value={permission}
                                                //input={<Input />}
                                                //onChange={handlePermissionChange}
                                                renderValue={(selected) => selected}
                                            >
                                                {Object.entries(Permissions).map(([key, value]) => (
                                                    <MenuItem key={key} value={value}>
                                                        <Radio
                                                            checked={value === initialData.permission}
                                                            value={value}
                                                            inputProps={value}
                                                        />
                                                        <ListItemText primary={t(`users.permissions.${value}.title`)} />
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                                </FormControl>*/}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={onCancel}>
                                            {t('actions.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            //disabled={isSubmitting}
                                            onClick={submitForm}
                                            color="primary">
                                            {t('actions.roles.update')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}
                        </Formik>
                    )}
                />
            )}
        </>
    );
};

export default RolesDialogScene;
