import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import {
    MenuItem,
    Tooltip,
    Menu,
    IconButton,
    ListItemText,
    ListItem,
    ListItemIcon
} from '@material-ui/core';
import clsx from 'clsx';
import { Settings as SettingsIcon } from '@material-ui/icons';


const styles = theme => ({
    navItem: {
        background: '#123350',
        width: '100%',
        color: "white",
        padding: '12px 10px 12px 16px',
        borderBottom: '1px solid #404854',
        whiteSpace: 'nowrap',
        '&:hover': {
            background: 'rgba(51,97,136, 1)',
        },
    },
    projectClose: {
        height: '84px',
        display: 'block',
        padding: '12px 0 12px 0',
        textAlign: 'center',
    },
    itemIcon: {
        minWidth: 'auto',
        color: "white",
        marginRight: theme.spacing(2),
        width: '16px',
        height: 'auto',
        display: "block",
    },
    itemIconClose: {
        width: '24px',
        marginBottom: '4px',
    },
    itemIconImg: {
        width: '100%',
    },
    itemText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: '500',
    },
    listItem: {
        padding: 0,
        color: "white",
    },
    listItemClose: {
        padding: '0 14px 0 14px',
    },
    itemActiveItem: {
        color: '#4fc3f7',
        background: 'rgba(51,97,136, 1)',
    },
    iconButton: {
        borderLeft: '1px solid #404854',
        color: 'white',
        borderRadius: 0,
        paddingLeft: 10
    },
    iconButtonClose: {
        borderLeft: 'none',
        paddingLeft: 0,
    },
    sideMenu: {
        minWidth: 'auto',
        marginLeft: 10,
    },
    sideMenuClose: {
        marginLeft: 0,
        padding: '0 14px 0 14px',
    }
})

const NavItem = ({ classes, open, mainRoute, item, placeholderName, menuLinks, placeholderIcon }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <MenuItem
            className={clsx(classes.navItem, {
                [classes.navItemOpen]: open,
                [classes.navItemClose]: !open,
            })}
            component={NavLink}
            to={mainRoute}
            activeClassName={classes.itemActiveItem}>
            <ListItemText>
                <Tooltip
                    placement="right"
                    title={item.name}
                    disableHoverListener={open}
                    disableFocusListener={open}
                    disableTouchListener={open}>
                    <ListItem
                        className={clsx(classes.listItem, {
                            [classes.listItemClose]: !open,
                        })}
                    >
                        <ListItemIcon
                            className={clsx(classes.itemIcon, {
                                [classes.itemIconOpen]: open,
                                [classes.itemIconClose]: !open,
                            })}>
                            {!!item.image_url ?
                                <img src={item.image_url} alt={item.name} className={classes.itemIconImg} /> :
                                placeholderIcon}
                        </ListItemIcon>
                        <ListItemText className={classes.itemText}>
                            {item.name}
                        </ListItemText>
                    </ListItem>
                </Tooltip>
            </ListItemText>
            <Tooltip
                placement="right"
                title={t(`${placeholderName}.tabs.settings`)}
                disableHoverListener={open}
                disableFocusListener={open}
                disableTouchListener={open}>
                <ListItemIcon
                    className={clsx(classes.sideMenu, {
                        [classes.sideMenuOpen]: open,
                        [classes.sideMenuClose]: !open,
                    })}>
                    <>
                        <IconButton
                            className={clsx(classes.iconButton, {
                                [classes.iconButtonOpen]: open,
                                [classes.iconButtonClose]: !open,
                            })}
                            aria-label={t(`nav.${placeholderName}s`)}
                            size="small"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <SettingsIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={!!anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleClose}
                        >
                            {
                                menuLinks.map(tab => (
                                    <MenuItem
                                        component={NavLink}
                                        value={tab.to}
                                        to={tab.to}
                                        label={tab.label}
                                        key={tab.to}
                                        onClick={handleClose}>
                                        {tab.label}
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                    </>
                </ListItemIcon>
            </Tooltip>
        </MenuItem>
    )
}

export default withStyles(styles)(NavItem);