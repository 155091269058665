export const InvoicingTypes = Object.freeze({
    Quote: 'quote',
    Order: 'order',
    Invoice: 'invoice',
    CreditNote: 'credit_note',
});

export const InvoicingFollowUpTypes = Object.freeze({
    quote: 'order',
    order: 'invoice',
    invoice: 'credit_note',
    credit_note: null,
});

export const InvoicingStatus = Object.freeze({
    Draft: 'draft',
    Published: 'published',
    Accepted: 'accepted',
    Refused: 'refused',
});

export const InvoicingApporvalStatus = Object.freeze({
    ApprovalAwaiting: 'approval_awaiting',
    ApprovalAccepted: 'approval_accepted',
    ApprovalRefused: 'approval_refused',
});

export const MarginLevels = Object.freeze({
    Accepted: 50,
    Warning: 25,
    Caution: 0,
});