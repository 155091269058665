import { useSelector } from 'react-redux';
import {
    userRequiresWorkspace,
} from '../../core/modules/workspaces/selectors';
import React from 'react';
import Progress from '../shared/progress/Progress';
import { selectCurrentWorkspaceId, selectCurrentClientId } from 'core/modules/app/selectors';

export const WorkspaceProvider = ({ children }) => {
    const user = useSelector(state => state.auth.user);
    const currentWorkspace = useSelector(state => selectCurrentWorkspaceId(state));
    const currentClient = useSelector(state => selectCurrentClientId(state));

    return (
        <>{
            !userRequiresWorkspace(user) ?
                children :
                (!!currentWorkspace || !user.isFullyRegistered) ? (
                    <div key={currentWorkspace}>{children}</div>
                ) : (
                        (!!currentClient || !user.isFullyRegistered) ? (
                            <div key={currentClient}>{children}</div>
                        ) : (
                                (!currentWorkspace && !currentClient && user.isFullyRegistered) ? (
                                    <div key={"ROUTE_USER"}>{children}</div>
                                ) : (
                                        <Progress isLoading={true} />
                                    )
                            )
                    )}
        </>
    )
};
