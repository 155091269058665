import React from 'react';
import AuthenticatedRoute from './route/AuthenticatedRoute';
import {
    ROUTE_APP,
    ROUTE_ONBOARDING,
    ROUTE_ADMINISTRATION_PUBLIC,
    ROUTE_LOGOUT,
    ROUTE_USER
} from '../../core/routing/routing';
import OnboardingContainer from '../onboarding/OnboardingContainer';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppContainer from '../app/AppContainer';
import { useSelector } from 'react-redux';
import { AuthStates } from '../../core/modules/auth/constants';
import InvoicingPublic from '../app/accounting/invoicing/public/InvoicingPublic';
import LogoutScene from './logout/LogoutScene';

const AuthContainer = () => {
    const isAppLoaded = useSelector(
        state => state.auth.status !== AuthStates.Idle
    );

    const isAuthenticated = useSelector(
        state => state.auth.status === AuthStates.LoggedIn && !!state.auth.user
    );

    // TODO styling and loader if app not loaded
    return isAppLoaded ? (
        <Switch>

            <Route
                path={ROUTE_LOGOUT}
                component={LogoutScene}
            />
            {/* Shareable link for administration document pdf view */}
            <Route
                path={ROUTE_ADMINISTRATION_PUBLIC}
                component={InvoicingPublic}
            />

            {!isAuthenticated && (
                <Route
                    path={ROUTE_ONBOARDING}
                    component={OnboardingContainer}
                />
            )}

            <AuthenticatedRoute
                path={ROUTE_APP}
                component={AppContainer}
                isAuthenticated={isAuthenticated}
            />

            <Redirect to={ROUTE_USER} />
        </Switch>
    ) : null;
};

export default AuthContainer;
