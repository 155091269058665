import React, { useMemo } from 'react';
import * as yup from 'yup';
import {
    Paper,
    Button
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import TextField from '../../../../../shared/form/generic/TextField';
import { t } from '../../../../../../core/i18n';
import { withStyles } from '@material-ui/core/styles';
import {
    storeProductGroup,
    getProductGroupById,
} from '../../../../../../core/modules/accounting/products/groups/actions';
import BaseFormContainer from '../../../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../../../core/hooks/useForm';

const ProductGroupSchema = yup.object().shape({
    name: yup.string().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
});

const ProductGroupsFormContainer = ({ classes, id, onSuccess }) => {
    const config = useMemo(
        () => ({
            fetch: () => getProductGroupById(id),
            store: values => storeProductGroup(values),
            defaultValue: {
                name: '',
            },
            onSuccess,
            id,
        }),
        [id, onSuccess]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        <BaseFormContainer
            isLoading={isLoading}
            error={error}
            initialData={initialData}
            render={() => (
                <Paper className={classes.paper}>
                    <Formik
                        initialValues={initialData}
                        validationSchema={ProductGroupSchema}
                        onSubmit={onSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <TextField
                                    label={t('product_groups.fields.name')}
                                    name="name"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}>
                                    {t(
                                        id ? 'actions.update' : 'actions.create'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            )}
        />
    );
};

export default withStyles(styles)(ProductGroupsFormContainer);
