import React from 'react';
import PageHeader from '../../../../shared/header/PageHeader';
import { t } from '../../../../../core/i18n';
import AppContent from '../../../../shared/content/AppContent';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    ROUTE_PRODUCT_GROUPS,
    ROUTE_PRODUCT_GROUPS_ADD,
    ROUTE_PRODUCT_GROUPS_EDIT,
} from 'core/routing/routing';
import ProductGroupsOverviewScene from './overview/ProductGroupsOverviewScene';
import ProductGroupsFormScene from './form/ProductGroupsFormScene';

const ProductGroupsScene = props => {
    return (
        <>
            <PageHeader title={t('product_groups.title')} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_PRODUCT_GROUPS_EDIT}
                        component={ProductGroupsFormScene}
                    />
                    <Route
                        path={ROUTE_PRODUCT_GROUPS_ADD}
                        component={ProductGroupsFormScene}
                    />
                    <Route
                        path={ROUTE_PRODUCT_GROUPS}
                        component={ProductGroupsOverviewScene}
                    />
                    <Redirect to={ROUTE_PRODUCT_GROUPS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default ProductGroupsScene;
