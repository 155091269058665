export const selectCurrentProjectId = state => {
    return state.project.current;
}

export const selectCurrentProject = state => {
    return state.project.project;
};

export const selectCurrentProjectName = state => {
    return state.project.project?.name || "";
};