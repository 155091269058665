import { Collections, fireStore } from "core/networking/firebase";
import { getDocData } from "core/networking/utils";
import { getCollectionByType } from "../actions";

export const fetchInvoiceEventsById = async (type, id) => {
    return await fireStore
        .collection(getCollectionByType(type))
        .doc(id)
        .collection(Collections.events)
        .orderBy('createdAt', 'desc')
        .get()
        .then(resp => {
            return resp.docs.map(doc => getDocData(doc))
        })
        .catch(err => {
            console.log(err)
        });
};

export const getInvoiceEventsById = (type, id) => (dispatch, getState) => {
    return fetchInvoiceEventsById(type, id);
};

export const copyEvents = async (oldType, oldId, newId, newType) => {
    return await fireStore
        .collection(getCollectionByType(oldType))
        .doc(oldId)
        .collection(Collections.events)
        .get()
        .then(resp => {
            return resp.docs.map(async doc => {
                return await fireStore
                    .collection(getCollectionByType(newType))
                    .doc(newId)
                    .collection(Collections.events)
                    .add(doc.data());
            })
        })
}