import React from 'react';
import WorkspaceUsersFormContainer from './WorkspaceUsersFormContainer';
import { useSelector } from 'react-redux';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';

const WorkspaceUsersFormScene = ({ onSuccess }) => {
    const workspaceId = useSelector(state => selectCurrentWorkspaceId(state));

    return (
        <>
            <WorkspaceUsersFormContainer id={workspaceId} onSuccess={onSuccess} />
        </>
    )
}

export default WorkspaceUsersFormScene;