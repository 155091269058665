import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '../generic/TextField';
import { t } from 'core/i18n';
import {
    Box,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { vatValidator } from 'core/modules/dashboard/clients/billing-accounts/actions';
import VatResult from './VatResult';
import { useFormikContext } from 'formik';
import { isEmptyText } from 'core/utils/isEmptyText';
import {
    Search as SearchIcon,
} from '@material-ui/icons';

const styles = () => ({

});

const VatValidationField = ({ classes, onValidate, vatType, errors }) => {
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const [vatData, setVatData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmedVat, setConfirmedVat] = useState(!!values.vat_number?.summary ? values.vat_number.summary : null);

    const fetchVatValidation = useCallback(() => {
        setIsLoading(true);
        const vatNumber = document.getElementById('vat_number.vat').value;
        dispatch(vatValidator(vatNumber))
            .then(data => {
                setIsLoading(false);
                setVatData(data);
            })
    }, [dispatch]);


    const onSelectVat = result => {
        setConfirmedVat(result);
        setFieldValue('vat_number.vat', result.country_code + result.vat_number)
        setFieldValue('vat_number.summary', result.company_address + "\n" +
            result.company_name + "\n" +
            result.country_code);
        if (isEmptyText(values.legal_name)) {
            setFieldValue('legal_name', result.company_name)
        }
        if (isEmptyText(values.name)) {
            setFieldValue('name', result.company_name)
        }
        setVatData(null);
    }

    return (
        <>
            <Box display="flex">
                {(vatType === "eu") ? (
                    <TextField
                        label={t('vat.fields.vat_number')}
                        name="vat_number.vat"
                        id="vat_number.vat"
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={t('actions.search')}
                                        onClick={fetchVatValidation}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                ) :
                    <TextField
                        label={t('vat.fields.vat_number')}
                        name="vat_number.vat"
                        id="vat_number.vat"
                    />
                }
            </Box>
            {!!confirmedVat && (
                <Box>
                    <TextField
                        label={""}
                        name="vat_number.summary"
                        multiline
                        rows={4}
                        disabled={true}
                    />
                </Box>
            )}
            <VatResult
                result={vatData}
                onSelectVat={onSelectVat}
                isLoading={isLoading}
                onClose={() => setVatData(null)}
                errors={errors} />
        </>
    );
};

export default withStyles(styles)(VatValidationField);
