import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { history } from 'core/routing/routing';
import ProjectLogsFormContainer from './ProjectLogsFormContainer';
import {
    route,
    ROUTE_PROJECT_LOGS,
} from '../../../../../../core/routing/routing';
import { useSelector } from 'react-redux';

const ProjectLogsFormScene = ({ projectId }) => {
    const { logId } = useParams();
    const workspaceId = useSelector(state => state.app.workspace);

    const handleSuccess = useCallback(() => {
        history.push(route(ROUTE_PROJECT_LOGS, { projectId: projectId, workspaceId }));
    }, [projectId]);

    return (
        <div>
            <ProjectLogsFormContainer
                id={logId}
                projectId={projectId}
                onSuccess={handleSuccess}
            />
        </div>
    );
};

export default ProjectLogsFormScene;
