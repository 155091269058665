import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Progress from '../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import { getErrorMessage } from '../../../../core/networking/utils';
import { getProjectById } from '../../../../core/modules/dashboard/projects/actions';
import ProjectLogsContainer from './logs/ProjectLogsContainer';
import { setProject } from '../../../../core/modules/dashboard/projects/reducer';

const ProjectDetailScene = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [currentProject, setCurrentProject] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!!projectId) {
            dispatch(getProjectById(projectId))
                .then(project => {
                    const action = {};
                    action.project = project;
                    action.current = project.id;
                    setProject(action);
                    setCurrentProject(project);
                })
                .catch(error => {
                    setError(getErrorMessage(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [projectId, dispatch]);

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {!!currentProject && (
                    <div>
                        <ProjectLogsContainer
                            project={currentProject}
                            projectId={currentProject.id}
                        />
                    </div>
                )}
            </>
        );
    }
};

export default ProjectDetailScene;
