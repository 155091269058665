import { createBrowserHistory } from 'history';
import { Roles } from '../modules/auth/constants';
import { InvoicingTypes } from 'core/modules/accounting/invoicing/constants';
import { ApiCenterSources } from '../modules/apicenter/constants';
import { isEmptyText } from 'core/utils/isEmptyText';
import { firebaseAuth } from 'core/networking/firebase';
import { selectCurrentUser } from 'core/modules/users/selectors';
import { selectCurrentWorkspace } from 'core/modules/workspaces/selectors';
import { selectCurrentClientName } from 'core/modules/dashboard/clients/selector';
import { selectCurrentProjectName } from 'core/modules/dashboard/projects/selector';
import { isVoid } from 'core/utils/isVoid';

// onboarding
export const ROUTE_ONBOARDING = '';
export const ROUTE_LOGIN = ROUTE_ONBOARDING + '/login';
export const ROUTE_REGISTER = ROUTE_ONBOARDING + '/register';
export const ROUTE_REGISTER_ON_INVITE =
    ROUTE_ONBOARDING + '/register-on-invite';
export const ROUTE_PASSWORD_RESET = ROUTE_ONBOARDING + '/password/forgot';
export const ROUTE_AUTH = ROUTE_ONBOARDING + '/_/auth/action';

// logout
export const ROUTE_LOGOUT = ROUTE_ONBOARDING + '/logout';

// main
export const ROUTE_APP = '/:workspaceId';

// guest
export const ROUTE_GUEST = '';
export const ROUTE_COMPLETE_REGISTER_ON_INVITE =
    ROUTE_GUEST + '/complete-register';

// user
export const ROUTE_USER = '/_';
export const ROUTE_USER_OVERVIEW = ROUTE_USER + '/user/overview';
export const ROUTE_USER_EDIT = ROUTE_USER + '/user/edit';
export const ROUTE_USER_PREFERENCES = ROUTE_USER + '/user/preferences';

// super admin
export const ROUTE_SUPER_ADMIN = '/0/app';
export const ROUTE_SUPER_ADMIN_USERS = ROUTE_SUPER_ADMIN + '/users';
export const ROUTE_SUPER_ADMIN_USER_DETAIL = ROUTE_SUPER_ADMIN + '/:userId/user';

// super admin workspaces
export const ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES =
    ROUTE_SUPER_ADMIN + '/agencies';
export const ROUTE_SUPER_ADMIN_EVENT_LOGS =
    ROUTE_SUPER_ADMIN + '/reports';
export const ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES_ADD =
    ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES + '/add';

// Administration documents shareable links
export const ROUTE_ADMINISTRATION_PUBLIC = ROUTE_APP + '/my/:type/:id';

// accounting
export const ROUTE_ACCOUNTING = ROUTE_APP + '/a';

// accounting • vat
export const ROUTE_VAT_TYPES = ROUTE_ACCOUNTING + '/vat_types';
export const ROUTE_VAT_TYPES_ADD = ROUTE_VAT_TYPES + '/add';
export const ROUTE_VAT_TYPES_EDIT = ROUTE_VAT_TYPES + 'edit/:id';

// accounting • products
export const ROUTE_PRODUCTS = ROUTE_ACCOUNTING + '/products';
export const ROUTE_PRODUCTS_ADD = ROUTE_PRODUCTS + '/add';
export const ROUTE_PRODUCTS_EDIT = ROUTE_PRODUCTS + '/edit/:id';

// accounting • products • groups
export const ROUTE_PRODUCT_GROUPS = ROUTE_ACCOUNTING + '/product_groups';
export const ROUTE_PRODUCT_GROUPS_ADD = ROUTE_PRODUCT_GROUPS + '/add';
export const ROUTE_PRODUCT_GROUPS_EDIT = ROUTE_PRODUCT_GROUPS + '/edit/:id';

// accounting • suppliers
export const ROUTE_SUPPLIERS = ROUTE_ACCOUNTING + '/suppliers';
export const ROUTE_SUPPLIERS_ADD = ROUTE_SUPPLIERS + '/add';
export const ROUTE_SUPPLIERS_EDIT = ROUTE_SUPPLIERS + '/edit/:id';

// Stock.
export const ROUTE_STOCK = ROUTE_APP + '/stock';
export const ROUTE_STOCK_ITEMS = ROUTE_STOCK + '/stock-items';

// Stock.
export const ROUTE_API_CENTER = ROUTE_APP + '/api-center';
export const ROUTE_API_CENTER_SOURCE = ROUTE_API_CENTER + '/:type';

// Administration
export const ROUTE_ADMINISTRATION = ROUTE_APP + '/i';
export const ROUTE_ADMINISTRATION_TYPE = ROUTE_ADMINISTRATION + '/administration' + '/:type';
export const ROUTE_ADMINISTRATION_QUOTE = ROUTE_ADMINISTRATION + '/administration' + '/:type';
export const ROUTE_ADMINISTRATION_TYPE_ADD = ROUTE_ADMINISTRATION_TYPE + '/add';
export const ROUTE_ADMINISTRATION_TYPE_EDIT =
    ROUTE_ADMINISTRATION_TYPE + '/edit/:id';
export const ROUTE_ADMINISTRATION_TYPE_DETAIL =
    ROUTE_ADMINISTRATION_TYPE + '/:id';
export const ROUTE_ADMINISTRATION_TYPE_DETAIL_PDF =
    ROUTE_ADMINISTRATION_TYPE_DETAIL + '/pdf';
export const ROUTE_ADMINISTRATION_TYPE_CHANGE =
    ROUTE_ADMINISTRATION + '/from/:fromType/:id/to/:toType';
export const ROUTE_ADMINISTRATION_RECURRING =
    ROUTE_ADMINISTRATION + '/recurring';
export const ROUTE_ADMINISTRATION_RECURRING_ADD =
    ROUTE_ADMINISTRATION_RECURRING + '/add';
export const ROUTE_ADMINISTRATION_RECURRING_EDIT =
    ROUTE_ADMINISTRATION_RECURRING + '/edit/:id';

// dashboard
export const ROUTE_DASHBOARD = ROUTE_APP + '/dashboard';

// Workspace • clients
export const ROUTE_CLIENTS = ROUTE_APP + '/c';
export const ROUTE_CLIENTS_ADD = ROUTE_CLIENTS + '/add';
export const ROUTE_CLIENTS_EDIT = ROUTE_CLIENTS + '/:clientId/edit';
export const ROUTE_CLIENTS_DETAIL = ROUTE_CLIENTS + '/:clientId';
export const ROUTE_CLIENTS_USERS = ROUTE_CLIENTS_DETAIL + '/users';
export const ROUTE_CLIENT_PROJECTS = ROUTE_CLIENTS_DETAIL + '/projects';
export const ROUTE_CLIENT_PROJECTS_ADD = ROUTE_CLIENT_PROJECTS + '/add';

// accounting • billing accounts
export const ROUTE_BILLING_ACCOUNTS = ROUTE_ACCOUNTING + '/billing-accounts';
export const ROUTE_BILLING_ACCOUNTS_ADD = ROUTE_BILLING_ACCOUNTS + '/add';
export const ROUTE_BILLING_ACCOUNTS_DETAIL = ROUTE_BILLING_ACCOUNTS + '/:billingId';
export const ROUTE_BILLING_ACCOUNTS_EDIT =
    ROUTE_BILLING_ACCOUNTS_DETAIL + '/edit';


// dashboard • clients • billing accounts
export const ROUTE_CLIENT_BILLING_ACCOUNTS =
    ROUTE_CLIENTS_DETAIL + '/billing-accounts';
export const ROUTE_CLIENT_BILLING_ACCOUNTS_ADD =
    ROUTE_CLIENT_BILLING_ACCOUNTS + '/add';
export const ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL =
    ROUTE_CLIENT_BILLING_ACCOUNTS + '/:billingId';
export const ROUTE_CLIENT_BILLING_ACCOUNTS_EDIT =
    ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL + '/edit';

// dashboard • projects
export const ROUTE_PROJECTS = ROUTE_APP + '/projects';
export const ROUTE_PROJECTS_ADD = ROUTE_PROJECTS + '/add';
export const ROUTE_PROJECTS_EDIT = ROUTE_PROJECTS + '/:projectId/edit';
export const ROUTE_PROJECTS_DETAIL = ROUTE_PROJECTS + '/:projectId';

// workspace
export const ROUTE_WORKSPACE = ROUTE_APP + '/workspaces';
export const ROUTE_WORKSPACE_SETTINGS_GENERAL = ROUTE_WORKSPACE + '/general';
export const ROUTE_WORKSPACE_SETTINGS_TAGS = ROUTE_WORKSPACE + '/tags';
export const ROUTE_WORKSPACE_SETTINGS_ACCOUNTING =
    ROUTE_WORKSPACE + '/accounting';
export const ROUTE_WORKSPACE_EVENT_LOGS = ROUTE_WORKSPACE + '/reports';
export const ROUTE_WORKSPACE_USERS = ROUTE_WORKSPACE + '/users';
export const ROUTE_WORKSPACE_USERS_ADD = ROUTE_WORKSPACE + '/users/add';
export const ROUTE_WORKSPACE_CLIENTS = ROUTE_APP + '/workspaces';
export const ROUTE_WORKSPACE_CLIENTS_ADD = ROUTE_WORKSPACE_CLIENTS + '/add';

// dashboard • projects • logs
export const ROUTE_PROJECT_LOGS = ROUTE_PROJECTS_DETAIL + '/logs';
export const ROUTE_PROJECT_LOGS_ADD = ROUTE_PROJECT_LOGS + '/add';
export const ROUTE_PROJECT_LOGS_EDIT = ROUTE_PROJECT_LOGS + '/edit/:logId';

export const history = createBrowserHistory();

export const route = (path, options) => {
    //console.log('path', path)
    //console.log('options', options)
    if (!isVoid(options)) {
        Object.keys(options).forEach(key => {
            if (key === 'query') {
                const queryOptions = options[key];
                path = path + '?';
                Object.keys(queryOptions).forEach((key, index) => {
                    if (!!queryOptions[key]) {
                        path =
                            path +
                            (index > 0 ? '&' : '') +
                            key +
                            '=' +
                            queryOptions[key].toString().replace(/\s/g, '-');
                    }
                });
            } else {
                path = path.replace(
                    new RegExp(':' + key + '\\??', 'gi'),
                    options[key].toString().replace(/\s/g, '-')
                );
            }
        });
        return path;
    }
    return path;
};

export const getDefaultRoute = user => {
    if (!user.isFullyRegistered) {
        return ROUTE_COMPLETE_REGISTER_ON_INVITE;
    } else if (user.role === Roles.User) {
        return ROUTE_USER;
        /*if (!isEmptyText(user.workspace_permission)) {
            return ROUTE_WORKSPACE;
        } else {
            return ROUTE_CLIENTS
        }*/
    } else if (user.role === Roles.Admin) {
        return ROUTE_WORKSPACE;
    } else {
        return ROUTE_GUEST;
    }
};

export const createGroup = (path, children) => {
    return {
        path,
        children,
    };
};

export const createRoute = (path, hidden = false, params = {}) => {
    return {
        path,
        hidden,
        params,
    };
};

export const getMainRoutes = (user, workspaceId) => {
    let routes = [];

    if (user.role === undefined) {
        routes = [
            ...routes,
            createRoute(ROUTE_GUEST, true),
            createRoute(ROUTE_COMPLETE_REGISTER_ON_INVITE, true),
        ];
    } else if (user.role === Roles.Guest) {
        routes = [
            ...routes,
            createRoute(ROUTE_AUTH, true),
            createRoute(ROUTE_GUEST, true),
            createRoute(ROUTE_USER, true),
            createRoute(ROUTE_LOGOUT, true),
        ];
    } else if (user.role === Roles.User) {
        if (!isEmptyText(user.workspace_permission)) {
            routes = [
                ...routes,
                createRoute(ROUTE_USER, true),
                createRoute(ROUTE_LOGOUT, true),
                createRoute(ROUTE_WORKSPACE, true),
                createRoute(ROUTE_COMPLETE_REGISTER_ON_INVITE, true),
                createRoute(ROUTE_REGISTER_ON_INVITE, true),
                createRoute(ROUTE_CLIENTS, true),
                createRoute(ROUTE_PROJECTS, true),
                createGroup(ROUTE_ACCOUNTING, [
                    createRoute(ROUTE_PRODUCTS, false, {
                        workspaceId: workspaceId,
                    }),
                    createRoute(ROUTE_PRODUCT_GROUPS, false, {
                        workspaceId: workspaceId,
                    }),
                    createRoute(ROUTE_SUPPLIERS, false, {
                        workspaceId: workspaceId,
                    }),
                    createRoute(ROUTE_BILLING_ACCOUNTS, false, {
                        workspaceId: workspaceId,
                    }),
                ]),
                createGroup(ROUTE_ADMINISTRATION, [
                    createRoute(ROUTE_ADMINISTRATION_TYPE, true, {
                        workspaceId: workspaceId, type: InvoicingTypes.Quote,
                    }),
                    createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                        workspaceId: workspaceId, type: InvoicingTypes.Order,
                    }),
                    createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                        workspaceId: workspaceId, type: InvoicingTypes.Invoice,
                    }),
                    createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                        workspaceId: workspaceId, type: InvoicingTypes.CreditNote,
                    }),
                    createRoute(ROUTE_ADMINISTRATION_RECURRING, false, {
                        workspaceId: workspaceId,
                    }),
                ]),
                createGroup(ROUTE_STOCK, [
                    createRoute(ROUTE_STOCK_ITEMS, false, {
                        workspaceId: workspaceId,
                    }),
                ]),
                createGroup(
                    ROUTE_API_CENTER,
                    Object.keys(ApiCenterSources).map(key => {
                        return createRoute(ROUTE_API_CENTER_SOURCE, false, {
                            type: key,
                            workspaceId: workspaceId,
                        });
                    })
                ),
            ];
        } else {
            routes = [
                ...routes,
                createRoute(ROUTE_USER, true),
                createRoute(ROUTE_LOGOUT, true),
                createRoute(ROUTE_COMPLETE_REGISTER_ON_INVITE, true),
                createRoute(ROUTE_REGISTER_ON_INVITE, true),
                createRoute(ROUTE_CLIENTS, true),
                createRoute(ROUTE_PROJECTS, true),
            ];
        }
    } else if (user.role === Roles.Admin) {
        routes = [
            ...routes,
            createRoute(ROUTE_SUPER_ADMIN, true),
            createRoute(ROUTE_USER, true),
            createRoute(ROUTE_LOGOUT, true),
            createRoute(ROUTE_WORKSPACE, true),
            createRoute(ROUTE_COMPLETE_REGISTER_ON_INVITE, true),
            createRoute(ROUTE_REGISTER_ON_INVITE, true),
            createRoute(ROUTE_CLIENTS, true),
            createRoute(ROUTE_PROJECTS, true),
            createGroup(ROUTE_ACCOUNTING, [
                createRoute(ROUTE_PRODUCTS, false, {
                    workspaceId: workspaceId,
                }),
                createRoute(ROUTE_PRODUCT_GROUPS, false, {
                    workspaceId: workspaceId,
                }),
                createRoute(ROUTE_SUPPLIERS, false, {
                    workspaceId: workspaceId,
                }),
                createRoute(ROUTE_BILLING_ACCOUNTS, false, {
                    workspaceId,
                }),
            ]),
            createGroup(ROUTE_ADMINISTRATION, [
                createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                    workspaceId: workspaceId, type: InvoicingTypes.Quote,
                }),
                createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                    workspaceId: workspaceId, type: InvoicingTypes.Order,
                }),
                createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                    workspaceId: workspaceId, type: InvoicingTypes.Invoice,
                }),
                createRoute(ROUTE_ADMINISTRATION_TYPE, false, {
                    workspaceId: workspaceId, type: InvoicingTypes.CreditNote,
                }),
                createRoute(ROUTE_ADMINISTRATION_RECURRING, false, {
                    workspaceId: workspaceId,
                }),
            ]),
            createGroup(ROUTE_STOCK, [createRoute(ROUTE_STOCK_ITEMS, false, {
                workspaceId: workspaceId,
            })]),
            createGroup(
                ROUTE_API_CENTER,
                Object.keys(ApiCenterSources).map(key => {
                    return createRoute(ROUTE_API_CENTER_SOURCE, false, {
                        type: key,
                        workspaceId: workspaceId,
                    });
                })
            ),
        ];
    }

    return routes;
};

export const historyLogging = (route) => async (dispatch, getState) => {
    const pushHistoryLogging = firebaseAuth.functions().httpsCallable('historyLogging');
    const user = selectCurrentUser(getState());
    const workspace = selectCurrentWorkspace(getState());
    const location = {
        workspace: workspace?.name || "",
        workspace_client: selectCurrentClientName(getState()),
        project: selectCurrentProjectName(getState()),
    };

    return await pushHistoryLogging({
        user,
        route,
        location,
    })
        .catch(function (error) {
            // Getting the Error details.
            console.log(error);
            /*var code = error.code;
            var message = error.message;
            var details = error.details;*/
            // ...
        });
}