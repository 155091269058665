import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/Dalta.svg';
import clsx from 'clsx';
import { route, ROUTE_WORKSPACE_CLIENTS, ROUTE_WORKSPACE_EVENT_LOGS, ROUTE_WORKSPACE_SETTINGS_ACCOUNTING, ROUTE_WORKSPACE_SETTINGS_GENERAL, ROUTE_WORKSPACE_SETTINGS_TAGS, ROUTE_WORKSPACE_USERS } from 'core/routing/routing';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Permissions, Roles } from 'core/modules/auth/constants';

const styles = theme => ({
    menuItem: {
        color: 'rgba(255, 255, 255, 0.7)',
        borderTop: '1px solid #404854',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        fontWeight: '700',
        padding: '12px 10px 12px 16px',
    },
    menuItemClose: {
        height: '84px',
        display: 'block',
        //padding: '12px 14px 12px 14px',
        padding: '12px 0 12px 0',
        textAlign: 'center',
    },
    logoClose: {
        paddingLeft: '12px',
        paddingRight: '12px'
    },
    itemIconImg: {
        width: 22,
        display: "flex"
        /*height: 'auto',
        marginRight: 6,
        minWidth: 'auto',*/
        //width: '100%',
    },
    iconButton: {
        color: 'white',
        fontSize: '12px',
        letterSpacing: '.5px',
        fontWeight: '700',
        borderRadius: 0,

    },
    iconButtonClose: {
        borderLeft: 'none',
        paddingLeft: 0,
    },
    itemText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: '500',
        whiteSpace: 'nowrap',
    },
    listItem: {
        padding: 0,
        color: 'white',
        flex: '1 1 auto',
    },
    listItemClose: {
        padding: '0 14px 0 14px',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
        height: 'auto',
        display: 'block'
    },
    itemIconClose: {
        width: '24px',
        marginBottom: '4px',
    },
    sideMenu: {
        minWidth: 'auto',
        marginLeft: 10,
    },
    sideMenuClose: {
        marginLeft: 0,
        padding: '0 14px 0 14px',
    }
})

const WorkspaceMenu = ({ classes, open }) => {
    const workspaceLocalstorage = useSelector(state => state.app.workspace);
    const workspaceRoles = useSelector(state => state.auth.user.workspace_roles);

    const isSuperAdmin = useSelector(
        state => (state.auth.user.role === Roles.Admin && state.auth.user.permission === Permissions.Owner)
    );

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <List
            className={clsx(classes.menuItem, {
                [classes.menuItemOpen]: open,
                [classes.menuItemClose]: !open,
            })}>
            <ListItemText>
                <ListItem
                    className={clsx(classes.listItem, {
                        [classes.listItemClose]: !open,
                    })}>
                    <ListItemIcon
                        className={clsx(classes.itemIcon, {
                            [classes.itemIconOpen]: open,
                            [classes.itemIconClose]: !open,
                        })}>
                        <Logo className={classes.itemIconImg} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography={true}
                        className={classes.itemText}
                        primary={t('app.name')}
                    />
                </ListItem>
            </ListItemText>

            {(!!workspaceRoles[workspaceLocalstorage] || isSuperAdmin) && (
                <Tooltip
                    placement="right"
                    title={t('nav.modify')}
                    disableHoverListener={open}
                    disableFocusListener={open}
                    disableTouchListener={open}>
                    <ListItemIcon
                        className={clsx(classes.sideMenu, {
                            [classes.sideMenuOpen]: open,
                            [classes.sideMenuClose]: !open,
                        })}>
                        <>
                            <IconButton
                                className={clsx(classes.iconButton, {
                                    [classes.iconButtonOpen]: open,
                                    [classes.iconButtonClose]: !open,
                                })}
                                disableFocusRipple={true}
                                aria-label={t('nav.clients')}
                                size="small"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                edge="end"
                                onClick={handleClick}>
                                {
                                    open ?
                                        (t('nav.modify')) :

                                        <SettingsIcon />
                                }
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={!!anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={handleClose}
                            >
                                {[
                                    {
                                        to: route(ROUTE_WORKSPACE_CLIENTS, { workspaceId: workspaceLocalstorage }),
                                        label: t('workspace.tabs.clients'),
                                    },
                                    {
                                        to: route(ROUTE_WORKSPACE_SETTINGS_GENERAL, { workspaceId: workspaceLocalstorage }),
                                        label: t('workspace.tabs.general'),
                                    },
                                    {
                                        to: route(ROUTE_WORKSPACE_SETTINGS_TAGS, { workspaceId: workspaceLocalstorage }),
                                        label: t('workspace.tabs.tags'),
                                    },
                                    {
                                        to: route(ROUTE_WORKSPACE_SETTINGS_ACCOUNTING, { workspaceId: workspaceLocalstorage }),
                                        label: t('workspace.tabs.accounting'),
                                    },
                                    {
                                        to: route(ROUTE_WORKSPACE_USERS, { workspaceId: workspaceLocalstorage }),
                                        label: t('workspace.tabs.users_and_permissions'),
                                    },
                                    {
                                        to: route(ROUTE_WORKSPACE_EVENT_LOGS, { workspaceId: workspaceLocalstorage }),
                                        label: t('workspace.tabs.event_logs'),
                                    },
                                ].map(tab => (
                                    <MenuItem
                                        component={NavLink}
                                        value={tab.to}
                                        to={tab.to}
                                        label={tab.label}
                                        key={tab.to}
                                        onClick={handleClose}>
                                        {tab.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    </ListItemIcon>
                </Tooltip>
            )}
        </List >
    )
}

export default withStyles(styles)(WorkspaceMenu);