import { createSlice } from '@reduxjs/toolkit';
import { AuthStates } from './constants';

const INITIAL_STATE = {
    status: AuthStates.Idle,
    user: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        setUser(state, action) {
            state.status = AuthStates.LoggedIn;
            state.user = action.payload;
        },
        clearUser(state) {
            state.status = AuthStates.LoggedOut;
            state.user = null;
        },
        updateUser(state, action) {
            const user = action.payload;
            const { first_name, last_name, avatar, phoneNumber, dob, gender, street_nr, city, country, postal_code } = action.payload;

            state.user = {
                ...user,
                first_name: first_name,
                last_name: last_name,
                avatar: avatar,
                displayName: first_name + " " + last_name,
                phoneNumber: phoneNumber,
                dob,
                gender,
                street_nr,
                city,
                country,
                postal_code
            };
        }
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice;
// Extract and export each action creator by name
export const { setUser, clearUser, updateUser } = actions;
// Export the reducer, either as a default or named export
export default reducer;
