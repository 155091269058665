import React, { useEffect, useCallback, useState } from 'react';
import {
    Paper,
    Typography,
    Button,
    Box,
    Link
} from '@material-ui/core';
import { t } from 'core/i18n';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userManagementActions, handleConfirmPasswordReset } from 'core/modules/auth/actions';
import { getErrorMessage } from 'core/networking/utils';
import { Formik, Form } from 'formik';
import TextField from '../../shared/form/generic/TextField';
import Alert from '@material-ui/lab/Alert';
import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { UserManagementActionsType } from 'core/modules/auth/constants';
import { useSelector } from 'react-redux';
import { setUser } from 'core/modules/auth/reducer';
import {
    route,
    history,
    ROUTE_GUEST,
    ROUTE_LOGIN,
} from 'core/routing/routing';

// let's keep this out of the render to avoid useMemo
const Schema = yup.object().shape({
    new_password: yup
        .string()
        .min(8)
        .required(),
});


const styles = () => ({
    paper: {
        padding: 30,
    },
    loginFooter: {
        color: 'white',
        padding: 30,
    },
    linkWhite: {
        '&:after': {
            backgroundColor: 'white',
        },
    },
    btn: {
        margin: '1em 0',
    },
});

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const UserManagementScene = ({ classes }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    const [initialData, setInitialData] = useState({ new_password: '' });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resetEmail, setResetEmail] = useState(null)

    const query = useQuery();
    const mode = query.get('mode');
    const oobCode = query.get('oobCode');
    const apiKey = query.get('apiKey');
    const lang = query.get('lang');
    //const continueUrl = query.get('continueUrl');

    useEffect(() => {
        setInitialData({});
        dispatch(userManagementActions(mode, oobCode, apiKey, lang))
            .then(data => {
                if (mode === UserManagementActionsType.ResetPassword) {
                    !!data ? setResetEmail(data) : setResetEmail('');
                } else if (mode === UserManagementActionsType.VerifyEmail) {
                    if (!!data.message) {
                        setError(getErrorMessage(data));
                    } else if (!!user) {
                        history.push(route(ROUTE_GUEST));
                        dispatch(setUser({ ...user, emailVerified: true }));
                    } else {
                        history.push(route(ROUTE_LOGIN));
                    }
                }
            })
            .catch(
                error => {
                    // not loading anymore
                    setLoading(false);
                    // show error
                    if (
                        error.code === 'auth/user-not-found' ||
                        error.code === 'auth/wrong-password'
                    ) {
                        setError(t('onboarding.login.errors.auth'));
                    } else {
                        setError(getErrorMessage(error));
                    }
                }
            );
    }, [dispatch, mode, oobCode, apiKey, lang, user])

    const onSubmit = useCallback(
        data => {
            // set as loading
            setLoading(true);
            // try to log in
            dispatch(handleConfirmPasswordReset(resetEmail, oobCode, data.new_password))
                .catch(
                    error => {
                        // not loading anymore
                        setLoading(false);
                        // show error
                        if (
                            error.code === 'auth/wrong-password'
                        ) {
                            setError(t('onboarding.login.errors.auth'));
                        } else {
                            setError(getErrorMessage(error));
                        }
                    }
                );
        },
        [dispatch, oobCode, resetEmail]
    );

    return (
        <>
            {(mode !== UserManagementActionsType.VerifyEmail) && (
                <>
                    <Paper className={classes.paper}>
                        <Typography component="p" variant="h6">
                            {mode === UserManagementActionsType.ResetPassword ?
                                t('onboarding.user_management.actions.title.reset_password') :
                                mode === UserManagementActionsType.VerifyEmail ?
                                    t('onboarding.user_management.actions.title.verify_email') :
                                    t('onboarding.user_management.actions.title.recover_email')}
                        </Typography>
                        {!!resetEmail && (
                            <Typography component="p" variant="body1">
                                {resetEmail}
                            </Typography>
                        )}
                        {(initialData && (mode === "resetPassword")) ? (
                            <Formik
                                initialValues={initialData}
                                validationSchema={Schema}
                                onSubmit={onSubmit}>
                                {({ errors, touched, isValid }) => (
                                    <Form>
                                        <TextField
                                            label={t('onboarding.login.form.new_password')}
                                            name="new_password"
                                            type="password"
                                            variant="outlined"
                                        />
                                        {error && (
                                            <Alert severity="error">{error}</Alert>
                                        )}
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            disabled={isLoading}
                                            className={classes.btn}
                                            disableElevation>
                                            {t('onboarding.login.form.save')}
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        ) : null}
                    </Paper>
                    <Box className={classes.loginFooter}>
                        <Link
                            component={RouterLink}
                            to={ROUTE_LOGIN}
                            variant="body1"
                            color="inherit"
                            underline="none"
                            className={classes.linkWhite}>
                            {t('onboarding.password_reset.cancel')}
                        </Link>
                    </Box>
                </>
            )}
            {error && (
                <Alert severity="error">{error}</Alert>
            )}
        </>
    )
}

export default withStyles(styles)(UserManagementScene);