import { logout } from 'core/modules/auth/actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const LogoutScene = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout())
    }, [dispatch])

    return (
        <>
            ...logging out
        </>
    )
}

export default LogoutScene;