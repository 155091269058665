import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import {
    Paper,
    Button,
    Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from '../../../../../core/i18n';
import {
    getInvoiceById,
    storeInvoice,
} from '../../../../../core/modules/accounting/invoicing/actions';
import {
    InvoicingStatus,
    InvoicingTypes,
} from '../../../../../core/modules/accounting/invoicing/constants';
import { Form, Formik } from 'formik';
import BaseFormContainer from '../../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../../core/hooks/useForm';
import InvoicingBillingFields from './fields/InvoicingBillingFields';
import { selectTotal } from '../../../../../core/modules/accounting/invoicing/selectors';
import TableFields from './fields/TableFields';
import { selectCurrentWorkspace } from '../../../../../core/modules/workspaces/selectors';
import { useSelector } from 'react-redux';
import InvoicingDateFields from './fields/InvoicingDateFields';
//import WysiwygField from 'components/shared/form/generic/WysiwygField';
import TextUltraFastField from 'components/shared/form/generic/TextUltraFastField';

// Let's keep this out of the render to avoid useMemo.
const InvoicingSchema = yup.object().shape({
    description: yup.string().required(),
    billing_account: yup.object().required(),
    items: yup
        .array()
        .of(
            yup.object().shape({
                // name: yup.string().required(), TODO, use .when for group vs non-group
                // quantity: yup.number().required(),
                // price: yup.number().required(),
            })
        )
        .min(1)
        .required(),
    due_period: yup.string().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
});

const updateItemsWithParent = items => {
    let parent = null;
    const list = [];

    for (const item of items) {
        const copy = { ...item };
        if (copy.group) {
            parent = copy.id;
        } else {
            copy.parent = parent;
        }
        list.push(copy);
    }
    return list;
};

const prepareDataToSave = (original, status) => {
    const data = { ...original };

    // count total
    data.total = selectTotal(data.items);
    data.total_with_vat = selectTotal(data.items, true);
    data.items = updateItemsWithParent(data.items);

    data.status = status;

    return data;
};

const prepareInitialData = original => {
    const data = { ...original };
    return { ...data };
};

const InvoicingFormContainer = ({
    classes,
    type,
    id,
    onSuccess,
    //defaultValue,
    toType,
}) => {
    const workspace = useSelector(state => selectCurrentWorkspace(state));

    const [status, setStatus] = useState(InvoicingStatus.Draft);

    const handlePublish = () => {
        setStatus(InvoicingStatus.Published);
    };

    const handleAccept = () => {
        setStatus(InvoicingStatus.Accepted);
    };

    const config = useMemo(
        () => ({
            fetch: () => getInvoiceById(type, id),
            store: values =>
                storeInvoice(type, prepareDataToSave(values, status), toType),
            defaultValue: {
                description: '',
                items: [
                    {
                        id: 'firstrowid',
                        group: false,
                        currency: workspace?.accounting?.currency || "",
                    },
                ],
                currency: workspace?.accounting?.currency || "",
                billing_account: '',
                //doc_date: new Date(2020, 6, 1, 9, 0, 0),
                //doc_date: moment(),
            },
            onSuccess,
            id,
        }),
        [id, onSuccess, toType, status]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        !!initialData && (
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <Paper className={classes.paper}>
                        <Formik
                            initialValues={prepareInitialData(initialData)}
                            validationSchema={InvoicingSchema}
                            validateOnChange={false}
                            //validateOnBlur={false}
                            onSubmit={onSubmit}>
                            {({ isSubmitting }) => (
                                <Form>
                                    {/*<TextFastField
                                        name="description"
                                        label={t('invoicing.fields.description')}
                                    />*/}
                                    <TextUltraFastField
                                        name="description"
                                        label={t('invoicing.fields.description')} />

                                    <InvoicingBillingFields />

                                    <InvoicingDateFields />

                                    <TableFields />

                                    {/*<WysiwygField
                                        name="comment"
                                        label={t('invoicing.fields.comment')}
                                    />*/}

                                    <TextUltraFastField
                                        variant="outlined"
                                        name="comment"
                                        label={t('invoicing.fields.comment')}
                                        multiline
                                        rows="4"
                                    />

                                    <Box display="flex" justifyContent="flex-end">
                                        {type !== InvoicingTypes.Order ? (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}>
                                                {!!toType
                                                    ? t(
                                                        `actions.invoicing.change_type.${toType}`
                                                    )
                                                    : t(
                                                        id
                                                            ? `actions.${type}.update`
                                                            : `actions.${type}.create`
                                                    )}
                                            </Button>
                                        ) : (
                                                !!toType && (
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}>
                                                        {t(
                                                            `actions.invoicing.change_type.${toType}`
                                                        )}
                                                    </Button>
                                                )
                                            )}
                                        {!!!toType && (
                                            <Button
                                                style={{ marginLeft: '8px' }}
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                                onClick={
                                                    type ===
                                                        InvoicingTypes.Invoice ||
                                                        type ===
                                                        InvoicingTypes.CreditNote
                                                        ? handleAccept
                                                        : handlePublish
                                                }
                                                disabled={isSubmitting}>
                                                {t(
                                                    `invoicing.detail.${type}.publish`
                                                )}
                                            </Button>
                                        )}
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}
            />
        )
    );
};

export default withStyles(styles)(InvoicingFormContainer);
