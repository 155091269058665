import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ShippingAddressSelector from './ShippingAddressSelector';
import Address from './Address';
import { useFormikContext } from 'formik';

const styles = () => ({
    paper: {
        padding: 20,
        marginBottom: 30,
    },
});

const ShippingAddress = ({ classes, address, billingAccountId, name }) => {
    const [shippingAddress, setShippingAddress] = useState(address);
    const { setFieldValue } = useFormikContext();

    const onChange = shippingAddressSelection => {
        setShippingAddress(shippingAddressSelection);
        setFieldValue(
            `${name}`,
            shippingAddressSelection
        );
    }

    return (
        <Box>
            <Address address={shippingAddress} />
            <ShippingAddressSelector
                billingAccountId={billingAccountId}
                address={address}
                onChange={onChange}
            />
        </Box>
    )
};

export default withStyles(styles)(ShippingAddress);
