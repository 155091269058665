import React from 'react';
import {
    ROUTE_GUEST, ROUTE_USER_EDIT, ROUTE_USER_OVERVIEW, ROUTE_USER_PREFERENCES,
} from 'core/routing/routing';
//import { t } from 'core/i18n';
import AppContent from '../shared/content/AppContent';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import GuestLandingPageScene from './landingPage/guestLandingPageScene';
import UserPreferencesFormScene from 'components/app/user/preferences/UserPreferencesFormScene';
import UserFormScene from 'components/app/user/form/UserFormScene';
import UserOverviewScene from 'components/app/user/overview/UserOverviewScene';

const GuestScene = props => {
    return (
        <>
            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_USER_PREFERENCES}
                        component={UserPreferencesFormScene}
                    />

                    <Route
                        path={ROUTE_USER_EDIT}
                        component={UserFormScene}
                    />

                    <Route
                        path={ROUTE_USER_OVERVIEW}
                        component={UserOverviewScene}
                    />

                    <Route
                        path={ROUTE_GUEST}
                        component={GuestLandingPageScene}
                    />

                    <Redirect to={ROUTE_GUEST} />
                </Switch>
            </AppContent>
        </>
    );
};

export default GuestScene;
