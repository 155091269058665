import { Collections, fireStore } from '../../../networking/firebase';
import { t } from '../../../i18n';
import {
    selectCurrentWorkspace,
} from '../../workspaces/selectors';
import { docMetaData, getDocData, getErrorMessage } from '../../../networking/utils';
import {
    InvoicingStatus,
    InvoicingTypes,
    InvoicingApporvalStatus
} from './constants';
import { createNotification } from '../../notifications/reducer';
import moment from 'moment';
import { isVoid } from '../../../utils/isVoid';
import { selectBillingFromBillingAccount } from './selectors';
import { isEmptyText } from 'core/utils/isEmptyText';
import { uuid } from 'uuidv4';
import { AuthStates } from '../../auth/constants';
import { caseFoldDocument } from '../../../utils/caseFold';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { copyEvents } from './events/actions';

export const getCollectionByType = type => {
    switch (type) {
        case InvoicingTypes.Order:
            return Collections.orders;
        case InvoicingTypes.Quote:
            return Collections.quotes;
        case InvoicingTypes.Invoice:
            return Collections.invoices;
        case InvoicingTypes.CreditNote:
            return Collections.credit_notes;
        default:
            throw new Error('Unknown invoicing type');
    }
};

export const fetchInvoicingByType = async (type, workspace, orderBy, order) => {
    return await fireStore
        .collection(getCollectionByType(type))
        .where('workspace_agency', '==', workspace)
        .orderBy('deleted_at', 'asc')
        .orderBy('query_fields.' + orderBy, order)
        .get()
        .then(resp => resp.docs.map(doc => getDocData(doc)));
};

export const getInvoicingByType = (type, orderBy, order) => async (dispatch, getState) => {
    return fetchInvoicingByType(type, selectCurrentWorkspaceId(getState()), orderBy, order);
};

export const fetchInvoiceById = async (type, id, workspace) => {
    const doc = await fireStore
        .collection(getCollectionByType(type))
        .doc(id)
        .get();

    if (doc.exists) {
        const data = getDocData(doc);

        if (data.workspace_agency === workspace) {
            return data;
        }
    }
    return Promise.reject(new Error(t('errors.not_found')));
};

export const getInvoiceById = (type, id) => (dispatch, getState) => {
    return fetchInvoiceById(type, id, selectCurrentWorkspaceId(getState()));
};

export const fetchInvoiceByIdAndAccessToken = async (type, id, access_token) => {
    const doc = await fireStore
        .collection(getCollectionByType(type))
        .doc(id)
        .get();

    if (doc.exists) {
        const data = getDocData(doc);

        // for early documents check if already has access_token in data object
        if (!!data.access_token) {

            // check if access_token in url is equal to access_token in object
            if (data.access_token === access_token) {
                return data;
            }
        } else {
            return data;
        }
    }
    return Promise.reject(new Error(t('errors.not_found')));
};

export const getInvoiceByIdAndAccessToken = (type, id, access_token) => (dispatch, getState) => {
    return fetchInvoiceByIdAndAccessToken(type, id, access_token);
};

export const storeInvoice = (type, { id, ...data }, toType) => async (
    dispatch,
    getState
) => {
    // check if create/update is an invoice type conversion
    const typeConversion = !!toType;

    const query = fireStore.collection(
        getCollectionByType(!!typeConversion ? toType : type)
    );
    data.meta = docMetaData(getState());
    // get current workspace
    const workspace = selectCurrentWorkspace(getState());

    // add workspace
    data.workspace_agency = workspace.id;

    // Create query map with selected fields
    const queryFields = {
        number: !!data.uid ? data?.uid + '/' + data?.version : "",
        customer: data?.billing_account.name,
        status: data?.status,
        total: data?.total_with_vat,
        description: data?.description,
        filter_value:
            data?.billing_account.name + ' ' + data?.description,
    }
    data.query_fields = caseFoldDocument(queryFields);

    const timestamp = moment().toDate();

    // add log (spread in case data.logs is undefined)
    data.logs = [
        ...(data.logs || []),
        {
            timestamp: timestamp,
            action: id ? 'update' : 'create',
            user: getState().auth.user.uid,
        },
    ];

    // Add empty field so we can orderBy in fetch
    data.deleted_at = null;

    // set current workspace
    if (isVoid(data.from)) {
        data.from = selectBillingFromBillingAccount(workspace.accounting);
        data.from.logo_image_url = !!workspace.logo_image_url ? workspace.logo_image_url : "";
    }

    if (!!data.created_at && (!data.doc_date || data.doc_date === null)) {
        data.doc_date = moment(data.created_at).toDate();
    }

    // no update will occur, only new versions will be created/duplicated
    if (id && !typeConversion) {
        if (
            (type === InvoicingTypes.Quote &&
                data.status !== InvoicingStatus.Accepted) ||
            (type !== InvoicingTypes.Order)
        ) {
            data.updated_at = timestamp;
            if ((type !== InvoicingTypes.Order) && (data.status !== InvoicingStatus.Draft) && (isEmptyText(data.uid))) {
                data.uid = await createNewUid(
                    !!typeConversion ? toType : type,
                    workspace
                );

                if (type === InvoicingTypes.Quote) {
                    data.version = await createNewVersion(type, workspace, data.uid);
                } else {
                    data.version = null;
                }
            }
            await query.doc(id).set(data);
        } else {
            if (!data.doc_date || data.doc_date === null) {
                data.doc_date = timestamp
            }
            data.created_at = timestamp;
            if ((type === InvoicingTypes.Invoice) || (type === InvoicingTypes.CreditNote)) {
                data.uid = await createNewUid(
                    !!typeConversion ? toType : type,
                    workspace
                );
                data.version = null;
            } else {
                data.version = await createNewVersion(type, workspace, data.uid);
            }
            const ref = await query.add(data);
            id = ref.id;
        }
    } else {
        if (!data.doc_date || data.doc_date === null) {
            data.doc_date = timestamp
        }
        data.created_at = timestamp;
        data.access_token = uuid();
        // With type conversion
        if (!!typeConversion) {
            data.converted_from = {
                type: type,
                id: data.uid,
            };

            if (toType !== InvoicingTypes.CreditNote) {
                dispatch(acceptInvoice(type, id, toType));
            }
        }

        // check if toType is equal to invoice
        // if it is an invoice/credit_note type then don't create uid
        // invoice type will only receive uid if status is accepted
        if (
            toType === InvoicingTypes.Invoice ||
            toType === InvoicingTypes.CreditNote ||
            (!typeConversion &&
                type === InvoicingTypes.CreditNote &&
                data.status !== InvoicingStatus.Accepted) ||
            (!typeConversion &&
                type === InvoicingTypes.Invoice &&
                data.status !== InvoicingStatus.Accepted)
        ) {
            data.uid = '';
            data.version = null;
        } else {
            if (toType === InvoicingTypes.Quote) {
                data.status = InvoicingStatus.Draft;
                data.version = await createNewVersion(type, workspace, data.uid);
            } else if (toType === InvoicingTypes.Order) {
                data.status = InvoicingStatus.Published;
                data.version = null;
                data.uid = await createNewUid(
                    !!typeConversion ? toType : type,
                    workspace
                );
            } else {
                if (data.status === InvoicingStatus.Draft) {
                    data.uid = '';
                    data.version = null;
                } else {

                    data.uid = await createNewUid(
                        !!typeConversion ? toType : type,
                        workspace
                    );

                    if (type === InvoicingTypes.Quote) {
                        data.version = await createNewVersion(type, workspace, data.uid);
                    } else {
                        data.version = null;
                    }
                }
            }
        }

        const ref = await query.add(data)
            .then(async (docRef) => {
                if (!!id) {
                    await copyEvents(type, id, docRef.id, toType);
                }
                return docRef;
            });
        id = ref.id;
    }
    dispatch(
        createNotification({
            type: 'success',
            message: t(`invoicing.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const approveInvoice = (type, id) => async (dispatch, getState) => {
    const query = fireStore.collection(
        getCollectionByType(type)
    ).doc(id);

    const doc = await query.get();
    const timestamp = moment().format();
    // check if exists
    if (!doc.exists) {
        return Promise.reject(new Error(t('errors.not_found')));
    }

    const data = doc.data(); // don't need id here, so no getDocData()

    const isAuthenticated = (getState().auth.status === AuthStates.LoggedIn && !!getState().auth.user);
    data.status = InvoicingApporvalStatus.ApprovalAccepted;
    data.document_approval = {
        timestamp: timestamp,
        status: InvoicingApporvalStatus.ApprovalAccepted,
        user: isAuthenticated ? getState().auth.user.uid : null,
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t('invoicing.notifications.accepted'),
        })
    );

    await query.set(data);

    return { ...data, id };


}

export const acceptInvoice = (type, id, toType) => async (dispatch, getState) => {
    try {
        const query = fireStore.collection(getCollectionByType(type)).doc(id);

        const doc = await query.get();

        // current workspace
        const workspace = selectCurrentWorkspace(getState());

        // check if exists
        if (!doc.exists) {
            return Promise.reject(new Error(t('errors.not_found')));
        }

        const data = doc.data(); // don't need id here, so no getDocData()

        // check workspace
        if (data.workspace_agency !== selectCurrentWorkspaceId(getState())) {
            return Promise.reject(new Error(t('errors.not_found')));
        }

        // set new Status
        if (type === toType) {
            data.status = InvoicingStatus.Refused;
        } else {
            data.status = InvoicingStatus.Accepted;
        }

        // create uid with version if it's an invoice/credit_note
        if (type === InvoicingTypes.Invoice || type === InvoicingTypes.CreditNote) {
            data.uid = await createNewUid(type, workspace);

        }

        // save to server without id
        await query.set(data);

        dispatch(
            createNotification({
                type: 'success',
                message: t('invoicing.notifications.accepted'),
            })
        );

        return { id, data };
    } catch (ignore) {
        return Promise.reject(getErrorMessage(ignore));
    }
};

export const publishInvoice = (type, id) => async (dispatch, getState) => {
    try {
        const query = fireStore.collection(getCollectionByType(type));

        const doc = await query.get();

        // check if exists
        if (!doc.exists) {
            return Promise.reject(new Error(t('errors.not_found')));
        }

        const data = doc.data(); // don't need id here, so no getDocData()

        // check workspace
        if (data.workspace_agency !== selectCurrentWorkspaceId(getState())) {
            return Promise.reject(new Error(t('errors.not_found')));
        }

        // set new Status
        data.status = InvoicingStatus.Published;

        // save to server without id
        await query.set(data);

        dispatch(
            createNotification({
                type: 'success',
                message: t('invoicing.notifications.published'),
            })
        );

        return { id, data };
    } catch (ignore) {
        return Promise.reject(getErrorMessage(ignore));
    }
};

export const softDeleteInvoice = (type, id) => async (dispatch, getState) => {
    const query = await fireStore.collection(getCollectionByType(type));

    const doc = await query.doc(id).get();

    // check if exists
    if (doc.exists) {
        const data = doc.data();

        const timestamp = moment().format();

        // add deleted tag (spread in case data.deleted is undefined)
        data.deleted_at =
        {
            timestamp: timestamp,
            user: getState().auth.user.uid,
            uid_version: data.uid + (!!data.version ? "/" + data.version : "")
        };

        data.uid = "";
        data.version = null;

        await query.doc(id).set(data);

        dispatch(
            createNotification({
                type: 'error',
                message: t('invoicing.notifications.delete'),
            })
        );
        return { ...data, id };
    } else {
        return Promise.reject(new Error(t('errors.not_found')));
    }

};

const createNewUid = async (type, workspace) => {
    // get workspace
    const prefix = workspace.accounting.prefix_current_book_year[type];

    // sort by uid
    let items = await fireStore
        .collection(getCollectionByType(type))
        .where('workspace_agency', '==', workspace.id)
        .orderBy('uid', 'desc')
        .get()
        .then(resp => resp.docs.map(doc => getDocData(doc)));

    // only keep items with the current prefix (remove other years)
    items = items.filter(item => item.uid.indexOf(prefix) >= 0);

    if (items.length === 0) {
        return `${prefix}000001`;
    } else {
        // add a 1 in front, to make sure to keep the trailing 0's in e.g. 000001
        const newNumber = parseInt('1' + items[0].uid.replace(prefix, '')) + 1;
        return `${prefix}${String(newNumber).substr(1)}`;
    }
};

const createNewVersion = async (type, workspace, uid) => {
    // get workspace
    const prefix = workspace.accounting.prefix_current_book_year[type];

    // sort by version
    let items = await fireStore
        .collection(getCollectionByType(type))
        .where('workspace_agency', '==', workspace.id)
        .where('uid', '==', uid)
        .orderBy('version', 'desc')
        .get()
        .then(resp => resp.docs.map(doc => getDocData(doc)));

    // only keep items with the current prefix (remove other years)
    items = items.filter(item => item.uid.indexOf(prefix) >= 0);

    if (items.length === 0) {
        return 1;
    } else {
        // add a 1 in front, to make sure to keep the trailing 0's in e.g. 000001
        const newNumber = parseInt(items[0].version) + 1;
        return newNumber;
    }
};
