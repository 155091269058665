import Address from './address/Address';
import React from 'react';
import { View, Text } from '@react-pdf/renderer';

const BillingAddress = ({ billingAccount, styles }) => {
    return (
        <View>
            {/* eslint-disable-next-line camelcase */}
            <Text>{billingAccount.legal_name || ''}</Text>
            {/* eslint-disable-next-line camelcase */}
            <Address address={billingAccount.billing_address} styles={styles} />
            {/* eslint-disable-next-line camelcase */}
            <Text>{billingAccount.vat_number?.vat || ''}</Text>
        </View>
    )
};

export default BillingAddress;
