import React from 'react';
import { Field, useField, useFormikContext } from 'formik';
import {
    Select as MaterialSelect,
    MenuItem,
    FormHelperText,
    FormControl,
    InputLabel,
    ListItemText,
    Radio,
} from '@material-ui/core';
import { isVoid } from '../../../../core/utils/isVoid';

const Select = ({
    label,
    options,
    radioOptions,
    map,
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const onChange = (ev, el) => {
        setFieldValue(field.name, ev.target.value);
        if (!!props.onChange) {
            props.onChange(ev, el);
        }
    };

    return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel id={`${props.name}_label`} shrink={true}>
                {label}
            </InputLabel>
            <Field
                as={MaterialSelect}
                fullWidth
                margin="dense"
                {...field}
                value={isVoid(field.value) ? '' : field.value} // allow "0" for numbers
                error={!!meta.error && meta.touched}
                labelId={`${props.name}_label`}
                renderValue={selected =>
                    radioOptions
                        ? radioOptions.filter(obj => obj.value === selected)[0]
                            .label
                        : options.filter(obj => obj.value === selected)[0].label
                }
                {...props}
                onChange={onChange}>
                {!!radioOptions
                    ? radioOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            <Radio
                                checked={
                                    option.value ===
                                    (isVoid(field.value) ? '' : field.value)
                                }
                                value={option.value}
                            />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))
                    : options.map(option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
            </Field>
            {!!meta.error && meta.touched && (
                <FormHelperText error={true}>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default Select;
