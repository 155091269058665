import React from 'react';
import { storeWorkspaceUserRole } from 'core/modules/workspaces/actions';
import UserPermissionDialogContainer from 'components/app/user/form/UserPermissionDialogContainer';
import { useSelector } from 'react-redux';

const WorkspaceRolesDialogScene = ({ ...props }) => {
    const workspace_agency = useSelector(state => state.app.workspace);
    return (
        <>
            <UserPermissionDialogContainer
                {...props}
                storeFunction={storeWorkspaceUserRole}
                spaceId={workspace_agency}
            />
        </>
    )
}

export default WorkspaceRolesDialogScene;