import React from 'react';
import {
    ROUTE_LOGIN,
    ROUTE_REGISTER,
    ROUTE_PASSWORD_RESET,
    ROUTE_AUTH,
    ROUTE_REGISTER_ON_INVITE,
} from '../../core/routing/routing';
import LoginScene from './login/LoginScene';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    Container,
    Box
} from '@material-ui/core';
import { ReactComponent as Logo } from 'assets/images/PAF-Logo-White.svg';
import { withStyles } from '@material-ui/core/styles';
import RegisterScene from './register/RegisterScene';
import UserManagementScene from './userManagement/UserManagementScene';
import PasswordResetScene from './passwordReset/PasswordResetScene';
import Notifications from '../notification/Notifications';
import LocalizationMenu from '../localization/menu/LocalizationMenu';
import { Link as RouterLink } from 'react-router-dom';
import CompleteInviteScene from './invite/CompleteInviteScene';

const styles = () => ({
    '@global': {
        body: {
            background: '#0064fa',
            height: '100%',
            width: '100%',
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            margin: 0,
        },
    },
    container: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    logo: {
        maxWidth: '250px',
        marginBottom: 30,
        marginLeft: 30,
    },
    localization: {
        position: 'absolute !important',
        right: 10,
        top: 10,
        color: 'white',
    },
});

const OnboardingContainer = ({ classes }) => {

    return (
        <>
            <div className={classes.localization}>
                <LocalizationMenu />
            </div>
            <Container
                component="main"
                maxWidth="xs"
                className={classes.container}>
                <Box component={RouterLink}
                    to={ROUTE_LOGIN}>
                    <Logo
                        className={classes.logo}
                    />
                </Box>
                <Switch>
                    <Route path={ROUTE_REGISTER_ON_INVITE} component={CompleteInviteScene} />
                    <Route path={ROUTE_AUTH} component={UserManagementScene} />
                    <Route path={ROUTE_LOGIN} component={LoginScene} />
                    <Route path={ROUTE_REGISTER} component={RegisterScene} />
                    <Route
                        path={ROUTE_PASSWORD_RESET}
                        component={PasswordResetScene}
                    />
                    <Redirect to={ROUTE_LOGIN} />
                </Switch>
                <Notifications />
            </Container>
        </>
    );
};

export default withStyles(styles)(OnboardingContainer);
