import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useField, useFormikContext, Field } from 'formik';
import {
    FormHelperText,
    TextField as MaterialTextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { isVoid } from 'core/utils/isVoid';

const styles = () => ({
    phoneInput: {
        width: '100% !important',
        paddingTop: '10.5px !important',
        paddingBottom: '10.5px !important',
    },
    phoneContainer: {
        margin: '8px 0 !important'
    }
})

const PhoneInputField = ({ name, label, type, defaultValue, classes, ...props }) => {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();

    return (
        <>
            <Field
                as={MaterialTextField}
                label={label}
                {...field}
                type={type || 'text'}
                value={isVoid(field.value?.toString()) ? '' : field.value?.toString()} // allow "0" for numbers
                helperText={meta.touched ? meta.error : ''}
                InputLabelProps={{
                    shrink: true,
                }}
                margin="dense"
                inputClass={classes.phoneInput}
                containerClass={classes.phoneContainer}
                specialLabel={label}
                country={'be'}
                enableSearch={true}
                preferredCountries={['be', 'ca', 'nl']}
                onChange={phone => setFieldValue(name, phone)}
                component={PhoneInput}
                {...props}
            />
            {!!meta.error && meta.touched && (
                <FormHelperText error={true}>{meta.error}</FormHelperText>
            )}
        </>
    );
};

export default withStyles(styles)(PhoneInputField);