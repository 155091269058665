import { selectCurrentWorkspaceId } from "core/modules/app/selectors";
import { Collections, fireStore } from "core/networking/firebase";
import { getDocData } from "core/networking/utils";

export const fetchEvents = async () => {
    return await fireStore
        .collection(Collections.event)
        .orderBy('createdAt', 'desc')
        .limit(250)
        .get()
        .then(resp => resp.docs.map(doc => getDocData(doc)));
};

export const getEvents = () => async (dispatch, getState) => {
    return fetchEvents();
};

export const fetchEventsByWorkspace = async workspace => {
    return await fireStore
        .collection(Collections.event)
        .where('workspace_agency', '==', workspace)
        .orderBy('createdAt', 'desc')
        .get()
        .then(resp => resp.docs.map(doc => getDocData(doc)));
};

export const getEventsByWorkspace = () => async (dispatch, getState) => {
    return fetchEventsByWorkspace(selectCurrentWorkspaceId(getState()));
};