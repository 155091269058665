import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Box
} from '@material-ui/core';

const Progress = ({ isLoading }) => {
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        if (isLoading) {
            const id = setTimeout(() => setVisible(true), 1000);
            return () => clearTimeout(id);
        } else {
            setVisible(false);
        }
    }, [isLoading]);

    return isVisible ? (
        <Box display="flex" justifyContent="center">
            <CircularProgress />
        </Box>
    ) : null;
};

export default Progress;
