import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import RadioGroup from '../../../../shared/form/generic/RadioGroup';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    IconButton,
    Button,
    TextField,
    FormHelperText
} from '@material-ui/core';
import { t } from '../../../../../core/i18n';
import { useField, useFormikContext } from 'formik';
import { isVoid } from '../../../../../core/utils/isVoid';
import { getStock } from '../../../../../core/modules/stock/actions';
import { useList } from '../../../../../core/hooks/useList';
import { Clear } from '@material-ui/icons';

const styles = () => ({
    resetbutton: {
        position: 'absolute',
        right: '0px',
        top: '5px',
    },
});

const StockItemSelector = ({
    onSourceSubmit,
    onSourceReset,
    defaultValue,
    classes,
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(
        isVoid(field.value) ? null : field.value
    );
    const [selectedDropdown, setSelectedDropdown] = React.useState(
        isVoid(field.value) ? null : field.value
    );

    const fetchList = useCallback(() => {
        return getStock();
    }, []);

    const { items } = useList(fetchList);

    React.useEffect(() => { }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const onSubmit = () => {
        setSelected(selectedDropdown);
        setFieldValue(field.name, selectedDropdown);
        setOpen(false);

        // Optionally call a passed handler.
        if (!!onSourceSubmit) {
            onSourceSubmit(sourceById(selectedDropdown));
        }
    };

    const handleChangeRadiosSelection = (ev, val) => {
        setSelectedDropdown(val);
    };

    const resetSelected = () => {
        setSelectedDropdown(null);
        setSelected(null);
        setFieldValue(field.name, null);
        if (!!onSourceReset) {
            onSourceReset();
        }
    };

    const sourceById = id => {
        if (!!id && !!items) {
            const filteredItems = items.filter(item => {
                return item.id === id;
            });
            if (filteredItems.length === 1) {
                return filteredItems[0];
            }
        }

        return null;
    };
    const displayedValue = id => {
        // If has value but items not loaded yet.
        if (!!id && !items) {
            return 'loading...';
        }

        // Get correct name of item.
        if (!!id && !!items) {
            const source = sourceById(id);
            if (!!source) {
                return source.name;
            } else {
                return 'not found';
            }
        }

        // If new, return empty.
        return '';
    };

    return (
        <>
            {!selected && (
                <Button onClick={openDialog}>
                    {t('product_stock.selector.select_stock_item')}
                </Button>
            )}
            {!!selected && (
                <>
                    <Box style={{ position: 'relative' }}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            disabled={true}
                            value={displayedValue(selected)}
                            label={t(
                                'product_stock.selector.selected_stock_item'
                            )}
                        />
                        <IconButton
                            className={classes.resetbutton}
                            onClick={resetSelected}>
                            <Clear />
                        </IconButton>
                        {/*<Button className={classes.resetbutton} onClick={resetSelected} size="small"><Clear /></Button>*/}
                    </Box>
                </>
            )}
            {!!meta.error && meta.touched && (
                <FormHelperText error={true}>{meta.error}</FormHelperText>
            )}
            <Dialog open={open}>
                <DialogTitle>Select stock item</DialogTitle>
                <DialogContent>
                    {!!items ? (
                        <RadioGroup
                            name="select_stock"
                            row
                            value={selectedDropdown}
                            onChange={handleChangeRadiosSelection}
                            options={items.map(value => ({
                                value: value.id,
                                label: value.name,
                            }))}
                        />
                    ) : (
                            <p>loading...</p>
                        )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        {t('actions.cancel')}
                    </Button>
                    <Button onClick={onSubmit}>{t('confirm.submit')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withStyles(styles)(StockItemSelector);
