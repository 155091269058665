import React, { useState } from 'react';
import {
    Snackbar,
    Fade
} from '@material-ui/core';
import useTimeout from '../../core/hooks/useTimeout';
import { removeNotification } from '../../core/modules/notifications/reducer';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

const AUTO_HIDE_TIMEOUT = 3000;

const Notification = ({ notification }) => {
    const dispatch = useDispatch();
    const [isVisible, setVisible] = useState(true);

    // we could use autoHideDuration and onClose props on Snackbar as well
    useTimeout(() => {
        setVisible(false);
        dispatch(removeNotification(notification.id));
    }, AUTO_HIDE_TIMEOUT);

    return (
        <Snackbar open={isVisible} TransitionComponent={Fade}>
            <Alert severity={notification.type}>{notification.message}</Alert>
        </Snackbar>
    );
};

export default Notification;
