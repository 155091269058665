import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Progress from '../../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    IconButton
} from '@material-ui/core';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons';
import { t } from '../../../../../core/i18n';
import { Link, useParams } from 'react-router-dom';
import {
    history,
    route,
    ROUTE_ADMINISTRATION_TYPE_ADD,
    ROUTE_ADMINISTRATION_TYPE_EDIT,
    ROUTE_ADMINISTRATION_TYPE_DETAIL,
} from '../../../../../core/routing/routing';
import { useList } from '../../../../../core/hooks/useList';
import { getInvoicingByType, softDeleteInvoice } from 'core/modules/accounting/invoicing/actions';
import {
    InvoicingStatus, InvoicingTypes,
} from '../../../../../core/modules/accounting/invoicing/constants';
import AppContent from '../../../../shared/content/AppContent';
import { Currencies } from '../../../../../core/modules/currency/constants';
import PageHeader from '../../../../shared/header/PageHeader';
import DeleteDialog from '../../../../shared/dialog/DeleteDialog';
import { getErrorMessage } from 'core/networking/utils';
import StatusChip from '../../../../shared/progress/StatusChip';
import BaseMaterialTable from '../../../../shared/table/BaseMaterialTable';
import { useLocation } from 'react-router-dom';
import { selectCurrentWorkspace } from 'core/modules/workspaces/selectors';
import NumberFormat from 'components/shared/form/generic/NumberFormat';

const styles = () => ({
    accepted: {
        backgroundColor: '#2ecc71',
    },
    disabled: {
        opacity: ".8",
        background: "repeating-linear-gradient( -45deg, #eee, #eee 2px, #fff 2px, #fff 4px )",
    },
    deleted: {
        opacity: ".8",
        background: "repeating-linear-gradient( -45deg, rgba(204, 0, 0, .2), rgba(204, 0, 0, .2) 2px, #fff 2px, #fff 4px )",
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    addBtn: {
        marginBottom: 20,
    },
});

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const InvoicingOverviewScene = ({ classes }) => {
    const dispatch = useDispatch();
    // get access token from url
    const query = useQuery();
    const { type } = useParams();

    const currentWorkspace = useSelector(state => selectCurrentWorkspace(state))
    const prefix = currentWorkspace?.accounting?.prefix_current_book_year[type] || "";

    const [softDeleteItem, setSoftDeleteItem] = useState(null);
    const [
        order,
        //setOrder
    ] = useState(!!query.get('order') ? query.get('order') : 'desc');
    const [
        orderBy,
        //setOrderBy
    ] = useState(!!query.get('sort') ? query.get('sort') : 'number');

    const fetchList = useCallback(() =>
        getInvoicingByType(type, orderBy, order)
        , [type, orderBy, order]);

    const { items, isLoading, error, setError, refreshList } = useList(fetchList);

    const handleRowClick = (event, rowData) => {
        history.push(
            route(ROUTE_ADMINISTRATION_TYPE_DETAIL, { type: type, id: rowData.id, workspaceId: currentWorkspace.id })
        );
    };

    const handleConfirmSoftDelete = () => {
        return dispatch(softDeleteInvoice(type, softDeleteItem.id))
            .then(() => {
                refreshList();
            })
            .catch(error => {
                setError(getErrorMessage(error));
            })
            .finally(() => {
                setSoftDeleteItem(null);
            });
    };

    //const headCellsPreSort = [
    const headCells = [
        /*{
            id: 'uid',
            title: t('invoicing.fields.number'),
            field: 'uid',
            defaultGroupOrder: 0,
            defaultGroupSort: 'desc',
            hidden: true,
            // render: rowData => rowData.uid + (rowData.version ? '/' + rowData.version : ""),
        },*/
        {
            id: 'number',
            title: t('invoicing.fields.number'),
            field: 'query_fields.number',
            render: rowData => (
                //console.log(parseFloat('1' + rowData.uid.replace(prefix, '') + '.' + rowData.version)),
                rowData.uid + (rowData.version ? '/' + rowData.version : "")
            ),
            customSort: (a, b) => (
                parseFloat('1' + a.uid.replace(prefix, '') + '.' + a.version) - parseFloat('1' + b.uid.replace(prefix, '') + '.' + b.version)
            ),
            defaultSort: 'desc',

        },
        {
            id: 'customer',
            title: t('invoicing.fields.billing_account'),
            field: 'query_fields.customer',
            render: rowData => rowData.billing_account.name,
        },
        {
            id: 'status',
            title: t('invoicing.fields.status'),
            field: 'query_fields.status',
            render: rowData => <StatusChip type={type} status={rowData.status} />,
            lookup: { 'draft': 'Draft', 'published': 'Published', 'accepted': 'Accepted', 'refused': 'Refused' },
        },
        {
            id: 'total',
            title: t('invoicing.fields.item.total'),
            field: 'query_fields.total',
            type: 'numeric',
            render: rowData => <NumberFormat currency={Currencies[rowData.currency]} number={rowData.total_with_vat || 0} />
        },
        {
            id: 'description',
            title: t('invoicing.fields.description'),
            field: 'query_fields.description',
            render: rowData => rowData.description,
        },

    ];

    const actions = [
        {
            icon: 'edit',
            tooltip: 'Edit Index',
            onClick: (e, rowData) => {
                e.stopPropagation();
                history.push(route(
                    ROUTE_ADMINISTRATION_TYPE_EDIT,
                    {
                        workspaceId: currentWorkspace.id,
                        id: rowData.id,
                        type: type,
                    }
                ))
            }
        },
        {
            icon: 'delete',
            tooltip: 'Delete Index',
            onClick: (e, rowData) => {
                e.stopPropagation();
                setSoftDeleteItem(rowData)
            }
        },
    ]

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                <PageHeader title={t(`invoicing.tabs.${type}`)} />
                <AppContent>
                    {error && <Alert severity="error">{error}</Alert>}
                    {items && (
                        <>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Button
                                    component={Link}
                                    to={route(
                                        ROUTE_ADMINISTRATION_TYPE_ADD,
                                        { type, workspaceId: currentWorkspace?.id || "" }
                                    )}
                                    className={classes.addBtn}
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    color="primary">
                                    {t('invoicing.add')}
                                </Button>
                            </Grid>
                            <BaseMaterialTable
                                title={t(`invoicing.tabs.${type}`)}
                                headCells={headCells}
                                items={items}
                                actions={actions}
                                //canEdit={canEdit}
                                //canSoftDelete={canSoftDelete}
                                //canDelete={canDelete}
                                handleRowClick={handleRowClick}
                                //onOrderChange={(column, order) => handleRequestSort(column, order)}
                                options={{
                                    sorting: true,
                                    //grouping: true,
                                    thirdSortClick: false,
                                    paging: false,
                                    filtering: true,
                                    actionsColumnIndex: 99,
                                    rowStyle: rowData => ({
                                        background: (InvoicingStatus.Refused === rowData.status) ? "repeating-linear-gradient( -45deg, #eee, #eee 2px, #fff 2px, #fff 4px )" :
                                            (!!rowData.deleted_at) ? "repeating-linear-gradient( -45deg, rgba(204, 0, 0, .1), rgba(204, 0, 0, .1) 2px, #fff 2px, #fff 4px )" : ""
                                    }),
                                }}

                                components={{
                                    //Groupbar: props => { return (null) },
                                    Action:
                                        props => {
                                            return (
                                                <>
                                                    {
                                                        (props.action.icon === 'edit') && (
                                                            ((props.data.status ===
                                                                InvoicingStatus.Draft) && !!!props.data.deleted_at) && (
                                                                <IconButton
                                                                    aria-label={t(
                                                                        'actions.edit'
                                                                    )}
                                                                    color="primary"
                                                                    onClick={(event) => props.action.onClick(event, props.data)}
                                                                    size="small"
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        (props.action.icon === 'delete') && (
                                                            ((type === InvoicingTypes.Quote) || (type === InvoicingTypes.Order)) && (
                                                                !!!props.data.deleted_at && (
                                                                    <IconButton
                                                                        aria-label={t(
                                                                            'actions.soft_delete'
                                                                        )}
                                                                        color="secondary"
                                                                        size="small"
                                                                        onClick={(event) => props.action.onClick(event, props.data)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                )
                                                            )
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                }}
                            />
                            <DeleteDialog
                                open={!!softDeleteItem}
                                item={!!softDeleteItem ? softDeleteItem : null}
                                onCancel={() => setSoftDeleteItem(null)}
                                onConfirm={handleConfirmSoftDelete}
                            />
                        </>
                    )}
                </AppContent>
            </>
        );
    }
};

export default withStyles(styles)(InvoicingOverviewScene);
