import React, { useMemo } from 'react';
import * as yup from 'yup';
import {
    Paper,
    Button,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import TextField from '../../../../shared/form/generic/TextField';
import { t } from '../../../../../core/i18n';
import { withStyles } from '@material-ui/core/styles';
import BaseFormContainer from '../../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../../core/hooks/useForm';
import {
    getRecurringById,
    storeRecurring,
} from '../../../../../core/modules/accounting/recurring/actions';
import InvoicingBillingFields from '../../invoicing/form/fields/InvoicingBillingFields';
import TableFields from '../../invoicing/form/fields/TableFields';
import { useSelector } from 'react-redux';
import { selectCurrentWorkspace } from '../../../../../core/modules/workspaces/selectors';
import { timestampToDate } from '../../../../../core/utils/formatTime';
import CheckboxInput from '../../../../shared/form/generic/CheckboxInput';
import RecurringFields from './RecurringFields';

const RecurringSchema = yup.object().shape({
    name: yup.string().required(),
    recurring: yup.object().shape({
        start_date: yup.object().required(),
    }),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
});

const initialItems = workspace => {
    return [
        {
            id: 'firstrowid',
            group: false,
            currency: workspace.accounting.currency,
        },
    ];
};

const updateItemsWithParent = items => {
    let parent = null;
    const list = [];

    for (const item of items) {
        const copy = { ...item };
        if (copy.group) {
            parent = copy.id;
        } else {
            copy.parent = parent;
        }
        list.push(copy);
    }
    return list;
};

const prepareDataToSave = values => {
    const data = { ...values };
    data.items = updateItemsWithParent(data.items);
    if (!!data.recurring.start_date) {
        data.recurring.start_date = timestampToDate(data.recurring.start_date);
    }

    return data;
};

const prepareInitialData = (original, workspace) => {
    const data = { ...original };

    // In case items field does not exist for the document (old documents).
    if (!data.items) {
        data.items = initialItems(workspace);
    }

    return { ...data };
};

const RecurringFormContainer = ({ classes, id, onSuccess }) => {
    const workspace = useSelector(state => selectCurrentWorkspace(state));

    const config = useMemo(
        () => ({
            fetch: () => getRecurringById(id),
            store: (values, initialDataToCompare) =>
                storeRecurring(prepareDataToSave(values), initialDataToCompare),
            defaultValue: {
                name: '',
                items: initialItems(workspace),
                recurring: {
                    active: true,
                    start_date: null,
                },
            },
            onSuccess,
            id,
        }),
        [id, onSuccess, workspace]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        <BaseFormContainer
            isLoading={isLoading}
            error={error}
            initialData={initialData}
            render={() => (
                <Paper className={classes.paper}>
                    <Formik
                        initialValues={prepareInitialData(
                            initialData,
                            workspace
                        )}
                        validationSchema={RecurringSchema}
                        onSubmit={onSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <TextField
                                    name="name"
                                    label={t('recurring.fields.name')}
                                />

                                <InvoicingBillingFields />

                                <TableFields recurringContext={true} />

                                <RecurringFields />

                                <CheckboxInput
                                    name="recurring.active"
                                    label={t('recurring.active')}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}>
                                    {t(
                                        id ? 'actions.update' : 'actions.create'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            )}
        />
    );
};

export default withStyles(styles)(RecurringFormContainer);
