import React, { useCallback, useState } from 'react';
import SuppliersFormContainer from '../../../app/accounting/suppliers/form/SuppliersFormContainer';
import AutoComplete from '../generic/AutoComplete';
import { getSuppliers } from '../../../../core/modules/accounting/suppliers/actions';
import { useFormikContext } from 'formik';
import {
    Add as AddIcon
} from '@material-ui/icons';
import { t } from 'core/i18n';
import {
    Button,
    Box,
    Dialog
} from '@material-ui/core';

const SupplierAutoComplete = ({ label, performQuery, name, ...props }) => {
    const [open, setOpen] = useState(false);

    const { setFieldValue } = useFormikContext();

    const handleCreate = useCallback(
        data => {
            // save data
            setFieldValue(name, {
                id: data.id,
                name: data.name,
            });
            // close dialog
            setOpen(false);
        },
        [name, setFieldValue]
    );

    return (
        <>
            <Box display="flex">
                <AutoComplete
                    label={label}
                    name={name}
                    {...props}
                    performQuery={getSuppliers}
                    addCustomOption={true}
                    renderOption={(option, key) => (
                        (option.name === '') ? (
                            <Button
                                onClick={() => setOpen(true)}
                                size="small"
                                variant="contained"
                                color="primary"
                                endIcon={<AddIcon />}>
                                {t('actions.add')}
                            </Button>
                        ) : (
                                <>
                                    {option.name}
                                </>
                            )
                    )}
                />
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <SuppliersFormContainer onSuccess={handleCreate} />
            </Dialog>
        </>
    );
};

export default SupplierAutoComplete;
