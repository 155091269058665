import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    history,
    ROUTE_WORKSPACE_CLIENTS,
    ROUTE_CLIENT_PROJECTS,
    route,
} from '../../../../core/routing/routing';
import ClientsFormContainer from './ClientsFormContainer';

const ClientsFormScene = () => {
    const { clientId } = useParams();
    const workspaceId = useSelector(state => state.app.workspace);

    const handleSuccess = useCallback(() => {
        !!clientId ?
            history.push(route(ROUTE_CLIENT_PROJECTS, { clientId: clientId, workspaceId })) :
            history.push(ROUTE_WORKSPACE_CLIENTS, { workspaceId });
    }, [clientId]);

    return (
        <div>
            <ClientsFormContainer onSuccess={handleSuccess} />
        </div>
    );
};

export default ClientsFormScene;