import React, { useCallback } from 'react';
import ProjectsFormContainer from './ProjectsFormContainer';
import { useParams } from 'react-router-dom';
import {
    history,
    ROUTE_CLIENT_PROJECTS,
    ROUTE_WORKSPACE,
    route
} from '../../../../core/routing/routing';

const ProjectsFormScene = () => {
    const { clientId, workspaceId } = useParams();

    const handleSuccess = useCallback(() => {
        !!clientId ?
            history.push(route(ROUTE_CLIENT_PROJECTS, { clientId, workspaceId })) :
            history.push(route(ROUTE_WORKSPACE, { workspaceId }));
    }, [clientId]);

    return (
        <div>
            <ProjectsFormContainer clientId={clientId} onSuccess={handleSuccess} />
        </div>
    );
};

export default ProjectsFormScene;
