import React, { useMemo, useState } from 'react';
import { storeUser } from 'core/modules/users/actions';
import {
    Paper,
    Typography,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useForm } from 'core/hooks/useForm';
import { t } from 'core/i18n';
import BaseFormContainer from 'components/shared/form/BaseFormContainer';
import Select from 'components/shared/form/generic/Select';
import { Locales } from 'core/i18n';
import { DecimalSeparators, ThousandSeparator } from 'components/shared/form/generic/NumberFormat';
import { TimeFormat, DateFormatObject } from 'components/shared/form/generic/DateFormat';
import RadioGroup from 'components/shared/form/generic/RadioGroup';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { timestampToDateFormat } from 'core/utils/formatTime';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from 'components/shared/form/generic/TextField';

const Schema = yup.object().shape({
    preferences: yup.object().shape({
        decimal_seperator: yup.string()
            .required('Required'),
        thousand_seperator: yup.string()
            .notOneOf([yup.ref('decimal_seperator'), null], "Decimal and Thousands separators can not be equal!")
            .required('Required')
    })
});

const styles = () => ({
    paper: {
        padding: 20,
    },
    image: {
        maxWidth: 100,
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, .05)',
        padding: 4,
        margin: '10px 0'
    },
    large: {
        margin: "20px 0",
        width: "120px",
        height: "120px",
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
});

const UserPreferencesFormContainer = ({ onSuccess, classes, user, app }) => {
    const [initialData] = useState(user || {})
    const initialTimeZone = initialData.preferences?.time_zone || momentTimeZone.tz.guess();


    const newInitialData = {
        ...initialData,
        preferences: {
            default_language: 'en',
            thousand_seperator: 'Point',
            decimal_seperator: 'Comma',
            time_zone: initialTimeZone,
            time_format: '24',
            date_format: 'DD/MM/YYYY',
            ...initialData.preferences
        }
    }

    const config = useMemo(
        () => ({
            store: values => storeUser(values),
            defaultValue: {

            },
            onSuccess
        }),
        [onSuccess]
    );

    const { onSubmit, isLoading, error } = useForm(config);

    return (
        !!initialData && (
            <>
                <Typography variant="h4" component="h2" gutterBottom>
                    {t('user.profile.preferences.title')}
                </Typography>
                <BaseFormContainer
                    isLoading={isLoading}
                    error={error}
                    initialData={newInitialData}
                    render={() => (
                        <Paper className={classes.paper}>
                            <Formik
                                validationSchema={Schema}
                                initialValues={newInitialData}
                                onSubmit={onSubmit}>
                                {({ setFieldValue, submitForm, isSubmitting }) => (
                                    <Form>
                                        <Select
                                            label={t('users.preferences.fields.default_language')}
                                            name="preferences.default_language"
                                            options={Locales.map(l => ({
                                                value: l,
                                                label: t(`language.${l}`),
                                            }))}
                                        />
                                        <Typography variant="h5" component="h3" gutterBottom>
                                            {t('user.profile.preferences.number_format')}
                                        </Typography>
                                        <Select
                                            label={t('users.preferences.fields.decimal_seperator')}
                                            name="preferences.decimal_seperator"
                                            options={Object.keys(DecimalSeparators).map(key => ({
                                                value: key,
                                                label: t(`seperator.${key}`),
                                            }))}
                                        />

                                        <Select
                                            label={t('users.preferences.fields.thousand_seperator')}
                                            name="preferences.thousand_seperator"
                                            options={Object.keys(ThousandSeparator).map(key => ({
                                                value: key,
                                                label: t(`seperator.${key}`),
                                            }))}
                                        />

                                        <Typography variant="h5" component="h3" gutterBottom>
                                            {t('user.profile.preferences.date_format')}
                                        </Typography>

                                        <Autocomplete
                                            options={momentTimeZone.tz.names()}
                                            defaultValue={newInitialData.preferences.time_zone}
                                            name="preferences.time_zone"
                                            onChange={(_, value) => setFieldValue("preferences.time_zone", value)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    name="preferences.time_zone"
                                                    label={t('users.preferences.fields.time_zone')}
                                                />
                                            }
                                        />

                                        <RadioGroup
                                            label={t('users.preferences.fields.time_format')}
                                            name="preferences.time_format"
                                            inline={true}
                                            row
                                            onChange={(e) => setFieldValue('preferences.time_format', e.target.value)}
                                            options={Object.keys(TimeFormat).map(key => ({
                                                value: key,
                                                label: t(`time_format.${key}`),
                                            }))}
                                        />
                                        <RadioGroup
                                            label={t('users.preferences.fields.date_format')}
                                            name="preferences.date_format"
                                            inline={true}
                                            row
                                            onChange={(e) => setFieldValue('preferences.date_format', e.target.value)}
                                            options={Object.keys(DateFormatObject).map(key => ({
                                                value: key,
                                                label: timestampToDateFormat(moment(), DateFormatObject[key]) + " " + t(`date_format.${DateFormatObject[key]}`),
                                            }))}
                                        />

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn}
                                            disabled={isSubmitting}
                                        >
                                            {t('actions.update')}
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    )}
                />
            </>
        )
    )
}

export default withStyles(styles)(UserPreferencesFormContainer);