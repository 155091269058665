import React from 'react';
import { t } from '../../../../core/i18n';
import { getClients } from '../../../../core/modules/dashboard/clients/actions';
import AutoComplete from '../generic/AutoComplete';
import { Box } from '@material-ui/core';

const ClientSelect = props => {
    return (
        <Box display="flex" {...props}>
            <AutoComplete
                label={t('client.fields.client')}
                name="client"
                style={{ width: '50%' }}
                performQuery={getClients}
            />
        </Box>
    );
};

export default ClientSelect;
