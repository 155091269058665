import React, {
    useCallback,
} from 'react';
import { t } from '../../../../core/i18n';
import AutoComplete from '../generic/AutoComplete';
import { Box } from '@material-ui/core';
import { getClientBillingAccounts } from '../../../../core/modules/dashboard/clients/billing-accounts/actions';

const BillingAccountSelect = ({ clientId, onChange }) => {
    //const { setFieldValue } = useFormikContext();

    const handleQuery = useCallback(() => {
        return getClientBillingAccounts(clientId);
    }, [clientId]);

    //const prevClientRef = useRef();

    // clear billing_account when clientId changes
    /*useEffect(() => {
        if (!isVoid(prevClientRef.current)) {
            setFieldValue('billing_account', null);
        }
        prevClientRef.current = clientId;
    }, [clientId, setFieldValue]);*/
    return (
        <AutoComplete
            label={t('client.fields.billing_account')}
            name="billing_account"
            style={{ width: '50%' }}
            performQuery={handleQuery}
            onChange={onChange}
        />
    );
};

const ClientBillingAccountSelect = ({ onChange, ...props }) => {
    //const { values } = useFormikContext();
    return (
        <Box display="flex" {...props}>
            <BillingAccountSelect
                //clientId={values.client.id}
                onChange={onChange}
            />
            {/*<AutoComplete
                label={t('client.fields.client')}
                name="client"
                style={{ width: '50%' }}
                performQuery={getClients}
            />*/}
        </Box>
    );
};

export default ClientBillingAccountSelect;
