import React from 'react';
import { selectItemTotal } from '../../../../../../core/modules/accounting/invoicing/selectors';
import { formatValuta } from '../../../../../../core/utils/formatValuta';
import {
    Text,
    View,
} from '@react-pdf/renderer';

const ItemRow = ({ item, classes, status, styles }) => {

    return (
        <View wrap={false} style={styles.tableRow}>
            <View style={styles.tableCol1}>
                <Text style={[styles.tableCell, { fontWeight: 'bold', fontSize: '12' }]}>
                    {item.name}
                </Text>
                <Text style={[styles.tableCell, { fontStyle: 'italic' }]}>
                    {item.description || ''}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                    {item.quantity || ''}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                    {item.price ? formatValuta(item.price) : ''}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                    {item.vat}%
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                    {formatValuta(selectItemTotal(item || {}, true))}
                </Text>
            </View>
        </View>
    );
};

export default ItemRow;
