import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ListItemIcon,
    Avatar,
    MenuItem,
    Tooltip
} from '@material-ui/core';
import {
    Home as HomeIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import {
    history,
    route,
    ROUTE_WORKSPACE_CLIENTS,
} from '../../../core/routing/routing';
import clsx from 'clsx';
import { setCurrentWorkspace } from 'core/modules/workspaces/reducer';
import { setLocalStorage } from 'core/modules/app/reducer';
import { getCurrentWorkspaces } from 'core/modules/workspaces/selectors';

const styles = theme => ({
    workspace: {
        padding: '8px',
        color: 'white',
        whiteSpace: 'nowrap',
    },
    workspaceClose: {
        display: 'block',
        textAlign: 'center',
    },
    itemIcon: {
        minWidth: 'auto',
        color: 'white',
        height: 'auto',
    },
    itemIconImg: {
        width: 32,
        height: 32,
        border: '2px solid #041E34',
        borderRadius: '4px',
    },
    listItem: {
        padding: 0,
        color: 'white',
        flex: '1 1 auto',
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    sideMenu: {
        minWidth: 'auto',
        marginLeft: 10,
    },
    sideMenuClose: {
        marginLeft: 0,
    },
    rounded: {
        width: 40,
        height: 40,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    roundedActive: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
    },
});

const WorkspaceList = ({ classes, open }) => {

    const dispatch = useDispatch();
    const workspaceLocalstorage = useSelector(state => state.app.workspace);
    const workspaceRoles = useSelector(
        state => state.auth.user.workspace_roles
    );

    const filteredSpaces = useSelector(state => getCurrentWorkspaces(state));

    const handleClick = (type, id) => {
        const action = {};
        history.push(route(ROUTE_WORKSPACE_CLIENTS, {
            workspaceId: id
        }));
        action.current = id;
        action.permission = workspaceRoles[id];
        dispatch(setCurrentWorkspace(action));
        action.workspace = id;
        action.workspace_client = null;
        action.project = null;
        dispatch(setLocalStorage(action));
    };

    return !!filteredSpaces ? (
        (filteredSpaces || []).map(space => (
            <Tooltip
                title={space.name}
                placement="right"
                key={(space.type === "workspace_agency") ? space.id : space.workspace_agency}>
                <MenuItem
                    key={(space.type === "workspace_agency") ? space.id : space.workspace_agency}
                    value={space.id}
                    onClick={() => handleClick(space.type, (space.type === "workspace_agency") ? space.id : space.workspace_agency)}
                    className={clsx(classes.workspace, {
                        [classes.workspaceOpen]: open,
                        [classes.workspaceClose]: !open,
                    })}>
                    <ListItemIcon
                        className={clsx(classes.itemIcon, {
                            [classes.itemIconOpen]: open,
                            [classes.itemIconClose]: !open,
                        })}>
                        <Avatar variant="rounded" className={clsx(classes.rounded, {
                            [classes.roundedActive]: (space.id === workspaceLocalstorage || space.workspace_agency === workspaceLocalstorage) ? true : false,
                        })}>
                            {(space.type === "workspace_agency") ?
                                !!space.image_url ?
                                    <img src={space.image_url} key={space.id} alt={space.id} className={classes.itemIconImg} /> :
                                    <HomeIcon key={space.id} />
                                :
                                !!space.workspace.image_url ?
                                    <img src={space.workspace.image_url} key={space.workspace_agency} alt={space.workspace.name} className={classes.itemIconImg} /> :
                                    <HomeIcon key={space.id} />
                            }
                        </Avatar>
                    </ListItemIcon>
                </MenuItem>
            </Tooltip>
        ))
    ) : null;
};

export default withStyles(styles)(WorkspaceList);
