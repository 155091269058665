import { t } from '../../../../../../core/i18n';
import React from 'react';
import {
    Button,
    TableCell
} from '@material-ui/core';
//import TextField from '../../../../../shared/form/generic/TextField';
import DraggableRow from './DraggableRow';
import TextUltraFastField from 'components/shared/form/generic/TextUltraFastField';

const GroupRow = ({ name, onDelete, onMove, index, row }) => {
    return (
        <DraggableRow onMove={onMove} index={index} id={row.id}>
            <TableCell component="th" scope="row" colSpan="3">
                <TextUltraFastField
                    label={t('invoicing.fields.group.name')}
                    name={`${name}.name`}
                />
            </TableCell>
            <TableCell colSpan="3" />
            <TableCell>
                <Button onClick={onDelete}>X</Button>
            </TableCell>
            <TableCell colSpan="" />
        </DraggableRow>
    );
};

export default GroupRow;
