import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { ApiCenterSources } from '../../../../core/modules/apicenter/constants';
import { useList } from '../../../../core/hooks/useList';
import { t } from '../../../../core/i18n';
import Progress from '../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import BaseMaterialTable from '../../../shared/table/BaseMaterialTable';
import { getStockByType } from '../../../../core/modules/stock/actions';

const styles = () => ({});

const ApiCenterSource = ({ classes }) => {
    const { type } = useParams();

    const fetchList = useCallback(() => getStockByType(type), [type]);

    const { items, isLoading, error } = useList(fetchList);

    const headCells = [
        {
            title: t('api_center.fields.name'),
            field: 'name',
            render: rowData => rowData.name,
        },
    ];

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <BaseMaterialTable
                            title={ApiCenterSources[type]}
                            headCells={headCells}
                            items={items}
                            paging={true}
                        />
                    </>
                )}
            </>
        );
    }
};

export default withStyles(styles)(ApiCenterSource);
