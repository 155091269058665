import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';

export const Collections = Object.freeze({
    users: 'users',
    products: 'sales_product',
    product_groups: 'sales_product_group',
    product_stock: 'sales_product_stock',
    suppliers: 'sales_supplier',
    quotes: 'sales_quote',
    orders: 'sales_order',
    invoices: 'sales_invoice',
    credit_notes: 'sales_credit_note',
    workspaces: 'workspace_agency',
    clients: 'client',
    clients_billing_accounts: 'client_billing_account',
    projects: 'project',
    project_logs: 'project_log',
    project_log_connector: 'project_log_connector',
    recurring: 'sales_recurring',
    private_data: 'private_data',
    user_invitations: 'user_invitation',
    tags: 'tags',
    event: 'event',
    events: 'events',
    user_private_data: 'user_private_data',
    client_private_data: 'client_private_data',
    workspace_private_data: 'workspace_private_data',
    workspace_public_data: 'workspace_public_data',
});

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseAuth = firebase.initializeApp(firebaseConfig);

export const fireStore = firebaseAuth.firestore();
export const oldRealTimeDb = firebaseAuth.database();

export const fireStorage = firebaseAuth.storage();

export const fuctions = firebaseAuth.functions();
