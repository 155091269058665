import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { withStyles } from '@material-ui/core/styles';
import { isVoid } from 'core/utils/isVoid';

const styles = () => ({
    phoneInput: {
        width: '100% !important',
        padding: '0 0 0 40px !important',
        border: 'none !important'
    },
    phoneContainer: {
        margin: '8px 0 !important'
    },
    dropDown: {
        paddingLeft: '0 !important',
    },
    dropDownBtn: {
        '& .selected-flag': {
            paddingLeft: '0 !important',
        },
        '& .arrow': {
            display: 'none !important'
        }
    }
})

const PhoneInputDisplay = ({ name, label, type, value, classes, ...props }) => {
    return (
        <PhoneInput
            type={type || 'text'}
            value={isVoid(value.toString()) ? '' : value.toString()} // allow "0" for numbers
            InputLabelProps={{
                shrink: true,
            }}
            disabled={true}
            inputClass={classes.phoneInput}
            containerClass={classes.phoneContainer}
            dropdownClass={classes.dropDown}
            buttonClass={classes.dropDownBtn}
            specialLabel={""}
            //country={'be'}
            enableSearch={true}
            //preferredCountries={['be', 'ca', 'nl']}
            //onChange={phone => setFieldValue(name, phone)}
            {...props}
            component={PhoneInput}
        />

    );
};

export default withStyles(styles)(PhoneInputDisplay);