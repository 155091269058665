import { IconButton, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
    MoreHoriz as MoreHorizIcon
} from '@material-ui/icons';
import ThreeDotsMenuItem from './ThreeDotsMenuItem';

const styles = () => ({
    icon: {
        minWidth: 'auto',
    }
});

const ThreeDotsMenu = ({ data, menuActions, onActionSelect, classes, canDelete, canEdit, canSoftDelete, action }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e, data) => {
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    }

    const handleClose = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    }

    return (
        <IconButton
            color="primary"
            onClick={(event) => { handleClick(event, data); }}
            size="small"
            aria-controls="simple-menu-actions"
            aria-haspopup="true"
        >
            <MoreHorizIcon />
            <Menu
                id="simple-menu1"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    menuActions.map((action, index) => (
                        <ThreeDotsMenuItem
                            key={index}
                            action={action}
                            data={data}
                            onActionSelect={onActionSelect}
                            classes={classes}
                            handleClose={handleClose}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                        />
                    ))
                }
            </Menu>
        </IconButton>
    )
}

export default withStyles(styles)(ThreeDotsMenu);