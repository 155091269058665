import { userCan } from 'components/shared/can/Can';
import { CRUD } from 'core/modules/auth/constants';
import { fireStore } from 'core/networking/firebase';
import { useEffect, useMemo, useState } from 'react';
import { getErrorMessage } from '../networking/utils';

export const useSnapshotList = fetchCollection => {
    const [items, setItems] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const canSoftDelete = userCan(CRUD.SoftDelete, fetchCollection);
    const canDelete = userCan(CRUD.Delete, fetchCollection);
    const canEdit = userCan(CRUD.Update, fetchCollection);

    const data = useMemo(() => {
        return {
            items,
            isLoading,
            error,
            setError,
            setLoading,
            canSoftDelete,
            canDelete,
            canEdit,
        };
    }, [items, isLoading, error, canSoftDelete, canDelete, canEdit]);

    // download items
    useEffect(() => {
        const listen = fireStore
            .collection(fetchCollection)
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    setError(null);
                    const object = [];
                    snapshot.docs.map(doc => {
                        return object.push({ ...doc.data(), id: doc.id });
                    })
                    setItems(object);
                    setLoading(false);
                } else {
                    // it's empty
                    setLoading(false)
                }
            }, error => {
                setError(getErrorMessage(error));
            })


        return () => {
            listen()
        }
    }, [fetchCollection]);

    return data;
};
