import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getProjects,
} from '../../../../core/modules/dashboard/projects/actions';
import { useList } from '../../../../core/hooks/useList';
import Progress from '../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import {
    route,
    ROUTE_CLIENT_PROJECTS_ADD,
    ROUTE_PROJECT_LOGS,
    history,
} from '../../../../core/routing/routing';
import { Link } from 'react-router-dom';
import {
    IconButton,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    Typography
} from '@material-ui/core';
import { setCurrentProject } from 'core/modules/dashboard/projects/reducer';
import { withStyles } from '@material-ui/core/styles';
import { setCurrentWorkspaceClient } from 'core/modules/dashboard/clients/reducer';
import { getClientById } from 'core/modules/dashboard/clients/actions';
import { setLocalStorage } from 'core/modules/app/reducer';
import { CRUD, Roles } from 'core/modules/auth/constants';
import Tile from 'components/shared/content/Tile';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { t } from 'core/i18n';
import { Can, userCan } from 'components/shared/can/Can';
import { Collections } from 'core/networking/firebase';

const styles = () => ({
    addBtn: {
        fontSize: '50px',
    },
    tile: {
        minHeight: "200px",
        textAlign: 'center',
        display: "flex",
    },
    tileContent: {
        align: 'self-center'
    }
});

const ProjectsOverviewScene = ({ classes, props }) => {
    const dispatch = useDispatch();
    const { clientId } = useParams();
    const workspaceRoles = useSelector(state => state.auth.user.workspace_roles);
    const { workspace, workspace_client } = useSelector(state => state.app);
    const isAdmin = useSelector(state => state.auth.user.role === Roles.Admin);

    useEffect(() => {
        if (!!clientId) {
            const action = {}
            dispatch(getClientById(clientId))
                .then(client => {
                    action.client_id = clientId;
                    action.client = client;
                    dispatch(setCurrentWorkspaceClient(action));
                    action.workspace_client = client.id;
                    action.workspace = client.workspace_agency;
                    /*if (!!workspaceRoles[client.workspace_agency] || !!isAdmin) {
                        action.workspace = client.workspace_agency;
                    }*/
                    dispatch(setLocalStorage(action))
                })
        }
    }, [dispatch, clientId, workspaceRoles, isAdmin])

    const canSoftDelete = userCan(CRUD.SoftDelete, Collections.projects);
    const fetchList = useCallback(() => getProjects(canSoftDelete, clientId), [clientId]);

    const { items, isLoading, error } = useList(
        fetchList, Collections.projects
    );

    const handleRowClick = (id) => {
        const action = {};
        dispatch(setCurrentProject(id));
        action.project = id;
        action.workspace = workspace;
        /*if (!!workspaceRoles[workspace] || !!isAdmin) {
            action.workspace = workspace;
        }*/
        action.workspace_client = workspace_client;
        dispatch(setLocalStorage(action));
        history.push(route(ROUTE_PROJECT_LOGS, { projectId: id, workspaceId: workspace }));
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                <>
                    <Grid
                        container
                        spacing={3}>
                        {items && (
                            items.map(item => (
                                <Tile
                                    key={item.id}
                                    tagLine={item.client.name}
                                    title={item.name}
                                    data={item}
                                    onClick={() => handleRowClick(item.id)}
                                />
                            ))
                        )}
                        <Can i={CRUD.Create} the={Collections.projects}>
                            <Grid
                                item
                                xs={4}>
                                <Card>
                                    <CardActionArea
                                        className={classes.tile}
                                        component={Link}
                                        to={route(ROUTE_CLIENT_PROJECTS_ADD, { clientId: workspace_client, workspaceId: workspace })}>
                                        <CardContent
                                            className={classes.tileContent}
                                        >
                                            <IconButton
                                            >
                                                <AddCircleIcon
                                                    className={classes.addBtn}
                                                />
                                            </IconButton>
                                            <Typography>
                                                {t('projects.add')}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Can>
                    </Grid>
                </>
            </>
        );
    }
};

export default withStyles(styles)(ProjectsOverviewScene);
