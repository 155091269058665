import React, { useEffect } from 'react';
import { Home as HomeIcon } from '@material-ui/icons';
import {
    ROUTE_CLIENT_PROJECTS,
    ROUTE_CLIENT_BILLING_ACCOUNTS,
    ROUTE_CLIENTS_EDIT,
    ROUTE_CLIENT_PROJECTS_ADD,
    route,
    ROUTE_CLIENTS_USERS
} from '../../../../core/routing/routing';
import {
    getClientById,
} from '../../../../core/modules/dashboard/clients/actions';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../../../core/i18n';
import NavItem from 'components/sidebar/NavItem';
import { setCurrentWorkspaceClient } from 'core/modules/dashboard/clients/reducer';

const ClientListItem = ({ open }) => {
    const dispatch = useDispatch();
    const localStorage_client = useSelector(state => state.app.workspace_client);
    const workspaceId = useSelector(state => state.app.workspace);
    const client = useSelector(state => state.client.client);
    useEffect(() => {
        const action = {}
        if (!!localStorage_client) {
            dispatch(getClientById(localStorage_client))
                .then(res => {
                    action.client_id = res.id;
                    action.client = res;
                    dispatch(setCurrentWorkspaceClient(action));
                })
        }
    }, [localStorage_client, dispatch]);

    if (!!client && !!localStorage_client) {
        return (
            <NavItem
                placeholderName="client"
                placeholderIcon={<HomeIcon />}
                open={open}
                mainRoute={route(ROUTE_CLIENT_PROJECTS, { workspaceId, clientId: localStorage_client })}
                item={client}
                menuLinks={[
                    {
                        to: route(ROUTE_CLIENT_PROJECTS, { workspaceId, clientId: localStorage_client }),
                        label: t('client.tabs.projects'),
                    },
                    {
                        to: route(ROUTE_CLIENTS_EDIT, { workspaceId, clientId: localStorage_client }),
                        label: t('client.tabs.settings'),
                    },
                    {
                        to: route(ROUTE_CLIENT_BILLING_ACCOUNTS, { workspaceId, clientId: localStorage_client }),
                        label: t('client.tabs.billing_accounts'),
                    },
                    {
                        to: route(ROUTE_CLIENT_PROJECTS_ADD, { workspaceId, clientId: localStorage_client }),
                        label: t('projects.add'),
                    },
                    {
                        to: route(ROUTE_CLIENTS_USERS, { workspaceId, clientId: localStorage_client }),
                        label: t('nav.users_and_permissions'),
                    },
                ]}
            />
        );
    }
    else {
        return null;
    }

};

export default ClientListItem;
