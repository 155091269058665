import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@material-ui/core';
import { t } from '../../../../../../core/i18n';
import DuePeriodSelector from '../../../shared/DuePeriodSelector';
import DatePicker from 'components/shared/form/generic/DatePicker';
import moment from 'moment';

const InvoicingDateFields = () => {
    const { values, setFieldValue } = useFormikContext();
    const [dueDate, setDueDate] = useState(values.due_date);

    /*const duePeriodOptions = [
        {
            id: 'on_receipt',
            name: 'Due on receipt',
            momentAction: { function: 'add', params: [{ days: 0 }] },
        },
        {
            id: '15',
            name: '15 days',
            momentAction: { function: 'add', params: [{ days: 15 }] },
        },
        {
            id: '30',
            name: '30 days',
            momentAction: { function: 'add', params: [{ days: 30 }] },
        },
        {
            id: '60',
            name: '60 days',
            momentAction: { function: 'add', params: [{ days: 60 }] },
        },
    ];*/

    /*const onChangeDocDate = val => {
        if (!!values.due_period) {
            fillDueDate(val, values.due_period);
        }
    };*/

    const onChangeDuePeriod = (ev, el) => {
        const numberOfDays = parseInt(ev.target.value)
        if (!!values.doc_date) {
            setDueDate(moment(values.doc_date.toDate()).add(numberOfDays, 'days'));
            setFieldValue('due_date', moment(values.doc_date.toDate()).add(numberOfDays, 'days').toDate())
            return
        } else {
            setDueDate(moment().add(numberOfDays, 'days'))
            setFieldValue('due_date', moment().add(numberOfDays, 'days').toDate())
            return
        }
    };

    return (
        <>
            <Box
                display="flex"
                style={{
                    border: '1px solid #ddd',
                    backgroundColor: '#eee',
                    margin: '10px 0',
                    padding: '15px',
                }}>
                <Box style={{ width: '33%', padding: '10px' }}>
                    <DatePicker
                        label={t('invoicing.fields.doc_date')}
                        name="doc_date"
                        inputValue={values.doc_date || moment()}
                    />
                </Box>
                <Box style={{ width: '33%', padding: '10px' }}>
                    <DuePeriodSelector
                        name="due_period"
                        onChange={onChangeDuePeriod}
                    />
                </Box>
                <Box style={{ width: '33%', padding: '10px' }}>
                    <DatePicker
                        label={t('invoicing.fields.due_date')}
                        name="due_date"
                        inputValue={dueDate}
                    />
                </Box>
            </Box>
        </>
    );
};

export default InvoicingDateFields;
