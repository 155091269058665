import { isEmptyText } from '../../../core/utils/isEmptyText';
import React from 'react';

const Address = ({ address }) => (
    <>
        {!isEmptyText(address.contact_name) && (
            <>
                {`${address.contact_name}`}
                <br />
            </>
        )}
        {!isEmptyText(address.email) && (
            <>
                {`${address.email}`}
                <br />
            </>
        )}
        {!isEmptyText(address.street) && (
            <>
                {`${address.street} ${address.number || ''}`}
                <br />
            </>
        )}
        {!isEmptyText(address.city) && (
            <>
                {`${address.postal_code || ''} ${address.city || ''}`}
                <br />
            </>
        )}
        {!isEmptyText(address.state) && (
            <>
                {`${address.state}`}
                <br />
            </>
        )}
        {!isEmptyText(address.country) && address.country}
    </>
);

export default Address;
