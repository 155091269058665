import React, { useCallback } from 'react'
import { getAllWorkspaces } from 'core/modules/workspaces/actions';
import { useList } from 'core/hooks/useList';
import { t } from 'core/i18n';
import Progress from '../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import BaseMaterialTable from '../../../shared/table/BaseMaterialTable';
import { Add as AddIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
    Button,
    Grid
} from '@material-ui/core';
import {
    ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES_ADD
} from 'core/routing/routing';
import { withStyles } from '@material-ui/core/styles';
import { Collections } from 'core/networking/firebase';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    }
});

const WorkspaceOverviewScene = ({ classes }) => {
    const fetchList = useCallback(() => getAllWorkspaces(), []);

    const { items, isLoading, error, canSoftDelete, canDelete, canEdit } = useList(
        fetchList, Collections.workspaces
    );

    const headCells = [
        {
            id: 'id',
            title: t('workspace.fields.id'),
            field: 'id',
        },
        {
            id: 'name',
            title: t('workspace.fields.name'),
            field: 'name',
            render: rowData => rowData.name,
        },
    ]

    const handleRowClick = (event, rowData) => {

        //console.log(rowData);
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.addBtn}
                        >
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                to={ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES_ADD}>
                                {t('workspaces.add')}
                            </Button>
                        </Grid>
                        <BaseMaterialTable
                            title={t('workspaces.title')}
                            headCells={headCells}
                            items={items}
                            handleRowClick={handleRowClick}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                            collection={Collections.workspaces}
                        />
                    </>
                )}
            </>
        )
    }
}

export default withStyles(styles)(WorkspaceOverviewScene);