import { useSelector } from 'react-redux';
import { CRUD, Permissions, Roles } from 'core/modules/auth/constants';
import { Collections } from 'core/networking/firebase';

export const userCan = (crudAction, collection) => {
    const isAdmin = useSelector(state => state.auth.user.role === Roles.Admin);
    const role = useSelector(state => state.auth.user.role);
    const adminPermission = useSelector(state => state.auth.user.permission);
    const workspaceRoles = useSelector(state => state.auth.user.workspace_roles);
    const clientRoles = useSelector(state => state.auth.user.client_roles);
    const workspace_agency = useSelector(state => state.app.workspace);
    const workspace_client = useSelector(state => state.app.workspace_client);

    let currentPermission = null;
    let userType = null;
    if (isAdmin) {
        currentPermission = adminPermission;
    } else {
        const workspacePermission = workspaceRoles[workspace_agency];
        const clientPermission = clientRoles[workspace_client];

        userType = !!workspacePermission ? "workspace" : "client";
        currentPermission = !!workspacePermission ? workspacePermission : clientPermission;
    }
    switch (role) {
        case Roles.Admin:
            switch (currentPermission) {
                case Permissions.Owner:
                    return true;
                case Permissions.Editor:
                    switch (crudAction) {
                        case CRUD.Read:
                            return true;
                        case CRUD.Update:
                        case CRUD.Create:
                            return true;
                        case CRUD.Delete:
                        case CRUD.SoftDelete:
                            switch (collection) {
                                case Collections.users:
                                case Collections.workspaces:
                                    return false;
                                default:
                                    return true;
                            }
                        default:
                            return false;
                    }
                case Permissions.Viewer:
                    switch (crudAction) {
                        case CRUD.Read:
                            return true
                        case CRUD.Update:
                        case CRUD.Create:
                        case CRUD.Delete:
                        case CRUD.SoftDelete:
                            return false;
                        default:
                            return false;
                    }
                default:
                    return false;
            }
        case Roles.User:
            switch (userType) {
                case "workspace":
                    switch (currentPermission) {
                        case Permissions.Owner:
                            switch (crudAction) {
                                case CRUD.Read:
                                    switch (collection) {
                                        case Collections.workspaces:
                                        case Collections.users:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Create:
                                case CRUD.Update:
                                    switch (collection) {
                                        case Collections.workspaces:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.SoftDelete:
                                    switch (collection) {
                                        case Collections.workspaces:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Delete:
                                    return false;
                                default:
                                    return false;
                            }
                        case Permissions.Editor:
                            switch (crudAction) {
                                case CRUD.Read:
                                    switch (collection) {
                                        case Collections.workspaces:
                                        case Collections.users:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Create:
                                case CRUD.Update:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.SoftDelete:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Delete:
                                    return false;
                                default:
                                    return false;
                            }
                        case Permissions.Viewer:
                            switch (crudAction) {
                                case CRUD.Read:
                                    switch (collection) {
                                        case Collections.workspaces:
                                        case Collections.users:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Create:
                                case CRUD.Update:
                                case CRUD.Delete:
                                case CRUD.SoftDelete:
                                    return false;
                                default:
                                    return false;
                            }
                        default:
                            return false;
                    }
                case "client":
                    switch (currentPermission) {
                        case Permissions.Owner:
                            switch (crudAction) {
                                case CRUD.Read:
                                    switch (collection) {
                                        case Collections.users:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_public_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Create:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.client_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Update:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.SoftDelete:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.client_private_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Delete:
                                    return false;
                                default:
                                    return false;
                            }
                        case Permissions.Editor:
                            switch (crudAction) {
                                case CRUD.Read:
                                    switch (collection) {
                                        case Collections.users:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_public_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Create:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Update:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.SoftDelete:
                                    switch (collection) {
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Delete:
                                    return false;
                                default:
                                    return false;
                            }
                        case Permissions.Viewer:
                            switch (crudAction) {
                                case CRUD.Read:
                                    switch (collection) {
                                        case Collections.users:
                                        case Collections.projects:
                                        case Collections.project_logs:
                                        case Collections.tags:
                                        case Collections.clients:
                                        case Collections.client_private_data:
                                        case Collections.workspace_public_data:
                                            return true;
                                        default:
                                            return false;
                                    }
                                case CRUD.Create:
                                case CRUD.Update:
                                case CRUD.Delete:
                                case CRUD.SoftDelete:
                                    return false;
                                default:
                                    return false;
                            }
                        default:
                            return false;
                    }
                default:
                    return false;
            }
        case Roles.Guest:
            return false;
        default:
            return false;
    }
}

export const Can = props => {
    // constant I equals the CRUD action
    // const THE equals the collection that is gooing to be affected by the CRUD action
    const { children, i, the } = props

    if (userCan(i, the)) {
        return children;
    } else {
        return null;
    }

}