const KEY_LOCALIZATION = 'locale';
const KEY_WORKSPACE = 'workspace';
const KEY_WORKSPACE_CLIENT = 'workspace_client';
const KEY_PROJECT = 'project';

const setLocale = locale => {
    localStorage.setItem(KEY_LOCALIZATION, locale);
};

const getLocale = () => {
    return localStorage.getItem(KEY_LOCALIZATION) || 'en';
};

const setWorkspace = workspace => {
    localStorage.setItem(KEY_WORKSPACE, workspace);
};

const getWorkspace = () => {
    return localStorage.getItem(KEY_WORKSPACE) || null;
};

const clearWorkspace = () => {
    localStorage.removeItem(KEY_WORKSPACE);
}

const setWorkspaceClient = client => {
    localStorage.setItem(KEY_WORKSPACE_CLIENT, client);
};

const getWorkspaceClient = () => {
    return localStorage.getItem(KEY_WORKSPACE_CLIENT) || null;
};

const clearWorkspaceClient = () => {
    localStorage.removeItem(KEY_WORKSPACE_CLIENT);
}

const setProject = project => {
    localStorage.setItem(KEY_PROJECT, project);
};

const getProject = () => {
    return localStorage.getItem(KEY_PROJECT) || null;
};

const clearProject = () => {
    localStorage.removeItem(KEY_PROJECT);
}

export const LocalStorage = {
    setLocale,
    getLocale,
    setWorkspace,
    getWorkspace,
    clearWorkspace,
    setWorkspaceClient,
    getWorkspaceClient,
    clearWorkspaceClient,
    setProject,
    getProject,
    clearProject,
    KEY_WORKSPACE_CLIENT,
    KEY_PROJECT,
    KEY_WORKSPACE
};
