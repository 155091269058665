import { useCallback, useEffect, useMemo, useState } from 'react';
import { getErrorMessage } from '../networking/utils';
import { useDispatch } from 'react-redux';

export const useForm = ({ fetch, store, defaultValue, id, onSuccess }) => {
    const dispatch = useDispatch();
    //console.log('useForm')
    const [initialData, setInitialData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // download item
    useEffect(() => {
        if (id) {
            dispatch(fetch())
                .then(initialData => {
                    setInitialData(initialData);
                })
                .catch(error => {
                    setError(getErrorMessage(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setInitialData({ ...defaultValue });
        }
    }, [id, dispatch, defaultValue, fetch]);

    const onSubmit = useCallback(
        (values, { setSubmitting }) => {
            // set as loading
            setSubmitting(true);

            // store item
            dispatch(store(values, initialData))
                .then(data => {
                    setSubmitting(false);
                    if (onSuccess) {
                        onSuccess(data);
                    } else {
                        setInitialData(data);
                    }
                })
                .catch(error => {
                    setSubmitting(false);
                    setError(getErrorMessage(error));
                });
        },
        [dispatch, onSuccess, store, initialData]
    );

    // useMemo might not be needed here
    return useMemo(
        () => ({
            initialData,
            isLoading,
            error,
            onSubmit,
        }),
        [initialData, isLoading, error, onSubmit]
    );
};
