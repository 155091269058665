import React from 'react';
import PageHeader from '../../shared/header/PageHeader';
import {
    ROUTE_CLIENTS_EDIT,
    ROUTE_CLIENT_PROJECTS,
    ROUTE_CLIENT_BILLING_ACCOUNTS,
    ROUTE_CLIENT_PROJECTS_ADD,
    ROUTE_CLIENTS_USERS,
    ROUTE_CLIENTS,
} from '../../../core/routing/routing';
import ClientsFormScene from './form/ClientsFormScene';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import AppContent from '../../shared/content/AppContent';
import ProjectsScene from '../projects/overview/ProjectsOverviewScene';
import ProjectsFormScene from '../projects/form/ProjectsFormScene';
import ClientUsersOverviewScene from './users/overview/ClientUsersOverviewScene';
import ClientsOverviewScene from './overview/ClientsOverviewScene';
import { useSelector } from 'react-redux';
import BillingAccountsScene from '../accounting/billing-accounts/BillingAccountsScene';


const ClientsScene = props => {
    const clientName = useSelector(state => state.client.client?.name || "")
    return (
        <>
            <PageHeader title={clientName} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_CLIENTS_USERS}
                        component={ClientUsersOverviewScene}
                    />

                    <Route
                        path={ROUTE_CLIENT_PROJECTS_ADD}
                        component={ProjectsFormScene}
                    />

                    <Route
                        path={ROUTE_CLIENT_BILLING_ACCOUNTS}
                        component={BillingAccountsScene}
                    />

                    <Route
                        path={ROUTE_CLIENTS_EDIT}
                        component={ClientsFormScene}
                    />

                    <Route
                        path={ROUTE_CLIENT_PROJECTS}
                        component={ProjectsScene}
                    />

                    <Route
                        path={ROUTE_CLIENTS}
                        component={ClientsOverviewScene}
                    />

                    <Redirect
                        to={ROUTE_CLIENTS}
                    />
                </Switch>
            </AppContent>
        </>
    );
};

export default ClientsScene;
