import { useCallback, useEffect, useMemo, useState } from 'react';
import { getErrorMessage } from '../networking/utils';
import { useDispatch } from 'react-redux';
import { userCan } from 'components/shared/can/Can';
import { CRUD } from 'core/modules/auth/constants';

export const useList = (getAction, collection) => {
    const dispatch = useDispatch();

    const [items, setItems] = useState(null);
    const [isLoading, setLoading] = useState(true);
    // const [isRefreshing, setIsRefreshing] = useState(true);
    const [error, setError] = useState(null);

    const canSoftDelete = userCan(CRUD.SoftDelete, collection);
    const canDelete = userCan(CRUD.Delete, collection);
    const canEdit = userCan(CRUD.Update, collection);

    const fetchList = useCallback(() => {
        // setIsRefreshing(true);
        dispatch(getAction(canSoftDelete))
            .then(items => {
                setError(null);
                setItems(items);
            })
            .catch(error => {
                setError(getErrorMessage(error));
            })
            .finally(() => {
                setLoading(false);
                //setIsRefreshing(false);
            });
    }, [dispatch, getAction]);

    const data = useMemo(() => {
        return {
            items,
            isLoading,
            error,
            setError,
            setLoading,
            refreshList: fetchList,
            canSoftDelete,
            canDelete,
            canEdit,
            // isRefreshing,
        };
    }, [
        items,
        isLoading,
        error,
        fetchList,
        canSoftDelete,
        canDelete,
        canEdit,
        // isRefreshing
    ]);

    // download items
    useEffect(() => {
        // setIsRefreshing(true);
        fetchList();
    }, [fetchList]);

    return data;
};
