import React from 'react';
import {
    ROUTE_PRODUCTS,
    ROUTE_PRODUCT_GROUPS,
    ROUTE_SUPPLIERS,
    ROUTE_BILLING_ACCOUNTS,
} from '../../../core/routing/routing';
import { Redirect, Route, Switch } from 'react-router-dom';
//import InvoicingScene from './invoicing/InvoicingScene';
import ProductsScene from './products/ProductsScene';
import ProductGroupsScene from './products/groups/ProductGroupsScene';
import SuppliersScene from './suppliers/SuppliersScene';
import BillingAccountsScene from './billing-accounts/BillingAccountsScene';

const AccountingScene = props => {
    return (
        <>
            <Switch>
                {/*<Route path={ROUTE_INVOICING} component={InvoicingScene} />*/}
                <Route path={ROUTE_PRODUCTS} component={ProductsScene} />
                <Route
                    path={ROUTE_PRODUCT_GROUPS}
                    component={ProductGroupsScene}
                />
                <Route path={ROUTE_SUPPLIERS} component={SuppliersScene} />
                <Route
                    path={ROUTE_BILLING_ACCOUNTS}
                    component={BillingAccountsScene}
                />
                <Redirect to={ROUTE_BILLING_ACCOUNTS} />
            </Switch>
        </>
    );
};

export default AccountingScene;
