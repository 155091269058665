import { Collections, fireStore } from 'core/networking/firebase';
import { docMetaData, getDocData } from 'core/networking/utils';
import { t } from 'core/i18n';
import { createNotification } from '../../../notifications/reducer';
import { isVoid } from 'core/utils/isVoid';
import moment from 'moment';
import { isEmptyObject } from 'core/utils/isEmptyObject';
import { randomStringGen } from 'core/utils/randomStringGen';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchClientBillingAccounts = async (allItems, clientId, workspace) => {
    const query = fireStore
        .collection(Collections.clients_billing_accounts)
        .where('workspace_agency', '==', workspace)
        .orderBy('name_lower', 'asc');


    if (isVoid(clientId)) {
        if (allItems) {
            return await query
                .get()
                .then(resp => resp.docs.map(doc => getDocData(doc)));
        } else {
            return await query
                .where('meta.doc_status', '==', DocStatus.Active)
                .get()
                .then(resp => resp.docs.map(doc => getDocData(doc)));
        }
    } else {
        if (allItems) {
            return await query
                .where('client.id', '==', clientId)
                .get()
                .then(resp => resp.docs.map(doc => getDocData(doc)));
        } else {
            return await query
                .where('client.id', '==', clientId)
                .where('meta.doc_status', '==', DocStatus.Active)
                .get()
                .then(resp => resp.docs.map(doc => getDocData(doc)));
        }
    }
};

export const getClientBillingAccounts = (allItems, clientId) => (dispatch, getState) => {
    return fetchClientBillingAccounts(
        allItems,
        clientId,
        selectCurrentWorkspaceId(getState())
    );
};

export const fetchClientBillingAccountById = (id, clientId, workspace) => {
    return fireStore
        .collection(Collections.clients_billing_accounts)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);

                if (
                    data.client.id === clientId &&
                    data.workspace_agency === workspace
                ) {
                    return data;
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const fetchBillingAccountById = (id, workspace) => {
    return fireStore
        .collection(Collections.clients_billing_accounts)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = getDocData(doc);

                if (data.workspace_agency === workspace) {
                    return data;
                }
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getClientBillingAccountById = (id, clientId) => (
    dispatch,
    getState
) => {

    return !!clientId
        ? fetchClientBillingAccountById(
            id,
            clientId,
            selectCurrentWorkspaceId(getState())
        )
        : fetchBillingAccountById(id, selectCurrentWorkspaceId(getState()));
};

export const storeBillingAccount = ({ id, ...data }) => async (
    dispatch,
    getState
) => {
    const query = fireStore.collection(Collections.clients_billing_accounts);

    const timestamp = moment().format();

    // add log (spread in case data.logs is undefined)
    data.logs = [
        ...(data.logs || []),
        {
            timestamp: timestamp,
            action: id ? 'update' : 'create',
            user: getState().auth.user.uid,
        },
    ];

    data.meta = docMetaData(getState());

    // add workspace
    data.workspace_agency = selectCurrentWorkspaceId(getState());

    // add lowercase name field to order by
    data.name_lower = data.name.toLowerCase();

    // check for same vat in other accounts
    const vatAlreadyExists = (data.vat_number.type === "none") ?
        false :
        await getBillingAccountVat(id, data.vat_number.vat, selectCurrentWorkspaceId(getState()))


    if (id && !vatAlreadyExists) {
        await dispatch(vatValidator(data.vat_number.vat))
            .then(resp => {
                data.vat_number.vat = resp.country_code + resp.vat_number
            });

        await query.doc(id).set(data);
    } else if (!vatAlreadyExists) {
        let clientNumber = randomStringGen(6, '0123456789');
        let numberExists = await validateBillingAccountsClientId(clientNumber);
        if (numberExists) {
            do {
                clientNumber = randomStringGen(6, '0123456789');
                numberExists = await validateBillingAccountsClientId(clientNumber)
            }
            while (numberExists)
            if (!numberExists) {
                data.account_number = clientNumber;
            }

        } else {
            data.account_number = clientNumber;
        }

        const ref = await query.add(data);
        id = ref.id;
    } else {
        return Promise.reject(new Error(t('errors.billing_account.vat_exists')));
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`clients.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const deleteBillingAccount = data => async dispatch => {
    await fireStore
        .collection(Collections.clients_billing_accounts)
        .doc(data.id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('clients.notifications.delete'),
        })
    );
};

export const vatValidator = (vatNumber) => async dispatch => {
    const apiKey = process.env.REACT_APP_VATLAYER_API_KEY;
    const apiUrl = 'https://apilayer.net/api/validate?access_key=' +
        apiKey +
        '&vat_number=' +
        vatNumber;

    return await fetch(apiUrl)
        .then(resp => resp.json())
    /*.finally(data => {
        console.log('data vat', data)
        return data;
    })
    .catch(error => {
        console.log('error vat', error)
        console.log(error);
    });*/
}

export const vatValidatorTest = (vatNumber, currentWorkspace) => {
    const apiKey = process.env.REACT_APP_VATLAYER_API_KEY;
    const apiUrl = 'https://apilayer.net/api/validate?access_key=' +
        apiKey +
        '&vat_number=' +
        vatNumber;

    return fetch(apiUrl)
        .then(resp =>
            resp.json()
        )
        .finally(data => {
            return data;
        })
        .catch(error => {
            console.log(error)
        });
}

export const validateBillingAccountsClientId = async (clientNumber) => {
    const items = await fireStore
        .collection(Collections.clients_billing_accounts)
        .where('account_number', '==', clientNumber)
        .get()
        .then(resp => resp.docs.map(doc => doc.id))

    if (!isEmptyObject(items)) {
        return true;
    } else {
        return false;
    }
};

const getBillingAccountVat = async (id, vat, currentWorkspace) => {
    // check if vat already exists in DB for current workspace
    const items = await fireStore
        .collection(Collections.clients_billing_accounts)
        .where('vat_number.vat', '==', vat)
        .where('workspace_agency', '==', currentWorkspace)
        .get()
        .then(resp => resp.docs.map(doc => doc.id))

    // check if query returns values
    if (!isEmptyObject(items)) {
        // check if id
        if (id) {
            // check if one of the id's equals current id
            if (Object.values(items).indexOf(id) > -1) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
};