import {
    TableRow,
    TableCell
} from '@material-ui/core';
import { selectTotal } from '../../../../../core/modules/accounting/invoicing/selectors';
import React from 'react';
import { t } from 'core/i18n';
import NumberFormat from 'components/shared/form/generic/NumberFormat';

const OverviewGroups = ({ items, currency }) => {
    return (
        <>
            {items
                .filter(i => i.group)
                .map(group => (
                    <TableRow key={group.id}>
                        <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                        <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                            {group.name} {t('invoicing.overview.exclusive_vat')}
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{
                                fontVariantNumeric: 'tabular-nums',
                            }}>
                            <NumberFormat
                                currency={currency}
                                number={selectTotal(
                                    items.filter(i => i.parent === group.id),
                                    false
                                )} />
                        </TableCell>
                        <TableCell />
                    </TableRow>
                ))}
        </>
    );
};

export default OverviewGroups;
