import { Collections, fireStore } from '../../../networking/firebase';
import { createNotification } from '../../notifications/reducer';
import { t } from '../../../i18n';
import { docMetaData, getDocData } from '../../../networking/utils';
import { Locales } from '../../../i18n';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { DocStatus } from 'core/modules/auth/constants';

export const fetchProducts = async (allItems, workspace) => {
    console.log('fetch products')
    const query = fireStore
        .collection(Collections.products)
        .where('workspace_agency', '==', workspace)
        .orderBy('name_lower', 'asc');

    if (allItems) {
        return await query
            .get()
            .then(resp => resp.docs.map(doc => getDocData(doc)));
    } else {
        return await query
            .where('meta.doc_status', '==', DocStatus.Active)
            .get()
            .then(resp => resp.docs.map(doc => getDocData(doc)));
    }
};

export const getProducts = (allItems) => async (dispatch, getState) => {
    return fetchProducts(allItems, selectCurrentWorkspaceId(getState()));
};

export const fetchProductById = async (id, workspace) => {
    console.log('fetch product by id')
    const doc = await fireStore
        .collection(Collections.products)
        .doc(id)
        .get();

    if (doc.exists) {
        const data = getDocData(doc);

        if (data.workspace_agency === workspace) {
            return data;
        }
    }
    return Promise.reject(new Error(t('errors.not_found')));
};

export const getProductById = id => (dispatch, getState) => {
    return fetchProductById(id, selectCurrentWorkspaceId(getState()));
};

export const storeProduct = ({ id, ...data }) => async (dispatch, getState) => {
    const query = fireStore.collection(Collections.products);

    // add workspace
    data.workspace_agency = selectCurrentWorkspaceId(getState());
    data.meta = docMetaData(getState());
    // add lowercase name field to order by
    data.name_lower = {};
    Locales.map(locale =>
        !!data.name[locale] ? data.name_lower[locale] = data.name[locale].toLowerCase() : false
    )

    if (id) {
        await query.doc(id).set(data);
    } else {
        const ref = await query.add(data);
        id = ref.id;
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`products.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const deleteProduct = data => async dispatch => {
    await fireStore
        .collection(Collections.products)
        .doc(data.id)
        .delete();

    return dispatch(
        createNotification({
            type: 'error',
            message: t('products.notifications.delete'),
        })
    );
};
