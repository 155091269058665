import moment, { isMoment } from 'moment';
import { isVoid } from './isVoid';
import { isEmptyText } from './isEmptyText';

export const secondsToMinutesHours = totalSeconds => {
    let sign = '';
    if (totalSeconds < 0) {
        sign = '-';
        totalSeconds = totalSeconds * -1;
    }
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    // let seconds = totalSeconds % 60;
    return (
        sign +
        (!!hours ? hours + 'h' : '') +
        ' ' +
        (!!minutes ? minutes + 'm' : '')
    );
};

export const secondsToMinutes = totalSeconds => {
    totalSeconds %= 3600;
    return Math.floor(totalSeconds / 60);
};

export const secondsToHours = totalSeconds => {
    return Math.floor(totalSeconds / 3600);
};

export const minutesHoursToSeconds = (minutes, hours) => {
    const seconds =
        (!!hours ? hours * 3600 : 0) + (!!minutes ? minutes * 60 : 0);
    return Number(seconds);
};

export const timestampToDateFormat = (timestamp, format) => {
    const date = isVoid(timestamp) ? null : timestamp.toDate();
    if (!isEmptyText(format)) {
        return moment(date).format(format);
    } else {
        return moment(date);
    }
};

/*export const timestampToDateTimeFormat = (timestamp, format) => {
    console.log('format', format)
    const date = isVoid(timestamp) ? null : timestamp.toDate();
    if (!isEmptyText(format)) {
        return moment(date).format(format);
    } else {
        return moment(date).format('DD/MM/YYYY - hh:mm');
    }
};*/

export const timestampToDate = timestamp => {
    return isVoid(timestamp) ? null : timestamp.toDate();
};

export const isTimestamp = timestamp => {
    return (
        !isVoid(timestamp) &&
        !isMoment(timestamp) &&
        typeof timestamp.toDate === 'function'
    );
};

export const isDateObject = value =>
    Object.prototype.toString.call(value) === '[object Date]';
