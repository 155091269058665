import React, { useMemo } from 'react';
import * as yup from 'yup';
import {
    Paper,
    Button
} from '@material-ui/core';
import { t } from 'core/i18n';
import TextField from '../../../shared/form/generic/TextField';
import { patchWorkspace } from 'core/modules/workspaces/actions';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useForm } from 'core/hooks/useForm';
import BaseFormContainer from '../../../shared/form/BaseFormContainer';
import AppContent from '../../../shared/content/AppContent';

const Schema = yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
});

const WorkspaceFormContainer = ({ classes, id, onSuccess }) => {
    const config = useMemo(
        () => ({
            //fetch: () => getWorkspaceById(id),
            store: values => patchWorkspace(values),
            defaultValue: {

            },
            onSuccess,
            id,
        }),
        [id, onSuccess]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        <>
            <AppContent>
                <BaseFormContainer
                    isLoading={isLoading}
                    error={error}
                    initialData={initialData}
                    render={() => (
                        <Paper className={classes.paper}>
                            <Formik
                                initialValues={initialData}
                                validationSchema={Schema}
                                onSubmit={onSubmit}>
                                {({ isSubmitting }) => (
                                    <Form>

                                        <TextField
                                            label={t('workspace.fields.id')}
                                            name="id"
                                        />

                                        <TextField
                                            label={t('workspace.fields.name')}
                                            name="name"
                                        />

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}>
                                            {t(
                                                id ? 'actions.update' : 'actions.create'
                                            )}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    )}
                />
            </AppContent>
        </>
    )
}

export default withStyles(styles)(WorkspaceFormContainer);