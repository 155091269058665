import { isTimestamp, timestampToDate } from 'core/utils/formatTime';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import momentTimeZone from 'moment-timezone';

export const TimeFormat = Object.freeze({
    12: 'hh',
    24: 'HH',
});

export const DateFormatObject = Object.freeze({
    'DD/MM/YYYY': 'DD/MM/YYYY',
    'MM/DD/YYYY': 'MM/DD/YYYY',
    'YYYY/MM/DD': 'YYYY/MM/DD'
});

const DateFormat = ({ date, time, tz }) => {
    // Time is a boolean to display hours and minutes
    const { time_format, date_format, time_zone } = useSelector(state => state.auth.user?.preferences || {})
    const dateObject = !!isTimestamp(date) ? timestampToDate(date) : date;
    const dateFormat = date_format || "DD/MM/YYYY";
    const timeFormat = !!time_format ? `${TimeFormat[time_format]}:mm` : "HH:mm";
    return (
        <Moment
            date={dateObject}
            format={!time ? dateFormat : dateFormat + " - " + timeFormat}
            tz={!tz ? time_zone || momentTimeZone.tz.guess() : false}
        />
    );
}

export default DateFormat;