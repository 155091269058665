import React, { useMemo } from 'react';
import BaseFormContainer from '../../../../../shared/form/BaseFormContainer';
import {
    Button,
    Paper,
    InputAdornment,
    Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { t } from 'core/i18n';
import TextField from '../../../../../shared/form/generic/TextField';
import { useForm } from '../../../../../../core/hooks/useForm';
import {
    storeProjectLog,
    getProjectLogById,
} from 'core/modules/dashboard/projects/logs/actions';
import {
    minutesHoursToSeconds,
    secondsToHours,
    secondsToMinutes,
} from 'core/utils/formatTime';
import {
    getLogLabelByType,
    LogLabels,
} from '../../../../../../core/modules/dashboard/projects/logs/constants';
import Select from '../../../../../shared/form/generic/Select';
import { isVoid } from '../../../../../../core/utils/isVoid';
import DatePicker from 'components/shared/form/generic/DatePicker';

const ProjectLogsSchema = yup.object().shape({
    //log_date: yup.string().required(),
    title: yup.string().required(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
    inputLeft: {
        paddingRight: '4px',
    },
    inputRight: {
        paddingLeft: '4px',
    },
    btn: {
        margin: '1em 0',
    },
});

const prepareData = (original) => {
    const data = { ...original };
    const seconds = minutesHoursToSeconds(data.minutes, data.hours);
    delete data.minutes;
    delete data.hours;
    //const date = !!selectedDate ? timestampToDate(selectedDate) : data.log_date;

    if (!!data.label) {
        data.labels = [data.label];
    }
    delete data.label;

    data.amount_time = seconds;
    data.log_type = 'system_entry';

    return data;
};

const prepareUpdateData = original => {
    const data = { ...original };
    const hours = secondsToHours(data.amount_time);
    const minutes = secondsToMinutes(data.amount_time);
    // eslint-disable-next-line camelcase
    // const log_date = timestampToDate(data.log_date);

    let label = '';
    if (
        !isVoid(data.labels) &&
        Array.isArray(data.labels) &&
        data.labels.length > 0
    ) {
        const logLabel = getLogLabelByType(data.labels[0]);
        if (!!logLabel) {
            label = logLabel.type;
        }
    }

    return { ...data, hours, minutes, label };
};

const ProjectLogsFormContainer = props => {
    const { classes, id, projectId, onSuccess } = props;

    const config = useMemo(() => {
        return {
            fetch: () => getProjectLogById(id, projectId),
            store: values => storeProjectLog(prepareData(values)),
            defaultValue: {
                project_id: projectId,
            },
            onSuccess,
            id,
        };
    }, [id, projectId, onSuccess]);

    const { isLoading, initialData, error, onSubmit } = useForm(config);
    return (
        <div>
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <Paper className={classes.paper}>
                        <Formik
                            validationSchema={ProjectLogsSchema}
                            initialValues={
                                id
                                    ? prepareUpdateData(initialData)
                                    : initialData
                            }
                            onSubmit={onSubmit}>
                            {({ isSubmitting, values }) => (
                                <Form>
                                    <DatePicker
                                        label={t('projects.logs.fields.date')}
                                        name="log_date"
                                        inputValue={values.log_date}
                                    />
                                    <TextField
                                        label={t('projects.logs.fields.title')}
                                        name="title"
                                        required
                                    />
                                    <TextField
                                        label={t(
                                            'projects.logs.fields.description'
                                        )}
                                        name="description"
                                        multiline
                                        rows="4"
                                    />
                                    <Box display="flex">
                                        <TextField
                                            label={t(
                                                'projects.logs.fields.hours'
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        h
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                            name="hours"
                                            className={classes.inputLeft}
                                        />
                                        <TextField
                                            label={t(
                                                'projects.logs.fields.minutes'
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                            name="minutes"
                                            className={classes.inputRight}
                                        />
                                    </Box>
                                    <Select
                                        label={t('projects.logs.fields.labels')}
                                        name={'label'}
                                        options={Object.keys(LogLabels).map(
                                            LogLabelKey => ({
                                                value:
                                                    LogLabels[LogLabelKey].type,
                                                label: t(
                                                    `projects.logs.fields.labels.${LogLabels[LogLabelKey].type}`
                                                ),
                                            })
                                        )}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn}
                                        disabled={isSubmitting}>
                                        {t(
                                            id
                                                ? 'actions.update'
                                                : 'actions.create'
                                        )}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}
            />
        </div>
    );
};

export default withStyles(styles)(ProjectLogsFormContainer);
