import React from 'react';
import AppContent from 'components/shared/content/AppContent';
import { t } from '../../../core/i18n';
import { useSelector, useDispatch } from 'react-redux';
import {
    history,
    route,
    ROUTE_WORKSPACE_CLIENTS,
    ROUTE_CLIENT_PROJECTS,
} from 'core/routing/routing';
import { setCurrentWorkspace } from 'core/modules/workspaces/reducer';
import { getClientById } from 'core/modules/dashboard/clients/actions';
import { setCurrentWorkspaceClient } from 'core/modules/dashboard/clients/reducer';
import { setLocalStorage } from 'core/modules/app/reducer';
import {
    Typography,
    Grid
} from '@material-ui/core';
import Tile from 'components/shared/content/Tile';

const UserLandingScene = () => {
    const dispatch = useDispatch();
    const workspaces = useSelector(state => state.workspaces.workspaces || []);
    const clients = useSelector(state => state.client.clients || []);
    const workspaceRoles = useSelector(state => state.auth.user.workspace_roles);
    const { client_roles } = useSelector(state => state.auth.user);

    const items = [...workspaces, ...clients];
    let newItems = {};
    if (!!items) {
        newItems = items.map((item) =>
            Object.assign({}, item, !!item.workspace_agency ? { type: "client" } : { type: "workspace_agency" })
        )
    }

    const handleRowClick = (id, type) => {
        const action = {};
        if (type === "workspace_agency") {
            action.current = id;
            action.permission = workspaceRoles[id];
            dispatch(setCurrentWorkspace(action));
            action.workspace = id;
            action.workspace_client = null;
            action.project = null;
            dispatch(setLocalStorage(action));
            history.push(route(ROUTE_WORKSPACE_CLIENTS, { workspaceId: id }));
        } else if (type === "client") {
            dispatch(getClientById(id))
                .then(client => {
                    action.client_id = id;
                    action.client = client;
                    action.permission = client_roles[id];
                    dispatch(setCurrentWorkspaceClient(action));
                    action.workspace_client = id;
                    if (!!workspaceRoles[client.workspace_agency]) {
                        action.workspace = client.workspace_agency;
                    }
                    dispatch(setLocalStorage(action));
                    history.push(route(ROUTE_CLIENT_PROJECTS, { clientId: id, workspaceId: client.workspace_agency }))
                })
        }
    };

    return (
        <>
            <Grid
                container
                spacing={3}>
                <Typography variant="h4" component="h2" gutterBottom>
                    {t('app.projects_overview')}
                </Typography>
            </Grid>
            <AppContent>
                {items && (
                    <Grid
                        container
                        spacing={3}>
                        {newItems.map(item => (
                            <Tile
                                key={item.id}
                                tagLine={t(`users.fields.${item.type}`)}
                                title={item.name}
                                onClick={() => handleRowClick(item.id, item.type)}
                            />
                        ))}
                    </Grid>
                )}

            </AppContent>
        </>
    );
};

export default UserLandingScene;
