import React from 'react';
import {
    Page,
    Text,
    Image,
    View,
    Document,
    StyleSheet,
    Font,
} from '@react-pdf/renderer';
import { t } from 'core/i18n';
import BillingAddress from './billing/BillingAddress';
import ShippingAddress from './billing/ShippingAddress';
import fontNormal from 'assets/fonts/Roboto-Regular.ttf';
import fontBold from 'assets/fonts/Roboto-Bold.ttf';
import fontItalic from 'assets/fonts/Roboto-Italic.ttf';
import ItemRow from './row/ItemRow';
import GroupRow from './row/GroupRow';
import OverviewRows from './table/OverviewRows';
import OverviewGroups from './table/OverviewGroups';

Font.register({
    family: 'Roboto',
    format: "truetype",
    fonts: [
        {
            src: fontNormal,
            fontWeight: 'normal',
        },
        {
            src: fontBold,
            fontWeight: 'bold'
        },
        {
            src: fontItalic,
            fontWeight: 'normal',
            fontStyle: 'italic'
        }
    ]
})

const BORDER_COLOR = '#e0e0e0'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 60
const COLN_WIDTH = (100 - COL1_WIDTH) / 4

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        //padding: '24 0 100 0',
        padding: '20 20 100 20',
        //padding: '14px 24px 14px 24px',
        display: 'block',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
    },
    addressTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        //display: "flex",
        marginBottom: '4px',
    },
    addressBody: {
        //fontSize: 10,
        margin: '1px 0',
    },
    image: {
        height: 'auto',
        width: 160,
        maxHeight: '60px',
    },
    accountDetails: {
        margin: '20px 0 20px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 10,
    },
    col6: {
        width: '50%',
    },
    documentNumber: {
        fontSize: 18,
        marginBottom: '20px',
        fontWeight: 'bold',
    },
    footer: {
        marginTop: '20px',
        padding: '10px 0',
        borderTopWidth: 1,
        borderColor: '#000000',
        borderStyle: 'solid',
        display: 'flex',
        fontSize: 8,
    },
    table: {
        display: "table",
        width: "100%",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomWidth: 1,
        alignItems: 'center',
    },
    tableGroupRow: {
        margin: "auto",
        flexDirection: "row",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomWidth: 1,
        backgroundColor: '#f0f0f0',
    },
    tableCol1Header: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderBottomWidth: 1,
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderBottomWidth: 1,
    },
    tableCol1: {
        width: COL1_WIDTH + '%',
    },
    tableCol: {
        width: COLN_WIDTH + "%",
    },
    table2Col: {
        width: 2 * COLN_WIDTH + "%",
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
    },
    tableCellBold: {
        margin: 5,
        fontSize: 10,
        fontWeight: 'bold',
    },
});

const InvoicingPdfScene = ({ type, item }) => {
    // not the best option to fix the CORS issue
    // https://stackoverflow.com/questions/43462367/how-to-overcome-the-cors-issue-in-reactjs
    const logoUrl = 'https://cors-anywhere.herokuapp.com/' + item.from.logo_image_url;

    return (
        <Document>

            <Page
                size="A4"
                style={styles.page}
            >

                <View>
                    {/* Start Header */}
                    <View fixed>
                        <Image source={{ uri: logoUrl, method: 'GET', headers: {} }} alt="images" style={styles.image} />
                    </View>
                    {/* End Header */}

                    {/* Start Customer details */}
                    <View style={styles.accountDetails}>
                        <View style={styles.col6}>
                            <Text style={styles.addressTitle}>
                                {!!item.to.shipping_address ?
                                    t('clients.billing_accounts.fields.billing_address') :
                                    t('clients.billing_accounts.fields.billing_shipping_address')}
                            </Text>
                            <BillingAddress
                                billingAccount={item.to || {}}
                                styles={styles}
                            />
                        </View>
                        {!!item.to.shipping_address && (
                            <View style={styles.col6}>
                                <Text style={styles.addressTitle}>
                                    {t('clients.billing_accounts.fields.shipping_address')}
                                </Text>
                                <ShippingAddress
                                    billingAccount={item.to || {}}
                                    styles={styles}
                                />
                            </View>
                        )}
                    </View>
                    {/* End Customer details */}

                    <Text style={styles.documentNumber}>
                        {!!item.uid ? "#" + item.uid : null}{!!item.version ? "/" + item.version : null}
                    </Text>

                    {/* Start document details */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol1Header}>
                                <Text style={styles.tableCellHeader}>
                                    {t('invoicing.fields.item.description')}
                                </Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>
                                    {t('invoicing.fields.item.quantity')}
                                </Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>
                                    {t('invoicing.fields.item.price')}
                                </Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>
                                    {t('invoicing.fields.item.vat')}
                                </Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>
                                    {t('invoicing.fields.item.total')}
                                </Text>
                            </View>
                        </View>
                        {item.items.map((row, index) =>
                            !row.group ? (
                                <ItemRow
                                    key={`item_${index}`}
                                    item={row}
                                    status={item.status}
                                    styles={styles}
                                    wrap={false}
                                />
                            ) : (
                                    <GroupRow
                                        key={`group_${index}`}
                                        item={row}
                                        styles={styles}
                                    />
                                )
                        )}
                        <View style={{ alignSelf: 'flex-end' }}>
                            <OverviewGroups
                                currency={item.currency}
                                items={item.items || []}
                                styles={styles}
                            />
                            <OverviewRows
                                currency={item.currency}
                                items={item.items || []}
                                status={item.status}
                                styles={styles}
                            />
                        </View>
                    </View>
                    {/* End document details */}
                </View>

                {/* start Footer */}
                <View fixed wrap={false} style={styles.footer}>
                    <BillingAddress
                        billingAccount={item.from || {}}
                        styles={styles}
                    />
                </View>
                {/* end Footer */}
            </Page >
        </Document >
    )
}

export default InvoicingPdfScene;