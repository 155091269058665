import Select from '../generic/Select';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentWorkspace } from '../../../../core/modules/workspaces/selectors';

const VatSelect = ({ label, name, ...props }) => {
    const workspace = useSelector(state => selectCurrentWorkspace(state));

    return (
        !!workspace?.accounting ? (
            <Select
                label={label}
                name={name}
                options={workspace.accounting.vat_percentages.map(key => ({
                    value: key,
                    label: key,
                }))}
                {...props}
            />
        ) : null
    );
};

export default VatSelect;
