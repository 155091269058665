import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { sendEmailVerification } from '../../core/modules/auth/actions';
import Alert from '@material-ui/lab/Alert';
import { t } from '../../core/i18n';

const VerificationAlert = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    const [sent, setSend] = useState(false);

    const handleResend = () => {
        dispatch(sendEmailVerification())
            .then(() => {
                setSend(true);
            })
            .catch(() => {
                window.alert(t('errors.generic'));
            });
    };

    return !user.emailVerified ? (
        <>
            <Alert
                severity="warning"
                action={
                    <Button
                        color="inherit"
                        size="small"
                        disabled={sent}
                        onClick={handleResend}>
                        {t(`email_verification.${sent ? 'sent' : 'resend'}`)}
                    </Button>
                }>
                {t('email_verification.title')}
            </Alert>
        </>
    ) : null;
};

export default VerificationAlert;
