import React from 'react';
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Button,
    Avatar,
} from '@material-ui/core';
import {
    withStyles,
} from '@material-ui/core/styles';
import { t } from 'core/i18n';
import { useSelector } from 'react-redux';
import { ROUTE_USER_EDIT } from 'core/routing/routing';
import { NavLink } from 'react-router-dom';
import DateFormat from 'components/shared/form/generic/DateFormat';
import PhoneInputDisplay from 'components/shared/form/generic/PhoneInputDisplay';

const styles = () => ({
    paper: {
        padding: 20,
    },
    divider: {
        margin: "20px 0",
        opacity: .3,
    },
    large: {
        margin: "20px 0",
        width: "120px",
        height: "120px",
    },
    subTitle: {
        margin: "20px 0",
    }
});

const UserOverviewScene = ({ classes }) => {
    const user = useSelector(state => state.auth.user);

    return (
        <>
            <Typography variant="h4" component="h2" gutterBottom>
                {t('user.profile.title')}
            </Typography>
            <Paper className={classes.paper}>
                <Typography variant="h5" component="h3" gutterBottom>
                    {t('user.overview.profile.title')}
                </Typography>
                <Avatar
                    alt={user.userName}
                    src={user.photoUrl}
                    className={classes.large}
                />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.email')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {user.email}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.user_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {user.displayName}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Typography component="p" variant="h6" className={classes.subTitle}>
                    {t('user.overview.profile.personal_data')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.gender')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {!!user.gender ? t(`user.overview.profile.fields.gender.${user.gender}`) : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.dob')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {!!user.dob ? <DateFormat date={user.dob} tz={false} /> : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.phone')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            <PhoneInputDisplay
                                value={user.phoneNumber || ""}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Typography component="p" variant="h6" className={classes.subTitle}>
                    {t('user.overview.profile.address')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.street_nr')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {user.street_nr || ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.city')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {user.city || ""} {user.postal_code || ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {t('user.overview.profile.country')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" component="p" >
                            {user.country || ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Button
                    color="primary"
                    component={NavLink}
                    to={
                        ROUTE_USER_EDIT
                    }
                    variant="contained"
                >
                    {t('nav.user.edit')}
                </Button>
            </Paper>
        </>
    )
}

export default withStyles(styles)(UserOverviewScene);