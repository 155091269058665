import { isEmptyText } from '../../utils/isEmptyText';

export const formatAddressShort = address => {
    const lines = [];
    if (!isEmptyText(address.street)) {
        lines.push(`${address.street} ${address.number || ''}`);
    }
    if (!isEmptyText(address.city)) {
        lines.push(`${address.postal_code || ''} ${address.city}`);
    }
    if (!isEmptyText(address.country)) {
        // translate in future
        lines.push(address.country);
    }
    return lines.join(', ');
};
