import React, { useState } from 'react';
import {
    Box,
    Collapse,
    Divider,
    List
} from '@material-ui/core';
import NavHeader from './NavHeader';
import NavChild from './NavChild';
import clsx from 'clsx';

const NavGroup = ({ route, classes, drawerOpen }) => {
    const [openNavGroup, setOpenNavGroup] = useState(true);

    const handleNavGroupToggle = () => {
        setOpenNavGroup(!openNavGroup);
    };

    return (
        <Box classes={classes.navGroup}>
            <NavHeader
                key={route.path}
                route={route}
                classes={classes}
                isHeading={true}
                customClickEvent={handleNavGroupToggle}
                openVar={openNavGroup}
                drawerOpen={drawerOpen}
                routeChildren={route.children}
            />
            <Collapse
                in={openNavGroup}
                timeout="auto">
                <List>
                    {route.children &&
                        route.children.map((subRoute, index) => (
                            <NavChild
                                key={subRoute.path + index}
                                route={subRoute}
                                options={subRoute.options}
                                classes={classes}
                                drawerOpen={drawerOpen}
                            />
                        ))}
                </List>
            </Collapse>
            {(!drawerOpen && !openNavGroup) ? (
                null
            ) : <Divider className={clsx(classes.divider, {
                [classes.dividerClose]: !drawerOpen,
            })} />}
        </Box>
    )
}

export default NavGroup;