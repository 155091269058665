import React from 'react';
import AuthContainer from './auth/AuthContainer';
import {
    createMuiTheme,
    ThemeProvider
} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { red } from '@material-ui/core/colors';
import LocalizationProvider from './localization/LocalizationProvider';

const DaltaTheme = createMuiTheme({
    '@global': {
        body: {
            padding: 0,
            margin: '0 !important',
        },
    },
    palette: {
        type: 'light',
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 14,
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: 'Roboto',
                fontSize: 14,
            },
            body1: {
                fontFamily: 'Roboto',
                fontSize: 14,
                a: {
                    color: red,
                },
            },
        },
        MuiDrawer: {
            paper: {
                // backgroundColor: '#041E34',
                justifyContent: 'space-between',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiIcon: {
            root: {
                lineHeight: 'initial',
            },
        },
        MuiTabs: {
            root: {
                // marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: '#FFF',
                // backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                /* [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },*/
            },
        },
        MuiIconButton: {
            root: {
                // padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
                backgroundColor: '#123350',
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                // fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiFormControl: {
            root: {
                margin: '8px 0',
            },
            fullWidth: {
                margin: '8px 0',
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
        MuiLink: {
            underlineNone: {
                position: 'relative',
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '2px',
                    backgroundColor: '#3f51b5',
                    opacity: 0.4,
                    bottom: '-2px',
                    left: 0,
                },
                '&:hover:after': {
                    opacity: 1,
                },
            },
        },
    },
});

const App = (props) => {
    return (
        <ThemeProvider theme={DaltaTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <LocalizationProvider>
                    <AuthContainer {...props} />
                </LocalizationProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
