import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ProductGroupsFormContainer from './ProductGroupsFormContainer';
import {
    history,
    route,
    ROUTE_PRODUCT_GROUPS,
} from '../../../../../../core/routing/routing';
import { useSelector } from 'react-redux';

const ProductGroupsFormScene = () => {
    const { id } = useParams();
    const workspaceId = useSelector(state => state.app.workspace);

    const handleSuccess = useCallback(() => {
        history.push(route(ROUTE_PRODUCT_GROUPS, { workspaceId }));
    }, []);

    return (
        <div>
            <ProductGroupsFormContainer id={id} onSuccess={handleSuccess} />
        </div>
    );
};

export default ProductGroupsFormScene;
