import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
    Paper,
    Button,
    Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t, Locales } from 'core/i18n';
import {
    getClientBillingAccountById,
    storeBillingAccount,
} from '../../../../../../core/modules/dashboard/clients/billing-accounts/actions';
import TextFastField from '../../../../../shared/form/generic/TextFastField';
import TextField from '../../../../../shared/form/generic/TextField';
import Select from '../../../../../shared/form/generic/Select';
import BaseFormContainer from '../../../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../../../core/hooks/useForm';
import VatNumberField from '../../../../../shared/form/vat/VatNumberField';
import ClientSelect from '../../../../../shared/form/client/ClientSelect';
import { vatValidatorTest } from 'core/modules/dashboard/clients/billing-accounts/actions';
import { useParams } from 'react-router-dom';
import { history, route, ROUTE_BILLING_ACCOUNTS, ROUTE_BILLING_ACCOUNTS_DETAIL, ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL } from 'core/routing/routing';

yup.addMethod(yup.string, 'vatValidator', function () {
    return this.test('vat_number.vat', 'vat.validator.dialog.vat.not_valid', function (value) {
        return new Promise((resolve, reject) => {
            if (!!value) {
                vatValidatorTest(value)
                    .then(data => {
                        if (!!data) {
                            if (data.valid) {
                                resolve(true)
                            } else {
                                resolve(false)
                            }
                        } else {
                            resolve(false)
                        }
                    })
            } else {
                resolve(false)
            }
        })
    })
});


const Schema = yup.object().shape({
    name: yup.string().required(),
    legal_name: yup.string().required(),
    language: yup.string().required(),
    //client: yup.string().required(),
    contact_name: yup.string().required(),
    vat_number: yup.object().shape({
        type: yup.string().required(),
        vat: yup.string()
            .when('type', {
                is: (val) => val === "eu",
                then: yup.string().vatValidator(),
                otherwise: yup.string(),
            })
            .when('type', {
                is: (val) => val === "none",
                then: yup.string(),
                otherwise: yup.string().required(),
            })
    })
});

const styles = () => ({
    paper: {
        padding: 20,
        marginBottom: 30,
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
    btnCancel: {
        marginLeft: 12
    }
});

const prepareData = original => {
    const data = { ...original };
    if (data.is_eu) {
        delete data.vat_other;
    } else {
        delete data.vat_eu;
    }

    if (!!data.client) {
        if (!!data.client.id) {
            return data;
        } else {
            data.client_id = data.client.id;
        }
    }

    return data;
};

const BillingAccountsFormContainer = ({ classes, onSuccess }) => {
    const { billingId, clientId, workspaceId } = useParams();
    const [edited, setEdited] = useState(!!billingId ? true : false);
    const config = useMemo(
        () => ({
            fetch: () => getClientBillingAccountById(billingId, clientId),
            store: values => storeBillingAccount(prepareData(values)),
            defaultValue: {
                name: '',
                legal_name: '',
                contact_name: '',
                language: '',
                vat_number: {
                    vat: '',
                    type: '',
                    summary: '',
                },
                billing_address: {
                    street: '',
                    number: '',
                    country: '',
                    email: '',
                    contact_name: '',
                    postal_code: '',
                    city: '',
                    state: '',
                },
                /*client: !!clientId ? {
                    id: clientId,
                } */
            },
            id: billingId,
            onSuccess,
        }),
        [billingId, clientId, onSuccess]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    const onInputChange = (e, setFieldValue) => {
        const value = e.target.value;
        if (!edited) {
            setFieldValue('legal_name', value);
            setFieldValue('name', value);
        } else {
            setFieldValue('legal_name', value);
        }
    };

    const onEdit = (e, setFieldValue) => {
        const value = e.target.value;
        setFieldValue('name', value);
    };

    const handleCancel = () => {
        if (!!clientId && !!billingId) {
            history.push(route(ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL, { workspaceId, billingId, clientId }))
        } else if (!!billingId) {
            history.push(route(ROUTE_BILLING_ACCOUNTS_DETAIL, { workspaceId, billingId }))
        } else {
            history.push(route(ROUTE_BILLING_ACCOUNTS, { workspaceId }))
        }
    }

    return (
        <BaseFormContainer
            isLoading={isLoading}
            error={error}
            initialData={initialData}
            render={() => (
                <Paper className={classes.paper}>
                    <Formik
                        validationSchema={Schema}
                        initialValues={initialData}
                        validateOnChange={false}
                        onSubmit={onSubmit}>
                        {({ isSubmitting, setFieldValue, errors }) => (
                            <Form>
                                {!!clientId && (
                                    <ClientSelect />
                                )}
                                <VatNumberField errors={errors} />

                                <Box display="flex">
                                    <TextField
                                        label={t(
                                            'clients.billing_accounts.fields.legal_name'
                                        )}
                                        name="legal_name"
                                        onChange={e =>
                                            onInputChange(e, setFieldValue)
                                        }
                                    />
                                    <TextField
                                        label={t(
                                            'clients.billing_accounts.fields.alias'
                                        )}
                                        name="name"
                                        className={classes.flexBoxItem}
                                        onChange={e => {
                                            onEdit(e, setFieldValue);
                                            setEdited(true);
                                        }}
                                    />
                                </Box>
                                <Box display="flex">
                                    <TextFastField
                                        label={t(
                                            'clients.billing_accounts.fields.contact_name'
                                        )}
                                        name="contact_name"
                                    />
                                    <Select
                                        label={t(
                                            'clients.billing_accounts.fields.language'
                                        )}
                                        name="language"
                                        options={Locales.map(l => ({
                                            value: l,
                                            label: l,
                                        }))}
                                        className={classes.flexBoxItem}
                                    />
                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn}
                                    disabled={isSubmitting}>
                                    {t(
                                        billingId ? 'actions.update' : 'actions.create'
                                    )}
                                </Button>
                                <Button
                                    onClick={() => handleCancel()}
                                    variant="contained"
                                    className={classes.btnCancel}
                                    disabled={isSubmitting}>
                                    {t(
                                        'actions.cancel'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            )}
        />
    );
};

export default withStyles(styles)(BillingAccountsFormContainer);
