import React from 'react';
import {
    Text,
    View,
} from '@react-pdf/renderer';

const GroupRow = ({ item, styles }) => {
    return (
        <View style={styles.tableGroupRow}>
            <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                    {item.name}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                </Text>
            </View>
        </View>
    );
};

export default GroupRow;
