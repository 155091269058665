import React from 'react';
import PageHeader from '../../shared/header/PageHeader';
import { Redirect, Route, Switch } from 'react-router-dom';
import { t } from '../../../core/i18n';
import AppContent from '../../shared/content/AppContent';
import {
    ROUTE_API_CENTER,
    ROUTE_API_CENTER_SOURCE,
} from '../../../core/routing/routing';
import ApiCenterSource from './overview/ApiCenterSource';

const ApiCenterScene = props => {
    return (
        <>
            <PageHeader title={t('api_center.title')} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_API_CENTER_SOURCE}
                        component={ApiCenterSource}
                    />
                    <Redirect to={ROUTE_API_CENTER} />
                </Switch>
            </AppContent>
        </>
    );
};

export default ApiCenterScene;
