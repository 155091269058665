// Languages
import { isEmptyText } from '../utils/isEmptyText';

export const Locales = ['en', 'fr', 'nl'];

// Settings Language
export const initLocalizations = () => {
    Localization.init({
        fallbackLocale: 'en',
    });
};

// Localization
export const Localization = {
    dictionary: {},
    init: function (config) {
        this.fallbackLocale = config.fallbackLocale;
        this.dictionary[this.fallbackLocale] = {}; // fix later
        return import(`./locales/${this.fallbackLocale}`).then(dictionary => {
            this.dictionary[this.fallbackLocale] = dictionary.default;
        });
    },
    setLocale: function (locale) {
        this.currentLocale = locale;
        return import(`./locales/${locale}`).then(dictionary => {
            this.dictionary[locale] = dictionary.default;
        });
    },
    getTranslation: function (key) {
        return (
            this.dictionary[this.currentLocale][key] ||
            this.dictionary[this.fallbackLocale][key] ||
            '{' + key + '}'
        );
    },
    getFieldTranslation: function (field) {
        if (!isEmptyText(field[this.currentLocale])) {
            return field[this.currentLocale];
        }
        return '';
    },
};

// Translation
export const t = function (key, mapping) {
    if (key) {
        key = key.toLowerCase();
    }
    if (!mapping) {
        return Localization.getTranslation(key);
    } else {
        let translation = Localization.getTranslation(key);
        // replace mapping values
        Object.keys(mapping).forEach(key => {
            translation = translation.replace('{' + key + '}', mapping[key]);
        });
        return translation;
    }
};

// TF = translate field
export const tf = function (field = {}) {
    return Localization.getFieldTranslation(field);
};
