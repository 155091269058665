import ClientBillingAccountSelect from '../../../../../shared/form/billing/ClientBillingAccountSelect';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import Address from '../../../../../shared/address/Address';
import ShippingAddress from '../../../../../shared/address/ShippingAddress';
import { selectBillingFromBillingAccount } from '../../../../../../core/modules/accounting/invoicing/selectors';
import {
    Box,
    Typography
} from '@material-ui/core';
import { t } from 'core/i18n';

const InvoicingBillingFields = () => {
    const { values, setFieldValue } = useFormikContext();
    const [shippingAddress, setShippingAddress] = useState(values.to?.shipping_address ? values.to.shipping_address : null)

    const handleBillingSelect = billingAccount => {
        if (billingAccount != null) {
            setFieldValue(
                'to',
                selectBillingFromBillingAccount(billingAccount)
            );
            setFieldValue('language', billingAccount.language);
            setFieldValue('to.billing_account_id', billingAccount.id);
            if (!!billingAccount.shipping_address && !!billingAccount.shipping_address[0]) {
                setShippingAddress(billingAccount.shipping_address[0])
                setFieldValue('to.shipping_address', billingAccount.shipping_address[0]);
            } else {
                setShippingAddress(null)
            }
        }
    };

    return (
        <>
            <ClientBillingAccountSelect onChange={handleBillingSelect} />

            <Box display="flex">
                {/* eslint-disable-next-line camelcase */}
                {values.to?.billing_address && (
                    <Box style={{ width: '50%' }}>
                        <Typography variant="h6">
                            {t('clients.billing_accounts.fields.billing_address')}
                        </Typography>
                        <Address address={values.to?.billing_address || {}} />
                    </Box>
                )}

                {!!shippingAddress && (
                    <Box style={{ width: '50%' }}>
                        <Typography variant="h6">
                            {t('clients.billing_accounts.fields.shipping_address')}
                        </Typography>
                        <ShippingAddress
                            billingAccountId={values.to?.billing_account_id || null}
                            address={shippingAddress}
                            name="to.shipping_address"
                        />
                    </Box>
                )}
            </Box>
            {/*<AddressFields values={values.to?.billing_address || {}} name="to.billing_address" />*/}
        </>
    );
};

export default InvoicingBillingFields;
