import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Select,
    MenuItem
} from '@material-ui/core';
import { setCurrentWorkspaceClient } from 'core/modules/dashboard/clients/reducer';
import { withStyles } from '@material-ui/core/styles';
/*import {
    history,
    ROUTE_CLIENT_PROJECTS,
    route
} from '../../../core/routing/routing';*/
import { Roles, Permissions } from 'core/modules/auth/constants';
import { getUserClients, getClientById } from 'core/modules/dashboard/clients/actions';
import { LocalStorage } from 'core/storage/LocalStorage';

const styles = theme => ({
    clientSelect: {
        color: "rgba(255, 255, 255, 0.7)",
        padding: 0,
        width: '100%',
    },
})

const ClientSelect = (props) => {
    const dispatch = useDispatch();
    const localstorageClientId = LocalStorage.getWorkspaceClient();

    const { clients, client_id } = useSelector(state => state.client);
    //const { client_roles } = useSelector(state => state.auth.user);
    const clientId = useSelector(state => state.auth.user.client_id);

    const isSuperAdmin = useSelector(
        state => (state.auth.user.role === Roles.Admin && state.auth.user.permission === Permissions.Owner)
    );

    const { classes, ...other } = props;

    useEffect(() => {
        const action = {};
        if (!clients) {
            dispatch(getUserClients());
            if (!!localstorageClientId) {
                dispatch(getClientById(localstorageClientId))
                    .then(client => {
                        action.client_id = client.id;
                        action.client = client;
                        dispatch(setCurrentWorkspaceClient(action));
                    })
            }
        }
    }, [dispatch, clients, isSuperAdmin, client_id, clientId, localstorageClientId]);

    /*const handleChange = e => {
        history.push(route(ROUTE_CLIENT_PROJECTS, { clientId: e.target.value }))
        const action = {};
        dispatch(getClientById(e.target.value))
            .then(client => {
                action.client_id = e.target.value;
                action.client = client;
                action.permission = client_roles[e.target.value];
                dispatch(setCurrentWorkspaceClient(action));
            })
    };*/

    return (clients && isSuperAdmin) ? (
        <Select
            value={client_id || ""}
            //onChange={handleChange}
            className={classes.clientSelect}
            disableUnderline
            {...other}>
            {(clients || []).map(space => (
                <MenuItem
                    key={space.id}
                    value={space.id}
                    className={classes.itemTitle}
                    {...other}>
                    {space.name}
                </MenuItem>
            ))}
        </Select>
    ) : null;
};

export default withStyles(styles)(ClientSelect);
