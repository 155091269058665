import React from 'react';
import { useSelector } from 'react-redux';
import UserPermissionDialogContainer from 'components/app/user/form/UserPermissionDialogContainer';
import { storeClientUserRole } from 'core/modules/dashboard/clients/actions';

const ClientRolesDialogScene = ({ ...props }) => {
    const clientId = useSelector(state => state.app.workspace_client);
    return (
        <>
            <UserPermissionDialogContainer
                {...props}
                storeFunction={storeClientUserRole}
                spaceId={clientId}
            />
        </>
    )
}

export default ClientRolesDialogScene;