import React from 'react'
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardActionArea
} from '@material-ui/core';
import {
    withStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import { DocStatus } from 'core/modules/auth/constants';

const styles = () => ({
    tile: {
        minHeight: "200px",
    },
    tileDeleted: {
        background: 'repeating-linear-gradient( -45deg, rgba(204, 0, 0, .1), rgba(204, 0, 0, .1) 2px, #fff 2px, #fff 4px )',
    }
});

const Tile = (props) => {
    const { tagLine, title, onClick, classes, key, data } = props;
    return (
        <Grid
            item
            xs={4}
            key={key}>
            <Card>
                <CardActionArea
                    className={clsx(classes.tile, {
                        [classes.tileDeleted]: (data?.meta?.doc_status === DocStatus.SoftDeleted)
                    })}
                    onClick={onClick}
                >
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {tagLine}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default withStyles(styles)(Tile);