import React from 'react';
import { Box } from '@material-ui/core';

const GuestLandingPageScene = props => {
    return (
        <Box display="flex" justifyContent="center">
            <p>Welcome to Dalta</p>
        </Box>
    );
};

export default GuestLandingPageScene;
