import React, { useCallback } from 'react';
import { t } from 'core/i18n';
import {
    Button,
    Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
    route,
    ROUTE_CLIENT_BILLING_ACCOUNTS_ADD,
    ROUTE_BILLING_ACCOUNTS_ADD,
    ROUTE_BILLING_ACCOUNTS_EDIT,
    history,
    ROUTE_BILLING_ACCOUNTS_DETAIL,
    ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL,
} from 'core/routing/routing';
import { Link, useParams } from 'react-router-dom';
import { getClientBillingAccounts } from 'core/modules/dashboard/clients/billing-accounts/actions';
import { useList } from 'core/hooks/useList';
import { deleteBillingAccount } from 'core/modules/dashboard/clients/billing-accounts/actions';
import Progress from '../../../../../shared/progress/Progress';
import { formatAddressShort } from '../../../../../../core/modules/address/utils';
import { Add as AddIcon } from '@material-ui/icons';
import BaseMaterialTable from '../../../../../shared/table/BaseMaterialTable';
import { withStyles } from '@material-ui/core/styles';
import { Collections } from 'core/networking/firebase';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    },
});

const BillingAccountsOverview = ({ classes }) => {
    const { workspaceId, clientId } = useParams()
    const fetchList = useCallback(() => getClientBillingAccounts(Collections.clients_billing_accounts, clientId), [
        clientId,
    ]);

    const { items, isLoading, error, refreshList, canSoftDelete, canDelete, canEdit } = useList(
        fetchList, Collections.clients_billing_accounts
    );

    const headCells = [
        {
            title: t('clients.billing_accounts.fields.alias'),
            field: 'name',
            render: rowData => rowData.name,
        },
        {
            title: t('clients.billing_accounts.fields.legal_name'),
            field: 'legal_name',
            render: rowData => rowData.legal_name,
        },
        {
            title: t('clients.billing_accounts.fields.language'),
            field: 'language',
            render: rowData => rowData.language.toUpperCase(),
        },
        {
            title: t('clients.billing_accounts.fields.billing_address'),
            field: 'billing_address',
            render: rowData =>
                rowData.billing_address &&
                formatAddressShort(rowData.billing_address),
        },
        {
            title: t('clients.billing_accounts.fields.vat'),
            field: 'vat_number.vat',
            render: rowData => rowData.vat_number?.vat || '',
        },
    ];

    const menuActions = [
        {
            action: 'edit',
            to: ROUTE_BILLING_ACCOUNTS_EDIT,
            routeParam: 'billingId',
            routeParamExtra: { workspaceId: workspaceId }
        },
        {
            action: 'delete',
        },
        {
            action: 'deleteForever',
        },
        {
            action: 'restoreDelete',
        },
    ]

    const handleRowClick = (event, rowData) => {
        !clientId ?
            history.push(route(ROUTE_BILLING_ACCOUNTS_DETAIL, { workspaceId, billingId: rowData.id }))
            :
            history.push(route(ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL, { workspaceId, billingId: rowData.id, clientId }));
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.addBtn}>
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                to={
                                    !!clientId
                                        ? route(
                                            ROUTE_CLIENT_BILLING_ACCOUNTS_ADD,
                                            { clientId: clientId, workspaceId }
                                        )
                                        : route(ROUTE_BILLING_ACCOUNTS_ADD, { workspaceId })
                                }>
                                {t('clients.billing_accounts.add')}
                            </Button>
                        </Grid>
                        <BaseMaterialTable
                            title={t('clients.billing_accounts.title')}
                            headCells={headCells}
                            items={items}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                            deleteItemFunc={deleteBillingAccount}
                            menuActions={menuActions}
                            refreshList={refreshList}
                            collection={Collections.clients_billing_accounts}
                            handleRowClick={handleRowClick}
                        />
                    </>
                )}
            </>
        );
    }
};

export default withStyles(styles)(BillingAccountsOverview);
