import React, { useCallback } from 'react';
import WorkspaceFormContainer from './WorkspaceFormContainer';
import {
    ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES,
    history
} from 'core/routing/routing';
import { useParams } from 'react-router-dom';

const WorkspaceFormScene = () => {
    const { id } = useParams();

    const handleSuccess = useCallback(() => {
        history.push(ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES)
    }, [])

    return (
        <>
            <WorkspaceFormContainer id={id} onSuccess={handleSuccess} />
        </>
    )
}

export default WorkspaceFormScene;