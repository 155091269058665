import { isEmptyText } from '../../../utils/isEmptyText';
import { isVoid } from '../../../utils/isVoid';
import { formatValuta } from '../../../utils/formatValuta';

/*
 * TODO: move to utils?
 */

export const selectItemTotalVat = (item, addVat = true) => {
    if (item.price && item.quantity) {
        if (item.vat && addVat) {
            return item.price * item.quantity * (1 + item.vat / 100);
        }
        return item.price * item.quantity;
    }
    return 0;
};

export const selectItemTotal = item => {
    if (item.price && item.quantity) {
        return item.price * item.quantity;
    }
    return 0;
};

export const selectItemTotalPurchase = item => {
    if (item.purchase_price && item.quantity) {
        return item.purchase_price * item.quantity;
    }
    return 0;
};

export const selectItemVat = item => {
    if (item.price && item.quantity && item.vat) {
        return item.price * item.quantity * (item.vat / 100);
    }
    return 0;
};

export const selectTotal = (items, addVat = false) => {
    let total = 0;
    let itemTotal;
    for (const item of items) {
        itemTotal = selectItemTotalVat(item, addVat);
        if (itemTotal != null) {
            total += itemTotal;
        }
    }
    return total;
};

export const selectVatRates = items => {
    const rates = {};
    const subTotalValues = {};
    for (const item of items) {
        if (!isEmptyText(item.vat)) {
            if (isVoid(rates[item.vat])) {
                rates[item.vat] = 0;
            }
            if (isVoid(subTotalValues[item.vat])) {
                subTotalValues[item.vat] = 0;
            }
            rates[item.vat] += selectItemVat(item, true);
            subTotalValues[item.vat] += selectItemTotal(item);
        }
    }
    return Object.keys(rates, subTotalValues).map(key => ({
        rate: key,
        value: rates[key],
        subTotal: subTotalValues[key],
    }));
};

export const selectMarginRow = row => {
    // let percentage = null;
    // let totalPrice = 0;
    // let totalPurchasePrice = 0;
    //let itemTotal;
    //let itemTotalPurchase;

    const itemTotal = selectItemTotal(row);
    const itemTotalPurchase = selectItemTotalPurchase(row);
    if (itemTotal !== null && itemTotalPurchase !== null) {
        // totalPrice += itemTotal;
        // totalPurchasePrice += itemTotalPurchase;
        // if (totalPrice !== 0) {
        //     percentage = 1 - (totalPurchasePrice / totalPrice);
        //     percentage = formatValuta(percentage * 100);
        // }
        if (itemTotalPurchase !== 0) {
            return formatValuta(
                ((itemTotal - itemTotalPurchase) / itemTotalPurchase) * 100
            );
        }
    }

    return null;
};

export const selectMargin = items => {
    let percentage = null;
    let totalPrice = 0;
    let totalPurchasePrice = 0;
    let itemTotal;
    let itemTotalPurchase;

    for (const item of items) {
        if (!item.group) {
            itemTotal = selectItemTotal(item);
            itemTotalPurchase = selectItemTotalPurchase(item);
            if (itemTotal != null && itemTotalPurchase != null) {
                totalPrice += itemTotal;
                totalPurchasePrice += itemTotalPurchase;
            }
        }
    }

    if (totalPrice !== 0 && totalPurchasePrice !== 0) {
        percentage = formatValuta(
            ((totalPrice - totalPurchasePrice) / totalPurchasePrice) * 100
        );
    }

    return percentage;
};

export const selectBillingFromBillingAccount = ({
    // eslint-disable-next-line camelcase
    legal_name,
    // eslint-disable-next-line camelcase
    billing_address,
    // eslint-disable-next-line camelcase
    vat_number,
}) => {
    return {
        legal_name,
        billing_address,
        vat_number,
    };
};

export const selectShippingAddressFromBillingAccount = ({
    // eslint-disable-next-line camelcase
    shipping_address,
}) => {
    return {
        shipping_address,
    };
};
