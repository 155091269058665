import React, { useCallback, Suspense } from 'react';
import { getStock } from '../../../../../core/modules/stock/actions';
import { useList } from '../../../../../core/hooks/useList';
import Alert from '@material-ui/lab/Alert';
import { t } from '../../../../../core/i18n';
import BaseMaterialTable from '../../../../shared/table/BaseMaterialTable';
import Progress from '../../../../shared/progress/Progress';

//let mapToHtmlLevel = 0;
const mapToHtml = (map, mapToHtmlLevel) => {
    return Object.keys(map)
        .sort()
        .map(key => {
            let str = '';
            if (typeof map[key] !== 'object') {
                str =
                    '<b>' +
                    '-'.repeat(mapToHtmlLevel * 4) +
                    key +
                    '</b>: ' +
                    map[key].toString() +
                    '<br/>';
            } else {
                str =
                    '<b>' +
                    '-'.repeat(mapToHtmlLevel * 4) +
                    key +
                    ': </b><br/>';
                mapToHtmlLevel++;
                str += mapToHtml(map[key], mapToHtmlLevel);
                mapToHtmlLevel--;
            }
            return str;
        })
        .join('');
};

const StockItemOverviewScene = () => {
    const fetchList = useCallback(() => getStock(), []);

    const { items, isLoading, error } = useList(fetchList);

    const DocTagList = React.lazy(() =>
        import('../../../../tagging/DocTagList')
    );

    // Build array for sources filter.
    const lookupValuesSourceCollections = {};
    if (!!items) {
        items.forEach(item => {
            if (
                !!item.stock_source_collection &&
                !lookupValuesSourceCollections.hasOwnProperty(
                    item.stock_source_collection
                )
            ) {
                lookupValuesSourceCollections[item.stock_source_collection] =
                    item.stock_source_collection;
            }
        });
    }

    const headCells = [
        {
            id: 'name',
            title: t('product_stock.fields.name'),
            field: 'name',
            render: rowData => {
                return <b>{rowData.name}</b>;
            },
        },
        {
            id: 'source',
            title: t('product_stock.fields.source'),
            field: 'stock_source_collection',
            render: rowData => rowData.stock_source_collection,
            lookup: lookupValuesSourceCollections,
        },
        {
            id: 'is_recurring',
            title: t('product_stock.fields.is_recurring'),
            field: 'is_recurring_stock',
            render: rowData => (rowData.is_recurring_stock ? 'yes' : 'no'),
            lookup: { true: 'yes', false: 'no' },
        },
        {
            id: 'status',
            title: t('product_stock.fields.status'),
            render: rowData => {
                return '';
            },
        },
        {
            id: 'tags',
            title: t('product_stock.fields.tags'),
            render: rowData => {
                const initialTags =
                    !!rowData.tags && Array.isArray(rowData.tags)
                        ? rowData.tags
                        : [];
                return (
                    <Suspense fallback={<></>}>
                        <DocTagList
                            initialTagIds={initialTags}
                            docId={rowData.id}
                        />
                    </Suspense>
                );
            },
        },
    ];

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <BaseMaterialTable
                            title={t('stock_items.title')}
                            headCells={headCells}
                            items={items}
                            paging={true}
                            handleRowClick={(event, rowData, togglePanel) =>
                                togglePanel()
                            }
                            options={{ filtering: true, search: false }}
                            detailPanel={rowData => {
                                return (
                                    <>
                                        <p>
                                            <b>External data:</b>
                                        </p>
                                        {!!rowData.ext_data && (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: mapToHtml(
                                                        rowData.ext_data,
                                                        0
                                                    ),
                                                }}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    </>
                )}
            </>
        );
    }
};

export default StockItemOverviewScene;
