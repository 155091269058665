import { isEmptyText } from 'core/utils/isEmptyText';
import React from 'react';
import { Text, View } from '@react-pdf/renderer';



const Address = ({ address, styles }) => {
    return (
        <View>
            {!isEmptyText(address.contact_name) && (
                <Text style={styles.addressBody}>
                    {`${address.contact_name}`}

                </Text>
            )}
            {!isEmptyText(address.email) && (
                <Text style={styles.addressBody}>
                    {`${address.email}`}

                </Text>
            )}
            {!isEmptyText(address.street) && (
                <Text style={styles.addressBody}>
                    {`${address.street} ${address.number || ''}`}

                </Text>
            )}
            {!isEmptyText(address.city) && (
                <Text style={styles.addressBody}>
                    {`${address.postal_code || ''} ${address.city || ''}`}

                </Text>
            )}
            {!isEmptyText(address.state) && (
                <Text style={styles.addressBody}>
                    {`${address.state}`}

                </Text>
            )}
            <Text style={styles.addressBody}>
                {!isEmptyText(address.country) && address.country}
            </Text>
        </View>
    )
};

export default Address;
