import React from 'react';
import {
    Card,
    Box
} from '@material-ui/core';
import { t } from '../../../../core/i18n';
import TextFastField from '../generic/TextFastField';
import CountrySelect from '../country/CountrySelect';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    flexBoxItem: {
        marginLeft: '8px',
    },
});

const AddressFields = ({ name, disabled, classes }) => {
    return (
        <Card
            style={{
                padding: '20px 0',
                boxShadow: 'none',
                opacity: disabled ? '0.8' : '1',
            }}>
            <TextFastField
                label={t('address.fields.contact_name')}
                name={`${name}.contact_name`}
                disabled={disabled}
            />
            <TextFastField
                label={t(
                    'address.fields.email'
                )}
                type="email"
                name={`${name}.email`}
                disabled={disabled}
            />
            <Box display="flex">
                <TextFastField
                    label={t('address.fields.street')}
                    name={`${name}.street`}
                    disabled={disabled}
                />
                <TextFastField
                    label={t('address.fields.number')}
                    name={`${name}.number`}
                    disabled={disabled}
                    className={classes.flexBoxItem}
                />
            </Box>
            <Box display="flex">
                <TextFastField
                    label={t('address.fields.postal_code')}
                    name={`${name}.postal_code`}
                    disabled={disabled}
                />
                <TextFastField
                    label={t('address.fields.city')}
                    name={`${name}.city`}
                    disabled={disabled}
                    className={classes.flexBoxItem}
                />
            </Box>
            <TextFastField
                label={t('address.fields.state')}
                name={`${name}.state`}
                disabled={disabled}
            />
            <CountrySelect
                label={t('address.fields.country')}
                name={`${name}.country`}
                disabled={disabled}
            />
        </Card>
    );
};

export default withStyles(styles)(AddressFields);
