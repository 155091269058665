import React, { useMemo, useEffect } from 'react';
import {
    getDefaultRoute,
    getMainRoutes,
    ROUTE_ACCOUNTING,
    ROUTE_GUEST,
    ROUTE_AUTH,
    ROUTE_CLIENTS,
    ROUTE_PROJECTS,
    ROUTE_ADMINISTRATION,
    ROUTE_STOCK,
    ROUTE_SUPER_ADMIN,
    ROUTE_COMPLETE_REGISTER_ON_INVITE,
    ROUTE_REGISTER_ON_INVITE,
    ROUTE_USER,
    ROUTE_API_CENTER,
    historyLogging,
    history,
    ROUTE_WORKSPACE_CLIENTS,
} from '../../core/routing/routing';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import {
    CssBaseline,
} from '@material-ui/core';
import Header from '../header/Header';
import { withStyles } from '@material-ui/core/styles';
import AccountingScene from './accounting/AccountingScene';
import StockScene from './stock/StockScene';
import Sidebar from '../sidebar/Sidebar';
import VerificationAlert from '../alert/VerificationAlert';
import Notifications from '../notification/Notifications';
import { useSelector, useDispatch } from 'react-redux';
import GuestScene from '../guest/GuestScene';
import { WorkspaceProvider } from '../workspace/WorkspaceProvider';
import ProjectsScene from './projects/ProjectsScene';
import WorkspaceScene from './workspace/WorkspaceScene';
import ClientsScene from './clients/ClientsScene';
import AdministrationScene from './administration/AdministrationScene';
import UserManagementScene from '../onboarding/userManagement/UserManagementScene';
import AdminScene from 'components/app/admin/AdminScene';
import { AuthStates, Roles } from 'core/modules/auth/constants';
import CompleteRegisterAlert from 'components/alert/CompleteRegisterAlert';
import CompleteRegisterScene from 'components/onboarding/invite/CompleteRegisterScene';
import CompleteInviteScene from 'components/onboarding/invite/CompleteInviteScene';
import { setLocalStorage } from 'core/modules/app/reducer';
import { LocalStorage } from 'core/storage/LocalStorage';
import { isEmptyText } from 'core/utils/isEmptyText';
import ApiCenterScene from './apicenter/ApiCenterScene';
import UserScene from './user/UserScene';

const styles = theme => ({
    paper: {
        //maxWidth: 936,
        maxWidth: 876,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
    btnGoogle: {
        backgroundColor: '#777',
        marginTop: 10,
        marginBottom: 30,
    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
});

const getComponentByRoute = route => {
    switch (route.path) {
        case ROUTE_ADMINISTRATION:
            return AdministrationScene;

        case ROUTE_CLIENTS:
            return ClientsScene;

        case ROUTE_ACCOUNTING:
            return AccountingScene;

        case ROUTE_STOCK:
            return StockScene;

        case ROUTE_API_CENTER:
            return ApiCenterScene;

        case ROUTE_PROJECTS:
            return ProjectsScene;

        case ROUTE_WORKSPACE_CLIENTS:
            return WorkspaceScene;

        case ROUTE_AUTH:
            return UserManagementScene;

        case ROUTE_SUPER_ADMIN:
            return AdminScene;

        case ROUTE_COMPLETE_REGISTER_ON_INVITE:
            return CompleteRegisterScene;

        case ROUTE_REGISTER_ON_INVITE:
            return CompleteInviteScene;

        case ROUTE_USER:
            return UserScene;

        case ROUTE_GUEST:
            return GuestScene;

        default:
            return UserScene;
    }
};

const AppContainer = props => {
    const dispatch = useDispatch();
    const { workspaceId, clientId, projectId } = useParams();
    //console.log('useparams appContainer:', workspaceId || "not found");

    const { classes } = props;
    const userWorkspace = useSelector(
        state => state.auth.user.workspace_agency
    );
    const userWorkspaceClient = useSelector(state => state.auth.user.client_id);
    const user = useSelector(state => state.auth.user);

    const isAdmin = useSelector(state => state.auth.user.role === Roles.Admin);

    const isUser = useSelector(state => state.auth.user.role === Roles.User);
    const isAuthenticated = useSelector(
        state => state.auth.status === AuthStates.LoggedIn && !!state.auth.user
    );

    useEffect(() => {
        const action = {};
        action.workspace = (!!workspaceId && (workspaceId !== "0") && (workspaceId !== "_")) ? workspaceId : (LocalStorage.getWorkspace() !== "login" ? LocalStorage.getWorkspace() : "");
        action.workspace_client = !!clientId ? clientId : LocalStorage.getWorkspaceClient();

        if (
            isEmptyText(action.workspace) &&
            isEmptyText(action.workspace_client)
        ) {
            if (!!userWorkspace) {
                action.workspace = userWorkspace;
            } else {
                action.workspace_client = userWorkspaceClient;
            }
        }
        action.project = !!projectId ? projectId : LocalStorage.getProject();
        dispatch(setLocalStorage(action));

        if (isAuthenticated) {
            history.listen((location, action) => {
                dispatch(historyLogging(location.pathname));
            })
        }
    }, [dispatch, userWorkspace, userWorkspaceClient, isAuthenticated, workspaceId]);

    const isFullyRegistered = useSelector(
        state => state.auth.user.isFullyRegistered
    );
    // since this is a top container, we'll memo this value
    const routes = useMemo(() => {
        return getMainRoutes(user, workspaceId);
    }, [user, workspaceId]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <WorkspaceProvider>
                {isFullyRegistered && (isAdmin || isUser) && <Sidebar />}
            </WorkspaceProvider>
            <div className={classes.app}>
                <VerificationAlert />
                <CompleteRegisterAlert />
                <Header />
                <WorkspaceProvider>
                    <Switch>
                        {routes.map(route => (
                            <Route
                                key={route.path}
                                path={route.path}
                                component={getComponentByRoute(route)}
                            />
                        ))}
                        <Redirect to={getDefaultRoute(user)} />
                    </Switch>
                </WorkspaceProvider>

                <Notifications />
            </div>
        </div>
    );
};

export default withStyles(styles)(AppContainer);
