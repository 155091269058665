import React from 'react';
import ClientUsersFormContainer from './ClientUsersFormContainer';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'core/modules/app/selectors';

const ClientUsersFormScene = ({ onSuccess }) => {
    const clientId = useSelector(state => selectCurrentClientId(state));

    return (
        <>
            <ClientUsersFormContainer id={clientId} onSuccess={onSuccess} />
        </>
    )
}

export default ClientUsersFormScene;