import { useDrag, useDrop } from 'react-dnd';
import React, { useRef } from 'react';
import {
    TableRow,
    TableCell
} from '@material-ui/core';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';

const DraggableRow = ({ id, index, onMove, children, ...props }) => {
    const ref = useRef(null);

    // see https://react-dnd.github.io/react-dnd/examples/sortable/simple
    const [, drop] = useDrop({
        accept: 'row',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    // const [{ isDragging }, drag, preview] = useDrag({
    //     item: { type: 'row', id, index },
    //     collect: monitor => ({
    //         isDragging: monitor.isDragging(),
    //     }),
    // });
    //
    // drag(drop(preview(ref)));

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'row', id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));

    return (
        <TableRow style={{ opacity: isDragging ? 0 : 1 }} ref={ref} {...props}>
            <TableCell
                padding="none"
            >
                <div
                    ref={drag}
                    style={{
                        display: 'block',
                        cursor: 'move',
                    }}>
                    <DragIndicatorIcon />
                </div>
            </TableCell>
            {children}
        </TableRow>
    );
};

export default DraggableRow;
