import React, { useState } from 'react';
import { t } from '../../../core/i18n';
import {
    IconButton,
    Typography,
    TextField,
    Button,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { DocStatus } from 'core/modules/auth/constants';

const styles = () => ({
    closeButton: {
        //position: 'absolute',
        // right: 10,
        // top: 10,
    },
    deleteAlert: {
        marginBottom: 20
    }
});

const DeleteDialog = ({ open, onCancel, onConfirm, item, hardDelete, classes }) => {
    const [enabled, setEnabled] = useState(false);
    const restoreDeletion = item?.meta?.doc_status === DocStatus.SoftDeleted ? true : false;
    // Name string to uppercase and remove spaces
    const upperCaseName = (!!item && (typeof item?.name === "string")) ? item?.name.toUpperCase().replace(/ /g, '') : "DELETE";

    const handleChange = (e) => {
        if (upperCaseName === e.target.value) {
            setEnabled(true);
        } else {
            if (enabled === true) {
                setEnabled(false);
            }
        }
    };

    return (
        !!upperCaseName && (
            <Dialog
                open={open}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle color="secondary" id="alert-dialog-title">
                    {t(restoreDeletion ? 'delete.restore.title' : 'delete.title')}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {!!hardDelete && (
                        <Alert severity="warning" className={classes.deleteAlert}>{t('delete.alert')}</Alert>
                    )}
                    <DialogContentText variant="body1" component="div" id="alert-dialog-description">
                        {t(restoreDeletion ? 'delete.restore.description' : 'delete.description')}
                    </DialogContentText>
                    {!!hardDelete && (
                        <>
                            <Typography variant="h6" component="div">
                                {t('delete.type_commando.intro') + upperCaseName + t('delete.type_commando.outro')}
                            </Typography>
                            <TextField
                                id="checkField"
                                size="small"
                                name="name"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                onPaste={e => {
                                    e.preventDefault();
                                    return false;
                                }}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions
                    disableSpacing={true}>
                    <Button
                        onClick={onConfirm}
                        color="secondary"
                        variant="contained"
                        fullWidth={true}
                        disabled={!!hardDelete ? !enabled : false}
                    >
                        {t('delete.submit')}
                    </Button>
                </DialogActions>
            </Dialog>
        ));
};

export default withStyles(styles)(DeleteDialog);
