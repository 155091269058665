export const selectCurrentLocale = state => {
    return state.app.locale;
};

export const selectCurrentClientId = state => {
    return state.app.workspace_client || "";
};

export const selectCurrentWorkspaceId = state => {
    return state.app.workspace || "";
};

export const selectCurrentAppLocation = state => {
    return {
        workspace: state.app.workspace || "",
        client_workspace: state.app.client_workspace || "",
        project: state.app.project || "",
    };
};