import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Progress from '../../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import { getErrorMessage } from '../../../../../core/networking/utils';
import { useParams } from 'react-router-dom';
import { getInvoiceById } from '../../../../../core/modules/accounting/invoicing/actions';
import InvoicingDetail from './InvoicingDetail';
import InvoicingEventsScene from '../events/InvoicingEventsScene';

const InvoicingDetailScene = () => {
    const dispatch = useDispatch();
    const { type, id } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [item, setItem] = useState(null);
    const [error, setError] = useState(null);

    const fetchInvoice = useCallback(() => {
        dispatch(getInvoiceById(type, id))
            .then(item => {
                setItem(item);
            })
            .catch(error => {
                setError(getErrorMessage(error));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, type, dispatch]);

    useEffect(() => {
        fetchInvoice();
    }, [id, type, dispatch, fetchInvoice]);

    const handleUpdate = () => {
        fetchInvoice();
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {item && (
                    <>
                        <InvoicingDetail
                            type={type}
                            item={item}
                            onUpdate={handleUpdate}
                        />
                        <InvoicingEventsScene
                            type={type}
                            item={item}
                        />
                    </>
                )}
            </>
        );
    }
};

export default InvoicingDetailScene;
