import React, { useMemo } from 'react';
import { getUserById } from 'core/modules/users/actions';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from '@material-ui/core';
import { t } from 'core/i18n';
import { Permissions } from 'core/modules/auth/constants';
import BaseFormContainer from '../../../shared/form/BaseFormContainer';
import { Formik, Form } from 'formik';
import { useForm } from 'core/hooks/useForm';
import * as yup from 'yup';
import Select from '../../../shared/form/generic/Select';
import Alert from '@material-ui/lab/Alert';
import { isEmptyObject } from 'core/utils/isEmptyObject';

const UserRoleSchema = yup.object().shape({
    permission: yup.string().required(),
});

const UserPermissionDialogContainer = ({ openDialog, onCancel, rowData, onSuccess, storeFunction, spaceId }) => {
    const config = useMemo(
        () => ({
            fetch: () => getUserById(rowData.id),
            store: values => storeFunction(spaceId, values),
            defaultValue: false,
            onSuccess,
            id: rowData.id,
        }),
        [rowData.id, spaceId, onSuccess, storeFunction]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);
    return (
        <>
            {error && <Alert severity="error">{error}</Alert>}
            {!isEmptyObject(initialData) && (
                <BaseFormContainer
                    isLoading={isLoading}
                    error={error}
                    initialData={rowData}
                    render={() => (
                        <Formik
                            validationSchema={UserRoleSchema}
                            initialValues={rowData}
                            onSubmit={onSubmit}>
                            {({ isSubmitting, submitForm, handleChange, setFieldValue }) => (
                                <Dialog
                                    open={openDialog}
                                    onClose={onCancel}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="sm"
                                    fullWidth={true}>
                                    <DialogTitle>
                                        <Typography component="p" variant="body1">{!!initialData.full_name ? initialData.full_name : initialData.first_name + " " + initialData.last_name}</Typography>
                                        <Typography component="p" variant="body1">{initialData.email}</Typography>
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <Form>
                                            <Select
                                                label={t(
                                                    'users.fields.permission'
                                                )}
                                                //value={!!data.permission ? data.permission : ""}
                                                //onChange={handleChange}
                                                name="permission"
                                                radioOptions={Object.keys(Permissions).map(
                                                    key => ({
                                                        value: Permissions[key],
                                                        label: t(`users.permissions.${Permissions[key]}.title`),
                                                    })
                                                )}
                                            />
                                        </Form>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={onCancel}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                            color="primary"
                                        >
                                            {t('actions.roles.update')}
                                        </Button>
                                    </DialogActions>

                                </Dialog>

                            )}
                        </Formik>
                    )
                    } />
            )}
        </>
    )
}

export default UserPermissionDialogContainer;