// https://stackoverflow.com/questions/48096063/cloud-firestore-case-insensitive-sorting-using-query
export const caseFoldNormalize = (string) => {
    return string
        .normalize('NFKC')
        .toLowerCase()
        .toUpperCase()
        .toLowerCase();
}

export const caseFoldDocument = (doc) => {
    if (doc != null) {
        for (const field in doc) {
            if (doc.hasOwnProperty(field)) {
                if (
                    doc.hasOwnProperty(field) &&
                    Object.prototype.toString.call(doc[field]) ===
                    '[object String]'
                ) {
                    doc[field] = caseFoldNormalize(doc[field]);
                }
            }
        }
    }
    return doc;
}