import React, { useMemo } from 'react';
import * as yup from 'yup';
import {
    Paper,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from '../../../../../core/i18n';
import {
    getProductById,
    storeProduct,
} from '../../../../../core/modules/accounting/products/actions';
import { nullableNumber } from '../../../../../core/utils/yup';
import { getProductGroups } from '../../../../../core/modules/accounting/products/groups/actions';
import TextField from '../../../../shared/form/generic/TextField';
import AutoComplete from '../../../../shared/form/generic/AutoComplete';
import { Formik, Form } from 'formik';
import SupplierAutoComplete from '../../../../shared/form/supplier/SupplierAutoComplete';
import BaseFormContainer from '../../../../shared/form/BaseFormContainer';
import { useForm } from '../../../../../core/hooks/useForm';
import TranslatableTextField from '../../../../shared/form/generic/TranslatableTextField';
import { useSelector } from 'react-redux';
import { selectCurrentWorkspace } from '../../../../../core/modules/workspaces/selectors';
import VatSelect from '../../../../shared/form/vat/VatSelect';

// let's keep this out of the render to avoid useMemo
const Schema = yup.object().shape({
    name: yup.object().shape({
        en: yup.string().required(),
    }),
    description: yup.object().shape({
        en: yup.string().max(200),
    }),
    price: yup.number().transform(nullableNumber),
    purchase_price: yup.number().transform(nullableNumber),
    vat: yup
        .number()
        .transform(nullableNumber)
        .integer(),
});

const styles = () => ({
    paper: {
        padding: 20,
    },
});

const prepareData = original => {
    return original;
};

const ProductsFormContainer = ({ classes, id, onSuccess }) => {
    const currentWorkspace = useSelector(state => selectCurrentWorkspace(state));
    const workspaceAccounting = currentWorkspace?.accounting || "";
    const config = useMemo(
        () => ({
            fetch: () => getProductById(id),
            store: values => storeProduct(prepareData(values)),
            defaultValue: {
                name: {
                    en: '',
                },
                description: {
                    en: '',
                },
                vat: workspaceAccounting.vat_default || "",
                currency: workspaceAccounting.currency || "",
            },
            onSuccess,
            id,
        }),
        [id, onSuccess, workspaceAccounting]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        !!initialData && (
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <Paper className={classes.paper}>
                        <Formik
                            initialValues={initialData}
                            validationSchema={Schema}
                            onSubmit={onSubmit}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <TranslatableTextField
                                        label={t('products.fields.name')}
                                        name="name"
                                    />

                                    <TranslatableTextField
                                        label={t('products.fields.description')}
                                        name="description"
                                        multiline
                                        rows="2"
                                    />
                                    <TextField
                                        label={t('products.fields.price')}
                                        type="number"
                                        name="price"
                                    />
                                    <VatSelect
                                        label={t('products.fields.vat')}
                                        type="number"
                                        name="vat"
                                    />
                                    <TextField
                                        label={t('products.fields.purchase_price')}
                                        type="number"
                                        name="purchase_price"
                                    />
                                    <SupplierAutoComplete
                                        label={t('products.fields.supplier')}
                                        name="supplier"
                                    />
                                    <AutoComplete
                                        label={t('products.fields.product_group')}
                                        name="product_group"
                                        performQuery={getProductGroups}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}>
                                        {t(
                                            id ? 'actions.update' : 'actions.create'
                                        )}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}
            />
        )
    );
};

export default withStyles(styles)(ProductsFormContainer);
