import { Collections, fireStore } from '../../networking/firebase';
import { getDocData } from '../../networking/utils';
import { selectCurrentWorkspaceId } from '../app/selectors';

export const fetchProjectLogConnectors = async workspace => {
    return await fireStore
        .collection(Collections.project_log_connector)
        .where('workspace_agency', '==', workspace)
        .orderBy('query_fields.name', 'asc')
        .get()
        .then(resp => {
            return resp.docs.map(doc => getDocData(doc));
        });
};

export const getProjectLogConnectors = () => async (dispatch, getState) => {
    const items = await fetchProjectLogConnectors(
        selectCurrentWorkspaceId(getState())
    );
    return items;
};
