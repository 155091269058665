import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormHelperText
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useField, useFormikContext } from 'formik';
import moment, { isMoment } from 'moment';
import { useSelector } from 'react-redux';
import { isTimestamp } from 'core/utils/formatTime';

const timeFormatter = (date, dateFormat) => {
    return isTimestamp(date) ?
        (moment(date.toDate()).format(dateFormat || "DD/MM/YYYY") || null) :
        (isMoment(date) ? moment(date).format(dateFormat || "DD/MM/YYYY") : null)
}

const DatePicker = (props) => {
    const { name, label, inputValue } = props;
    const dateFormat = useSelector(state => state.auth.user.preferences?.date_format || "")
    const [, meta] = useField(name);
    const { setFieldValue } = useFormikContext();
    const [selectedDate, setDate] = useState(null);
    const [newInputValue, setInputValue] = useState(timeFormatter(inputValue, dateFormat) || null);


    const onChangeFunction = (date, value) => {
        setDate(date);
        setInputValue(value);
        setFieldValue(name, date?.toDate());
    };

    useEffect(() => {
        setInputValue(timeFormatter(inputValue, dateFormat) || null);
    }, [inputValue]);

    return (
        <FormControl style={{ width: '100%' }}>
            <KeyboardDatePicker
                onChange={onChangeFunction}
                disableToolbar
                fullWidth
                variant="inline"
                inputVariant="outlined"
                autoOk={true}
                format={dateFormat || "DD/MM/YYYY"}
                margin="dense"
                label={label}
                name={name}
                value={selectedDate}
                {...props}
                inputValue={newInputValue || null}
            />
            {!!meta.error && meta.touched && (
                <FormHelperText error={true}>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default DatePicker;
