import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from 'components/App';
import { history } from './core/routing/routing';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rootReducers } from './core/modules';
import { setupAuth } from './core/modules/auth/actions';
import { initLocalizations } from './core/i18n';
import CacheBuster from 'core/cache/CacheBuster';
import packageJson from '../package.json';
global.appVersion = packageJson.version;

initLocalizations();

Sentry.init({
    dsn: "https://b3cbe66b740c49f998d8c5cb1530a14d@o474700.ingest.sentry.io/5511386",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    release: `${process.env.REACT_APP_TITLE}@` + global.appVersion,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

const store = configureStore({
    reducer: rootReducers,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});

// setup auth
store.dispatch(setupAuth());

ReactDOM.render(
    <CacheBuster>
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    </CacheBuster>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
