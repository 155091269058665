import React, { useCallback } from 'react';
import { t } from 'core/i18n';
import {
    Button,
    Chip,
    Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
    getProjectLogs,
    deleteProjectLog,
} from 'core/modules/dashboard/projects/logs/actions';
import { useList } from 'core/hooks/useList';
import Progress from '../../../../../shared/progress/Progress';
import BaseMaterialTable from '../../../../../shared/table/BaseMaterialTable';
import Alert from '@material-ui/lab/Alert';
import {
    secondsToMinutesHours,
} from 'core/utils/formatTime';
import { nl2br } from 'core/utils/formatText';
import {
    history,
    route,
    ROUTE_PROJECT_LOGS_ADD,
    ROUTE_PROJECT_LOGS_EDIT,
} from 'core/routing/routing';
import { getLogLabelByType } from 'core/modules/dashboard/projects/logs/constants';
import { withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { checkNestedProperty } from '../../../../../../core/utils/objectHelpers';
import { Can } from 'components/shared/can/Can';
import { CRUD } from 'core/modules/auth/constants';
import { Collections } from 'core/networking/firebase';
import { useSelector } from 'react-redux';
import DateFormat from 'components/shared/form/generic/DateFormat';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    },
});

const ProjectLogsOverview = ({ classes, project }) => {
    const workspaceId = useSelector(state => state.app.workspace);
    const fetchList = useCallback(() => {
        return getProjectLogs(Collections.project_logs, project);
    }, [project]);

    const { items, isLoading, error, refreshList, canSoftDelete, canDelete, canEdit } = useList(
        fetchList, Collections.project_logs
    );

    const totalSeconds = items => {
        let result = 0;
        items.forEach(item => {
            if (!!item.amount_time) {
                result += item.amount_time;
            }
        });
        return result;
    };

    const labelRender = item => {
        if (item.labels) {
            return item.labels.map((label, index) => {
                let labelValue = label;
                const logLabel = getLogLabelByType(labelValue);
                if (!!logLabel) {
                    labelValue = logLabel.text;
                }
                return (
                    <Chip
                        label={labelValue}
                        size="small"
                        key={index}
                        style={{
                            marginRight: '2px',
                        }}
                    />
                );
            });
        }
        return <></>;
    };

    const headCells = [
        {
            id: 'date',
            title: t('projects.logs.fields.date'),
            field: 'log_date',
            render: rowData => <DateFormat date={rowData.log_date} />,
        },
        {
            id: 'user',
            title: t('projects.logs.fields.user'),
            render: rowData => {
                if (
                    rowData.log_type === 'system_entry' &&
                    checkNestedProperty(rowData, 'user', 'name')
                ) {
                    return rowData.user.name;
                }
                if (
                    rowData.log_type === 'external' &&
                    checkNestedProperty(rowData, 'external', 'user_name')
                ) {
                    return rowData.external.user_name;
                }
                return '';
            },
        },
        {
            id: 'desc',
            title: t('projects.logs.fields.description'),
            field: 'description',
            render: rowData => (
                <>
                    <b>{nl2br(rowData.title)}</b>
                    <br />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: nl2br(rowData.description),
                        }}
                    />
                </>
            ),
        },
        {
            id: 'labels',
            title: '',
            field: 'labels',
            render: rowData => labelRender(rowData),
        },
        {
            id: 'time',
            title: t('projects.logs.fields.time'),
            field: 'amount_time',
            render: rowData => secondsToMinutesHours(rowData.amount_time),
        },
    ];

    const menuActions = [
        {
            action: 'edit',
            to: ROUTE_PROJECT_LOGS_EDIT,
            routeParam: "logId",
            routeParamExtra: { projectId: project.id, workspaceId: workspaceId }
        },
        {
            action: 'delete',
        },
        {
            action: 'deleteForever',
        },
        {
            action: 'restoreDelete',
        },
    ]

    const handleRowClick = (event, rowData) => {
        history.push(route(ROUTE_PROJECT_LOGS_EDIT, { workspaceId, logId: rowData.id, projectId: project.id }))
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            className={classes.addBtn}>
                            <h2>
                                Total:{' '}
                                {secondsToMinutesHours(totalSeconds(items))}
                            </h2>
                            <div>
                                {(() => {
                                    if (
                                        checkNestedProperty(
                                            project,
                                            'integrations',
                                            'log_connectors',
                                            'data'
                                        ) &&
                                        Array.isArray(
                                            project.integrations.log_connectors
                                                .data
                                        ) &&
                                        project.integrations.log_connectors.data
                                            .length > 0
                                    ) {
                                        return (
                                            <>
                                                <p>
                                                    Connected log integrations:
                                                </p>
                                                <ul>
                                                    {project.integrations.log_connectors.data.map(
                                                        (conn, i) => (
                                                            <li key={i}>
                                                                {conn.name}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </>
                                        );
                                    }
                                })()}
                            </div>
                            <Can i={CRUD.Create} the={Collections.project_logs}>
                                <Button
                                    component={Link}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    to={route(ROUTE_PROJECT_LOGS_ADD, {
                                        projectId: project.id,
                                        workspaceId
                                    })}>
                                    {t('projects.logs.add')}
                                </Button>
                            </Can>
                        </Grid>
                        <BaseMaterialTable
                            title={t('projects.logs.title')}
                            headCells={headCells}
                            items={items}
                            paging={true}
                            canEdit={canEdit}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                            menuActions={menuActions}
                            deleteItemFunc={deleteProjectLog}
                            refreshList={refreshList}
                            collection={Collections.project_logs}
                            handleRowClick={handleRowClick}
                        /*options={{
                            rowStyle: rowData => ({
                                background:
                                    rowData.log_type === 'system_entry'
                                        ? 'repeating-linear-gradient( -45deg, #eee, #eee 2px, #fff 2px, #fff 4px )'
                                        : '',
                            }),
                        }}*/
                        />
                    </>
                )}
            </>
        );
    }
};

export default withStyles(styles)(ProjectLogsOverview);
