import React, { useCallback } from 'react';
import {
    ROUTE_USER_OVERVIEW,
    history
} from 'core/routing/routing';
import { useSelector } from 'react-redux';
import UserFormContainer from './UserFormContainer';

const UserFormScene = () => {
    const user = useSelector(state => state.auth.user);

    const handleSuccess = useCallback(() => {
        history.push(ROUTE_USER_OVERVIEW);
    }, []);
    return (
        <>
            <UserFormContainer
                user={user}
                onSuccess={handleSuccess}
            />
        </>
    )
}

export default UserFormScene;