import React from 'react';
import { useSelector } from 'react-redux';
import UserPreferencesFormContainer from './UserPreferencesFormContainer';

const UserPreferencesFormScene = () => {
    const app = useSelector(state => state.app);
    const user = useSelector(state => state.auth.user);

    return (
        <>
            <UserPreferencesFormContainer
                user={user}
                app={app}
            />
        </>
    )
}

export default UserPreferencesFormScene;