import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../../../core/i18n';
import {
    Button,
    Grid
} from '@material-ui/core';
import { useList } from '../../../../core/hooks/useList';
import Progress from '../../../shared/progress/Progress';
import BaseMaterialTable from '../../../shared/table/BaseMaterialTable';
import Alert from '@material-ui/lab/Alert';
import {
    route,
    ROUTE_WORKSPACE_CLIENTS_ADD,
    history,
    ROUTE_CLIENT_PROJECTS,
} from '../../../../core/routing/routing';
import { Link, useParams } from 'react-router-dom';
import {
    getClients,
    getUserClients,
    getClientById,
} from '../../../../core/modules/dashboard/clients/actions';
import { setCurrentWorkspaceClient } from 'core/modules/dashboard/clients/reducer';
import { Add as AddIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { isEmptyText } from 'core/utils/isEmptyText';
import { setLocalStorage } from 'core/modules/app/reducer';
import { CRUD, Roles } from 'core/modules/auth/constants';
import { Can } from 'components/shared/can/Can';
import { Collections } from 'core/networking/firebase';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    }
});

const ClientsOverviewScene = ({ classes }) => {
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const { client_roles } = useSelector(state => state.auth.user);

    const isAdmin = useSelector(state => state.auth.user.role === Roles.Admin);
    const workspaceRoles = useSelector(state => state.auth.user.workspace_roles);
    const workspace_agency = useSelector(state => state.app.workspace);
    const getFunction = isEmptyText(workspaceRoles[workspace_agency]) ?
        (isAdmin ? getClients : getUserClients) :
        (!!workspaceRoles[workspace_agency] ? getClients : getUserClients);

    const { items, isLoading, error } = useList(
        getFunction
    );

    let clients = [];
    let filteredClients = [];
    if (!!items) {
        clients = items.map((item) =>
            Object.assign({}, item, { selected: false })
        );
        // Filter workspaces array on duplicate agencies
        if (isAdmin) {
            filteredClients = clients;
        } else {
            filteredClients = clients.filter(client => client.workspace_agency === workspace_agency)
        }
    }

    const handleRowClick = (event, rowData) => {
        const action = {}
        dispatch(getClientById(rowData.id))
            .then(client => {
                action.client_id = rowData.id;
                action.client = client;
                action.permission = client_roles[rowData.id];
                dispatch(setCurrentWorkspaceClient(action));
                action.workspace_client = rowData.id;
                action.workspace = client.workspace_agency;
                /*if (!!workspaceRoles[client.workspace_agency] || !!isAdmin) {
                    action.workspace = client.workspace_agency;
                }*/
                dispatch(setLocalStorage(action));

            })
        history.push(route(ROUTE_CLIENT_PROJECTS, { workspaceId, clientId: rowData.id }));
    };

    const headCells = [
        {
            id: 'name',
            title: t('clients.fields.name'),
            field: 'name',
            render: rowData => rowData.name,
        },
    ];

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {filteredClients && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Can i={CRUD.Create} the={Collections.clients}>
                                <Button
                                    component={Link}
                                    variant="contained"
                                    color="primary"
                                    className={classes.addBtn}
                                    startIcon={<AddIcon />}
                                    to={route(ROUTE_WORKSPACE_CLIENTS_ADD, { workspaceId })}>
                                    {t('clients.add')}
                                </Button>
                            </Can>
                        </Grid>
                        <BaseMaterialTable
                            title={t('clients.title')}
                            headCells={headCells}
                            items={filteredClients}
                            handleRowClick={handleRowClick}
                        />
                    </>
                )
                }
            </>
        );
    }
};

export default withStyles(styles)(ClientsOverviewScene);
