import React from 'react';
import { Container } from '@material-ui/core';

const AppContent = ({ children }) => (
    <Container fixed style={{ marginTop: '24px' }}>
        {children}
    </Container>
);

export default AppContent;
