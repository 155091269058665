import React from 'react';
import {
    deleteProduct,
    getProducts,
} from '../../../../../core/modules/accounting/products/actions';
import Progress from '../../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import { t } from '../../../../../core/i18n';
import {
    Button,
    Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
    history,
    route,
    ROUTE_PRODUCTS_ADD,
    ROUTE_PRODUCTS_EDIT,
} from '../../../../../core/routing/routing';
import { useList } from '../../../../../core/hooks/useList';
import { tf } from '../../../../../core/i18n/localization';
import { Currencies } from '../../../../../core/modules/currency/constants';
import BaseMaterialTable from '../../../../shared/table/BaseMaterialTable';
import { withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectCurrentLocale } from 'core/modules/app/selectors';
import { Collections } from 'core/networking/firebase';
import NumberFormat from 'components/shared/form/generic/NumberFormat';

const styles = () => ({
    addBtn: {
        marginBottom: 20,
    }
});

const ProductsOverviewScene = ({ classes }) => {
    const currentLocale = useSelector(state => selectCurrentLocale(state));
    const workspaceId = useSelector(state => state.app.workspace);

    const { items, isLoading, error, refreshList, canSoftDelete, canDelete, canEdit } = useList(
        getProducts, Collections.products
    );

    const headCells = [
        {
            id: 'name',
            title: t('products.fields.name'),
            field: `name.${currentLocale}`,
            render: rowData => tf(rowData.name),
        },
        {
            id: 'name',
            title: t('products.fields.price'),
            field: 'price',
            render: rowData => <NumberFormat currency={Currencies[rowData.currency]} number={rowData.price || 0} />
        },
        {
            id: 'name',
            title: t('products.fields.vat'),
            field: 'vat',
            render: rowData => rowData.vat && `${rowData.vat}%`,
        },
        {
            id: 'name',
            title: t('products.fields.purchase_price'),
            field: 'purchase_price',
            render: rowData => <NumberFormat currency={Currencies[rowData.currency]} number={rowData.purchase_price || 0} />
        },
        {
            id: 'name',
            title: t('products.fields.supplier'),
            field: 'supplier.name',
            render: rowData => rowData.supplier
                ? rowData.supplier.name
                : '',
        },
        {
            id: 'name',
            title: t('products.fields.product_group'),
            field: 'product_group.name',
            render: rowData => rowData.product_group
                ? rowData.product_group.name
                : '',
        },
    ];

    const menuActions = [
        {
            action: 'edit',
            to: ROUTE_PRODUCTS_EDIT,
            routeParam: 'id',
            routeParamExtra: { workspaceId: workspaceId }
        },
        {
            action: 'delete',
        },
        {
            action: 'deleteForever',
        },
        {
            action: 'restoreDelete',
        },
    ]

    const handleRowClick = (event, rowData) => {
        history.push(route(ROUTE_PRODUCTS_EDIT, { workspaceId, id: rowData.id }))
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.addBtn}
                        >
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                to={route(ROUTE_PRODUCTS_ADD, { workspaceId })}>
                                {t('products.add')}
                            </Button>
                        </Grid>
                        <BaseMaterialTable
                            title={t('products.title')}
                            headCells={headCells}
                            items={items}
                            canEdit={canEdit}
                            handleRowClick={handleRowClick}
                            canSoftDelete={canSoftDelete}
                            canDelete={canDelete}
                            deleteItemFunc={deleteProduct}
                            menuActions={menuActions}
                            refreshList={refreshList}
                            collection={Collections.products}
                        />
                    </>
                )}
            </>
        );
    }
};

export default withStyles(styles)(ProductsOverviewScene);
