import { t } from '../../../../i18n';

export const LogTypes = Object.freeze({
    MarketingHub: 'marketing_hub_log',
    TimeLog: 'time_log',
});

export const getLogLabelByType = type => {
    let logLabel = null;
    Object.keys(LogLabels).forEach(LogLabelKey => {
        if (LogLabels[LogLabelKey].type === type) {
            logLabel = LogLabels[LogLabelKey];
        }
    });

    return logLabel;
};

export const LogLabels = Object.freeze({
    Administration: {
        type: 'administration',
        get text() {
            return t('projects.logs.fields.labels.' + this.type);
        },
        color: '#7d7',
    },
    TimeCorrection: {
        type: 'time_correction',
        get text() {
            return t('projects.logs.fields.labels.' + this.type);
        },
        color: '#7d7',
    },
    Note: {
        type: 'note',
        get text() {
            return t('projects.logs.fields.labels.' + this.type);
        },
        color: '#dd7',
    },
    Survey: {
        type: 'survey',
        get text() {
            return t('projects.logs.fields.labels.' + this.type);
        },
        color: '#dd7',
    },
});
