import React from 'react';
import Notification from './Notification';
import { useSelector } from 'react-redux';

const Notifications = () => {
    const notifications = useSelector(state => state.notifications);

    return (
        <>
            {notifications.map(notification => (
                <Notification
                    key={notification.id}
                    notification={notification}
                />
            ))}
        </>
    );
};

export default Notifications;
