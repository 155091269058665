import {
    TableCell,
    TableRow
} from '@material-ui/core';
import React from 'react';

const GroupRow = ({ item }) => {
    return (
        <TableRow selected>
            <TableCell component="th" scope="row">
                <p style={{ fontWeight: 'bold', margin: 0, whiteSpace: 'pre-wrap' }}>{item.name}</p>
            </TableCell>
            <TableCell colSpan="5" />
        </TableRow>
    );
};

export default GroupRow;
