import React from 'react';
import { useParams } from 'react-router-dom';
import { history, ROUTE_BILLING_ACCOUNTS, ROUTE_BILLING_ACCOUNTS_DETAIL, ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL } from 'core/routing/routing';
import BillingAccountsFormContainer from './BillingAccountsFormContainer';
import {
    route,
} from 'core/routing/routing';

const BillingAccountsFormScene = () => {
    const { billingId, clientId, workspaceId } = useParams();

    const handleSuccess = () => {
        if (!!clientId && !!billingId) {
            history.push(route(ROUTE_CLIENT_BILLING_ACCOUNTS_DETAIL, { workspaceId, billingId, clientId }))
        } else if (!!billingId) {
            history.push(route(ROUTE_BILLING_ACCOUNTS_DETAIL, { workspaceId, billingId }))
        } else {
            history.push(route(ROUTE_BILLING_ACCOUNTS, { workspaceId }))
        }
    };

    return (
        <BillingAccountsFormContainer
            onSuccess={handleSuccess}
        />
    );
};

export default BillingAccountsFormScene;
