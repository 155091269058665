import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import DateFormat from 'components/shared/form/generic/DateFormat';
import Progress from 'components/shared/progress/Progress';
import BaseMaterialTable from 'components/shared/table/BaseMaterialTable';
import { useList } from 'core/hooks/useList';
import { t } from 'core/i18n';
import { getEvents } from 'core/modules/users/event/actions';
import React, { useCallback } from 'react';

const styles = () => ({

});

const EventsOverview = ({ classes }) => {
    const fetchList = useCallback(() => getEvents(), []);

    const { items, isLoading, error } = useList(
        fetchList
    );

    const headCells = [
        {
            id: 'level',
            title: t('event.fields.level'),
            field: 'level',
            lookup: { 'info': 'Info', 'error': 'Error' },
        },
        {
            id: 'name',
            title: t('event.fields.action'),
            field: 'name',
            render: rowData => rowData.name,
            lookup: { 'doc.created': 'Create', 'doc.updated': 'Update', 'doc.deleted': 'Delete', 'user.logged_in': 'User login' },
        },
        {
            id: 'createdAt',
            title: t('event.fields.action'),
            field: 'createdAt',
            render: rowData => <DateFormat date={rowData.createdAt} time={true} />,
            sorting: true
        },
        {
            id: 'user',
            title: t('event.fields.user'),
            field: 'user',
            render: rowData => rowData.user.displayName,
        },
    ]

    const handleRowClick = (event, rowData) => {

        //console.log(rowData);
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {items && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.addBtn}
                        >

                        </Grid>
                        <BaseMaterialTable
                            title={t('events.title')}
                            headCells={headCells}
                            items={items}
                            //actions={actions}
                            handleRowClick={handleRowClick}
                            options={{
                                filtering: true,
                                sorting: true,
                                paging: true
                            }}
                        />
                    </>
                )}
            </>
        )
    }
}

export default withStyles(styles)(EventsOverview);