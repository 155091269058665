import Address from '../../../../../shared/address/Address';
import React from 'react';

const ShippingAddress = ({ billingAccount, ...props }) => (
    <>
        {/* eslint-disable-next-line camelcase */}
        {billingAccount.legal_name || ''}<br />
        {/* eslint-disable-next-line camelcase */}
        <Address address={billingAccount.shipping_address} />
    </>
);

export default ShippingAddress;
