import React from 'react';
import PageHeader from '../../../shared/header/PageHeader';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ROUTE_SUPPLIERS,
    ROUTE_SUPPLIERS_ADD,
    ROUTE_SUPPLIERS_EDIT,
} from '../../../../core/routing/routing';
import SuppliersOverviewScene from './overview/SuppliersOverviewScene';
import SuppliersFormScene from './form/SuppliersFormScene';
import { t } from '../../../../core/i18n';
import AppContent from '../../../shared/content/AppContent';

const SuppliersScene = props => {
    return (
        <>
            <PageHeader title={t('suppliers.title')} />

            <AppContent>
                <Switch>
                    <Route
                        path={ROUTE_SUPPLIERS_EDIT}
                        component={SuppliersFormScene}
                    />
                    <Route
                        path={ROUTE_SUPPLIERS_ADD}
                        component={SuppliersFormScene}
                    />
                    <Route
                        path={ROUTE_SUPPLIERS}
                        component={SuppliersOverviewScene}
                    />
                    <Redirect to={ROUTE_SUPPLIERS} />
                </Switch>
            </AppContent>
        </>
    );
};

export default SuppliersScene;
