import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { getInvoiceByIdAndAccessToken } from '../../../../../core/modules/accounting/invoicing/actions';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '../../../../../core/networking/utils';
import Progress from '../../../../shared/progress/Progress';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Container,
    AppBar,
    Toolbar,
    Divider,
    Typography
} from '@material-ui/core';
import InvoicingPdf from '../pdf/InvoicingPdf';
import InvoicingPdfDownload from '../pdf/InvoicingPdfDownload';
import { t } from 'core/i18n';
import { Link } from 'react-router-dom';
import {
    Print as PrintIcon,
    Done as DoneIcon,
} from '@material-ui/icons';
import {
    route,
    ROUTE_ADMINISTRATION_PUBLIC,
    ROUTE_USER,
} from 'core/routing/routing';
import BillingAddress from '../detail/billing/BillingAddress';
import ShippingAddress from '../detail/billing/ShippingAddress';
import OverviewRows from '../table/OverviewRows';
import ItemRow from '../detail/row/ItemRow';
import GroupRow from '../detail/row/GroupRow';
import OverviewGroups from '../table/OverviewGroups';
import { NavLink } from 'react-router-dom';
import {
    ROUTE_ONBOARDING
} from 'core/routing/routing';
import { useSelector } from 'react-redux';
import { AuthStates } from 'core/modules/auth/constants';
import { logout } from 'core/modules/auth/actions';
import StatusChip from '../../../../shared/progress/StatusChip';
import ConfirmDialog from 'components/shared/dialog/ConfirmDialog';
import {
    approveInvoice
} from 'core/modules/accounting/invoicing/actions';
import { green } from '@material-ui/core/colors';
import { InvoicingApporvalStatus } from 'core/modules/accounting/invoicing/constants';

const styles = theme => ({
    '@global': {
        body: {
            padding: 0,
            margin: 0,
            backgroundColor: '#f6f7f9',
        },
    },
    paper: {
        padding: 20,
    },
    actionsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 20,
    },
    root: {
        flexGrow: 1,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
    firebase: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.common.white,
    },
    logo: {
        width: 24,
        height: 18,
        marginRight: 16
    },
    appBar: {
        marginBottom: 30,
        backgroundColor: "#fff",
        borderBottom: "1px solid #eeeeee"
    },
    divider: {
        margin: '20px 0',
    },
    confirmBtn: {
        color: "#ffffff",
    },
})

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[600]),
        backgroundColor: green[600],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const InvoicingPublic = ({ classes }) => {
    const dispatch = useDispatch();
    const { type, id } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [item, setItem] = useState(null);
    const [error, setError] = useState(null);
    const [confirmItem, setConfirmItem] = useState(null);

    // get access token from url
    const query = useQuery();
    const accessToken = query.get('access_token');
    const reportType = query.get('report_type');

    const isAuthenticated = useSelector(
        state => state.auth.status === AuthStates.LoggedIn && !!state.auth.user
    );

    const fetchInvoice = useCallback(() => {
        dispatch(getInvoiceByIdAndAccessToken(type, id, accessToken))
            .then(item => {
                setItem(item);
            })
            .catch(error => {
                setError(getErrorMessage(error));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, type, dispatch, accessToken]);

    useEffect(() => {
        fetchInvoice();
    }, [fetchInvoice]);

    const handleConfirm = () => {
        return dispatch(approveInvoice(type, id))
            .then((data) => {
                //fetchInvoice();
                setItem(data)
            })
            .catch(error => {
                setError(getErrorMessage(error));
            })
            .finally(() => {
                setConfirmItem(null);
            });
    };

    const handleLogoutClick = () => {
        dispatch(logout());
    };

    if (isLoading) {
        return <Progress isLoading={isLoading} />;
    } else {
        return (
            <Box>
                {error && <Alert severity="error">{error}</Alert>}
                {item && (
                    (reportType === "pdf") ? (
                        <>
                            <InvoicingPdf
                                type={type}
                                item={item}
                            />
                        </>
                    ) : (
                            <>
                                <AppBar color="transparent" position="sticky" elevation={0} className={classes.appBar}>
                                    <Toolbar>
                                        <Container maxWidth="lg" className={classes.root}>
                                            <Grid container spacing={3} display="flex" alignItems="center" justify="space-between">
                                                <Grid item >
                                                    <Button
                                                        component={NavLink}
                                                        to={ROUTE_USER}
                                                    >
                                                        {!!item.from.logo_image_url ?
                                                            <img width="160px" height="auto" src={item.from.logo_image_url} alt={item.from.contact_name} /> :
                                                            null}

                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    {isAuthenticated ? (
                                                        <Button
                                                            color="inherit"
                                                            onClick={handleLogoutClick}>
                                                            {t('navbar.logout')}
                                                        </Button>
                                                    ) : (
                                                            <Button
                                                                component={NavLink}
                                                                to={ROUTE_ONBOARDING}
                                                                color="inherit">
                                                                {t('navbar.login')}
                                                            </Button>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Toolbar>
                                </AppBar>
                                <Container maxWidth="lg" className={classes.root}>
                                    <Grid container spacing={3}>
                                        <Grid item md={4}>
                                            <Paper className={classes.paper} elevation={0} variant="outlined">
                                                <Box>
                                                    <Typography variant="h4">
                                                        {item.currency} {item.total_with_vat}
                                                    </Typography>
                                                    <Divider className={classes.divider} />
                                                    <ColorButton
                                                        aria-label={t(
                                                            `invoicing.detail.accept.${type}`
                                                        )}
                                                        color='primary'
                                                        className={classes.confirmBtn}
                                                        variant="contained"
                                                        disabled={
                                                            (item.document_approval?.status === InvoicingApporvalStatus.ApprovalAccepted) ?
                                                                true : false
                                                        }
                                                        onClick={() => setConfirmItem(item)}
                                                        startIcon={<DoneIcon />}
                                                    >
                                                        {t(
                                                            `invoicing.detail.accept.${type}`
                                                        )}
                                                    </ ColorButton>
                                                    <Divider className={classes.divider} />
                                                </Box>
                                                <Box className={classes.actionsWrapper}>
                                                    <Box>
                                                        <InvoicingPdfDownload
                                                            type={type}
                                                            item={item}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            component={Link}
                                                            target="_blank"
                                                            startIcon={<PrintIcon />}
                                                            to={route(
                                                                ROUTE_ADMINISTRATION_PUBLIC,
                                                                {
                                                                    workspaceId: item.workspace_agency,
                                                                    id: item.id,
                                                                    type: type,
                                                                    query: { access_token: item.access_token, report_type: "pdf" }
                                                                }
                                                            )}>
                                                            {t(
                                                                `invoicing.detail.print`
                                                            )}
                                                        </Button>
                                                    </Box>

                                                </Box >
                                            </Paper>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Paper className={classes.paper} elevation={0} variant="outlined">
                                                {/* todo remove use styling */}
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="flex-start"
                                                >
                                                    <Typography variant="h4">
                                                        {t(`invoicing.type.${type}`)} {!!item.uid ? item.uid : null}{!!item.version ? "/" + item.version : null}
                                                    </Typography>
                                                    <StatusChip
                                                        type={type}
                                                        status={
                                                            item.document_approval?.status ?
                                                                item.document_approval.status :
                                                                null
                                                        }
                                                    />
                                                </Grid>
                                                <Divider className={classes.divider} />

                                                <Box display="flex">
                                                    <Box style={{ width: '50%' }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                {!!item.to.shipping_address ?
                                                                    t('clients.billing_accounts.fields.billing_address') :
                                                                    t('clients.billing_accounts.fields.billing_shipping_address')}
                                                            </strong><br />
                                                            <BillingAddress
                                                                billingAccount={item.to || {}}
                                                            />
                                                        </Typography>
                                                    </Box>
                                                    {!!item.to.shipping_address && (
                                                        <Box style={{ width: '50%' }}>
                                                            <Typography variant="body1">
                                                                <strong>{t('clients.billing_accounts.fields.shipping_address')}</strong><br />
                                                                <ShippingAddress
                                                                    billingAccount={item.to || {}}
                                                                />
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>

                                                <TableContainer style={{ marginTop: '24px' }}>
                                                    <Table aria-label={t('invoicing.items')} size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {t('invoicing.fields.item.description')}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        fontVariantNumeric: 'tabular-nums',
                                                                    }}>
                                                                    {t('invoicing.fields.item.quantity')}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        fontVariantNumeric: 'tabular-nums',
                                                                    }}>
                                                                    {t('invoicing.fields.item.price')}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        fontVariantNumeric: 'tabular-nums',
                                                                    }}>
                                                                    {t('invoicing.fields.item.vat')}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    style={{
                                                                        fontVariantNumeric: 'tabular-nums',
                                                                    }}>
                                                                    {t('invoicing.fields.item.total')}
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item.items.map((row, index) =>
                                                                !row.group ? (
                                                                    <ItemRow
                                                                        key={`item_${index}`}
                                                                        item={row}
                                                                        status={item.status}
                                                                    />
                                                                ) : (
                                                                        <GroupRow
                                                                            key={`group_${index}`}
                                                                            item={row}
                                                                        />
                                                                    )
                                                            )}
                                                            <OverviewGroups
                                                                currency={item.currency}
                                                                items={item.items || []}
                                                            />
                                                            <OverviewRows
                                                                currency={item.currency}
                                                                items={item.items || []}
                                                                status={item.status}
                                                            />

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Divider className={classes.divider} />

                                                <Typography variant="body1">
                                                    <BillingAddress
                                                        billingAccount={item.from || {}}
                                                        classes={classes}
                                                    />
                                                </Typography>
                                            </Paper >
                                        </Grid >
                                    </Grid>
                                </Container>
                                <ConfirmDialog
                                    open={!!confirmItem}
                                    onCancel={() => setConfirmItem(null)}
                                    onConfirm={handleConfirm}
                                />
                            </>
                        )
                )}
            </Box>
        )
    }
}

export default withStyles(styles)(InvoicingPublic);