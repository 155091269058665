import { t } from 'core/i18n';
import { selectCurrentWorkspaceId } from 'core/modules/app/selectors';
import { getCurrentUserId } from 'core/modules/users/selectors';
import { DocStatus } from 'core/modules/auth/constants';

export const getErrorMessage = error => {
    if (error.code && error.code.indexOf('network-request-failed') >= 0) {
        return t('error.network');
    }
    if (error.message) {
        return error.message;
    }
    return t('errors.generic');
};

export const getDocData = doc => {
    return { ...doc.data(), id: doc.id };
};

export const docMetaData = (state, docStatus) => {
    const currentUserId = getCurrentUserId(state);
    const currentWorkspaceId = selectCurrentWorkspaceId(state);
    return {
        uid: currentUserId,
        workspace_agency: currentWorkspaceId,
        doc_status: !!docStatus ? docStatus : DocStatus.Active,
    }
}