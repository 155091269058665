import React from 'react';
import InvoicingPdfScene from './InvoicingPdfScene';
import {
    PDFDownloadLink
} from '@react-pdf/renderer';
import { SaveAlt as SaveAltIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { t } from 'core/i18n';

const InvoicingPdfDownload = ({ type, item }) => {
    return (
        <>
            {item && (
                <>
                    <PDFDownloadLink
                        style={{ textDecoration: 'none' }}
                        document={
                            <InvoicingPdfScene
                                type={type}
                                item={item}
                            />
                        }
                        fileName={
                            (!!item.uid ? item.uid : null) + (!!item.version ? "/" + item.version : null) + ".pdf"
                        }>
                        {({ blob, url, loading, error }) => (
                            <Button
                                style={{ marginRight: '8px' }}
                                variant="contained"
                                color="primary"
                                disabled={loading ? true : false}
                                startIcon={<SaveAltIcon />}
                            >
                                {t(
                                    `invoicing.detail.download`
                                )}
                            </Button>
                        )}
                    </PDFDownloadLink>
                </>
            )}
        </>
    )

}

export default InvoicingPdfDownload;