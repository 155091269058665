import React from 'react';
import {
    ROUTE_ACCOUNTING,
    ROUTE_PRODUCTS,
    ROUTE_PRODUCT_GROUPS,
    ROUTE_SUPPLIERS,
    ROUTE_BILLING_ACCOUNTS,
    ROUTE_ADMINISTRATION,
    ROUTE_ADMINISTRATION_TYPE,
    ROUTE_STOCK,
    ROUTE_API_CENTER,
} from '../../core/routing/routing';
import { t } from '../../core/i18n';
import {
    ExpandLess,
    ExpandMore
} from '@material-ui/icons';
import {
    Box,
    ListItemText,
    ListItem
} from '@material-ui/core';
import clsx from 'clsx';

const NavHeader = ({
    route,
    classes,
    drawerOpen,
    openVar,
    customClickEvent,
    routeChildren,
}) => {
    let label;

    const routeSwitcher = (val, type) => {
        switch (val) {
            case ROUTE_ADMINISTRATION:
                label = t('nav.administration');
                break;

            case ROUTE_ADMINISTRATION_TYPE:
                label = t('nav.administration.' + type);
                break;

            case ROUTE_ACCOUNTING:
                label = t('nav.accounting');
                break;

            case ROUTE_PRODUCTS:
                label = t('nav.accounting.products');
                break;

            case ROUTE_PRODUCT_GROUPS:
                label = t('nav.accounting.product_groups');
                break;

            case ROUTE_ADMINISTRATION:
                label = t('nav.accounting.invoices');
                break;

            case ROUTE_SUPPLIERS:
                label = t('nav.accounting.suppliers');
                break;

            case ROUTE_BILLING_ACCOUNTS:
                label = t('nav.accounting.billing_accounts');
                break;

            case ROUTE_STOCK:
                label = t('nav.stock.overview');
                break;

            case ROUTE_API_CENTER:
                label = t('nav.api_center.overview');
                break;

            default:
                label = '';
                break;
        }
    };

    routeSwitcher(route.path);

    return (
        <ListItem
            button
            className={clsx(classes.categoryHeader, {
                [classes.categoryHeaderShown]: drawerOpen,
                [classes.categoryHeaderHidden]: !drawerOpen,
            })}
            onClick={customClickEvent}>
            <ListItemText
                classes={{
                    primary: classes.categoryHeaderPrimary,
                }}>
                {label}
            </ListItemText>
            {openVar ? <ExpandLess /> : <ExpandMore />}
            <Box
                className={clsx(classes.headerDescription, {
                    [classes.headerDescriptionOpen]: openVar,
                    [classes.headerDescriptionClose]: !openVar,
                })}>
                {routeChildren.map((subRoute, index) => (
                    <span key={subRoute.path + index}>
                        <span
                            key={routeSwitcher(
                                subRoute.path,
                                subRoute.params.type
                            )}>
                            {label},{' '}
                        </span>
                    </span>
                ))}

            </Box>
        </ListItem>
    );
};

export default NavHeader;
