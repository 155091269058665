import { Collections, fireStore } from '../../networking/firebase';
import { getDocData } from '../../networking/utils';
import { selectCurrentWorkspaceId } from '../app/selectors';

export const fetchStock = async workspace => {
    return await fireStore
        .collection(Collections.product_stock)
        .where('workspace_agency', '==', workspace)
        .orderBy('query_fields.name', 'asc')
        .get()
        .then(resp => {
            return resp.docs.map(doc => getDocData(doc));
        });
};

export const fetchStockBySource = async (workspace, source) => {
    return await fireStore
        .collection(Collections.product_stock)
        .where('workspace_agency', '==', workspace)
        .where('stock_source_collection', '==', source)
        .orderBy('query_fields.name', 'asc')
        .get()
        .then(resp => {
            return resp.docs.map(doc => getDocData(doc));
        });
};

export const getStock = () => async (dispatch, getState) => {
    const items = await fetchStock(selectCurrentWorkspaceId(getState()));
    return items.map(item => preprocessStockItem(item));
};

export const getStockByType = source => async (dispatch, getState) => {
    const items = await fetchStockBySource(
        selectCurrentWorkspaceId(getState()),
        source
    );
    return items.map(item => preprocessStockItem(item));
};

const preprocessStockItem = item => {
    if (!item.is_recurring_stock) {
        item.is_recurring_stock = false;
    }
    if (!item.stock_source_collection) {
        item.stock_source_collection = 'none';
    }
    return item;
};
