import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import TextField from 'components/shared/form/generic/TextField';
import {
    Paper,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import BaseFormContainer from 'components/shared/form/BaseFormContainer';
import { useForm } from 'core/hooks/useForm';
import { useSelector } from 'react-redux';
import { patchWorkspace } from '../../../../core/modules/workspaces/actions';
import UploadField from 'components/shared/form/generic/UploadField';
import { isEmptyText } from 'core/utils/isEmptyText';

const Schema = yup.object().shape({
    name: yup.string().required(),
});

// only select the values we'll actually need
const selectData = data => {
    return {
        id: data.id,
        name: data.name,
        image_url: isEmptyText(data.image_url) ? null : data.image_url,
        logo_image_url: isEmptyText(data.logo_image_url)
            ? null
            : data.logo_image_url,
    };
};

const styles = () => ({
    paper: {
        padding: 20,
    },
    image: {
        maxWidth: 150,
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, .05)',
        padding: 4,
        margin: '10px 0',
    },
});

const WorkspaceGeneralSettingsScene = ({ classes }) => {
    const workspaceId = useSelector(state => state.app.workspace);
    const workspaces = useSelector(state => state.workspaces.workspaces);
    let workspace = [];
    if (!!workspaces) {
        workspace = workspaces.find(space => space.id === workspaceId);
    }

    const config = useMemo(
        () => ({
            store: values => patchWorkspace(values),
            defaultValue: selectData(workspace) || {
                //name: '',
            },
        }),
        [workspace]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        !!initialData?.id && (
            <BaseFormContainer
                isLoading={isLoading}
                error={error}
                initialData={initialData}
                render={() => (
                    <Paper className={classes.paper}>
                        <Formik
                            validationSchema={Schema}
                            initialValues={initialData}
                            onSubmit={onSubmit}>
                            {({ isSubmitting, setFieldValue, submitForm }) => (
                                <Form>
                                    <TextField
                                        label={t('workspace.general.fields.name')}
                                        name="name"
                                    />
                                    {!isEmptyText(initialData.image_url) ? (
                                        <img
                                            src={initialData.image_url}
                                            alt={initialData.name}
                                            className={classes.image}
                                        />
                                    ) : null}
                                    <UploadField
                                        label={t('workspace.general.fields.icon')}
                                        name="image_url"
                                        storagePath={`files/workspace_agencies/${workspace.id}`}
                                        setFieldValue={setFieldValue}
                                        submitForm={submitForm}
                                    />
                                    {!isEmptyText(initialData.logo_image_url) ? (
                                        <img
                                            src={initialData.logo_image_url}
                                            alt={initialData.name}
                                            className={classes.image}
                                        />
                                    ) : null}
                                    <UploadField
                                        label={t('workspace.general.fields.logo')}
                                        name="logo_image_url"
                                        storagePath={`files/workspace_agencies/${workspace.id}`}
                                        setFieldValue={setFieldValue}
                                        submitForm={submitForm}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn}
                                        disabled={isSubmitting}>
                                        {t('actions.update')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}
            />
        )
    );
};

export default withStyles(styles)(WorkspaceGeneralSettingsScene);
