import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import InvoicingFormContainer from '../form/InvoicingFormContainer';
import AppContent from '../../../../shared/content/AppContent';
import {
    history,
    route,
    ROUTE_ADMINISTRATION_TYPE,
} from '../../../../../core/routing/routing';
import { useSelector } from 'react-redux';

const InvoicingFromScene = () => {
    const { fromType, id, toType } = useParams();
    const workspaceId = useSelector(state => state.app.workspace)

    const handleSuccess = useCallback(() => {
        history.push(route(ROUTE_ADMINISTRATION_TYPE, { type: toType, workspaceId }));
    }, [toType]);

    return (
        <div>
            <AppContent>
                <InvoicingFormContainer
                    type={fromType}
                    id={id}
                    toType={toType}
                    onSuccess={handleSuccess}
                />
            </AppContent>
        </div>
    );
};

export default InvoicingFromScene;
