import React from 'react';
import { t } from '../../../../core/i18n';
import Select from '../../../shared/form/generic/Select';

const DuePeriodSelector = ({ name, label, onChange }) => {
    const duePeriodOptions = [
        {
            id: 'on_receipt',
            name: 'Due on receipt',
            momentAction: { function: 'add', params: [{ days: 0 }] },
        },
        {
            id: '15',
            name: '15 days',
            momentAction: { function: 'add', params: [{ days: 15 }] },
        },
        {
            id: '30',
            name: '30 days',
            momentAction: { function: 'add', params: [{ days: 30 }] },
        },
        {
            id: '60',
            name: '60 days',
            momentAction: { function: 'add', params: [{ days: 60 }] },
        },
    ];

    return (
        <Select
            label={label ?? t('invoicing.fields.due_period')}
            name={name}
            options={duePeriodOptions.map(value => ({
                value: value.id,
                label: value.name,
            }))}
            onChange={onChange}
        />
    );
};

export default DuePeriodSelector;
