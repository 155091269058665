import { FieldArray, useFormikContext } from 'formik';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Button,
} from '@material-ui/core';
import { t } from '../../../../../../core/i18n';
import ItemRow from '../row/ItemRow';
import GroupRow from '../row/GroupRow';
import OverviewRows from '../../table/OverviewRows';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentWorkspace } from '../../../../../../core/modules/workspaces/selectors';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const generateRandomId = () => {
    return (
        Math.random()
            .toString(36)
            .substring(2) + Date.now().toString(36)
    );
};

const TableFields = props => {
    const { values, setFieldValue } = useFormikContext();

    if (!!values.items && Array.isArray(values.items)) {
        values.items.forEach((item, index) => {
            if (!item.index_id) {
                values.items[index].index_id = generateRandomId();
            }
        });
    } else {
        values.items = [];
    }

    const workspace = useSelector(state => selectCurrentWorkspace(state));

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const row = values.items[dragIndex];
            const items = [...values.items];

            setFieldValue(
                'items',
                update(items, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, row],
                    ],
                })
            );
        },
        [values.items, setFieldValue]
    );

    const addItemRow = helper => {
        helper.push({
            index_id: generateRandomId(),
            group: false,
            currency: workspace.accounting.currency,
        });
    };

    const addGroupRow = helper => {
        helper.push({
            id: generateRandomId(),
            index_id: generateRandomId(),
            group: true,
        });
    };

    return (
        <FieldArray
            name="items"
            render={helper => (
                <>
                    <TableContainer style={{ marginTop: '24px' }}>
                        <Table aria-label={t('invoicing.items')}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        padding="none"
                                    />
                                    <TableCell>
                                        {t('invoicing.fields.item.product')}
                                    </TableCell>
                                    <TableCell
                                        padding="none"
                                    >
                                        {t('invoicing.fields.item.quantity')}
                                    </TableCell>
                                    <TableCell>
                                        {t('invoicing.fields.item.price')}
                                    </TableCell>
                                    <TableCell>
                                        {t('invoicing.fields.item.vat')}
                                    </TableCell>
                                    <TableCell>
                                        {t('invoicing.fields.item.supplier')}
                                    </TableCell>
                                    <TableCell>
                                        {t('invoicing.fields.item.total')}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <DndProvider backend={HTML5Backend}>
                                    {values.items.map((field, index) =>
                                        !field.group ? (
                                            <ItemRow
                                                key={
                                                    values.items[index].index_id
                                                }
                                                field={field}
                                                index={index}
                                                onMove={moveRow}
                                                name={`items[${index}]`}
                                                row={values.items[index]}
                                                status={values.status}
                                                onDelete={() =>
                                                    helper.remove(index)
                                                }
                                                showRemoveButton={index > 0}
                                                recurringContext={
                                                    props.recurringContext ??
                                                    false
                                                }
                                            />
                                        ) : (
                                                <GroupRow
                                                    key={
                                                        values.items[index].index_id
                                                    }
                                                    field={field}
                                                    index={index}
                                                    onMove={moveRow}
                                                    name={`items[${index}]`}
                                                    row={values.items[index]}
                                                    status={values.status}
                                                    onDelete={() =>
                                                        helper.remove(index)
                                                    }
                                                />
                                            )
                                    )}
                                </DndProvider>
                                <OverviewRows
                                    currency={values.currency}
                                    items={values.items || []}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        style={{
                            margin: '24px 0',
                        }}>
                        <Button
                            variant="contained"
                            onClick={() => addItemRow(helper)}>
                            {t('invoicing.fields.item.add')}
                        </Button>

                        <Button
                            variant="contained"
                            style={{
                                marginLeft: '4px',
                            }}
                            onClick={() => addGroupRow(helper)}>
                            {t('invoicing.fields.group.add')}
                        </Button>
                    </Box>
                </>
            )}
        />
    );
};

export default TableFields;
