import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';
import { t } from 'core/i18n';

const ConfirmDialog = ({ open, onCancel, onConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle color="default" id="alert-dialog-title">
                {t('confirm.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('confirm.description')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>{t('actions.cancel')}</Button>
                <Button onClick={onConfirm} color="primary">
                    {t('confirm.submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
