import { Roles } from '../../auth/constants';

export const userRequiresWorkspace = user => {
    return [Roles.Client, Roles.Admin].indexOf(user.role) >= 0;
};

export const selectCurrentClientName = state => {
    return state.client.client?.name || "";
}

export const selectCurrentClient = state => {
    return selectClientById(state, selectClientById(state));
};

export const selectCurrentClientWorkspace = state => {
    return state.client.client?.workspace_agency;
};

export const selectCurrentUserClients = state => {
    const clients = [];
    Object.keys(state.auth.user.client_roles).map(key => clients.push(key))
    return clients;
};

export const selectCurrentUserClientPermission = (state, currentClientId) => {
    return state.auth.user.client_roles[currentClientId];
};

export const selectClientById = (state) => {
    return state.client.client_id;
};