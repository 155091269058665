import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    AppBar,
    Grid,
    Hidden,
    IconButton,
    Toolbar,
    Box,
    Menu,
    MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Roles, Permissions } from 'core/modules/auth/constants';
import { Menu as MenuIcon } from '@material-ui/icons';
import { logout } from '../../core/modules/auth/actions';
import { t } from '../../core/i18n';
import LocalizationMenu from '../localization/menu/LocalizationMenu';
import ClientSelect from 'components/client/select/ClientSelect';
import { NavLink } from 'react-router-dom';
import {
    ROUTE_USER_OVERVIEW,
    ROUTE_USER_EDIT,
    ROUTE_USER_PREFERENCES,
    ROUTE_SUPER_ADMIN_USERS,
    ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES,
    ROUTE_SUPER_ADMIN_EVENT_LOGS,
} from 'core/routing/routing';
import AvatarStatus from 'components/shared/content/AvatarStatus';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
});

const Header = props => {
    const { classes, onDrawerToggle } = props;
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const user = useSelector(state => state.auth.user);
    const isSuperAdmin = useSelector(
        state => (state.auth.user.role === Roles.Admin && state.auth.user.permission === Permissions.Owner)
    );

    const isAdmin = useSelector(
        state => state.auth.user.role === Roles.Admin
    );

    const isUser = useSelector(
        state => state.auth.user.role === Roles.User
    );
    const handleLogoutClick = () => {
        dispatch(logout());
    };

    const handleClick = e => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box displayPrint="none">
            <AppBar color="primary" position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            {((isAdmin || isUser) && user.isFullyRegistered) && (
                                <>
                                    {/*<WorkspaceSelect />*/}
                                    {!isSuperAdmin ? (
                                        <ClientSelect />
                                    ) : null}
                                </>
                            )}
                        </Grid>
                        <Hidden smUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={onDrawerToggle}
                                    className={classes.menuButton}>
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs />
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}>
                                    <MenuItem
                                        button
                                        component={NavLink}
                                        to={ROUTE_USER_OVERVIEW}
                                        onClick={handleClose}>
                                        {t('nav.user.overview')}
                                    </MenuItem>
                                    <MenuItem
                                        button
                                        component={NavLink}
                                        to={ROUTE_USER_EDIT}
                                        onClick={handleClose}>
                                        {t('nav.user.edit')}
                                    </MenuItem>
                                    <MenuItem
                                        button
                                        component={NavLink}
                                        to={ROUTE_USER_PREFERENCES}
                                        onClick={handleClose}
                                        divider>
                                        {t('nav.user.preferences')}
                                    </MenuItem>
                                    {isSuperAdmin &&
                                        (
                                            [
                                                {
                                                    to: ROUTE_SUPER_ADMIN_USERS,
                                                    label: t('nav.users_and_permissions'),
                                                },
                                                {
                                                    to: ROUTE_SUPER_ADMIN_WORKSPACE_AGENCIES,
                                                    label: t('nav.agencies'),
                                                },
                                                {
                                                    to: ROUTE_SUPER_ADMIN_EVENT_LOGS,
                                                    label: t('nav.event_logs'),
                                                },
                                            ].map((tab, i, { length }) => (
                                                <MenuItem
                                                    button
                                                    component={NavLink}
                                                    to={tab.to}
                                                    value={tab.to}
                                                    label={tab.label}
                                                    onClick={handleClose}
                                                    key={tab.to}
                                                    divider={(i + 1 === length) ? true : false}
                                                >
                                                    {tab.label}
                                                </MenuItem>
                                            ))
                                        )}
                                    <MenuItem
                                        button
                                        color="inherit"
                                        onClick={handleLogoutClick}>
                                        {t('navbar.logout')}
                                    </MenuItem>
                                </Menu>
                                <IconButton
                                    color="inherit"
                                    className={classes.iconButtonAvatar}
                                    onClick={handleClick}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true">
                                    <AvatarStatus user={user} />
                                </IconButton>
                                <LocalizationMenu />
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box >
    );
};

export default withStyles(styles)(Header);
