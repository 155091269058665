import React from 'react';
import {
    Button,
    Menu,
    MenuItem
} from '@material-ui/core';
import { Locales } from '../../../core/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from '../../../core/modules/app/reducer';
import { selectCurrentLocale } from '../../../core/modules/app/selectors';

const LocalizationMenu = () => {
    const [anchor, setAnchor] = React.useState(null);

    const currentLocale = useSelector(state => selectCurrentLocale(state));
    const dispatch = useDispatch();

    const handleOpen = event => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const handleLocaleClick = locale => {
        dispatch(setLocale(locale));
        handleClose();
    };

    return (
        <div>
            <Button
                color="inherit"
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleOpen}>
                {currentLocale.toUpperCase()}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchor}
                keepMounted
                open={!!anchor}
                onClose={handleClose}>
                {Locales.filter(l => l !== currentLocale).map(locale => (
                    <MenuItem
                        onClick={() => handleLocaleClick(locale)}
                        key={locale}>
                        {locale.toUpperCase()}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default LocalizationMenu;
