import {
    Collections,
    fireStore,
    firebaseAuth,
} from '../../networking/firebase';
import { t } from 'core/i18n';
import { createNotification } from '../notifications/reducer';
import { updateUser } from '../auth/reducer';
import { setLocale } from '../app/reducer';

export const fetchUsers = async () => {
    const users = await fireStore
        .collection(Collections.users)
        .get()
        .then(resp =>
            resp.docs.map(doc => {
                return { ...doc.data(), id: doc.id };
            })
        );

    const privData = async id => {
        return await fireStore
            .collection(Collections.users)
            .doc(id)
            .collection(Collections.private_data)
            .doc('private')
            .get()
            .then(doc => {
                if (doc.exists) {
                    return { ...doc.data() };
                }
            });
    }

    if (!!users) {
        const arr = (users).map(async (user) => {
            const userPrivData = await privData(user.id);
            if (!!userPrivData) {
                return { ...userPrivData, ...user }
            }
        })

        return Promise.all(arr);
    } else {
        return []
    }
};

export const getUsers = () => (dispatch, getState) => {
    return fetchUsers();
};

export const fetchSnapshotUsers = () => {
    return fireStore.collection(Collections.users)
        .onSnapshot(snapshot => {
            if (snapshot.size) {
                // we have something
                //setLoading(false)
                const userObject = [];
                snapshot.docs.map(doc => {
                    return userObject.push({ ...doc.data(), id: doc.id });
                })
                return userObject;
            } else {
                // it's empty
                return [];
            }
        })
};

export const getSnapshotUsers = () => (dispatch, getState) => {
    return fetchSnapshotUsers();
};

export const fetchUserById = id => {
    return fireStore
        .collection(Collections.users)
        .doc(id)
        .get()
        .then(async doc => {
            if (doc.exists) {
                const privData = await fireStore
                    .collection(Collections.users)
                    .doc(id)
                    .collection(Collections.private_data)
                    .get()
                    .then(resp =>
                        resp.docs.map(privdoc => {
                            return privdoc.data();
                        })
                    );
                return { ...doc.data(), id: doc.id, ...privData[0] };
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getUserById = id => dispatch => {
    return fetchUserById(id);
};

export const fetchUserEventsById = id => {
    return fireStore
        .collection(Collections.users)
        .doc(id)
        .get()
        .then(async doc => {
            if (doc.exists) {
                const eventData = await fireStore
                    .collection(Collections.users)
                    .doc(id)
                    .collection(Collections.events)
                    .orderBy('createdAt', 'desc')
                    .get()
                    .then(resp =>
                        resp.docs.map(privdoc => {
                            return { ...privdoc.data() };
                        })
                    );
                return { ...doc.data(), id: doc.id, events: eventData };
            }
            return Promise.reject(new Error(t('errors.not_found')));
        });
};

export const getUserEventsById = id => dispatch => {
    return fetchUserEventsById(id);
};

export const fetchUserByEmail = (email) => async dispatch => {
    const returnExisitingUserByEmail = firebaseAuth.functions().httpsCallable('returnExisitingUserByEmail');
    return await returnExisitingUserByEmail({
        email: email,
    })
        .then(function (result) {
            // Read result of the Cloud Function.
            if (!!result.data) {
                //console.log(result.data.email);
                return { ...result.data };
            } else {
                return { email: email, uid: "", newUser: true };
            }

        }).catch(function (error) {
            // Getting the Error details.
            return console.log(error);
            /*var code = error.code;
            var message = error.message;
            var details = error.details;*/
            // ...
        });
}

export const getUserByEmail = email => async dispatch => {
    return await dispatch(fetchUserByEmail(email));
};

export const storeUser = ({ ...data }) => async (dispatch, getState) => {
    const user = firebaseAuth.auth().currentUser;
    const query = fireStore.collection(Collections.users);
    const id = data.uid;
    const first_name = data.first_name || "";
    const last_name = data.last_name || "";
    const displayName = first_name + " " + last_name;
    const avatar = data.avatar || "";
    const dob = !!data.dob ? data.dob : "";
    const gender = data.gender || "";
    const street_nr = data.street_nr || "";
    const city = data.city || "";
    const postal_code = data.postal_code || "";
    const country = data.country || "";
    const lastSignIn = !!data.lastSignIn ? new Date(data.lastSignIn.seconds * 1000 + data.lastSignIn.nanoseconds / 1000000) : "";
    const createdAt = !!data.createdAt ? data.createdAt : "";
    const userData = {
        avatar: avatar,
        original_avatar: data.original_avatar || avatar,
        phoneNumber: data.phoneNumber || "",
        displayName: displayName,
        first_name: first_name,
        last_name: last_name,
        preferences: data.preferences || {},
        dob,
        gender,
        street_nr,
        city,
        postal_code,
        country,
        lastSignIn,
        createdAt
    }

    if (!!data.preferences?.default_language) {
        dispatch(setLocale(data.preferences?.default_language));
    }

    const updateProfile = {
        displayName: displayName,
        photoURL: avatar,
    }

    if (id) {
        await query.doc(id).set(userData, { merge: true })
            .then(() => {
                // update User
                dispatch(updateUser(data));
            }).catch((error) => {
                console.log(error)
                // An error happened.
            });;
        await user.updateProfile(updateProfile)
            .then(() => {
                // update User
                //dispatch(updateUser(data));
            })
            .catch((error) => {
                console.log(error)
                // An error happened.
            });
    } else {
        return Promise.reject(new Error(t('errors.not_found')));
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`users.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const storeUserRole = ({ id, ...data }) => async dispatch => {
    const query = fireStore.collection(Collections.users);

    dispatch(
        setUserCustomClaims(
            id,
            data.role,
            data.permission,
            //data.workspace_agency,
            //data.customer
        )
    );

    const security = {
        role: data.role || '',
        permission: data.permission || '',
        workspace_agency: data.workspace_agency || '',
        //customer: data.customer || '',
    };

    delete data.role;
    delete data.permission;
    delete data.workspace_agency;
    delete data.workspace_default;
    delete data.customer;

    if (id) {
        await query.doc(id).set(data);

        await query
            .doc(id)
            .collection(Collections.private_data)
            .doc('private')
            .set(security);
    } else {
        const ref = await query.add(data);
        id = ref.id;
        await query
            .doc(id)
            .collection(Collections.private_data)
            .doc('private')
            .set(security);
    }

    dispatch(
        createNotification({
            type: 'success',
            message: t(`users.notifications.${id ? 'update' : 'create'}`),
        })
    );

    return { ...data, id };
};

export const setUserCustomClaims = (
    id,
    role,
    permission,
) => async dispatch => {
    const setUserRoles = firebaseAuth.functions().httpsCallable('setUserRoles');
    await setUserRoles({
        uid: id,
        role: role,
        permission: permission,
    })
        .then(function (result) {
            // Read result of the Cloud Function.
            console.log(result);
        })
        .catch(function (error) {
            // Getting the Error details.
            console.log(error);
            /*var code = error.code;
            var message = error.message;
            var details = error.details;*/
            // ...
        });
};

export const setUserRole = async (id, docId, permission, collection) => {
    const arr = {
        [collection]: {
            roles: {
                [docId]: permission
            }
        }
    }

    return await fireStore
        .collection(Collections.users)
        .doc(id)
        .collection(Collections.private_data)
        .doc('private')
        .set(arr, { merge: true })
}

export const deleteUserRole = async (id, docId, collection) => {
    const privData = await fireStore
        .collection(Collections.users)
        .doc(id)
        .collection(Collections.private_data)
        .doc('private')
        .get()
        .then(doc => {
            if (doc.exists) {
                return doc.data();
            }
        });

    delete privData[collection].roles[docId];

    return await fireStore
        .collection(Collections.users)
        .doc(id)
        .collection(Collections.private_data)
        .doc('private')
        .set(privData)
}

export const disableUser = (
    id,
    role,
    permission,
) => async dispatch => {
    const disableUserFunction = firebaseAuth.functions().httpsCallable('disableUser');
    return await disableUserFunction({
        uid: id,
        role: role,
        permission: permission,
    })
        .then(function (result) {
            // Read result of the Cloud Function.
            console.log(result);
        })
        .catch(function (error) {
            // Getting the Error details.
            console.log(error);
            /*var code = error.code;
            var message = error.message;
            var details = error.details;*/
            // ...
        });
};