import React from 'react';
import { t } from '../../../../core/i18n';
import TextField from '../generic/TextField';
import { FieldArray, useFormikContext } from 'formik';
import {
    IconButton,
    Box
} from '@material-ui/core';
import {
    Add as AddIcon,
    Remove as RemoveIcon
} from '@material-ui/icons';

const RepeaterField = ({ label, name, ...props }) => {
    const { values } = useFormikContext();

    // for now, it will render a TextField. We could make this dynamic in the future

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <>
                    {values[name].map((value, index) => (
                        <Box display="flex" key={`${name}[${index}]`}>
                            <TextField
                                label={`${label}`}
                                name={`${name}[${index}]`}
                                {...props}
                            />
                            <IconButton
                                aria-label={t('actions.remove')}
                                size="small"
                                color="secondary"
                                onClick={() => arrayHelpers.remove(index)}>
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <IconButton
                        aria-label={t('actions.add')}
                        size="small"
                        color="primary"
                        onClick={() => arrayHelpers.push('')}>
                        <AddIcon />
                    </IconButton>
                </>
            )}
        />
    );
};

export default RepeaterField;
