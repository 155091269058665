import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'core/i18n';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Card,
    CardActions,
    CardContent
} from '@material-ui/core';
import {
    getClientBillingAccountById,
    storeBillingAccount,
} from '../../../../../../core/modules/dashboard/clients/billing-accounts/actions';
import BaseFormContainer from '../../../../../shared/form/BaseFormContainer';
import AddressFields from '../../../../../shared/form/address/AddressFields';
import { useForm } from '../../../../../../core/hooks/useForm';
import {
    Edit as EditIcon
} from '@material-ui/icons';
import { isEmptyText } from 'core/utils/isEmptyText';
import { useParams } from 'react-router-dom';

const Schema = yup.object().shape({
    billing_address: yup.object().shape({
        street: yup.string().required(),
        number: yup.string().required(),
        country: yup.string().required(),
        email: yup.string().email().required(),
    }),
});

const styles = () => ({
    paper: {
        marginBottom: 30,
    },
    flexBoxItem: {
        marginLeft: '8px',
    },
});

const prepareData = original => {
    const data = { ...original };

    if (!!data.client) {
        if (!!data.client.id) {
            return data;
        } else {
            data.client_id = data.client.id;
        }
    }
    return data;
};

const BillingAddressFormContainer = ({
    classes,
}) => {
    const { clientId, billingId } = useParams();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const config = useMemo(
        () => ({
            fetch: () => getClientBillingAccountById(billingId, clientId),
            store: values => storeBillingAccount(prepareData(values)),
            defaultValue: {
                name: '',
                legal_name: '',
                contact_name: '',
                language: '',
                vat_number: {
                    vat: '',
                    type: '',
                },
                billing_address: {
                    street: '',
                    number: '',
                    country: '',
                    email: '',
                    contact_name: '',
                    postal_code: '',
                    city: '',
                    state: '',
                },
                /*client: !!clientId ? {
                    id: clientId,
                } : null,*/
            },
            id: billingId,
            onSuccess: handleClose
        }),
        [billingId, clientId]
    );

    const { isLoading, initialData, error, onSubmit } = useForm(config);

    return (
        <>
            {initialData && (
                <BaseFormContainer
                    isLoading={isLoading}
                    error={error}
                    initialData={initialData}
                    render={() => (
                        <Formik
                            validationSchema={Schema}
                            initialValues={initialData}
                            onSubmit={onSubmit}>
                            {({ isSubmitting, errors }) => (
                                <>
                                    <Typography variant="h5">
                                        {t('clients.billing_accounts.fields.billing_address')}
                                    </Typography>
                                    <Card className={classes.paper}>
                                        {!isEmptyText(initialData.billing_address?.street) && (
                                            <CardContent>
                                                <Box>
                                                    <Typography variant="body1">
                                                        {initialData.billing_address.contact_name}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {initialData.billing_address.email}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {initialData.billing_address.street} {initialData.billing_address.number}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {initialData.billing_address.postal_code} {initialData.billing_address.city}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {initialData.billing_address.state}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {initialData.billing_address.country}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        )}
                                        <CardActions>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleClickOpen}
                                                startIcon={<EditIcon />}>
                                                {t((!!initialData.billing_address && !!billingId) ? 'clients.billing_accounts.fields.edit_billing_address' : 'clients.billing_accounts.fields.add_billing_address')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="form-dialog-title">
                                        <DialogTitle>
                                            {t(
                                                'clients.billing_accounts.fields.billing_address'
                                            )}
                                        </DialogTitle>
                                        <DialogContent>
                                            <Form>
                                                <AddressFields name="billing_address" />
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    disabled={isSubmitting}>
                                                    {t(
                                                        billingId ? 'actions.update' : 'actions.create'
                                                    )}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleClose}
                                                    className={classes.btn}>
                                                    {t('actions.cancel')}
                                                </Button>
                                            </Form>
                                        </DialogContent>
                                    </Dialog>
                                </>
                            )}
                        </Formik>
                    )}
                />
            )}
        </>
    );
}

export default withStyles(styles)(BillingAddressFormContainer);
