export const AuthStates = Object.freeze({
    Idle: 'idle',
    LoggedIn: 'logged_in',
    LoggedOut: 'logged_out',
});

export const Permissions = Object.freeze({
    Owner: 'owner',
    Editor: 'editor',
    Viewer: 'viewer',
});

export const Roles = Object.freeze({
    Admin: 'admin',
    User: 'user',
    Guest: 'guest',
});

export const CRUD = Object.freeze({
    All: 'all',
    Create: 'create',
    Read: 'read',
    Update: 'update',
    SoftDelete: 'soft_delete',
    Delete: 'delete',
});

export const DocStatus = Object.freeze({
    Active: 'active',
    SoftDeleted: 'soft_delete',
    Disabled: 'disabled',
});

export const UserManagementActionsType = Object.freeze({
    ResetPassword: 'resetPassword',
    RecoverEmail: 'recoverEmail',
    VerifyEmail: 'verifyEmail',
});
