import React, { useCallback, useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Link,
    Box,
    Paper,
    Icon
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import {
    ROUTE_PASSWORD_RESET,
    ROUTE_REGISTER,
} from '../../../core/routing/routing';
import * as yup from 'yup';
import {
    loginWithCredentials,
    loginWithGoogle,
} from '../../../core/modules/auth/actions';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '../../../core/networking/utils';
import { t } from '../../../core/i18n';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as GoogleLogo } from 'assets/images/google-logo.svg';
import TextField from '../../shared/form/generic/TextField';
import { Formik, Form } from 'formik';

// let's keep this out of the render to avoid useMemo
const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
    password: yup
        .string()
        .min(8)
        .required(),
});

const svgIcon = (
    <Icon>
        <GoogleLogo />
    </Icon>
);

const styles = () => ({
    paper: {
        padding: 30,
    },
    loginFooter: {
        color: 'white',
        padding: 30,
    },
    linkWhite: {
        '&:after': {
            backgroundColor: 'white',
        },
    },
    btn: {
        margin: '1em 0',
    },
});

const LoginScene = ({ classes }) => {
    // TODO classes won't be passed!!
    const dispatch = useDispatch();

    const [initialData, setInitialData] = useState({ email: '', password: '' });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setInitialData({});
    }, []);

    const onSubmit = useCallback(
        data => {
            // set as loading
            setLoading(true);
            // try to log in
            dispatch(loginWithCredentials(data.email, data.password)).catch(
                error => {
                    // not loading anymore
                    setLoading(false);
                    // show error
                    /*if (
                        error.code === 'auth/user-not-found' ||
                        error.code === 'auth/wrong-password'
                    ) {
                        setError(t('onboarding.login.errors.auth'));
                    } else {*/
                    //setError(getErrorMessage(error));
                    //}
                    console.log(error)
                }
            );
        },
        [dispatch]
    );

    const onLoginWithGoogle = useCallback(() => {
        // try to log in
        dispatch(loginWithGoogle()).catch(error => {
            // show error
            setError(getErrorMessage(error));
        });
    }, [dispatch]);

    return (
        <div>
            <Paper className={classes.paper}>
                <Typography component="p" variant="h6">
                    {t('onboarding.login.title')}
                </Typography>
                {initialData ? (
                    <Formik
                        initialValues={initialData}
                        validationSchema={LoginSchema}
                        onSubmit={onSubmit}>
                        {({ errors, touched, isValid }) => (
                            <Form>
                                <TextField
                                    label={t('onboarding.login.form.email')}
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                />
                                <TextField
                                    label={t('onboarding.login.form.password')}
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                />
                                {error && (
                                    <Alert severity="error">{error}</Alert>
                                )}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                    className={classes.btn}
                                    disableElevation>
                                    {t('onboarding.login.form.submit')}
                                </Button>
                                <Typography>
                                    <Link
                                        component={RouterLink}
                                        to={ROUTE_PASSWORD_RESET}
                                        className={classes.errorLink}
                                        underline="none">
                                        {t('onboarding.password_reset.title')}
                                    </Link>
                                </Typography>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </Paper>

            <Box className={classes.loginFooter}>
                <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    onClick={onLoginWithGoogle}
                    disableElevation
                    startIcon={svgIcon}
                    className={classes.btn}>
                    {t('onboarding.login.login_with_google')}
                </Button>
                <Typography variant="body1" gutterBottom>
                    {t('onboarding.login.no_account')}
                </Typography>
                <Link
                    component={RouterLink}
                    to={ROUTE_REGISTER}
                    variant="body1"
                    color="inherit"
                    underline="none"
                    className={classes.linkWhite}>
                    {t('onboarding.login.register')}
                </Link>
            </Box>
        </div>
    );
};

export default withStyles(styles)(LoginScene);
